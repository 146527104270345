import ReactIntercom from 'react-intercom';
import { useAuthContext } from '@/context/AuthContext';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const Intercom = () => {
    const { isAuthenticatedAs } = useAuthContext();
    const { data: user } = useUserQuery();

    const intercomProps = {
        appID:
            user?.language === 'fr' ? process.env.INTERCOM_TOKEN_FR : process.env.INTERCOM_TOKEN_EN,
        user_id: user?.id || null,
        user_hash: user?.intercomToken || null,
        email: user?.email || null,
        name: `${user?.firstname || ''} ${user?.lastname || ''}`,
        isLoggedAs: isAuthenticatedAs,
    };

    if (isAuthenticatedAs || !intercomProps.isTrackedInIntercom) return null;
    return <ReactIntercom {...intercomProps} />;
};

export default Intercom;
