import styled, { css, keyframes } from 'styled-components';
import ShowSelector from './ShowSelector';

const NavigationMenuSkeleton = () => {
    return (
        <NavigationMenu>
            <ShowSelector />
            <Navigation>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
            </Navigation>
            <RightBlockWrapper>
                <Block />
            </RightBlockWrapper>
        </NavigationMenu>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const NavigationMenu = styled.div`
    height: 4rem;
    display: flex;
    justify-content: space-between;
    background: white;
    display: flex;
    align-items: center;
    gap: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        gap: 0.5rem;
    }
`;
const NavigationItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.25rem;
`;
const NavigationItemIcon = styled.div`
    width: 2rem;
    height: 2rem;
    background-color: var(--neutral50);
    border-radius: var(--r-s);
    ${shimmering};
`;
const NavigationItemText = styled.div`
    width: 2rem;
    height: 0.375rem;
    background-color: var(--neutral50);
    border-radius: var(--r-xs);
    ${shimmering};
`;
const Navigation = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
    flex-grow: 1;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        column-gap: 2.5rem;
    }

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        column-gap: 5.5rem;
    }
`;
const RightBlockWrapper = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        flex-grow: 1;
        padding-right: 1rem;
        padding-block: 0.75rem;
        align-self: stretch;
        max-width: 280px;
        flex-shrink: 0;
        min-width: 0;
    }
`;
const Block = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
        flex-shrink: 0;
        width: 5rem;
        border-radius: var(--r-s);
        background-color: var(--neutral50);
    }
`;

export default NavigationMenuSkeleton;
