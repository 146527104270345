import { useLocation } from 'react-router';
import styled from 'styled-components';

interface SidebarItemProps {
    children: React.ReactNode;
    isActive?: boolean;
    isDisabled?: boolean;
    className?: string;
    path?: string;
}

const SidebarItem = ({
    children,
    path,
    isActive = false,
    isDisabled = false,
    className,
}: SidebarItemProps) => {
    const location = useLocation();
    const isCurrent =
        path && location.pathname.includes(path.split('/')[path.split('/').length - 1]);

    return (
        <ListItem
            data-active={isActive || isCurrent}
            data-disabled={isDisabled}
            className={className}
        >
            {children}
        </ListItem>
    );
};

const ListItem = styled.li<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--black);
    flex-shrink: 0;
    /* 0.25rem spacing + 0.125rem from indicator */
    padding-bottom: 0.375rem;

    @media (min-width: 64rem) {
        /* 0.5rem + 0.25rem from indicator */
        padding-left: 0.725rem;
        padding-bottom: 0;
    }

    &:hover:not([data-disabled='true']) {
        color: var(--primary);

        & > * {
            color: var(--primary);
        }
    }

    &[data-active='true'] {
        color: var(--primary);
        position: relative;

        & > * {
            color: var(--primary);
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 0.125rem;
            width: 100%;
            background-color: var(--primary);
            border-radius: var(--r-full);

            @media (min-width: 64rem) {
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 0.25rem;
                height: 1rem;
            }
        }
    }

    &[data-disabled='true'] {
        opacity: 0.5;
    }

    & > svg {
        width: 1rem;
        height: 1rem;
    }
`;

export default SidebarItem;
