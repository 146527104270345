import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchSubscription } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import subscriptionKeys from '@/queries/subscription/subscriptionKeys';
import { queryClient } from '@/components/Root';

export type Pricing = 'freemium' | 'launch' | 'boost' | 'supersonic' | 'enterprise';

export interface Subscription {
    activated: boolean;
    archivedShowsMax: number;
    endsAt?: Date;
    extraItems: any[];
    extraShows: number;
    id: string;
    incomplete: boolean;
    isFreemium: boolean;
    pastDue: boolean;
    pricing: Pricing;
    quantity: number;
    showsMax: number;
    yearly: boolean;
    currentPeriodStart: Date;
    currentPeriodEnd: Date;
    prices: {
        base: string;
        extra: string;
    };
    discount?: string;
    currency: 'eur' | 'gbp' | 'usd';
}

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const queryFn = async () => {
    const { data } = await fetchSubscription();
    return transform(data);
};

const useSubscriptionQuery = (options?: any): UseQueryResult<Subscription> => {
    return useQuery({
        queryKey: subscriptionKeys.detail(),
        queryFn,
        ...options,
    });
};

export const prefetchSubscriptionQuery = async () => {
    return await queryClient.prefetchQuery({
        queryKey: subscriptionKeys.detail(),
        queryFn,
    });
};

export default useSubscriptionQuery;
