import { FormattedMessage } from 'react-intl';
import LoginSideIllustration from '../../LoginSideIllustration';
import AushaLogo from '@ui/atoms/AushaLogo';
import Form from './Form';
import styled from 'styled-components';

const PasswordRecovery = () => {
    return (
        <PasswordRecoveryWrapper>
            <Left>
                <InnerLeft>
                    <Header>
                        <Logo isComplete isColored />
                        <Title>
                            <FormattedMessage defaultMessage="Réinitialisation du mot de passe" />
                        </Title>
                        <Subtitle>
                            <FormattedMessage defaultMessage="Veuillez saisir votre nouveau mot de passe." />
                        </Subtitle>
                    </Header>
                    <Form />
                </InnerLeft>
            </Left>
            <Right>
                <InstructionsInnerWrapper>
                    <LoginSideIllustration />
                </InstructionsInnerWrapper>
            </Right>
        </PasswordRecoveryWrapper>
    );
};

const PasswordRecoveryWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;
    min-height: 100%;
    background-color: var(--white);

    & > * {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
    }
`;
const Container = styled.div`
    align-self: stretch;
    padding: 1.5rem;
    overflow: hidden;
    max-width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        padding-inline: 4rem;
        padding-top: 4rem;
        padding-bottom: 0;
    }
`;
const Left = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const InnerLeft = styled.div`
    width: 100%;
    max-width: 28rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Title = styled.h1`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-heading-m);
`;
const Subtitle = styled.p`
    color: var(--neutral);
`;
const Header = styled.div``;
const Logo = styled(AushaLogo)`
    height: 2rem;
    width: 7.25rem;
    margin-bottom: 2rem;
`;
const Right = styled(Container)`
    display: none;
    background: var(--gradient-aurora);
    border-radius: var(--r-xl);

    @media (min-width: 900px) {
        display: block;
        padding-bottom: 4rem;
    }
`;
const InstructionsInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export default PasswordRecovery;
