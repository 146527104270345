import { useEffect, useMemo, useState } from 'react';
import Color from 'color';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import QRCode from 'qrcode.react';
import { useSwitch } from '@/shared/hooks/useSwitch';
import Stack from '@ui/layout/Stack';
import Flex from '@ui/layout/Flex';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import IconPlay from '@ui/icons/IconPlay';
import IconDownload from '@ui/icons/IconDownload';
import IconHelp from '@ui/icons/IconHelp';
import Cluster from '@ui/layout/Cluster';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import TabletOrDesktop from '@ui/molecules/TabletOrDesktop';
import TooltipHover from '@ui/smarties/TooltipHover';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import useDroppable from '@/shared/hooks/useDroppable';
import HideFor from '@/components/unorganized/HideFor';
import ClipTitleEditModal from './ClipTitleEditModal';
import { useOverlayTriggerState } from 'react-stately';
import Text from '@ui/atoms/Text';
import useUpdateClipTitle from './SucceededClipFormat/useUpdateClipTitle.hook';
import { CLIP_FORMATS } from '@/shared/config/constants';
import ClipFormatMenu from './ClipFormatMenu/ClipFormatMenu';
import IconButton from '@ui/atoms/IconButton';
import IconMoreHorizontal from '@ui/icons/IconMoreHorizontal';
import DeleteClipFormatModal from './DeleteClipFormatModal';

import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const ClipCover = styled(motion.img).attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 120px;
    max-width: 100%;
    border-radius: var(--r-xs);
`;

const EpisodeCover = styled.img.attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 1.5rem;
    max-width: 1.5rem;
    border-radius: var(--r-xs);
`;

const ClipFormatMenuButton = styled(IconButton)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: transparent;

    &:hover {
        background-color: var(--white200);
    }
`;

const ClipOpenButtonStyled = styled(motion.button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--white200);
    border-radius: var(--r-full);
    border: none;
    cursor: pointer;
    &:hover {
        background-color: var(--white300);
    }
`;
const ClipLabel = styled(motion.div)`
    position: absolute;
    padding: 2px 4px;
    background-color: rgba(38, 33, 43, 0.75);
    border-radius: var(--r-full);
`;
const ClipDurationLabelContainer = styled(ClipLabel)`
    bottom: 0.5rem;
    right: 0.5rem;
`;
const ClipFormatLabelContainer = styled(ClipLabel)`
    bottom: 0.5rem;
    left: 0.5rem;
`;
const Video = styled.video`
    width: 100%;
    max-width: 320px;
    border-radius: var(--r-xs);
    display: block;
    align-self: flex-start;
`;

const ClipOpenButton = ({ onClick }) => (
    <ClipOpenButtonStyled onClick={onClick}>
        <IconPlay color="white" />
    </ClipOpenButtonStyled>
);

const ClipFormatEpisode = ({ episode }) => (
    <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
        {episode.imageUrl && <EpisodeCover src={episode.imageUrl} />}
        <DeprecatedText color="--neutral500" ellipsis>
            {episode.name}
        </DeprecatedText>
    </Cluster>
);

const ClipFormatLabelName = ({ format }) => {
    const formatMatch = {
        [CLIP_FORMATS.SQUARE]: <FormattedMessage defaultMessage="Carré" />,
        [CLIP_FORMATS.STORY]: <FormattedMessage defaultMessage="Story" />,
        [CLIP_FORMATS.LANDSCAPE]: <FormattedMessage defaultMessage="Paysage" />,
    };

    return formatMatch[format];
};

const ClipFormatLabel = ({ format }) => (
    <ClipFormatLabelContainer
        variants={{
            hover: {
                opacity: 0,
            },
            idle: {
                opacity: 1,
            },
        }}
    >
        <Text color="white" variant="footnote" numberOfLines={1}>
            <ClipFormatLabelName format={format} />
        </Text>
    </ClipFormatLabelContainer>
);

const ClipDurationLabel = ({ duration }) => (
    <ClipDurationLabelContainer
        variants={{
            hover: {
                opacity: 0,
            },
            idle: {
                opacity: 1,
            },
        }}
    >
        <Text color="white" variant="footnote" numberOfLines={1}>
            <FormattedMessage
                defaultMessage="{seconds} sec"
                values={{ seconds: Math.round(duration) }}
            />
        </Text>
    </ClipDurationLabelContainer>
);

const SelectedClipFormatModal = ({ clipFormat, isOpened, onClose }) => {
    const intl = useIntl();

    useEffect(() => {
        if (!isOpened || !clipFormat.url) return;
        sendAmplitudeLogEvent('Video Clip Viewed');
    }, [isOpened, clipFormat]);

    if (!clipFormat.url) {
        return null;
    }

    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            title={clipFormat.clip.adminTitle || clipFormat.clip.title}
            maxW={600}
        >
            <Cluster $gap="2rem" $align="stretch" $justify="center">
                <Video controls poster={clipFormat.previewUrl}>
                    <source src={clipFormat.url} type="video/mp4" />
                </Video>
                <Flex $gap="1rem" $flexDir="column" $align="flex-start" $justify="space-between">
                    <TabletOrDesktop>
                        <Stack $gap="0.5rem" $align="center">
                            <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
                                <DeprecatedText weight="semibold">
                                    <FormattedMessage defaultMessage="Partager sur mobile" />
                                </DeprecatedText>
                                <TooltipHover
                                    arrow="left"
                                    target={<IconHelp size="1.25rem" color="--neutral200" />}
                                >
                                    <DeprecatedText>
                                        <FormattedMessage defaultMessage="Scannez ce QR Code pour partager directement le clip où vous le souhaitez." />
                                    </DeprecatedText>
                                    <DeprecatedText
                                        color="--primary"
                                        weight="semibold"
                                        as="a"
                                        target="_blank"
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://aide.ausha.co/fr/articles/5159114-comment-creer-personnaliser-et-telecharger-le-clip-video-d-un-episode',
                                        })}
                                    >
                                        <FormattedMessage defaultMessage="Visionner le tutoriel" />
                                    </DeprecatedText>
                                </TooltipHover>
                            </Cluster>
                            <QRCode value={clipFormat.url} renderAs="svg" size={150} />
                        </Stack>
                    </TabletOrDesktop>
                    <DeprecatedButton
                        as="a"
                        href={clipFormat.url}
                        target="_blank"
                        onClick={() => sendAmplitudeLogEvent('Video Clip Downloaded')}
                    >
                        <IconDownload />
                        <FormattedMessage defaultMessage="Télécharger la vidéo" />
                    </DeprecatedButton>
                </Flex>
            </Cluster>
        </DeprecatedModal>
    );
};

const SucceededClipFormat = ({ clipFormat, episode }) => {
    const [isHovered, setHovered] = useState(false);
    const [isSelectedModalOpened, openSelectedModal, closeSelectedModal] = useSwitch(false);
    const [refMenu, isMenuOpened, openMenu] = useDroppable();
    let deleteClipModal = useOverlayTriggerState({});
    let clipEditTitleModalState = useOverlayTriggerState({});
    const updateClipTitle = useUpdateClipTitle();

    const openMenuHandler = (event) => {
        event.stopPropagation();
        openMenu();
    };
    const openDeleteModalHandler = (event) => {
        event.stopPropagation();
        deleteClipModal.open();
    };
    const openClipEditTitleModal = (event) => {
        event.stopPropagation();
        clipEditTitleModalState.open();
    };
    // Compute background color based on clip color
    const bgColor = useMemo(() => {
        try {
            return Color(clipFormat.clip.color).alpha(0.07).string();
        } catch (error) {
            // Unable to parse color
            return null;
        }
    }, [clipFormat.clip.color]);

    const onEditClipTitle = (adminTitle) => {
        updateClipTitle.mutate(
            {
                clipId: clipFormat.clip.id,
                adminTitle,
            },
            {
                onSuccess: () => {
                    clipFormat.setAdminTitle(adminTitle);
                    clipEditTitleModalState.close();
                },
            },
        );
    };

    return (
        <Stack $gap="0.75rem">
            <Flex
                $pos="relative"
                $minH={152}
                $px="0.625rem"
                $py="1rem"
                $align="center"
                $justify="center"
                $bgColor={bgColor}
                $borderRadius="--r-xs"
                $overflow="hidden"
                as={motion.div}
                initial="idle"
                animate={isHovered ? 'hover' : 'idle'}
                onHoverStart={() => setHovered(true)}
                onHoverEnd={() => setHovered(false)}
            >
                <div>
                    {clipFormat.previewUrl && (
                        <ClipCover
                            src={clipFormat.previewUrl}
                            variants={{
                                hover: {
                                    filter: 'blur(30px)',
                                },
                                idle: {
                                    filter: 'blur(0px)',
                                },
                            }}
                        />
                    )}
                </div>
                <ClipFormatLabel format={clipFormat.format} />
                <ClipDurationLabel duration={clipFormat.clip.duration} />
                <AnimatePresence>
                    {isHovered && (
                        <Flex
                            key="clip-hover-container"
                            $pos="absolute"
                            $top={0}
                            $right={0}
                            $bottom={0}
                            $left={0}
                            $bgColor="rgba(0, 0, 0, 0.5)"
                            $align="center"
                            $justify="center"
                            as={motion.div}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={openSelectedModal}
                            $cursor="pointer"
                        >
                            <HideFor roles={['viewer']}>
                                <ClipFormatMenuButton
                                    variant="ghost"
                                    icon={<IconMoreHorizontal color="--white" />}
                                    onClick={openMenuHandler}
                                    background="transparent"
                                    isRound
                                ></ClipFormatMenuButton>
                                <ClipFormatMenu
                                    isOpen={isMenuOpened}
                                    onOpenClipEditTitleModal={openClipEditTitleModal}
                                    onOpenDeleteModal={openDeleteModalHandler}
                                    ref={refMenu}
                                />
                            </HideFor>
                            <ClipOpenButton onClick={openSelectedModal} />
                        </Flex>
                    )}
                </AnimatePresence>
            </Flex>
            <Stack $gap="0.25rem">
                <Text numberOfLines={1} fontWeight="--fw-semibold">
                    {clipFormat.clip.adminTitle || clipFormat.clip.title}
                </Text>
                {episode && <ClipFormatEpisode episode={episode} />}
            </Stack>
            <SelectedClipFormatModal
                clipFormat={clipFormat}
                isOpened={isSelectedModalOpened}
                onClose={closeSelectedModal}
            />
            {deleteClipModal.isOpen && (
                <DeleteClipFormatModal
                    clipFormat={clipFormat}
                    isOpen={deleteClipModal.isOpen}
                    onClose={deleteClipModal.close}
                />
            )}
            {clipEditTitleModalState.isOpen && (
                <ClipTitleEditModal
                    isOpen={clipEditTitleModalState.isOpen}
                    modalState={clipEditTitleModalState}
                    onClose={() => clipEditTitleModalState.close()}
                    value={clipFormat.clip.adminTitle || clipFormat.clip.title}
                    onEdit={onEditClipTitle}
                    clipFormat={clipFormat}
                    isDisabled={updateClipTitle.isLoading}
                />
            )}
        </Stack>
    );
};

export default SucceededClipFormat;
