import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchUser } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import userKeys from '@/queries/user/userKeys';
import { queryClient } from '@/components/Root';
import { useAuthContext } from '@/context/AuthContext';

export interface User {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    language: string;
    avatar: string;
    timezone: string;
    country: string;
    currency: string;
    pricing: string;
    showsMax: number;
    numberInvitations: number;
    archivedShowsMax: number;
    activated: boolean;
    maximumTrialEpisodes: number;
    emailVerifiedAt: Date;
    trialEndsAt: Date;
    createdAt: Date;
    updatedAt: Date;
    deletedAtNotNull: Date;
    pmType: string;
    pmLastFour: string;
    external: boolean;
    links: {
        self: string;
        userUpdate: string;
        passwordUpdate: string;
        emailUpdate: string;
        avatarUpdate: string;
    };
    canArchiveShows: boolean;
    canHostOnAusha: boolean;
    guest: boolean;
    intercomToken: string;
    isTrackedInIntercom: boolean;
    options: string[];
    showsCount: number;
    surveys: string[];
    trialEpisodes: number;
}

type InputProps = {
    enabled?: boolean;
};

const transform = (data: unknown) => {
    return camelcaseKeys(data as any, { deep: true });
};

const queryFn = async () => {
    const { data } = await fetchUser();

    // TODO: Do camelcase transformation in Axios interceptor
    return transform(data);
};

const useUserQuery = (options?: InputProps): UseQueryResult<User> => {
    const { isAuthenticated } = useAuthContext();
    return useQuery({
        queryKey: userKeys.detail(),
        queryFn,
        ...options,
        enabled: (options?.enabled || true) && isAuthenticated,
    });
};

export const prefetchUserQuery = async () => {
    return await queryClient.fetchQuery({
        queryKey: userKeys.detail(),
        queryFn,
    });
};

export default useUserQuery;
