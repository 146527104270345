export const trim = (string: string, maxLength: number) => {
    if (string.length <= maxLength) {
        return string;
    }

    // Find the last space before the maximum length.
    const lastSpaceIndex = string.lastIndexOf(' ', maxLength - 1);

    // If there is no space before the maximum length, return a substring of the string up to the maximum length.
    if (lastSpaceIndex === -1) {
        return string.substring(0, maxLength);
    }

    // Return a substring of the string up to the last space before the maximum length.
    return string.substring(0, lastSpaceIndex);
};

export const slugify = (text) =>
    text
        .toString() // Cast to string
        .normalize('NFKD') // Convert accents
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Remove - at the start
        .replace(/-+$/, ''); // Remove - at the end

export const getPlainText = (html) => {
    // TODO: Using a different technique than Tiptap to compute text length could
    // result to a different result. Maybe use Tiptap directly to have the same count
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    const textContent = tempElement.textContent;
    tempElement.remove();
    return textContent;
};
