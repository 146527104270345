import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchYoutubeBroadcastSettings = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/youtube` });
};
export const fetchYoutubeCategories = () => {
    return request({ method: 'GET', basePath, target: '/v1/youtube/categories' });
};
export const fetchYoutubeVideo = (podcast) => {
    return request({ method: 'GET', basePath, target: `/v1/podcasts/${podcast}/youtube` });
};
export const fetchSoundcloudBroadcastSettings = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/soundcloud` });
};
export const updateYoutubeBroadcastSettings = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/youtube`, body });
};
export const updateSoundcloudBroadcastSettings = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/soundcloud`, body });
};
