import Facebook from '@public/images/social/facebook.svg';
import FacebookRounded from '@public/images/social/facebook-rounded.svg';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import { isLoginProviderEnabled } from '@/shared/services/providerService';
import { useLinkLoginProviderWithPopup } from '@/shared/hooks/useLinkLoginProviderWithPopup';
import useThirdPartyFacebookLoginMutation from '@/queries/auth/useThirdPartyFacebookLoginMutation.hook';
import { LOGIN_PROVIDER } from '@/shared/config/constants';
import styled from 'styled-components';

interface FacebookButtonProps {
    rounded?: boolean;
    label?: React.ReactNode;
    dataLayerEvent?: string | null;
}

const FacebookButton = ({ rounded = false, label, dataLayerEvent = null }: FacebookButtonProps) => {
    const loginWithThirdParty = useThirdPartyFacebookLoginMutation({ dataLayerEvent });

    const { openWindow } = useLinkLoginProviderWithPopup('facebook', (e: any) => {
        loginWithThirdParty.mutate({
            code: e.data.code,
        });
    });
    return (
        <Tooltip
            variant="dark"
            passthrough={isLoginProviderEnabled(LOGIN_PROVIDER.FACEBOOK)}
            position="top"
            content={
                <FormattedMessage defaultMessage="Oups, la connexion via Facebook est actuellement indisponible. Nos équipes font le nécessaire pour réparer cela au plus vite. 💪" />
            }
        >
            <CustomButton
                isDisabled={!isLoginProviderEnabled(LOGIN_PROVIDER.FACEBOOK)}
                onPress={() => openWindow()}
            >
                <ButtonInner>
                    {rounded ? <FacebookRounded /> : <Facebook />}
                    {label || 'Facebook'}
                </ButtonInner>
            </CustomButton>
        </Tooltip>
    );
};

const CustomButton = styled(Button)`
    width: 100%;
    color: var(--black);
    background: var(--white);
    box-shadow: none;
    transition: all 350ms;
    border: 1px solid var(--neutral200);

    &:hover {
        background: var(--neutral100);
    }

    & path {
        fill: var(--facebook);
    }

    & svg {
        width: 1rem;
        height: auto;
    }
`;

const ButtonInner = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

const Tooltip = styled(UiKitTooltip)`
    width: auto;
`;

export default FacebookButton;
