import { z } from 'zod';

const useKeywordsRankingFormSchema = () => {
    return z.object({
        platform: z.enum(['itunes', 'spotify']),
        store: z.string(),
    });
};

export type KeywordsRankingForm = z.infer<ReturnType<typeof useKeywordsRankingFormSchema>>;
export default useKeywordsRankingFormSchema;
