import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const deleteCallToActionButton = (cta) => {
    return request({ method: 'DELETE', basePath, target: `/v1/ctas/${cta}` });
};
export const updateCallToActionButtons = (cta, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/ctas/${cta}`, body });
};
export const updateCallToActionButtonsPositions = (show, body) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/shows/${show}/ctas/positions`,
        body,
    });
};
export const fetchCallToActionButtons = (show, location) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${show}/ctas`,
        query: { location },
    });
};
export const addCallToActionButton = (show, body) => {
    return request({ method: 'POST', basePath, target: `/v1/shows/${show}/ctas`, body });
};
