import dayjs from 'dayjs';
import { timeToArray } from '@/shared/utils/time';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(duration);

export function formatDuration(
    seconds,
    { h = 'h', mn = 'mn', sec = 'sec', join = ' ', full = false, initialValue = false } = {},
) {
    let [hours, minutes, secs] = timeToArray(seconds);

    const strings = [];
    if (hours > 0 || full) {
        strings.push(`${hours}${h}`);
    }
    if (minutes > 0 || full) {
        minutes = hours > 0 || full ? `${minutes}`.padStart(2, '0') : minutes;
        strings.push(`${minutes}${mn}`);
    }
    if (hours === 0 || full) {
        secs = minutes > 0 || full ? `${secs}`.padStart(2, '0') : secs;
        strings.push(`${secs}${sec}`);
    }

    if (!strings.join(join) && initialValue) return seconds;
    return strings.join(join);
}

export function formatDurationParts(seconds) {
    let [hours, minutes, secs] = timeToArray(seconds);

    const values = [];
    if (hours > 0) {
        values.push({ unit: 'hours', value: hours, shortunit: 'h' });
    }
    if (minutes > 0) {
        minutes = hours > 0 ? `${minutes}`.padStart(2, '0') : minutes;
        values.push({ unit: 'minutes', value: minutes, shortunit: 'mn' });
    }
    if (hours === 0) {
        seconds = minutes > 0 ? `${secs}`.padStart(2, '0') : secs;
        values.push({ unit: 'seconds', value: seconds, shortunit: 'sec' });
    }

    return values;
}

export function formatSecondsDuration(duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

/**
 * Duration string format can be a number of seconds, or "mm:ss", or "hh:mm:ss"
 */
export function durationToSeconds(duration) {
    const [, h, m, s] = duration.match(/(?:(?:(\d+):)?(\d+):)?(\d+)$/) || [];
    return dayjs.duration({ h, m, s }).asSeconds();
}

export function secondsToDuration(seconds) {
    return dayjs.utc(seconds * 1000).format(seconds >= 3600 ? 'HH:mm:ss' : 'mm:ss');
}
