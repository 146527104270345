import { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { isTimeUnitValid, TIME_UNIT, wrapTimeAtUnit } from '@/shared/utils/time';
import Stack from '@ui/layout/Stack';
import Text from '../Text';

const formatDuration = (duration, numberOfDefaultZero = 2) =>
    `${duration}`.padStart(numberOfDefaultZero, '0');

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    background: white;
    border: 1px solid var(--neutral200);
    border-radius: var(--r-xs);
    padding: 0.5rem;
    width: 100%;

    &:hover {
        border: 1px solid var(--neutral500);
    }

    &:active,
    &:focus-within {
        border: 1px solid var(--primary);
    }

    &:isdisabled {
        color: var(--neutral50);
    }
`;
const UnitsWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;
const UnitInput = styled.input.attrs(({ size, maxLength }) => ({
    size: size || 2,
    maxLength: maxLength || 2,
}))`
    background: transparent;
    border: 0;
    border-radius: var(--r-xs);
    color: var(--black);
    font-size: var(--fs-body);
    outline: 0;
    padding: 0;
    text-align: center;
    width: calc(2ch + 0.25rem);

    &:isdisabled {
        color: var(--neutral50);
    }
`;
const EndIconWrapper = styled.div`
    display: flex;
    margin-left: auto;
`;
const Divider = styled.span`
    color: ${(props) => (props.isDisabled ? 'var(--neutral500)' : 'var(--black)')};
    font-size: var(--fs-body);
    text-align: center;
`;
// TODO: Refactor this component
const DurationInput = ({
    value,
    onChange,
    isDisabled,
    label,
    startIcon,
    endIcon,
    showMilliseconds,
    max,
}) => {
    // Internal Moment Duration computed based on value prop in seconds
    const [computedDuration, setComputedDuration] = useState(moment.duration({ seconds: value }));
    const [hoursText, setHoursText] = useState(formatDuration(computedDuration.hours()));
    const [minutesText, setMinutesText] = useState(formatDuration(computedDuration.minutes()));
    const [secondsText, setSecondsText] = useState(formatDuration(computedDuration.seconds()));
    const [millisecondsText, setMillisecondsText] = useState(
        formatDuration(computedDuration.milliseconds(), 3),
    );

    useEffect(() => {
        setComputedDuration(moment.duration({ seconds: value }));
    }, [value]);

    useEffect(() => {
        setHoursText(formatDuration(computedDuration.hours()));
        setMinutesText(formatDuration(computedDuration.minutes()));
        setSecondsText(formatDuration(computedDuration.seconds()));
        setMillisecondsText(formatDuration(Math.floor(computedDuration.milliseconds()), 3));
    }, [computedDuration]);

    const updateDuration = (duration, unit) => {
        if (isTimeUnitValid(unit, duration)) {
            // Moment Duration doesn't have setters so previous duration is copied then
            // unit updated is overridden with new value.
            // Milliseconds are voluntary omitted because they are not editable in this input.
            const newDuration = moment.duration({
                milliseconds: computedDuration.milliseconds(),
                seconds: computedDuration.seconds(),
                minutes: computedDuration.minutes(),
                hours: computedDuration.hours(),
                [unit]: duration,
            });
            const newDurationInSeconds = newDuration.asSeconds();

            if (max && Number(max) && newDurationInSeconds > max) {
                newDuration.subtract(newDurationInSeconds - max, 'seconds');
            }
            setComputedDuration(newDuration);
            onChange(newDuration.asSeconds());
            // DurationInput is a controlled input but, due to time validation, onChange will not always change the value prop.
            // To prevent DurationInput to become out of sync (internal state keeps changing but value prop stay the same and
            // doesn't trigger a new duration computation), we force a computation of the internal duration.
            setComputedDuration(moment.duration({ seconds: value }));
        } else {
            setComputedDuration(computedDuration.clone());
        }
    };

    const shiftDuration = (unit, amount) => {
        if (isDisabled) {
            return;
        }
        const newDuration = computedDuration.get(unit) + amount;
        updateDuration(wrapTimeAtUnit(unit, newDuration), unit);
    };

    const incrementDuration = (unit) => shiftDuration(unit, 1);

    const decrementDuration = (unit) => shiftDuration(unit, -1);

    const getInputBlurHandler = (unit) => (event) => {
        const text = event.target.value;
        updateDuration(parseInt(text, 10), unit);
    };

    const getInputChangeHandler = (unit) => (event) => {
        const text = event.target.value;
        switch (unit) {
            case TIME_UNIT.HOUR:
                setHoursText(text);
                break;
            case TIME_UNIT.MINUTE:
                setMinutesText(text);
                break;
            case TIME_UNIT.SECOND:
                setSecondsText(text);
                break;
            case TIME_UNIT.MILLISECOND:
                setMillisecondsText(text);
                break;
            default:
                break;
        }
    };

    const inputFocusHandler = (event) => {
        event.currentTarget.select();
    };

    const getInputKeyDownHandler = (unit) => (event) => {
        if (event.defaultPrevented) {
            return; // Do nothing if the event was already processed
        }

        switch (event.key) {
            case 'Down': // IE/Edge specific value
            case 'ArrowDown':
                decrementDuration(unit);
                break;
            case 'Up': // IE/Edge specific value
            case 'ArrowUp':
                incrementDuration(unit);
                break;
            case 'Enter':
                event.currentTarget.blur();
                break;
            default:
                return; // Quit when this doesn't handle the key event.
        }

        // Cancel the default action to avoid it being handled twice
        event.preventDefault();
    };

    return (
        <Stack $gap="0.5rem">
            {label && <Text fontWeight="--fw-semibold">{label}</Text>}
            <InputWrapper isDisabled={isDisabled}>
                {startIcon}
                <UnitsWrapper>
                    <UnitInput
                        onBlur={getInputBlurHandler(TIME_UNIT.HOUR)}
                        onChange={getInputChangeHandler(TIME_UNIT.HOUR)}
                        onFocus={inputFocusHandler}
                        onKeyDown={getInputKeyDownHandler(TIME_UNIT.HOUR)}
                        value={hoursText}
                        isDisabled={isDisabled}
                    />
                    <Divider isDisabled={isDisabled}>:</Divider>
                    <UnitInput
                        onBlur={getInputBlurHandler(TIME_UNIT.MINUTE)}
                        onChange={getInputChangeHandler(TIME_UNIT.MINUTE)}
                        onFocus={inputFocusHandler}
                        onKeyDown={getInputKeyDownHandler(TIME_UNIT.MINUTE)}
                        value={minutesText}
                        isDisabled={isDisabled}
                    />
                    <Divider isDisabled={isDisabled}>:</Divider>
                    <UnitInput
                        onBlur={getInputBlurHandler(TIME_UNIT.SECOND)}
                        onChange={getInputChangeHandler(TIME_UNIT.SECOND)}
                        onFocus={inputFocusHandler}
                        onKeyDown={getInputKeyDownHandler(TIME_UNIT.SECOND)}
                        value={secondsText}
                        isDisabled={isDisabled}
                    />
                    {showMilliseconds && (
                        <>
                            <Divider isDisabled={isDisabled}>.</Divider>
                            <UnitInput
                                onBlur={getInputBlurHandler(TIME_UNIT.MILLISECOND)}
                                onChange={getInputChangeHandler(TIME_UNIT.MILLISECOND)}
                                onFocus={inputFocusHandler}
                                onKeyDown={getInputKeyDownHandler(TIME_UNIT.MILLISECOND)}
                                value={millisecondsText}
                                isDisabled={isDisabled}
                                size={3}
                                maxLength={3}
                                style={{ width: 'calc(3ch + 0.25rem)' }}
                            />
                        </>
                    )}
                </UnitsWrapper>
                <EndIconWrapper>{endIcon}</EndIconWrapper>
            </InputWrapper>
        </Stack>
    );
};

export default DurationInput;
