import { FC, useState, createContext, useContext, ReactNode } from 'react';
import { useHistory } from 'react-router';
import {
    setAuthorizationCookies,
    setAxiosAuthorizationHeader,
    removeAuthorizationCookies,
} from '@/shared/services/authorizationService';
import { pushLoginEventToGTM } from '@/shared/services/gtmService';
import { prefetchSubscriptionQuery } from '@queries/subscription/useSubscriptionQuery.hook';
import { prefetchUserQuery } from '@queries/user/useUserQuery.hook';
import { useQueryClient } from '@tanstack/react-query';
import { User } from '@/queries/user/useUserQuery.hook';
import { setUser as sentrySetUser, getCurrentScope as sentryGetCurrentScope } from '@sentry/react';
import * as amplitude from '@amplitude/analytics-browser';
import Cookies from 'js-cookie';
import type { LoginResponse } from '@/queries/auth/useLoginMutation.hook';
import type { RegisterResponse } from '@/queries/auth/useRegisterMutation.hook';
import type { LoginAsResponse } from '@/queries/auth/useLoginAsMutation.hook';
import type { ThirdPartyLoginResponse } from '@/queries/auth/useThirdPartyGoogleLoginMutation.hook';

type AuthResponse = LoginResponse | RegisterResponse | LoginAsResponse | ThirdPartyLoginResponse;

interface TAuthContext {
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    isAuthenticatedAs: boolean;
    setIsAuthenticatedAs: (isAuthenticatedAs: boolean) => void;
    login: (data: AuthResponse) => void;
    redirectToApp: () => void;
    logout: () => void;
    user: User;
    setUser: (user: User) => void;
    accessToken: string;
    setAccessToken: (accessToken: string) => void;
    accessTokenTTL: string;
    setAccessTokenTTL: (accessTokenTTL: string) => void;
    refreshToken: string;
    setRefreshToken: (refreshToken: string) => void;
}

const AuthContext = createContext<TAuthContext | undefined>(undefined);

interface AuthContextProviderProps {
    children: ReactNode;
}

export const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
    const history = useHistory();
    const queryClient = useQueryClient();

    const accessTokenCookie = Cookies.get('token') || '';
    const accessTokenTTLCookie = Cookies.get('token-ttl') || '';
    const refreshTokenCookie = Cookies.get('refresh') || '';

    const [isAuthenticated, setIsAuthenticated] = useState(!!accessTokenCookie);
    const [isAuthenticatedAs, setIsAuthenticatedAs] = useState(false);
    const [user, setUser] = useState({} as User);
    const [accessToken, setAccessToken] = useState(accessTokenCookie);
    const [accessTokenTTL, setAccessTokenTTL] = useState(accessTokenTTLCookie);
    const [refreshToken, setRefreshToken] = useState(refreshTokenCookie);

    const thirdPartiesLoginEventTrigger = (user: User) => {
        /**
         * Google Tag Manager
         */
        pushLoginEventToGTM();

        /**
         * Sentry
         */
        sentrySetUser({
            id: user.id,
            username: `${user.firstname} ${user.lastname}`,
            email: user.email,
        });

        /**
         * Amplitude
         */
        amplitude.setUserId(`${user.id}`);
        const identify = new amplitude.Identify();
        identify
            .set('lang', user.language)
            .set('pricing', user.pricing)
            .set('active', user.activated);
        amplitude.identify(identify);
    };

    const login = async (data: AuthResponse) => {
        const { accessToken, refreshToken, expiresIn: accessTokenTTL } = data;

        /**
         * Write token payload in axios headers & cookies
         */
        setAxiosAuthorizationHeader(accessToken);
        setAuthorizationCookies({ accessToken, accessTokenTTL, refreshToken });

        /**
         * Write token payload in app state (context)
         */
        setAccessToken(accessToken);
        setAccessTokenTTL(`${accessTokenTTL}`);
        setRefreshToken(refreshToken);

        /**
         * Prefetch user and subscription data
         */
        const userAndSubscription = await Promise.all([
            prefetchSubscriptionQuery(),
            prefetchUserQuery(),
        ]);

        /**
         * Inform third-parties about the login event
         */
        const user = userAndSubscription[1];
        thirdPartiesLoginEventTrigger(user);

        /**
         * Write user's preferred locale in local storage (for retro-compatibility with legacy stores)
         */
        localStorage.setItem('preferredLocale', user.language);

        /**
         * Redirect user to the app
         */
        setIsAuthenticated(true);
    };

    const redirectToApp = () => {
        history.push('/app');
    };

    const logout = () => {
        /**
         * Reset cookies
         */
        removeAuthorizationCookies();

        /**
         * Reset app state (context)
         */
        setAccessToken('');
        setAccessTokenTTL('');
        setRefreshToken('');
        setUser({} as User);
        setIsAuthenticated(false);

        /**
         * Reset TSQuery cache
         */
        queryClient.clear();

        /**
         * Inform third-parties about the logout event
         */
        amplitude.reset();
        sentryGetCurrentScope().clear();
    };

    const contextValue = {
        isAuthenticated,
        setIsAuthenticated,
        isAuthenticatedAs,
        setIsAuthenticatedAs,
        user,
        setUser,
        accessToken,
        setAccessToken,
        accessTokenTTL,
        setAccessTokenTTL,
        refreshToken,
        setRefreshToken,
        login,
        redirectToApp,
        logout,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within a AuthContextProvider');
    }
    return context;
};
