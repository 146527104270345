import { useQuery, QueryFunctionContext, UseQueryResult } from '@tanstack/react-query';
import { fetchPlayerIframeCode } from '@/api';
import playerIframeKeys from './playerIframeKeys';

export interface PlayerIframeSettings {
    showId: number;
    color?: string;
    width?: string;
    display?: string;
    multishow?: boolean;
    playlist?: string;
    dark?: boolean;
    playlistSlug?: string;
    t?: number;
    podcastId?: string;
}

const queryFn = async ({
    queryKey: [{ settings }],
}: QueryFunctionContext<ReturnType<typeof playerIframeKeys.detailBySettings>>) => {
    const data = await fetchPlayerIframeCode(settings);
    return data.axios.data;
};

const usePlayerIframeQuery = (settings: PlayerIframeSettings): UseQueryResult<string> => {
    return useQuery({
        queryKey: playerIframeKeys.detailBySettings(settings),
        queryFn,
        enabled: !!settings,
    });
};

export default usePlayerIframeQuery;
