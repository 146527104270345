import { request } from '../api';
import type { PlayerIframeSettings } from '@/queries/playerIframe/usePlayerIframeQuery.hook';

const basePath = process.env.API_PUBLIC as string;

export const fetchPlayerIframeCode = (query: PlayerIframeSettings) => {
    return request({
        method: 'GET',
        basePath,
        target: '/iframe',
        query,
    });
};
export const fetchShowBySlug = (slug: string) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/slug/${slug}`,
    });
};
