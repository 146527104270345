export const addQueryParam = (url: string, key: string, value: string): string => {
    try {
        const urlObj = new URL(url);
        urlObj.searchParams.get(key) && urlObj.searchParams.delete(key);
        urlObj.searchParams.append(key, value);
        return urlObj.toString();
    } catch (error) {
        console.error(error);
        return url;
    }
};
