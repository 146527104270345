import { useMutation } from '@tanstack/react-query';
import { setResetPassword } from '@/api';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    email: string;
}

const mutationFn = async (payload: MutationFnProps) => {
    const { data } = await setResetPassword(payload);
    return camelcaseKeys(data, { deep: true });
};

const useSendVerificationEmailMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default useSendVerificationEmailMutation;
