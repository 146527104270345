import styled, { css, keyframes } from 'styled-components';

interface ShowListSkeletonProps {
    layout: 'stack' | 'grid';
}

const ShowListSkeleton = ({ layout }: ShowListSkeletonProps) => {
    return (
        <List $layout={layout}>
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
            <Item $layout={layout} />
        </List>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const List = styled.ul<{ $layout: 'stack' | 'grid' }>`
    list-style: none;
    flex-grow: 1;
    overflow-y: hidden;
    min-height: 0px;
    width: 100%;

    ${({ $layout }) =>
        $layout === 'stack' &&
        css`
            display: flex;
            flex-direction: column;
            row-gap: 0.25rem;
            padding-top: 0.5rem;
            padding-bottom: 1rem;
            padding-inline: 0.5rem;
        `}

    ${({ $layout }) =>
        $layout === 'grid' &&
        css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0.5rem;
            padding: 1rem;
        `}


    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        max-height: 22.125rem;
    }
`;
const Item = styled.li<{ $layout: 'stack' | 'grid' }>`
    border-radius: var(--r-s);
    cursor: pointer;
    background-color: var(--neutral50);
    ${shimmering};

    ${({ $layout }) =>
        $layout === 'stack' &&
        css`
            height: 2.75rem;
            flex-shrink: 0;
        `}

    ${({ $layout }) =>
        $layout === 'grid' &&
        css`
            width: 4.5rem;
            height: 4.5rem;
        `}
`;

export default ShowListSkeleton;
