import { useEffect } from 'react';
import useQuery from '@/shared/hooks/useQuery';

interface ThirdPartyLoginCallbackProps {
    provider: string;
}

const ThirdPartyLoginCallback = ({ provider }: ThirdPartyLoginCallbackProps) => {
    const params = useQuery();
    const code = params.get('code');

    useEffect(() => {
        window.opener.postMessage({ code, provider }, window.location.origin);
        window.close();
    }, []);
    return null;
};

export default ThirdPartyLoginCallback;
