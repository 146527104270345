import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchEpisodeTranscript = (episodeId) => {
    return request({ method: 'GET', basePath, target: `/v1/podcasts/${episodeId}/transcript` });
};
export const fetchEpisodeTranscriptStatus = (episodeId) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/podcasts/${episodeId}/transcript/status`,
    });
};
export const generateEpisodeTranscript = (episodeId) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/podcasts/${episodeId}/transcript`,
    });
};
export const updateEpisodeTranscript = (episodeId, transcript) => {
    return request({
        method: 'PUT',
        basePath,
        target: `/v1/podcasts/${episodeId}/transcript`,
        body: transcript,
    });
};
export const deleteEpisodeTranscript = (episodeId) => {
    return request({ method: 'DELETE', basePath, target: `/v1/podcasts/${episodeId}/transcript` });
};
