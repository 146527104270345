import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useRegisterFormSchema = () => {
    const intl = useIntl();
    const nameRegex = /^[a-zA-Z0-9-À-ÿ]+$/;

    return z.object({
        firstname: z
            .string()
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required))
            .regex(nameRegex, intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters)),
        lastname: z
            .string()
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required))
            .regex(nameRegex, intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters)),
        email: z
            .string()
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required))
            .email(intl.formatMessage(FORM_VALIDATION_MESSAGE.isEmail)),
        password: z
            .string()
            .min(8, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 })),
        referralCode: z.string().optional().nullable(),
    });
};

export type RegisterForm = z.infer<ReturnType<typeof useRegisterFormSchema>>;
export default useRegisterFormSchema;
