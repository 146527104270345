import { useMutation } from '@tanstack/react-query';
import { login } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';
import { useAuthContext } from '@/context/AuthContext';
import useQuery from '@/shared/hooks/useQuery';
import { requestAuthorizationCode } from '@/api';
import { z } from 'zod';

const LoginResponseSchema = z.object({
    accessToken: z.string(),
    expiresIn: z.number(),
    refreshToken: z.string(),
    tokenType: z.string(),
});
export type LoginResponse = z.infer<typeof LoginResponseSchema>;

interface MutationFnProps {
    formData: {
        email: string;
        password: string;
    };
    invitationToken: string | null;
    invitationShow: string | null;
}

const mutationFn = async ({ formData, invitationToken, invitationShow }: MutationFnProps) => {
    const data = await login(
        decamelizeKeys({
            ...formData,
            ...(invitationToken && { invitationToken }),
            ...(invitationShow && { invitationShow }),
        }),
    );

    return LoginResponseSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useClubLoginMutation = () => {
    const { login: localLogin } = useAuthContext();
    const params = useQuery();

    const clientId = params.get('client_id');
    const redirectUri = params.get('redirect_uri');
    const responseType = params.get('response_type');
    const scope = params.get('scope');

    const requestCode = async () => {
        // Request API to retrieve a validation code associated to this authentication
        const res = await requestAuthorizationCode({
            client_id: clientId,
            redirect_uri: redirectUri,
            response_type: responseType,
            scope,
        });

        return res?.data?.code;
    };

    const redirectToCircle = (code: string) => {
        if (!code) return;
        // Redirect to Circle with given code
        window.location.assign(`${redirectUri}?code=${code}`);
    };

    return useMutation({
        mutationFn,
        onSuccess: async (data: LoginResponse) => {
            const code = await requestCode();
            localLogin(data);
            redirectToCircle(code);
        },
    });
};

export default useClubLoginMutation;
