import { useState, useEffect } from 'react';
// import { FormattedMessage } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
// import illu_signin from '@public/images/illu_signin_en.webp';
// import Img from '../../ui/atoms/Img';
// import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import aiVideo from '@public/videos/ai-login.mp4';
import Text from '@ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import ExternalLink from '@/components/Link/ExternalLink';
import styled from 'styled-components';

const variants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
};

const LoginSideIllustration = () => {
    const [mount, unMount] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        unMount(true);
        return () => unMount(false);
    }, []);

    return (
        <AnimatePresence exitBeforeEnter>
            {mount && (
                <motion.div
                    style={{ width: '100%' }}
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <Stack $gap="2rem" $align="center">
                        {/* <CenteringText>
                            <Text
                                variant="largeTitle"
                                fontWeight="--fw-bold"
                                color="white"
                                textAlign="center"
                            >
                                <FormattedMessage defaultMessage="La solution idéale pour gérer vos podcasts." />
                            </Text>
                        </CenteringText> */}
                        <Stack $gap="1.5rem" $align="center">
                            <NewBadge>
                                <Text variant="bodyL" fontWeight="--fw-bold">
                                    <FormattedMessage defaultMessage="NOUVEAUTÉ" />
                                </Text>
                            </NewBadge>
                            <Title>
                                <FormattedMessage defaultMessage="Générez en 1 clic toutes vos métadonnées grâce à l’IA" />
                            </Title>
                        </Stack>
                        <Video muted autoPlay loop playsInline>
                            <source src={aiVideo} type="video/mp4" />
                        </Video>
                        {/* <Img src={illu_signin} w="100%" /> */}
                        <VideoLink
                            href={`${intl.formatMessage({
                                defaultMessage: 'https://youtu.be/WN80HUc0roE',
                            })}?utm_source=app&utm_medium=referral&utm_campaign=page_login`}
                            target="_blank"
                        >
                            <FormattedMessage defaultMessage="Découvrez la vidéo d'Ausha Intelligence" />
                        </VideoLink>
                    </Stack>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

// const CenteringText = styled.div`
//     max-width: 27.5rem;
// `;
const Video = styled.video`
    display: block;
    width: 100%;
    object-fit: cover;
    border-radius: var(--r-m);
`;
const NewBadge = styled.div`
    padding: 0.5rem 1rem;
    background-color: var(--primary-magic);
    border-radius: var(--r-s);
    color: var(--white);
`;
const VideoLink = styled(ExternalLink)`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-semibold);
    text-decoration: underline;
    color: var(--black);

    &[data-hovered='true'] {
        color: var(--black);
    }
`;
const Title = styled.p`
    line-height: var(--lh-heading-l);
    font-size: var(--fs-heading-l);
    font-weight: var(--fw-bold);
    background: var(--gradient-nebula);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
`;

export default LoginSideIllustration;
