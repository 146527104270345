import { useEffect } from 'react';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import FileUploaded from './FileUploaded';
import NoFile from './NoFile';
import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeFileUploadContext';
import styled from 'styled-components';
import AudioFileUploadLoader from '@/components/ui/AudioFileUploadLoader';
import { useQueryClient } from '@tanstack/react-query';
import episodeKeys from '@/queries/episode/episodeKeys';
import AudioFileUploaderSkeleton from '@/components/ui/AudioFileUploadLoader/AudioFileUploaderSkeleton';
import useEpisodeAudioFileJobsQuery from '@/queries/episode/useEpisodeAudioFileJobsQuery';

const AudioFile = () => {
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [uploadingAudioFile] = useEpisodeEditAudioFileState();
    const episodeBeingEdited = useEpisodeBeingEdited();
    const uploadedAudioFile = episodeBeingEdited?.files?.[0];
    const audioFileJobs = useEpisodeAudioFileJobsQuery({
        episodeId: episodeBeingEdited?.id,
        options: {
            enabled: !!episodeBeingEdited?.id,
        },
    });
    const queryClient = useQueryClient();
    const isLoading =
        episodeEditAudioFileMutation.isLoading ||
        audioFileJobs.data?.converttomp3?.isExecuting ||
        audioFileJobs.data?.converttomp3?.isQueued ||
        audioFileJobs.data?.importaudiofile?.isExecuting ||
        audioFileJobs.data?.importaudiofile?.isQueued ||
        false;

    useEffect(() => {
        if (
            audioFileJobs.data?.converttomp3?.isFinished &&
            audioFileJobs.data?.importaudiofile?.isFinished
        ) {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.detailById(episodeBeingEdited.id),
            });
        }
    }, [audioFileJobs.data]);

    if (!audioFileJobs) {
        return (
            <Container>
                <AudioFileUploaderSkeleton />
            </Container>
        );
    }

    if (isLoading) {
        return (
            <Container>
                <AudioFileUploadLoader file={uploadingAudioFile} />
            </Container>
        );
    }

    if (uploadedAudioFile) {
        return (
            <Container>
                <FileUploaded file={uploadedAudioFile} audioUrl={episodeBeingEdited.audioUrl} />
            </Container>
        );
    }

    return (
        <Container>
            <NoFile />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
`;

export default AudioFile;
