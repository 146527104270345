import { useMutation } from '@tanstack/react-query';
import { loginAs } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { useAuthContext } from '@/context/AuthContext';
import { z } from 'zod';

const LoginAsResponseSchema = z.object({
    accessToken: z.string(),
    expiresIn: z.number(),
    refreshToken: z.string(),
    tokenType: z.string(),
});
export type LoginAsResponse = z.infer<typeof LoginAsResponseSchema>;

export interface MutationFnProps {
    token: string;
}

const mutationFn = async ({ token }: MutationFnProps) => {
    const data = await loginAs({ token });
    return LoginAsResponseSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useLoginAsMutation = () => {
    const {
        logout,
        login: localStateLogin,
        redirectToApp,
        setIsAuthenticatedAs,
    } = useAuthContext();

    return useMutation({
        mutationFn,
        onSuccess: async (data: LoginAsResponse) => {
            logout();
            localStateLogin(data);
            setIsAuthenticatedAs(true);
            redirectToApp();
        },
    });
};

export default useLoginAsMutation;
