import { useFormContext } from 'react-hook-form';
import usePlayerIframeQuery from '@queries/playerIframe/usePlayerIframeQuery.hook';
import { useParams } from 'react-router-dom';
import ScriptLoader from 'react-script-loader-hoc';
import useShowQuery from '@queries/show/useShowQuery.hook';
import PlayerFormPreviewSkeleton from './PlayerFormPreviewSkeleton';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Alert from '@ui/atoms/Alert';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { isDark, isLight } from '@/shared/utils/color';

import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const PlayerFormPreview = () => {
    ScriptLoader(`${process.env.URL_PLAYER}ausha-player.js`);
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const { watch } = useFormContext();
    const formData = watch();
    const intl = useIntl();
    const guideLink = intl.formatMessage({
        defaultMessage:
            'https://help.ausha.co/fr/articles/2154047-comment-personnaliser-et-integrer-le-smartplayer-ausha-de-mon-emission-sur-mon-site-internet#h_9d573354d3',
    });
    const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);
    const settings = {
        showId: show.data?.publicId,
        color: formData.color,
        width: `${formData.width}${formData.sizeUnit === 'percent' ? '%' : 'px'}`,
        display: formData.format,
        multishow: formData.showSwitch,
        playlist:
            formData.type === 'show' || formData.type === 'playlist' ? true : formData.episodeList,
        dark: formData.darkMode,
        ...(formData.type === 'playlist' &&
            formData.playlistSlug && { playlistSlug: formData.playlistSlug }),
        ...(formData.type === 'episode' &&
            formData.episodeId && {
                t: formData.startAt,
                ...(formData.episodeId !== 'latest' && { podcastId: formData.episodeId }),
            }),
    };
    const { data: iframe, isFetching, isLoading } = usePlayerIframeQuery(settings);

    const handleCopyToClipboard = async () => {
        if (!iframe) return;
        await navigator.clipboard.writeText(iframe);
        setHasCopiedToClipboard(true);
        sendAmplitudeLogEvent('Smartplayer Snippet Copied');
    };

    if (show.isLoading || isLoading || isFetching) {
        return (
            <PlayerFormPreviewSkeleton
                type={formData.type}
                format={formData.format}
                episodeList={formData.episodeList}
            />
        );
    }

    return (
        <Wrapper>
            {/* @ts-ignore */}
            <Preview dangerouslySetInnerHTML={{ __html: iframe }} />
            {formData.darkMode === true && isDark(formData.color) && (
                // @ts-ignore
                <Alert variant="warning" defaultIcon>
                    {/* @ts-ignore */}
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Le contraste entre la couleur d'accentuation choisie et le mode sombre n'est pas optimal. Pour une meilleure lisibilité, veuillez choisir une couleur offrant un contraste plus élevé, ou basculez sur le mode clair." />
                    </Text>
                </Alert>
            )}
            {formData.darkMode === false && isLight(formData.color) && (
                // @ts-ignore
                <Alert variant="warning" defaultIcon>
                    {/* @ts-ignore */}
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Le contraste entre la couleur d'accentuation choisie et le mode clair n'est pas optimal. Pour une meilleure lisibilité, veuillez choisir une couleur offrant un contraste plus élevé, ou basculez sur le mode sombre." />
                    </Text>
                </Alert>
            )}
            <Divider />
            <Stack $gap="0.75rem">
                <CodeBlockTitleWrapper>
                    <Text variant="headingS" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Code à intégrer" />
                    </Text>
                    {/* @ts-ignore */}
                    <CopyButton
                        variant="secondary"
                        size="small"
                        startIcon={<CopyIcon icon={icon({ name: 'copy', style: 'solid' })} />}
                        onPress={handleCopyToClipboard}
                    >
                        <AnimatePresence>
                            {hasCopiedToClipboard ? (
                                <motion.span
                                    initial={{ opacity: 1 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ delay: 1 }}
                                    onAnimationComplete={() => setHasCopiedToClipboard(false)}
                                >
                                    <FormattedMessage defaultMessage="Code copié" />
                                </motion.span>
                            ) : (
                                <FormattedMessage defaultMessage="Copier le code" />
                            )}
                        </AnimatePresence>
                    </CopyButton>
                </CodeBlockTitleWrapper>
                <Stack $gap="1rem">
                    <CodeBlock>{iframe && <Text variant="bodyM">{iframe}</Text>}</CodeBlock>
                    <Alert
                        variant="robot"
                        description={
                            <Text color="--neutral700">
                                <FormattedMessage
                                    defaultMessage="Besoin d’aide pour intégrer le Smartplayer à votre site web ? <link>Consultez le guide.</link>"
                                    values={{
                                        link: (chunks: string) => (
                                            // @ts-ignore
                                            <AlertLink
                                                as="a"
                                                href={guideLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {chunks}
                                            </AlertLink>
                                        ),
                                    }}
                                />
                            </Text>
                        }
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Pour intégrer le Smartplayer, collez simplement ce code dans un bloc HTML au sein de votre gestionnaire de site web 💡" />
                        </Text>
                    </Alert>
                </Stack>
            </Stack>
        </Wrapper>
    );
};

const Divider = styled.hr`
    display: none;
    width: 100%;
    height: 0.0625rem;
    background-color: var(--neutral100);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    position: sticky;
    top: 8rem;
`;
const CodeBlock = styled.div`
    padding: 0.75rem;
    background-color: var(--black);
    color: var(--white);
    border-radius: var(--r-s);
`;
const CodeBlockTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const CopyIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const CopyButton = styled(Button)`
    background: transparent;
`;
const Preview = styled.div`
    padding: 1rem;
    background-color: var(--neutral50);
    border-radius: var(--r-m);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 0;
        background-color: transparent;
        border-radius: 0;
    }
`;
const AlertLink = styled(Text)`
    color: inherit;
    text-decoration: underline;
`;

export default PlayerFormPreview;
