import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const loginAs = (body) => {
    return request({ method: 'POST', basePath, target: '/loginas', body });
};
export const register = (body) => {
    return request({ method: 'POST', basePath, target: '/register', body });
};
export const login = (body) => {
    return request({ method: 'POST', basePath, target: '/login', body });
};
