import AushaHeader from './AushaHeader';
import { connect } from '@/components/legacy/connect';
import TopbarWarningBand from './TopbarWarningBand';
import TopbarPastDueWarningBand from './TopbarPastDueWarningBand';
import TopbarEndedBand from './TopbarEndedBand';
import TopbarEnterpriseAccountDeactivatedBand from './TopbarEnterpriseAccountDeactivatedBand';
import TopbarUnpaidInvoiceWarningBand from './TopbarUnpaidInvoiceWarningBand';
import styled from 'styled-components';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const enhance = connect(({ subscriptionStore }) => ({
    showWarning: subscriptionStore.subscription && subscriptionStore.subscription.onGrace,
    pastDue: subscriptionStore.subscription && subscriptionStore.subscription.pastDue,
    hasUnpaidInvoices:
        subscriptionStore.subscription &&
        subscriptionStore.subscription?.hasUnpaidInvoices === true,
    ended: subscriptionStore.subscription && subscriptionStore.subscription.ended,
}));

const Topbar = ({ showWarning, pastDue, hasUnpaidInvoices, ended, noBackground }) => {
    const { data: user } = useUserQuery();

    const enterpriseAccountDeactivated =
        user?.pricing === 'enterprise' && user?.activated === false;

    if (!user) return null;

    return (
        <TopbarWrapper>
            {ended && <TopbarEndedBand key={0} />}
            {showWarning && <TopbarWarningBand key={0} />}
            {enterpriseAccountDeactivated && <TopbarEnterpriseAccountDeactivatedBand />}
            {pastDue && <TopbarPastDueWarningBand key={0} />}
            {hasUnpaidInvoices && <TopbarUnpaidInvoiceWarningBand key={0} />}
            <AushaHeader noBackground={noBackground} />
        </TopbarWrapper>
    );
};

Topbar.defaultProps = {
    setTopbarHeight: () => {},
};
const TopbarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
`;

export default enhance(Topbar);
