import { useMutation } from '@tanstack/react-query';
import { deleteAccount } from '@/api';
import { DeleteAccountPasswordFormSchema } from '@/features/DeleteAccount/components/PasswordModal/PasswordForm/usePasswordFormSchema.hook';

const deleteAccountMutation = () => {
    return useMutation({
        mutationFn: (data: DeleteAccountPasswordFormSchema) => {
            return deleteAccount(data);
        },
    });
};

export default deleteAccountMutation;
