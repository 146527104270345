import { FormattedMessage } from 'react-intl';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Tooltip from '@ui/atoms/Tooltip';
import Img from '@ui/atoms/Img';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import { useResponsive } from '@/shared/hooks/useResponsive';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import Desktop from '@ui/molecules/Desktop';
import EpisodeUsefulLinks from './EpisodeUsefulLinks';
import { useParams } from 'react-router-dom';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatDuration } from '@/shared/utils/duration';

const statusPodcastMessage = {
    active: <FormattedMessage defaultMessage="Récapitulatif de votre épisode publié." />,
    scheduled: <FormattedMessage defaultMessage="Récapitulatif de votre épisode planifié." />,
};

const typeMessage = {
    full: <FormattedMessage defaultMessage="Normal" />,
    trailer: <FormattedMessage defaultMessage="Bande-annonce" />,
    bonus: <FormattedMessage defaultMessage="Bonus" />,
};
const privacyMessage = {
    public: <FormattedMessage defaultMessage="Public" />,
    unlisted: <FormattedMessage defaultMessage="Non-listé" />,
    private: <FormattedMessage defaultMessage="Privé" />,
};

const youtubeMessage = {
    draft: (
        <FormattedMessage defaultMessage="La vidéo YouTube de votre épisode est en train d’être générée et sera bientôt en ligne." />
    ),
    processing: (
        <FormattedMessage defaultMessage="La vidéo YouTube de votre épisode est en train d’être générée et sera bientôt en ligne." />
    ),
    done: <FormattedMessage defaultMessage="Votre épisode a également été publié sur YouTube." />,
    pending: (
        <FormattedMessage defaultMessage="La vidéo YouTube de votre épisode est en train d’être générée et sera bientôt en ligne." />
    ),
};

const ContentItemsOptionsAndPrivacy = ({ children, status, ...props }) => {
    if (status === 'scheduled') return null;

    return (
        <>
            <MobileOrTablet>
                <DeprecatedPaper flex column justify="center" align="center">
                    <DeprecatedWhitePaper b={1} py={15} mb={15}>
                        {children}
                    </DeprecatedWhitePaper>
                </DeprecatedPaper>
            </MobileOrTablet>
            <Desktop>
                <DeprecatedWhitePaper b={1} py={15} maxW={200} {...props}>
                    {children}
                </DeprecatedWhitePaper>
            </Desktop>
        </>
    );
};

const SummaryEpisode = () => {
    const { episodeId } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId });

    const { isMobileOrTablet, isDesktop } = useResponsive();

    if (episode.isLoading) return <ShortListFallback />;

    return (
        <DeprecatedPaper textAlign="center" maxW={630} m="auto">
            <DeprecatedText weight="bold" size="veryBig" align="center" mb={45}>
                {statusPodcastMessage[episode.state]}
            </DeprecatedText>
            <Img size={isMobileOrTablet ? 125 : 135} src={episode.imageUrl} bordered mx="auto" />
            <DeprecatedText size="big" align="center" weight="semibold" my={25}>
                <FormattedMessage
                    defaultMessage="{podcastName}"
                    values={{ linebreak: <br />, podcastName: episode.name }}
                />
            </DeprecatedText>
            <DeprecatedPaper flex={isDesktop} justify="center" mb={50}>
                <ContentItemsOptionsAndPrivacy mr={15}>
                    <DeprecatedText color="--neutral500" mb={10} size="normal" align="center">
                        <FormattedMessage defaultMessage="Durée" />
                    </DeprecatedText>
                    <DeprecatedText size="big" align="center">
                        {formatDuration(episode.duration)}
                    </DeprecatedText>
                </ContentItemsOptionsAndPrivacy>

                <ContentItemsOptionsAndPrivacy mr={15}>
                    <DeprecatedText
                        color="--neutral500"
                        mb={10}
                        size="normal"
                        align="center"
                        nowrap
                    >
                        <FormattedMessage defaultMessage="Type d’épisode" />
                    </DeprecatedText>
                    <DeprecatedText size="big" align="center" nowrap>
                        {typeMessage[episode.type]}
                    </DeprecatedText>
                </ContentItemsOptionsAndPrivacy>

                <ContentItemsOptionsAndPrivacy status={episode.state}>
                    <DeprecatedText color="--neutral500" mb={10} size="normal" align="center">
                        <FormattedMessage defaultMessage="Visibilité" />
                    </DeprecatedText>
                    <DeprecatedText size="big" align="center">
                        {privacyMessage[episode.privacy]}
                    </DeprecatedText>
                </ContentItemsOptionsAndPrivacy>
            </DeprecatedPaper>

            {episode?.youtubeVideo?.data?.state && (
                <Tooltip
                    type="info"
                    maxW={540}
                    flex
                    m="auto"
                    mb={25}
                    w={isDesktop && 'max-content'}
                    boxSizing="content-box"
                >
                    <FontAwesomeIcon icon={icon({ name: 'youtube', style: 'brands' })} size="xl" />
                    <DeprecatedText size="small" px={10} nowrap={isDesktop}>
                        {youtubeMessage[episode?.youtubeVideo?.data?.state]}
                    </DeprecatedText>
                </Tooltip>
            )}

            <EpisodeUsefulLinks border />
        </DeprecatedPaper>
    );
};

export default SummaryEpisode;
