import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const useAmplitudeLogLocationChangeEvent = () => {
    const location = useLocation();

    useEffect(() => {
        if (!amplitude.getUserId()) return;
        sendAmplitudeLogEvent('Page Viewed');
    }, [location]);
};

export default useAmplitudeLogLocationChangeEvent;
