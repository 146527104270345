import { FormattedMessage } from 'react-intl';
import LoginSideIllustration from '../../LoginSideIllustration';
import AushaLogo from '@ui/atoms/AushaLogo';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const HasResetPassword = () => {
    return (
        <HasResetPasswordWrapper>
            <Left>
                <InnerLeft>
                    <Header>
                        <Logo isComplete isColored />
                        <Title data-testid="login-heading">
                            <CheckIcon icon={icon({ name: 'circle-check', style: 'regular' })} />
                            <FormattedMessage defaultMessage="Votre mot de passe a été réinitialisé." />
                        </Title>
                        <Subtitle>
                            <FormattedMessage defaultMessage="Vous pouvez dès à présent vous connecter à votre compte." />
                        </Subtitle>
                    </Header>
                    <ActionWrapper>
                        <RouterLink to="/login" variant="button-primary">
                            <FormattedMessage defaultMessage="Retour à la connexion" />
                        </RouterLink>
                    </ActionWrapper>
                </InnerLeft>
            </Left>
            <Right>
                <InstructionsInnerWrapper>
                    <LoginSideIllustration />
                </InstructionsInnerWrapper>
            </Right>
        </HasResetPasswordWrapper>
    );
};

const HasResetPasswordWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;
    min-height: 100%;
    background-color: var(--white);

    & > * {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
    }
`;
const Container = styled.div`
    align-self: stretch;
    padding: 1.5rem;
    overflow: hidden;
    max-width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        padding-inline: 4rem;
        padding-top: 4rem;
        padding-bottom: 0;
    }
`;
const Left = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const InnerLeft = styled.div`
    width: 100%;
    max-width: 28rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Title = styled.h1`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-heading-m);
`;
const Subtitle = styled.p`
    color: var(--neutral);
`;
const Header = styled.div``;
const Logo = styled(AushaLogo)`
    height: 2rem;
    width: 7.25rem;
    margin-bottom: 2rem;
`;
const Right = styled(Container)`
    display: none;
    background: var(--gradient-aurora);
    border-radius: var(--r-xl);

    @media (min-width: 900px) {
        display: block;
        padding-bottom: 4rem;
    }
`;
const InstructionsInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    & > *:first-child {
        align-self: stretch;
    }
`;
const CheckIcon = styled(FontAwesomeIcon)`
    font-size: 1.5rem;
    color: var(--success);
`;

export default HasResetPassword;

// import { FormattedMessage } from 'react-intl';
// import LoginSideIllustration from '../../LoginSideIllustration';
// import AushaLogo from '@ui/atoms/AushaLogo';
// import RouterLink from '@/components/Link/RouterLink';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
// import styled from 'styled-components';

// const EmailSent = () => {
//     return (
//         <EmailSentWrapper>
//             <Left>
//                 <InnerLeft>
//                     <Header>
//                         <Logo isComplete isColored />
//                         <Title data-testid="login-heading">
//                             <CheckIcon icon={icon({ name: 'circle-check', style: 'regular' })} />
//                             <FormattedMessage defaultMessage="Le lien a bien été envoyé." />
//                         </Title>
//                         <Subtitle>
//                             <FormattedMessage defaultMessage="Merci de vérifier vos e-mails pour réinitialiser votre mot de passe." />
//                         </Subtitle>
//                     </Header>
//                     <ActionWrapper>
//                         <RouterLink to="/login" variant="button-primary">
//                             <FormattedMessage defaultMessage="Retour à la connexion" />
//                         </RouterLink>

//                         <span>
//                             <FormattedMessage
//                                 defaultMessage="Vous n'avez pas reçu le lien ? {retry}"
//                                 values={{
//                                     retry: (
//                                         <RouterLink to="/password-recovery">
//                                             <FormattedMessage defaultMessage="Réessayer" />
//                                         </RouterLink>
//                                     ),
//                                 }}
//                             />
//                         </span>
//                     </ActionWrapper>
//                 </InnerLeft>
//             </Left>
//             <Right>
//                 <InstructionsInnerWrapper>
//                     <LoginSideIllustration />
//                 </InstructionsInnerWrapper>
//             </Right>
//         </EmailSentWrapper>
//     );
// };

// const HasResetPasswordWrapper = styled.div`
//     display: flex;
//     justify-content: center;
//     padding: 2rem;
//     min-height: 100%;
//     background-color: var(--white);

//     & > * {
//         flex-grow: 1;
//         flex-shrink: 0;
//         flex-basis: 50%;
//     }
// `;
// const Container = styled.div`
//     align-self: stretch;
//     padding: 1.5rem;
//     overflow: hidden;
//     max-width: 100%;

//     ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
//         padding-inline: 4rem;
//         padding-top: 4rem;
//         padding-bottom: 0;
//     }
// `;
// const Left = styled(Container)`
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `;
// const InnerLeft = styled.div`
//     width: 100%;
//     max-width: 28rem;
//     display: flex;
//     flex-direction: column;
//     gap: 2rem;
// `;
// const Title = styled.h1`
//     display: flex;
//     align-items: center;
//     gap: 0.5rem;
//     font-size: var(--fs-heading-m);
// `;
// const Subtitle = styled.p`
//     color: var(--neutral);
// `;
// const Header = styled.div``;
// const Logo = styled(AushaLogo)`
//     height: 2rem;
//     width: 7.25rem;
//     margin-bottom: 2rem;
// `;
// const Right = styled(Container)`
//     display: none;
//     background: var(--gradient-aurora);
//     border-radius: var(--r-xl);

//     @media (min-width: 900px) {
//         display: block;
//         padding-bottom: 4rem;
//     }
// `;
// const InstructionsInnerWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     height: 100%;
// `;
// const ActionWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 1rem;

//     & > *:first-child {
//         align-self: stretch;
//     }
// `;
// const CheckIcon = styled(FontAwesomeIcon)`
//     font-size: 1.5rem;
//     color: var(--success);
// `;

// export default EmailSent;
