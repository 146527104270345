import Accordion from '@/components/ui/atoms/Accordion';
import SummaryAccordionItem from './SummaryAccordionItem';
import AccordionItem from '@/components/ui/atoms/AccordionItem';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import ContentSummary from '../ContentSummary';
import NewsletterSummary from '../NewsletterSummary/NewsletterSummary';
import SocialPostsSummary from '../SocialPostsSummary';
import { useFormContext } from 'react-hook-form';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';

import { filterFalsyValues } from '@/shared/utils/transform';

const SummaryAccordion = () => {
    const { watch } = useFormContext<NonHostingEpisodeFormSchema>();
    const values = watch();
    const nonEmptyValues = filterFalsyValues(values);
    const isContentEmpty = !nonEmptyValues?.content && !nonEmptyValues?.chapters;
    const isNewsletterEmpty = !nonEmptyValues?.newsletter;
    const isSocialPostsEmpty = !nonEmptyValues?.socialPosts;

    return (
        <AccordionStyled defaultIndex={0}>
            {!isContentEmpty && (
                <AccordionItem>
                    <SummaryAccordionItem
                        label={<FormattedMessage defaultMessage="Contenu" />}
                        icon={<Icon icon={icon({ name: 'album-collection', style: 'solid' })} />}
                    >
                        <ContentSummary />
                    </SummaryAccordionItem>
                </AccordionItem>
            )}
            {!isNewsletterEmpty && (
                <AccordionItem>
                    <SummaryAccordionItem
                        label={<FormattedMessage defaultMessage="Newsletter" />}
                        icon={<Icon icon={icon({ name: 'envelope-open-text', style: 'solid' })} />}
                    >
                        <NewsletterSummary />
                    </SummaryAccordionItem>
                </AccordionItem>
            )}
            {!isSocialPostsEmpty && (
                <AccordionItem>
                    <SummaryAccordionItem
                        label={
                            <FormattedMessage id="socialPosts" defaultMessage="Réseaux sociaux" />
                        }
                        icon={<Icon icon={icon({ name: 'message-heart', style: 'solid' })} />}
                    >
                        <SocialPostsSummary />
                    </SummaryAccordionItem>
                </AccordionItem>
            )}
        </AccordionStyled>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--black);
`;
const AccordionStyled = styled(Accordion)`
    gap: 0;
`;

export default SummaryAccordion;
