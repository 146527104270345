import { useEffect } from 'react';
import useLoginAsMutation from '@/queries/auth/useLoginAsMutation.hook';
import useQuery from '@/shared/hooks/useQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const LoginAs = () => {
    const login = useLoginAsMutation();

    const params = useQuery();
    const token = params.get('t');

    useEffect(() => {
        if (!token) return;
        login.mutate({ token });
    }, []);

    return (
        <LoginAsWrapper>
            <Loader icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
            <Title>We are login you as the selected user, hang tight! 😊</Title>
        </LoginAsWrapper>
    );
};

const LoginAsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;
const Loader = styled(FontAwesomeIcon)`
    font-size: 2rem;
    color: var(--neutral);
`;
const Title = styled.h1`
    font-size: 1.2rem;
    font-weight: var(--fw-semibold);
`;

export default LoginAs;
