export const delayQuery = ({ callback, delay }) => {
    return async (queryContext) => {
        const start = Date.now();
        const result = await callback(queryContext);
        const elapsed = Date.now() - start;

        if (elapsed < delay) {
            await new Promise((resolve) => setTimeout(resolve, delay - elapsed));
        }

        return result;
    };
};
