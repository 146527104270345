import { createContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Profile from './Profile';
import Subscription from './Subscription';
import EmailNotifications from './EmailNotifications';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import Guests from './Guests';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import PublicApi from './PublicApi';
import DeleteAccount from './DeleteAccount';
import Layout from './Layout';

export const RouteUserPageContext = createContext(null);

const User = () => {
    const { data: user } = useUserQuery();
    const userHasHostingAccess = user?.canHostOnAusha === true;
    const hasAccessToUsersManagement =
        user?.options?.includes(USER_OPTIONS.MULTI_USERS_MANAGEMENT) && !user?.guest;

    return (
        <Switch>
            <Route path="/app/user/delete">
                <Layout>
                    <DeleteAccount />
                </Layout>
            </Route>
            <Route path="/app/user/profile">
                <Layout>
                    <Profile />
                </Layout>
            </Route>
            <Route path="/app/user/billing">
                <Redirect to="/app/user/subscription" />
            </Route>
            <Route strict path="/app/user/subscription">
                <Layout>
                    <Subscription />
                </Layout>
            </Route>
            {hasAccessToUsersManagement && (
                <Route path="/app/user/users">
                    <Layout>
                        <Guests />
                    </Layout>
                </Route>
            )}
            {userHasHostingAccess && (
                <>
                    <Route path="/app/user/email">
                        <Layout>
                            <EmailNotifications />
                        </Layout>
                    </Route>
                    <Route path="/app/user/public-api">
                        <Layout>
                            <PublicApi />
                        </Layout>
                    </Route>
                </>
            )}
        </Switch>
    );
};

export default User;
