export const renderPrice = (price, currency) => {
    if (currency === '€') {
        return (
            <>
                <span>{price}</span>
                <span>{currency}</span>
            </>
        );
    }
    if (['$', '£'].includes(currency)) {
        return (
            <>
                <span>{currency}</span>
                <span>{price}</span>
            </>
        );
    }

    return (
        <>
            <span>{price}</span>
            <span>{currency}</span>
        </>
    );
};
