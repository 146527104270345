import Text from '@/components/ui/atoms/Text';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Snippet from '../Snippet';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const NameSummary = () => {
    const { getValues } = useFormContext<NonHostingEpisodeFormSchema>();
    const name = getValues('content.name');

    if (!name) return null;

    const handleCopy = () => {
        sendAmplitudeLogEvent('Copy Episode Title');
    };

    return (
        <Wrapper>
            <Text variant="bodyL" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Titre" />
            </Text>
            <Snippet onCopy={handleCopy} valueToCopy={name}>
                <Text>{name}</Text>
            </Snippet>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

export default NameSummary;
