import { useState } from 'react';
import PsoStoreEditModal from '../../PsoKeywordTracking/Header/PsoStoreEditModal';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import PsoStoreSelect from '../../PsoKeywordTracking/Header/PsoStoreSelect';
import styled from 'styled-components';
import Text from '@/components/ui/atoms/Text';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface HeaderProps {
    currentStore: string;
    userHasWriteAccess: boolean;
    stores: { name: string; alpha2Code: string }[];
    setCurrentStore: (store: string) => void;
}

const Header = ({ currentStore, userHasWriteAccess, stores, setCurrentStore }: HeaderProps) => {
    const [showStoreEditModal, setShowStoreEditModal] = useState(false);

    return (
        <>
            {showStoreEditModal && (
                <PsoStoreEditModal
                    isOpen={showStoreEditModal}
                    onClose={() => setShowStoreEditModal(false)}
                    existingStores={stores}
                />
            )}
            <HeaderWrapper>
                <TitleAndDescription>
                    <TitleWrapper>
                        <TitleIcon icon={icon({ name: 'grid-2', style: 'solid' })} />
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Vue d'ensemble" />
                        </Text>
                    </TitleWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="La vue d'ensemble de vos performances PSO. Accédez à un tableau de bord complet affichant votre score de visibilité, vos principaux mots-clés et des données historiques, vous permettant de suivre votre progression en un seul coup d'œil." />
                    </Text>
                </TitleAndDescription>
                <PsoStoreSelect
                    currentStore={currentStore}
                    stores={stores?.map((store) => store.alpha2Code) || []}
                    setCurrentStore={(store) => {
                        sendAmplitudeLogEvent('PSO Country Switched');
                        setCurrentStore(store);
                    }}
                    editStores={() => setShowStoreEditModal(true)}
                    userHasWriteAccess={userHasWriteAccess}
                />
            </HeaderWrapper>
        </>
    );
};

const HeaderWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    column-gap: 1rem;
`;
const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex: 1;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;

export default Header;
