import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import Button from '@/components/Button';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import saveNonHostingEpisodeMutation from '../saveNonHostingEpisodeMutation';
import { getChaptersIdsRequiringImageDeletion } from '../helpers/getChaptersIdsRequiringImageDeletion';
import { getDirtyValues } from '@/shared/utils/reactHookForm';

const SaveDraftAndCloseButton = () => {
    const { push } = useHistory();
    const episode = useEpisodeBeingEdited();
    const {
        getValues,
        reset,
        formState: { dirtyFields },
    } = useFormContext();
    const saveEpisodeMutation = saveNonHostingEpisodeMutation();
    const formData = getValues();
    const toast = useBodyToastQueue();

    const handleSaveDraft = async () => {
        if (!episode) return;

        const dirtyValues = getDirtyValues(dirtyFields, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );
        const { content } = dirtyValues;
        const chapters = dirtyFields.chapters ? formData.chapters : undefined;
        const hasDirtyMessages = dirtyFields.newsletter || dirtyFields.socialPosts;
        const messages = hasDirtyMessages
            ? {
                  newsletter: formData.newsletter,
                  ...formData.socialPosts,
              }
            : undefined;

        try {
            await saveEpisodeMutation.mutateAsync(
                {
                    episodeId: String(episode.id),
                    episode: { ...content },
                    chapters,
                    chaptersIdsRequiringImageDeletion,
                    messages,
                },
                {
                    onSuccess: () => {
                        reset();
                        push(`/app/show/${episode?.showId}/workspace`);
                    },
                },
            );
        } catch (error) {
            toast.alert();
        }
    };

    return (
        <Button
            isLoading={saveEpisodeMutation.isLoading}
            onPress={handleSaveDraft}
            variant="tertiary"
            startIcon={
                <CheckIcon
                    icon={icon({
                        name: 'check',
                        style: 'solid',
                    })}
                />
            }
        >
            <FormattedMessage defaultMessage="Enregistrer et fermer" />
        </Button>
    );
};

const CheckIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--black);
`;

export default SaveDraftAndCloseButton;
