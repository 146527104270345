import { FormattedMessage } from 'react-intl';
import useQuery from '@/shared/hooks/useQuery';
import LoginSideIllustration from '../LoginSideIllustration';
import Form from './Form';
import RouterLink from '@/components/Link/RouterLink';
import Alert from '@ui/atoms/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import GoogleButton from '@/shared/components/GoogleButton';
import FacebookButton from '@/shared/components/FacebookButton';
import AushaLogo from '@ui/atoms/AushaLogo';
import styled from 'styled-components';

const Login = () => {
    const searchParams = useQuery();
    const isInvitation =
        searchParams.get('email') && searchParams.get('show') && searchParams.get('token');

    const signupLink = (
        <FormattedMessage
            defaultMessage="Vous n’avez pas encore de compte ? <register>Créer un compte</register>"
            values={{
                register: (chunks: React.ReactNode) => (
                    <RouterLink
                        to={`/register?${searchParams.toString()}`}
                        data-testid="register-page-link"
                    >
                        {chunks}
                    </RouterLink>
                ),
            }}
        />
    );

    return (
        <LoginWrapper>
            <Left>
                <InnerLeft>
                    <Header>
                        <Logo isComplete isColored />
                        <Title data-testid="login-heading">
                            <FormattedMessage defaultMessage="Se connecter" />
                        </Title>
                        <DesktopAndUpSignupCallToAction>
                            {signupLink}
                        </DesktopAndUpSignupCallToAction>
                    </Header>

                    {isInvitation ? (
                        <Alert
                            variant="info"
                            icon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'circle-info', style: 'solid' })}
                                />
                            }
                            description={
                                <FormattedMessage defaultMessage="Il ne reste plus qu’à vous inscrire, ou à vous connecter si vous avez déjà un compte. À très vite !" />
                            }
                        >
                            <FormattedMessage
                                defaultMessage="{name} vous invite à rejoindre Ausha.{linebreak}"
                                values={{
                                    name: searchParams.get('name') || searchParams.get('showname'),
                                    linebreak: <br />,
                                }}
                            />
                        </Alert>
                    ) : (
                        <>
                            <ThirdPartyLoginWrapper>
                                <GoogleButton
                                    label={
                                        <FormattedMessage defaultMessage="Se connecter avec Google" />
                                    }
                                />
                                <FacebookButton
                                    label={
                                        <FormattedMessage defaultMessage="Se connecter avec Facebook" />
                                    }
                                    rounded={true}
                                />
                            </ThirdPartyLoginWrapper>
                            <Separator>
                                <Line />
                                <FormattedMessage defaultMessage="Ou connectez-vous par email" />
                                <Line />
                            </Separator>
                        </>
                    )}
                    <SignupCallToAction>{signupLink}</SignupCallToAction>
                    <Form />
                </InnerLeft>
            </Left>

            <Right>
                <InstructionsInnerWrapper>
                    <LoginSideIllustration />
                </InstructionsInnerWrapper>
            </Right>
        </LoginWrapper>
    );
};

const LoginWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;
    min-height: 100%;
    background-color: var(--white);

    & > * {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
    }
`;
const Container = styled.div`
    align-self: stretch;
    padding: 1.5rem;
    overflow: hidden;
    max-width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        padding-inline: 4rem;
        padding-top: 4rem;
        padding-bottom: 0;
    }
`;
const Left = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const InnerLeft = styled.div`
    width: 100%;
    max-width: 28rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Header = styled.div``;
const Logo = styled(AushaLogo)`
    height: 2rem;
    width: 7.25rem;
    margin-bottom: 2rem;
`;
const ThirdPartyLoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const Right = styled(Container)`
    display: none;
    background: var(--gradient-aurora);
    border-radius: var(--r-xl);

    @media (min-width: 900px) {
        display: block;
        padding-bottom: 4rem;
    }
`;
const InstructionsInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
const SignupCallToAction = styled.span`
    text-align: center;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const DesktopAndUpSignupCallToAction = styled.span`
    display: none;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        display: block;
    }
`;
const Title = styled.h1`
    font-size: var(--fs-heading-m);
`;
const Separator = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--neutral);
`;
const Line = styled.span`
    height: 1px;
    background-color: var(--neutral100);
    flex: 1;
`;

export default Login;
