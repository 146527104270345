import Topbar from '@/components/unorganized/Topbar';
import MainLayout from './Main.layout';
import NavigationMenu from '@/components/NavigationMenu';
import Footer from '@/components/Footer';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import PaymentValidationRequiredBand from '@/components/unorganized/PaymentValidationRequiredBand';

const MainLayoutSkeleton = () => {
    return (
        <MainLayout
            header={
                <>
                    <Topbar />
                    <NavigationMenu />
                </>
            }
            footer={<Footer />}
        >
            <SpinnerWrapper>
                <SpinnerIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
            </SpinnerWrapper>
            <PaymentValidationRequiredBand />
        </MainLayout>
    );
};

const SpinnerWrapper = styled.div`
    padding: var(--container-padding);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 4rem;
    height: 4rem;
    color: var(--neutral100);
`;

export default MainLayoutSkeleton;
