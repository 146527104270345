export const statusValue = ({ status, privacy }) => {
    if (status === 'online' && privacy === 'public') return 'online';
    if (status === 'online' && privacy === 'unlisted') return 'unlisted';
    if (status === 'online' && privacy === 'private') return 'private';
    if (status === 'active' && privacy === 'public') return 'online';
    if (status === 'active' && privacy === 'unlisted') return 'unlisted';
    if (status === 'active' && privacy === 'private') return 'private';
    if (status === 'planned') return 'planned';
    if (status === 'scheduled') return 'scheduled';
    if (status === 'pending') return 'pending';
    if (status === 'processing') return 'processing';
    if (status === 'error') return 'error';
    return 'draft';
};
