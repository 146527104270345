import { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import RingChartFallback from '../../unorganized/RingChartFallback';
import ListFormFallback from '../../unorganized/ListFormFallback';
import { round } from '@/shared/utils/number';
import { compose } from '@/shared/utils/compose';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled, { css } from 'styled-components';
import { ENTERPRISE_GRAPH_COLORS, GRAPH_COLORS } from '@/shared/config/constants';
import Text from '../../ui/atoms/Text';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import RingChartWithLegendSkeleton from './RingChartWithLegendSkeleton';
import { connect } from '@/components/legacy/connect';
import Ring from '@ui/atoms/Ring';
import RingChart from '@ui/molecules/RingChart';
import Legend from '@ui/molecules/Legend';

const enhance = compose(connect(() => ({})));

function RingChartWithLegend({ source, isLoading, onClickElement, ...props }) {
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [key, onChange] = useState(null);
    const { isMobileOrTablet } = useResponsive();
    const { data: subscription } = useSubscriptionQuery();
    const graphColors =
        subscription?.pricing === PRICING.ENTERPRISE ? ENTERPRISE_GRAPH_COLORS : GRAPH_COLORS;

    if (source.length === 0) {
        return (
            <RingChartEmptyWrapper>
                <RingChartWithLegendSkeleton />
            </RingChartEmptyWrapper>
        );
    }

    return (
        <RingChartWrapper align="center" flex column={isMobileOrTablet} {...props}>
            <RingWrapper>
                {isLoading ? (
                    <RingChartFallback />
                ) : (
                    <RingChart activeKey={key} onHover={onChange} onExit={() => onChange(null)}>
                        {source.map((src, i) => (
                            <Ring
                                onClick={
                                    src.name !== 'others' && onClickElement
                                        ? () => onClickElement(src)
                                        : null
                                }
                                value={src.value}
                                percent={src.percent}
                                key={src.key}
                                color={graphColors[i]}
                            />
                        ))}
                    </RingChart>
                )}
            </RingWrapper>
            <LegendWrapper>
                {isLoading ? (
                    <ListFormFallback />
                ) : (
                    <>
                        <Text color="--neutral500">
                            <FormattedMessage
                                defaultMessage="Répartition des parts d’écoute du {fromDate} au {toDate}"
                                values={{
                                    fromDate: (
                                        <FormattedDate
                                            value={startDate}
                                            day="2-digit"
                                            month="short"
                                            year="numeric"
                                        />
                                    ),
                                    toDate: (
                                        <FormattedDate
                                            value={endDate}
                                            day="2-digit"
                                            month="short"
                                            year="numeric"
                                        />
                                    ),
                                }}
                            />
                        </Text>
                        <LegendList>
                            {source.map((src, i) => (
                                <Legend
                                    className="legend"
                                    key={src.key}
                                    onClick={
                                        src.name !== 'others' && onClickElement
                                            ? () => onClickElement(src)
                                            : null
                                    }
                                    active={key === src.key.toString()}
                                    onMouseEnter={() => onChange(src.key.toString())}
                                    onMouseLeave={() => onChange(null)}
                                    label={src.label}
                                    color={graphColors[i]}
                                    value={`${round(src.percent)}%`}
                                    mb={5}
                                    ml={5}
                                    w={isMobileOrTablet ? '100%' : 'calc(50% - 10px)'}
                                />
                            ))}
                        </LegendList>
                    </>
                )}
            </LegendWrapper>
        </RingChartWrapper>
    );
}

RingChartWithLegend.defaultProps = {
    source: [],
};

const WrapperBase = css`
    background: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

const RingChartWrapper = styled.div`
    ${WrapperBase};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }
`;

const RingChartEmptyWrapper = styled.div`
    ${WrapperBase};
`;

const RingWrapper = styled.div`
    flex-grow: 1;
    flex-basis: 30%;
`;

const LegendWrapper = styled.div`
    flex-grow: 2;
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

const LegendList = styled.ul`
    flex: 1;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

export default enhance(RingChartWithLegend);
