import { Checkbox as AriaCheckbox } from 'react-aria-components';
import type { CheckboxProps as AriaCheckboxProps } from 'react-aria-components';
import type { ControllerFieldState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

export interface CheckboxProps extends AriaCheckboxProps {
    inputState?: ControllerFieldState;
    errorMessage?: string | React.ReactNode;
    icon?: React.ReactNode;
}

const Checkbox = ({ children, icon, ...props }: CheckboxProps) => {
    return (
        <CheckBoxStyled {...props} key={Math.random()}>
            <CheckboxWrapper>
                <CheckboxInnerWrapper>
                    {icon || (
                        <CheckboxIcon
                            icon={iconFA({ name: 'check', style: 'solid' })}
                            fixedWidth
                            size="xs"
                        />
                    )}
                </CheckboxInnerWrapper>
                {children}
            </CheckboxWrapper>
        </CheckBoxStyled>
    );
};

const CheckboxInnerWrapper = styled.div`
    position: relative;
    width: 1rem;
    height: 1rem;
    border-radius: var(--r-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid var(--neutral200);
    background-color: var(--white);
`;
const CheckboxWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
`;
const CheckBoxStyled = styled(AriaCheckbox)`
    line-height: 0;
    cursor: pointer;

    &[data-disabled],
    [data-readonly] {
        cursor: default;

        & > ${CheckboxWrapper} {
            p {
                opacity: 0.5;
            }
        }

        & > ${CheckboxWrapper} > ${CheckboxInnerWrapper} {
            border-color: var(--neutral300);
            background-color: var(--neutral100);
        }
    }

    &[data-selected] > ${CheckboxWrapper} > ${CheckboxInnerWrapper} {
        border-color: var(--primary);
        background-color: var(--primary);
    }
    &[data-invalid] > ${CheckboxWrapper} > ${CheckboxInnerWrapper} {
        border-color: var(--alert);
        background-color: var(--alert);
    }

    &:not([data-disabled]):not([data-readonly]):not([data-invalid])
        > ${CheckboxWrapper}
        > ${CheckboxInnerWrapper} {
        border-color: var(--neutral300);
    }

    & > ${CheckboxWrapper} > ${CheckboxInnerWrapper} > * {
        display: none;
    }

    &[data-selected] > ${CheckboxWrapper} > ${CheckboxInnerWrapper} > * {
        display: block;
    }
`;
const CheckboxIcon = styled(FontAwesomeIcon)`
    color: var(--white);
`;

export default Checkbox;
