import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedNumber, FormattedDate } from 'react-intl';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { PRICING_NAME } from '@/shared/config/pricing';
import usePricesQuery, { Currency } from '@queries/price/usePricesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Alert from '@ui/atoms/Alert';
import Text from '@ui/atoms/Text';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import styled from 'styled-components';

interface Product {
    name: string | React.ReactNode;
    quantity: number;
    unitaryPrice: string | React.ReactNode;
    totalPrice: string | React.ReactNode;
}

const columnHelper = createColumnHelper<Product>();
const columns = [
    // @ts-ignore
    columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: () => <FormattedMessage defaultMessage="Produit" />,
    }),
    columnHelper.accessor('quantity', {
        cell: (info) => info.getValue(),
        header: () => <FormattedMessage defaultMessage="Quantité" />,
    }),
    columnHelper.accessor('unitaryPrice', {
        cell: (info) => info.getValue(),
        header: () => <FormattedMessage defaultMessage="Prix unitaire" />,
    }),
    columnHelper.accessor('totalPrice', {
        cell: (info) => info.getValue(),
        header: () => <FormattedMessage defaultMessage="Prix total" />,
    }),
];

const OfferDetails = () => {
    const subscription = useSubscriptionQuery();
    const priceLists = usePricesQuery();

    const [totalPrice, setTotalPrice] = useState(0);
    const [data, setData] = useState<Product[]>([]);

    useEffect(() => {
        let localData: Product[] = [];
        if (!subscription?.data || !priceLists?.data) return;

        const currency = (subscription.data.currency as Currency) || 'usd';

        const offerBasePeriodPrices =
            priceLists.data[currency][subscription.data.pricing][
                subscription?.data?.yearly ? 'yearly' : 'monthly'
            ];
        const basePriceInt = parseInt(offerBasePeriodPrices?.base?.match(/\d+/)?.[0] || '0', 10);
        const extraPriceInt = parseInt(offerBasePeriodPrices?.extra?.match(/\d+/)?.[0] || '0', 10);

        /**
         * Base offer row
         */
        localData.push({
            name: (
                <FormattedMessage
                    defaultMessage="Offre {offer}"
                    values={{
                        offer: PRICING_NAME[subscription.data.pricing],
                    }}
                />
            ),
            quantity: 1,
            unitaryPrice: (
                <FormattedNumber
                    value={basePriceInt}
                    style="currency"
                    currency={subscription.data.currency}
                />
            ),
            totalPrice: (
                <FormattedNumber
                    value={basePriceInt}
                    style="currency"
                    currency={subscription.data.currency}
                />
            ),
        });

        /**
         * Extra show row
         */
        const extraCount = subscription.data.quantity ? subscription.data.quantity - 1 : 0;
        if (extraCount) {
            localData.push({
                name: <FormattedMessage defaultMessage="Émission active" />,
                quantity: extraCount,
                unitaryPrice: (
                    <FormattedNumber
                        value={extraPriceInt}
                        style="currency"
                        currency={subscription.data.currency}
                    />
                ),
                totalPrice: (
                    <FormattedNumber
                        value={extraCount * extraPriceInt}
                        style="currency"
                        currency={subscription.data.currency}
                    />
                ),
            });
        }

        /**
         * Discount
         */
        if (subscription.data.discount) {
            localData.push({
                name: <FormattedMessage defaultMessage="Code Promo" />,
                quantity: 1,
                unitaryPrice: -subscription.data.discount,
                totalPrice: -subscription.data.discount,
            });
        }

        /**
         * Extra items rows
         */
        type ExtraItemNames = 'newsletter' | 'import_rss_auto' | 'upload_hours';
        const extraItemsMessages = {
            newsletter: (
                <FormattedMessage defaultMessage="Newsletter (Emails supplémentaires x1000)" />
            ),
            import_rss_auto: <FormattedMessage defaultMessage="Import RSS automatique" />,
            upload_hours: <FormattedMessage defaultMessage="10H d'upload supplémentaires" />,
            transcript_seconds: (
                <FormattedMessage defaultMessage="Heures additionnelles de Transcription" />
            ),
            episode_ai: <FormattedMessage defaultMessage="Add-on Ausha Intelligence" />,
        };

        subscription.data.extraItems.forEach((item) => {
            const rawPriceUnitary = parseInt(item.price.base.match(/\d+/)[0], 10);
            const priceUnitary = isNaN(rawPriceUnitary) ? 0 : rawPriceUnitary;
            const priceExtra = item ? priceUnitary * item.quantity : 0;

            localData.push({
                name: extraItemsMessages[item.product as ExtraItemNames] ?? item.product,
                quantity: item.quantity,
                unitaryPrice: (
                    <FormattedNumber
                        value={priceUnitary}
                        style="currency"
                        currency={subscription.data.currency}
                    />
                ),
                totalPrice: (
                    <FormattedNumber
                        value={priceExtra}
                        style="currency"
                        currency={subscription.data.currency}
                    />
                ),
            });
        });

        const discountInt = subscription.data.discount
            ? parseInt(subscription?.data?.discount?.match(/\d+/)?.[0] || '0', 10)
            : 0;
        const extraItemsTotalPrice = subscription.data.extraItems.reduce(
            (acc, item) => acc + parseInt(item.price.base.match(/\d+/)[0], 10),
            0,
        );

        setTotalPrice(
            basePriceInt + extraCount * extraPriceInt - discountInt + extraItemsTotalPrice,
        );
        setData(localData);
    }, [subscription?.data, priceLists?.data]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <OfferDetailsWrapper>
            <BoxTitleWrapper>
                <Receipt icon={icon({ name: 'receipt', style: 'solid' })} />
                <BoxTitle>
                    <FormattedMessage defaultMessage="Détails de l'offre" />
                </BoxTitle>
            </BoxTitleWrapper>
            {/* @ts-ignore */}
            <Alert
                variant="info"
                icon={<InfoIcon icon={icon({ name: 'info-circle', style: 'solid' })} />}
            >
                {subscription?.data?.currentPeriodEnd && (
                    // @ts-ignore
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage
                            defaultMessage="Vous serez débité de {price} le {date}."
                            values={{
                                date: (
                                    <FormattedDate
                                        value={subscription?.data?.currentPeriodEnd}
                                        year="numeric"
                                        month="long"
                                        day="2-digit"
                                    />
                                ),
                                price: (
                                    <FormattedNumber
                                        value={totalPrice}
                                        style="currency"
                                        currency={subscription?.data?.currency || 'eur'}
                                    />
                                ),
                            }}
                        />
                    </Text>
                )}
            </Alert>

            <Table>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <Th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <Td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Td colSpan={3}>
                            {subscription?.data?.yearly || false ? (
                                <FormattedMessage defaultMessage="Total annuel" />
                            ) : (
                                <FormattedMessage defaultMessage="Total mensuel" />
                            )}
                        </Td>
                        <Td>
                            {' '}
                            <FormattedNumber
                                value={totalPrice}
                                style="currency"
                                currency={subscription?.data?.currency || 'eur'}
                            />
                        </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </OfferDetailsWrapper>
    );
};

const OfferDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: var(--r-l);
    min-height: 15rem;
    padding: 1.5rem;
    gap: 1.5rem;
    overflow-x: auto;
`;
const BoxTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Receipt = styled(FontAwesomeIcon)`
    font-size: 1rem;
    color: var(--primary);
`;
const BoxTitle = styled.div`
    font-weight: var(--fw-semibold);
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--info);

    & svg {
        width: initial;
        height: initial;
    }

    font-size: 1rem;
`;
const Table = styled.table`
    border-collapse: collapse;
`;
const Thead = styled.thead``;
const Td = styled.td`
    padding: 1rem;
`;
const Tr = styled.tr`
    & > ${Td}:not(:first-of-type) {
        text-align: right;
    }

    & > ${Td}:first-of-type {
        width: 100%;
    }
`;
const Th = styled.th`
    background-color: var(--neutral50);
    padding: 1rem;
    text-align: left;

    @media (min-width: 768px) {
        white-space: nowrap;
    }

    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    &:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const Tbody = styled.tbody`
    & > ${Tr} {
        border-bottom: 1px solid var(--neutral50);
    }
`;
const Tfoot = styled.tfoot`
    & ${Td} {
        font-weight: var(--fw-semibold);
    }
`;

export default OfferDetails;
