import styled from 'styled-components';
import ProgressBar from '@ui/ProgressBar';

interface DifficultyScoreCellProps {
    score: number;
}

const DifficultyScoreCell = ({ score }: DifficultyScoreCellProps) => {
    return (
        <DifficultyScoreWrapper>
            <Score>{score}</Score>
            <ProgressBar
                score={Math.min(100, Math.max(5, score))}
                thresholds={[0, 31, 71]}
                isReversed={true}
            />
        </DifficultyScoreWrapper>
    );
};

const DifficultyScoreWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > nth-child(1) {
        flex: 1;
    }
`;
const Score = styled.div`
    width: 1.5rem;
`;

export default DifficultyScoreCell;
