import AvatarIcon from '@public/icons/avatar-third-party-review.svg';
import CapterraLogo from '@public/images/capterra.png';

const PROVIDER = 'Capterra';

const LOGO = CapterraLogo;

const NOTE = {
    score: 4.8,
    gradeOf: 5,
    colorScheme: 'capterra',
};

const NOTICES = [
    {
        id: crypto.randomUUID(),
        user: 'Rick H.',
        avatar: AvatarIcon,
        link: 'https://www.capterra.fr/reviews/1017198/ausha?overall_rating_ge=5#facets',
        title: 'Ausha is a BIG hit in the US',
        notice: '"My overall experience with Ausha has been nothing but positive. The team at Ausha is committed to providing the best service and are dedicated to not just hosting your podcast but helping you build by offering you all the features on the site."',
    },
    {
        id: crypto.randomUUID(),
        user: 'Kelly B.',
        avatar: AvatarIcon,
        link: 'https://www.capterra.fr/reviews/1017198/ausha?overall_rating_ge=5#facets',
        title: 'Very happy since the beginning',
        notice: '"The customer service is just the best. Very reactive and detailed oriented. Ausha is easy to use and I am not looking at changing anytime soon."',
    },
    {
        id: crypto.randomUUID(),
        user: 'Adele D.',
        avatar: AvatarIcon,
        link: 'https://www.capterra.fr/reviews/1017198/ausha?overall_rating_ge=5#facets',
        title: 'The best way to get started',
        notice: '"Ausha is very easy to handle. It takes away the weight of the initial hassle. The customer service is very responsive to any question."',
    },
];

export default { PROVIDER, LOGO, NOTE, NOTICES };
