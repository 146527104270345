import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from '@/components/legacy/connect';
import Pagination from '@ui/molecules/Pagination';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import PaperGrid from '@ui/atoms/PaperGrid';
import Column from '@ui/atoms/Column';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import FetcherBrowsers from '@/components/unorganized/FetcherBrowsers';
import { round } from '@/shared/utils/number';
import { compose } from '@/shared/utils/compose';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import exportIcon from '@public/icons/external-link.svg';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import NoDataChart from '@/components/charts/NoData/NoDataChart';
import styled from 'styled-components';

const ExportCsv = ({ exportCsv }) => (
    <DeprecatedPaper flex justify="flex-end" mt={15}>
        <DeprecatedText flex align="center" color="--primary" weight="semibold" onClick={exportCsv}>
            <DeprecatedIcon as={exportIcon} color="--primary" mr={5} />
            <FormattedMessage defaultMessage="Exporter en .csv" />
        </DeprecatedText>
    </DeprecatedPaper>
);

const enhance = compose(
    injectIntl,
    connect(({ sourcesStatsStore, routerStore }) => ({
        browsers: sourcesStatsStore.browsers,
        showId: routerStore.params.showId,
        itemType: routerStore.params.itemType,
        itemId: routerStore.params.itemId,
        pagination: sourcesStatsStore.browsersPagination,
        exportCsv: sourcesStatsStore.fetchBrowsersExportCsv,
    })),
);

const SourcesBrowsersTable = ({
    browsers,
    showId,
    itemType,
    itemId,
    pagination,
    intl,
    exportCsv,
}) => {
    const { startDate, endDate } = useStatsDateSelectorContext();

    return (
        <>
            <DeprecatedWhitePaper my={25}>
                <PaperGrid column={4} py={8} px={20}>
                    <Column startColumn={1} endColumn={3} color="--neutral500">
                        <FormattedMessage defaultMessage="Navigateur" />
                    </Column>
                    <Column startColumn={3} endColumn={4} justify="flex-end" color="--neutral500">
                        <FormattedMessage defaultMessage="Écoutes" />
                    </Column>
                    <Column startColumn={4} endColumn={5} color="--neutral500" justify="flex-end">
                        <FormattedMessage defaultMessage="Pourcentage" />
                    </Column>
                </PaperGrid>

                <FetcherBrowsers
                    showId={showId}
                    start={startDate}
                    end={endDate}
                    itemType={itemType}
                    itemId={itemId}
                    page={pagination.currentPage}
                    fallback={ShortListFallback}
                >
                    {browsers.length > 0 ? (
                        browsers.map((browser) => (
                            <PaperGrid
                                column={4}
                                py={15}
                                px={20}
                                key={browser.name}
                                bt={1}
                                border="--neutral100"
                            >
                                <Column startColumn={1} endColumn={3} ellipsis>
                                    {browser.name === 'other'
                                        ? intl.formatMessage({ defaultMessage: 'Autres' })
                                        : browser.name}
                                </Column>
                                <Column startColumn={3} endColumn={4} justify="flex-end">
                                    {browser.count}
                                </Column>
                                <Column startColumn={4} endColumn={5} justify="flex-end">
                                    {`${round(browser.percentage)}%`}
                                </Column>
                            </PaperGrid>
                        ))
                    ) : (
                        <NoDataChartWrapper>
                            <NoDataChart />
                        </NoDataChartWrapper>
                    )}
                </FetcherBrowsers>
            </DeprecatedWhitePaper>
            <DeprecatedPaper position="relative">
                <Pagination
                    mb={25}
                    pagination={pagination}
                    plural={<FormattedMessage defaultMessage="navigateurs" />}
                    singular={<FormattedMessage defaultMessage="navigateur" />}
                >
                    <ExportCsv exportCsv={() => exportCsv(showId, startDate, endDate)} />
                </Pagination>
            </DeprecatedPaper>
        </>
    );
};

const NoDataChartWrapper = styled.div`
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 4rem;
    }
`;

export default enhance(SourcesBrowsersTable);
