import { createContext, useState, ReactNode, useContext } from 'react';

export interface CurrentShowContextType {
    showId: string;
    setShowId: (id: string) => void;
}

interface CurrentShowProviderProps {
    children: ReactNode;
}

export const CurrentShow = createContext<CurrentShowContextType | null>(null);

export const CurrentShowContext = ({ children }: CurrentShowProviderProps) => {
    const lastShowId = localStorage.getItem('last-show-id');
    const [showId, setShowId] = useState<string>(lastShowId || '');

    const handleShowIdChange = (id: string) => {
        if (!id) {
            localStorage.removeItem('last-show-id');
        } else {
            localStorage.setItem('last-show-id', id);
        }
        setShowId(id);
    };

    return (
        <CurrentShow.Provider value={{ showId, setShowId: handleShowIdChange }}>
            {children}
        </CurrentShow.Provider>
    );
};

export const useCurrentShow = () => {
    const context = useContext(CurrentShow);
    if (!context) {
        throw new Error('useCurrentShowContext must be used within a CurrentShowProvider');
    }
    return context;
};
