import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/context/EpisodeEditContext';
import styled from 'styled-components';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import EpisodeEditResumeNoAudioFile from '../EpisodeEditResumeNoAudioFile';
import EpisodeEditResumeAudioFileUploaded from '../EpisodeEditResumeAudioFileUploaded';
import { useEffect } from 'react';
import AudioFileUploadLoader from '@/components/ui/AudioFileUploadLoader';
import AudioFileUploaderSkeleton from '@/components/ui/AudioFileUploadLoader/AudioFileUploaderSkeleton';
import useEpisodeAudioFileJobsQuery from '@/queries/episode/useEpisodeAudioFileJobsQuery';

const EpisodeEditResumeAudioFile = () => {
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [uploadingAudioFile, setEpisodeEditAudioFile] = useEpisodeEditAudioFileState();
    const episodeBeingEdited = useEpisodeBeingEdited();
    const uploadedAudioFile = episodeBeingEdited?.files?.[0];
    const audioFileJobs = useEpisodeAudioFileJobsQuery({
        episodeId: episodeBeingEdited?.id,
        options: {
            enabled: !!episodeBeingEdited?.id,
        },
    });
    const isLoading =
        episodeEditAudioFileMutation.isLoading ||
        audioFileJobs.data?.converttomp3?.isExecuting ||
        audioFileJobs.data?.converttomp3?.isQueued ||
        false;

    useEffect(() => {
        if (audioFileJobs.isError || audioFileJobs.data?.converttomp3?.isFinished) {
            setEpisodeEditAudioFile(null);
        }
    }, [audioFileJobs.isError, audioFileJobs.data?.converttomp3?.isFinished]);

    if (!audioFileJobs) {
        return (
            <Container>
                <AudioFileUploaderSkeleton />
            </Container>
        );
    }

    if (isLoading) {
        return (
            <Container>
                <AudioFileUploadLoader file={uploadingAudioFile} />
            </Container>
        );
    }

    if (uploadedAudioFile) {
        return (
            <Container>
                <EpisodeEditResumeAudioFileUploaded
                    file={uploadedAudioFile}
                    audioUrl={episodeBeingEdited.audioUrl}
                />
            </Container>
        );
    }

    return (
        <Container>
            <EpisodeEditResumeNoAudioFile />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
`;

export default EpisodeEditResumeAudioFile;
