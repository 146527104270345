import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import RouterLink from '@/components/Link/RouterLink';

interface LiveSearchLinkCellProps {
    to: string;
}

const LiveSearchLinkCell = ({ to }: LiveSearchLinkCellProps) => {
    return (
        <CellWrapper>
            <RouterLink to={to}>
                <LinkIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
            </RouterLink>
        </CellWrapper>
    );
};

const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LinkIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default LiveSearchLinkCell;
