import { FormattedMessage } from 'react-intl';
import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';
import IconButton from '@/components/IconButton';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@/components/ui/atoms/Modal';
import PasswordForm from './PasswordForm';

interface DeleteAccountModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const PasswordModal = ({ onOpenChange, isOpen }: DeleteAccountModalProps) => (
    <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
        <Header>
            <Text variant="headingM" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Suppression du compte" />
            </Text>
            <IconButton
                icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                onPress={() => onOpenChange(false)}
                variant="ghost"
                aria-label="Close modal"
                style={{ padding: 0 }}
            />
        </Header>
        <PasswordForm onAfterSubmit={() => onOpenChange(false)} />
    </ModalStyled>
);

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

export default PasswordModal;
