import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchAINewsletterSuggestions } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import aiKeys from './aiKeys';
import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import { decamelizeKeys } from 'humps';
import { z } from 'zod';
import { delayQuery } from '@/shared/utils/async';

export const AINewsletterSuggestionsSchema = z.object({
    suggestion: z.object({
        title: z.string(),
        content: z.string(),
    }),
});

type Key = QueryFunctionContext<ReturnType<typeof aiKeys.newsletterMessageDetails>>;

export type AINewsletterMessageSuggestions = z.infer<
    typeof AINewsletterSuggestionsSchema
>['suggestion']['content'];

interface DelayedQueryFnParams {
    queryContext: Key;
    delay: number;
}

const delayedQueryFn = ({
    queryContext,
    delay,
}: DelayedQueryFnParams): ReturnType<typeof queryFn> =>
    delayQuery({ callback: queryFn, delay })(queryContext);

const queryFn = async ({ queryKey: [{ episodeId, settings }] }: Key) => {
    const { data } = await fetchAINewsletterSuggestions(episodeId, decamelizeKeys(settings));
    const suggestions = AINewsletterSuggestionsSchema.parse(camelcaseKeys(data, { deep: true }));
    return suggestions.suggestion.content;
};

interface AINewsletterMessageSuggestionsQueryParams {
    episodeId: string;
    settings: AICustomPromptFormSchema;
    delay?: number;
}

const useAINewsletterMessageSuggestionsQuery = ({
    episodeId,
    settings,
    delay = 0,
}: AINewsletterMessageSuggestionsQueryParams) => {
    return useQuery({
        queryKey: aiKeys.newsletterMessageDetails(episodeId, settings),
        queryFn: (queryContext: Key) => delayedQueryFn({ queryContext, delay }),
        enabled: false,
    });
};

export default useAINewsletterMessageSuggestionsQuery;
