import { Platform } from '@/api/pso/types';

const psoKeywordsRankingKeys = {
    all: () => [{ entity: 'psoKeywordsRanking' }],
    list: (showId: string, platform: Platform, store: string, format?: string) => [
        {
            ...psoKeywordsRankingKeys.all()[0],
            showId,
            platform,
            store,
            format,
            scope: 'list',
        },
    ],
};

export default psoKeywordsRankingKeys;
