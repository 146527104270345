import styled, { css, keyframes } from 'styled-components';
import Cluster from '../layout/Cluster';
import Stack from '../layout/Stack';

const AudioFileUploaderSkeleton = () => {
    return (
        <Cluster $gap="1rem">
            <Block />
            <Stack>
                <DarkLine />
                <LightLine />
            </Stack>
        </Cluster>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Line = styled.div`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${shimmering};
`;
const DarkLine = styled(Line)`
    width: 7.5rem;
    height: 0.5rem;
`;
const LightLine = styled(Line)`
    background-color: var(--neutral50);
    width: 2.5rem;
    height: 0.5rem;
`;
const Block = styled.div`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    width: 2rem;
    height: 2rem;
    ${shimmering};
`;

export default AudioFileUploaderSkeleton;
