import { useState } from 'react';
import Button from '@/components/Button';
import ExternalLink from '@/components/Link/ExternalLink';
import Alert from '@/components/ui/atoms/Alert';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { useCurrentShow } from '@/context/CurrentShowContext';
import useDeleteShowMutation from '@/queries/show/useDeleteShowMutation.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';
import useShowHasHostingAccess from '@/shared/hooks/useShowHasHostingAccess.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import DeleteShowModal from './components/DeleteShowModal';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import { useQueryClient } from '@tanstack/react-query';
import showKeys from '@/queries/show/showKeys';

const DeleteShow = () => {
    const intl = useIntl();
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const showHasHostingAccess = useShowHasHostingAccess(showId);
    const deleteShow = useDeleteShowMutation();
    const currentShow = useCurrentShow();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const queryClient = useQueryClient();

    if (show) {
        const hasEpisodes = show.podcastsCount > 0;

        const handleDeleteShow = async () => {
            if (hasEpisodes) return;

            deleteShow.mutate(
                { showId: `${show.id}` },
                {
                    onSuccess: () => {
                        // Remove this show from the current show context and from local storage
                        localStorage.removeItem('last-show-id');
                        currentShow?.setShowId('');
                        //Remove show queries from cache to avoid getting deleted show in AppMiddleware
                        queryClient.removeQueries({ queryKey: showKeys.detailById(`${show.id}`) });
                        queryClient.removeQueries({ queryKey: showKeys.list() });
                        // Redirect to shows list
                        history.push('/app');
                    },
                },
            );
        };

        return (
            <>
                <Container>
                    <Show>
                        <ShowCover src={show.imageUrl ?? IMG_PLACEHOLDER} alt={show.name} />
                        <Text fontWeight="--fw-bold">{show.name}</Text>
                    </Show>
                    <ResponsiverWrapper>
                        <Stack $gap="0.25rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Supprimer l'émission pour la retirer définitivement d'Ausha et de toutes les plateformes d'écoute." />
                            </Text>
                            <Text color="--neutral500">
                                <FormattedMessage
                                    defaultMessage="Cette action est irréversible et toutes les données associées seront perdues. Assurez-vous que vous souhaitez bien effacer définitivement votre émission avant de confirmer. <link>En savoir plus</link>"
                                    values={{
                                        link: (chunks: string) => (
                                            <ExternalLink
                                                href={intl.formatMessage({
                                                    defaultMessage:
                                                        'https://help.ausha.co/fr/articles/5056485-comment-supprimer-un-episode-de-mon-emission',
                                                })}
                                                target="_blank"
                                            >
                                                {chunks}
                                            </ExternalLink>
                                        ),
                                    }}
                                />
                            </Text>
                        </Stack>
                        <Button
                            variant="danger"
                            startIcon={<TrashIcon icon={icon({ name: 'trash', style: 'solid' })} />}
                            isDisabled={showHasHostingAccess && hasEpisodes}
                            onPress={() => setIsDeleteModalOpen(true)}
                        >
                            <FormattedMessage defaultMessage="Supprimer l'émission" />
                        </Button>
                    </ResponsiverWrapper>
                    {showHasHostingAccess && hasEpisodes && (
                        <Alert variant="warning" defaultIcon={true}>
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="Votre émission contient {count, plural, one {# épisode} other {# épisodes}}. Vous devez {count, plural, one {le} other {les}} supprimer ou {count, plural, one {le} other {les}} déplacer avant de pouvoir supprimer votre émission."
                                    values={{ count: show.podcastsCount }}
                                />
                            </Text>
                        </Alert>
                    )}
                </Container>
                <DeleteShowModal
                    isOpen={isDeleteModalOpen}
                    onOpenChange={setIsDeleteModalOpen}
                    onDelete={handleDeleteShow}
                    isLoading={deleteShow.isLoading}
                />
            </>
        );
    }

    return <ShortListFallback />;
};

const Container = styled.div`
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Show = styled.div`
    padding: 0.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral50);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    width: fit-content;
`;
const ShowCover = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-xs);
`;
const ResponsiverWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > :first-child {
            max-width: 38rem;
            flex-grow: 1;
        }

        & > :last-child {
            flex-shrink: 0;
        }
    }
`;
const TrashIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default DeleteShow;
