import styled from 'styled-components';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import Text from '../ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedDate, FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import RouterLink from '../Link/RouterLink';
import { IMG_PLACEHOLDER, USER_ROLES } from '@/shared/config/constants';
import useDuplicateEpisodeMutation from '@/queries/episode/useDuplicateEpisodeMutation.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { useHistory, useParams } from 'react-router';
import Button from '../Button';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '../ui/Tooltip';

import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface NonHostingEpisodePreviewProps {
    episode: Episode;
}

const NonHostingEpisodePreview = ({ episode }: NonHostingEpisodePreviewProps) => {
    const { showId } = useParams<{ showId: string }>();
    const showQuery = useShowQuery(showId);
    const history = useHistory();
    const duplicateEpisode = useDuplicateEpisodeMutation();
    const toast = useBodyToastQueue();
    const userHasWriteAccess = showQuery.data?.userRole !== USER_ROLES.VIEWER;

    const handleCreateDraft = () => {
        sendAmplitudeLogEvent('Copy in Workspace');
        duplicateEpisode.mutate(
            { episodeId: `${episode.id}` },
            {
                onSuccess: (episode) => {
                    localStorage.setItem(`episode-${episode.id}-audio-file-uploaded`, 'true');
                    history.replace(
                        `/app/show/${episode.showId}/workspace/episodes/${episode.id}?name=${episode.name}&loader=true`,
                    );
                },
                onError: () => {
                    // TODO: Use specific error message
                    toast.alert();
                },
            },
        );
    };

    return (
        <Wrapper>
            <Header>
                <TitleWrapper>
                    <GoBackButton
                        to={`/app/show/${episode.showId}/feed`}
                        variant="button-tertiary"
                        aria-label="back"
                        startIcon={
                            <ArrowIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                        }
                    />
                    <Text variant="title" fontWeight="--fw-bold">
                        {episode.name}
                    </Text>
                </TitleWrapper>
                <TooltipTrigger delay={0} closeDelay={0} isDisabled={userHasWriteAccess}>
                    <TooltipTriggerWrapper>
                        <Button
                            isDisabled={!userHasWriteAccess}
                            onPress={handleCreateDraft}
                            isLoading={duplicateEpisode.isLoading}
                            startIcon={
                                <OptimizeIcon
                                    icon={icon({ name: 'rocket-launch', style: 'solid' })}
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Copier dans l'espace de travail" />
                        </Button>
                    </TooltipTriggerWrapper>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="L'ajout ou la modification des épisodes n'est pas disponible en tant qu'invité Lecteur sur l'émission." />
                    </Tooltip>
                </TooltipTrigger>
            </Header>
            <EpisodeDetails>
                <EpisodeCoverWrapper>
                    <EpisodeCover
                        src={episode.imageUrl ?? episode.show?.imageUrl ?? IMG_PLACEHOLDER}
                        alt={episode.name ?? ''}
                    />
                    <MobileEpisodeDate>
                        <CalendarIcon icon={icon({ name: 'calendar-days', style: 'solid' })} />
                        <Text>{<FormattedDate value={episode.createdAt ?? ''} />}</Text>
                    </MobileEpisodeDate>
                </EpisodeCoverWrapper>
                <EpisodeDetailsInner>
                    <DesktopEpisodeDate>
                        <CalendarIcon icon={icon({ name: 'calendar-days', style: 'solid' })} />
                        <Text>{<FormattedDate value={episode.createdAt ?? ''} />}</Text>
                    </DesktopEpisodeDate>
                    <div>{ReactHtmlParser(episode.htmlDescription)}</div>
                    <EpisodeTags>
                        {episode?.tags?.map((tag) => (
                            <Tag key={tag.id}>{tag.name}</Tag>
                        ))}
                    </EpisodeTags>
                </EpisodeDetailsInner>
            </EpisodeDetails>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    max-width: var(--large-container-width);
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-items: center;
    }
`;
const ArrowIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;
const GoBackButton = styled(RouterLink)`
    border-radius: var(--r-full);
    background-color: var(--white);
    margin-top: 0.25rem;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        margin-top: 0;
    }
`;
const OptimizeIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const EpisodeDetails = styled.div`
    background-color: var(--white);
    padding: 2rem;
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 1.5rem;
    }
`;
const EpisodeCover = styled.img`
    width: 6rem;
    height: 6rem;
    border-radius: var(--r-m);
    object-fit: cover;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        width: 17.5rem;
        height: 17.5rem;
    }
`;
const EpisodeDate = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    color: var(--neutral500);
`;
const MobileEpisodeDate = styled(EpisodeDate)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const DesktopEpisodeDate = styled(EpisodeDate)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
    }
`;
const CalendarIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const EpisodeCoverWrapper = styled.div`
    display: flex;
    align-items: start;
    column-gap: 1rem;
    flex-shrink: 0;
`;
const EpisodeTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;
const Tag = styled.div`
    padding: 0.125rem 0.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-xs);
`;
const EpisodeDetailsInner = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-grow: 1;
    }
`;

export default NonHostingEpisodePreview;
