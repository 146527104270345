import PsoNav from '../PsoNav';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface PsoLayoutProps {
    userHasWriteAccess: boolean;
    children: React.ReactNode;
}

const PsoLayout = ({ userHasWriteAccess, children }: PsoLayoutProps) => {
    return (
        <PsoLayoutWrapper>
            <Sidebar>
                <TitleAndSubtitle>
                    <Title>
                        <FormattedMessage defaultMessage="Référencement" />
                    </Title>
                    <Subtitle>PSO Control Panel</Subtitle>
                </TitleAndSubtitle>
                <PsoNav userHasWriteAccess={userHasWriteAccess} />
            </Sidebar>
            <ContentWrapper>{children}</ContentWrapper>
        </PsoLayoutWrapper>
    );
};
const PsoLayoutWrapper = styled.div`
    width: 100%;
    max-width: var(--container-width);
    padding-inline: var(--container-padding);
    gap: 2rem;
    margin-block: 2rem;
    justify-content: center;

    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
`;
const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    width: 15rem;
    height: 20rem;
    position: sticky;
    top: 8.5rem;
    left: 0;
    gap: 2rem;
`;
const TitleAndSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const Title = styled.h1`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
    line-height: 1.4rem;
`;
const Subtitle = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
    color: var(--neutral);
    line-height: 1rem;
`;

export default PsoLayout;
