import styled from 'styled-components';
import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@/queries/ai/aiKeys';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import useAITitleSuggestionsQuery from '@queries/ai/useAITitleSuggestionsQuery.hook';
import { useParams } from 'react-router';
import { useEpisodeAILoading } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAILoadingContext';
import { useEpisodeAISettings } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAISettingsContext';

import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const EpisodeAITitleSuggestionsPanel = () => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const titleSettings = settings.get('title') || { customPrompt: '', saveAsDefault: false };
    const titleSuggestionsQuery = useAITitleSuggestionsQuery({
        episodeId,
        settings: titleSettings,
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('title') || false;
    const { setValue } = useFormContext();
    const queryClient = useQueryClient();
    const { open, isExpanded } = useAccordionItem();

    if (open && !isExpanded && titleSuggestionsQuery.isRefetching) {
        open();
    }

    // If the query is not fetching and there is no data, suggestions panel should not be displayed
    if (!titleSuggestionsQuery.data && !isLoading) {
        return null;
    }

    const handleClose = () => {
        // Reset the suggestions query when the panel is closed
        queryClient.resetQueries({ queryKey: aiKeys.title() });
    };

    const handleSetSuggestion = (suggestion: string) => {
        setValue('content.name', suggestion, { shouldDirty: true });
        handleClose();
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'title',
            custom_prompt: titleSettings.customPrompt || '',
            suggestions: titleSuggestionsQuery.data?.suggestions,
        });
    };

    return (
        <EpisodeAISuggestionsPanel
            title={
                <FormattedMessage defaultMessage="Choisissez votre titre parmi les suggestions ci-dessus" />
            }
            type="title"
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-title-suggestions-panel"
            onDislike={handleDislike}
        >
            <SuggestionsList>
                {titleSuggestionsQuery.data?.suggestions.map((suggestion) => (
                    <Suggestion onClick={() => handleSetSuggestion(suggestion)} key={suggestion}>
                        <Text fontWeight="--fw-semibold">{suggestion}</Text>
                    </Suggestion>
                ))}
            </SuggestionsList>
        </EpisodeAISuggestionsPanel>
    );
};

const SuggestionsList = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

const Suggestion = styled.li`
    width: fit-content;
    padding-inline: 0.75rem;
    padding-block: 0.5rem;
    background-color: var(--white500);
    border-top-left-radius: var(--r-s);
    border-bottom-right-radius: 62px; // var(--r-full) causes conflict with border-top-right-radius
    border-bottom-left-radius: var(--r-xl);
    border-top-right-radius: 62px; // var(--r-full) causes conflict with border-bottom-right-radius

    cursor: pointer;

    &:hover {
        background-color: var(--white);
    }
`;

export default EpisodeAITitleSuggestionsPanel;
