import { Modal, ModalOverlay } from 'react-aria-components';
import styled, { keyframes } from 'styled-components';

interface MobileDropdownProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    children: React.ReactNode;
}

const MobileDropdown = ({ children, ...props }: MobileDropdownProps) => {
    return (
        <Overlay isDismissable={true} {...props}>
            <ModalStyled isDismissable={true} {...props}>
                {children}
            </ModalStyled>
        </Overlay>
    );
};

const animationIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(40px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;
const animationOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(40px);
    }
`;
const Overlay = styled(ModalOverlay)`
    background: hsla(0, 0%, 0%, 0.5);
    position: fixed;
    z-index: 100;
    inset: 0;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 0.5rem;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const ModalStyled = styled(Modal)`
    border-radius: var(--r-l);
    width: 100%;
    background-color: var(--white);
    max-height: calc(100dvh - 8rem) !important;
    box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &[data-entering] {
        animation: ${animationIn} 0.25s ease-in-out;
    }
    &[data-exiting] {
        animation: ${animationOut} 0.25s ease-in-out;
    }
`;

export default MobileDropdown;
