import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';
import Sidebar from '@/shared/components/Sidebar';
import SidebarItem from '@/shared/components/Sidebar/Item/Item';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import styled from 'styled-components';

const Nav = () => {
    const location = useLocation();
    const currentSegment = location.pathname.split('/').pop();
    const basePath = `/app/user`;

    const { data: user } = useUserQuery();
    const userHasHostingAccess = user?.canHostOnAusha === true;
    const hasAccessToUsersManagement =
        user?.options?.includes(USER_OPTIONS.MULTI_USERS_MANAGEMENT) && !user?.guest;

    return (
        <Sidebar>
            <CustomSidebarItem isActive={currentSegment === 'profile'}>
                <NavRouterLink to={`${basePath}/profile`}>
                    <FormattedMessage defaultMessage="Profil" />
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'subscription'}>
                <NavRouterLink to={`${basePath}/subscription`}>
                    <FormattedMessage defaultMessage="Abonnement" />
                </NavRouterLink>
            </CustomSidebarItem>
            {hasAccessToUsersManagement && (
                <CustomSidebarItem isActive={currentSegment === 'users'}>
                    <NavRouterLink to={`${basePath}/users`}>
                        <FormattedMessage defaultMessage="Utilisateurs" />
                    </NavRouterLink>
                </CustomSidebarItem>
            )}
            {userHasHostingAccess && (
                <CustomSidebarItem isActive={currentSegment === 'email'}>
                    <NavRouterLink to={`${basePath}/email`}>
                        <FormattedMessage defaultMessage="Notifications" />
                    </NavRouterLink>
                </CustomSidebarItem>
            )}
            {userHasHostingAccess && (
                <CustomSidebarItem isActive={currentSegment === 'public-api'}>
                    <NavRouterLink to={`${basePath}/public-api`}>
                        <FormattedMessage defaultMessage="API Publique" />
                    </NavRouterLink>
                </CustomSidebarItem>
            )}
            <CustomSidebarItem isActive={currentSegment === 'delete'}>
                <NavRouterLink to={`${basePath}/delete`}>
                    <FormattedMessage defaultMessage="Suppression du compte" />
                </NavRouterLink>
            </CustomSidebarItem>
        </Sidebar>
    );
};

const NavRouterLink = styled(RouterLink)`
    color: inherit;
`;
const CustomSidebarItem = styled(SidebarItem)`
    &:not([data-active='true']) svg {
        color: var(--neutral);
    }
`;

export default Nav;
