import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchRssInfo = (query) => {
    return request({ method: 'GET', basePath, target: '/v1/rss/info', query });
};
export const fetchRssImport = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/rss/import` });
};
export const fetchRssSettings = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/rss/settings` });
};
export const addRssImport = (show, body) => {
    return request({ method: 'POST', basePath, target: `/v1/shows/${show}/rss/import`, body });
};
export const retryImport = (id) => {
    return request({ method: 'POST', basePath, target: `/v1/rss/imports/episodes/${id}/retry` });
};
export const deleteImportedEpisode = (id) => {
    return request({ method: 'DELETE', basePath, target: `/v1/rss/imports/episodes/${id}` });
};
export const deleteRssImport = (show) => {
    return request({ method: 'DELETE', basePath, target: `/v1/shows/${show}/rss/import` });
};
export const updateRssImport = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/rss/import`, body });
};
export const updateRssSettings = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/rss/settings`, body });
};
export const fetchAppleShows = (q) => {
    return request({ method: 'GET', basePath, target: '/v1/rss/search', query: { q } });
};
export const fetchCategories = () => {
    return request({ method: 'GET', basePath, target: '/v1/rss/categories' });
};
export const refreshRssFeed = (show) => {
    return request({ method: 'POST', basePath, target: `/v1/shows/${show}/rss/import/refresh` });
};
