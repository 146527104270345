import useUserQuery from '@queries/user/useUserQuery.hook';

type InputProps = {
    enabled?: boolean;
};

const useUserHasHostingAccess = (options: InputProps = {}) => {
    const user = useUserQuery(options);
    return user.data?.canHostOnAusha === true;
};

export default useUserHasHostingAccess;
