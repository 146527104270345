import { request } from '../api';
import { uploadImage } from '../uploadImage';

const basePath = process.env.API_CONTENT;

export const fetchPlaylists = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/playlists` });
};
export const fetchPlaylist = (playlist) => {
    return request({ method: 'GET', basePath, target: `/v1/playlists/${playlist}` });
};
export const addPlaylist = (show, body) => {
    return request({ method: 'POST', basePath, target: `/v1/shows/${show}/playlists`, body });
};
export const addPodcastToPlaylist = (playlist, podcast) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/playlists/${playlist}/podcasts/${podcast}`,
    });
};
export const removePodcastFromPlaylist = (playlist, playlistPodcast) => {
    return request({
        method: 'DELETE',
        basePath,
        target: `/v1/playlists/${playlist}/podcasts/${playlistPodcast}`,
    });
};
export const deletePlaylistImage = (playlist) => {
    return request({ method: 'DELETE', basePath, target: `/v1/playlists/${playlist}/image` });
};
export const deletePlaylist = (playlist) => {
    return request({ method: 'DELETE', basePath, target: `/v1/playlists/${playlist}` });
};
export function updatePlaylistImage(playlist, image) {
    return uploadImage(`/v1/playlists/${playlist}/image`, image);
}
export const moveEpisodesPlaylist = (playlist, playlistPodcast, body) => {
    return request({
        method: 'PUT',
        basePath,
        target: `/v1/playlists/${playlist}/podcasts/${playlistPodcast}`,
        body,
    });
};
export const updatePlaylist = (playlist, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/playlists/${playlist}`, body });
};
