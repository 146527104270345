import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import IconButton from '@/components/IconButton';

interface FollowCellProps {
    follow: boolean;
    onFollow: () => void;
}

const FollowCell = ({ follow, onFollow }: FollowCellProps) => {
    return (
        <CellWrapper>
            {follow ? (
                <TooltipTrigger delay={0} closeDelay={0}>
                    <TooltipTriggerWrapper>
                        <AlreadyFollowIcon icon={icon({ name: 'check', style: 'solid' })} />
                    </TooltipTriggerWrapper>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="Vous suivez ce mot-clé" />
                    </Tooltip>
                </TooltipTrigger>
            ) : (
                <TooltipTrigger delay={0} closeDelay={0}>
                    <TooltipTriggerWrapper>
                        <FollowButton
                            icon={<FollowIcon icon={icon({ name: 'plus', style: 'solid' })} />}
                            variant="ghost"
                            onPress={onFollow}
                            aria-label="follow"
                        />
                    </TooltipTriggerWrapper>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="Suivre" />
                    </Tooltip>
                </TooltipTrigger>
            )}
        </CellWrapper>
    );
};

const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const FollowButton = styled(IconButton)`
    padding: 0;
`;
const FollowIcon = styled(Icon)`
    color: var(--primary);
`;
const AlreadyFollowIcon = styled(Icon)`
    color: var(--neutral500);
`;

export default FollowCell;
