export const filterFalsyValues = (obj) => {
    if (Array.isArray(obj)) {
        // If it's an array, filter each element recursively
        const filteredArray = obj
            .map((item) => filterFalsyValues(item))
            .filter((item) => item !== undefined && item !== null);
        return filteredArray.length === 0 ? undefined : filteredArray;
    }

    if (typeof obj === 'object' && obj !== null) {
        // If it's an object, filter its properties recursively
        const filteredObj = {};
        for (const [key, value] of Object.entries(obj)) {
            const filteredValue = filterFalsyValues(value);
            if (filteredValue !== undefined && filteredValue !== null) {
                filteredObj[key] = filteredValue;
            }
        }

        // Check if all values in the object are falsy (or undefined/null)
        const allValuesFalsy = Object.values(filteredObj).every((value) => !value);
        return allValuesFalsy ? undefined : filteredObj;
    }

    // If it's a non-object value, return it if it's truthy, otherwise, exclude it
    return obj || undefined;
};
export const jsonToCSV = (jsonData, columns) => {
    // Check if jsonData is not an array or is empty
    if (!Array.isArray(jsonData) || jsonData.length === 0) {
        return '';
    }

    // Extract column headers (keys of the JSON objects)
    const headers = Object.keys(jsonData[0]);
    const csvRows = [];

    // Add the headers row
    if (columns) {
        csvRows.push(columns.join(','));
    } else {
        csvRows.push(headers.join(','));
    }

    // Loop over the rows
    for (const row of jsonData) {
        const values = headers.map((header) => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Escape double quotes
            return `"${escaped}"`; // Wrap values in double quotes to handle commas and line breaks
        });
        csvRows.push(values.join(','));
    }

    // Combine all rows into a single string with newline characters
    return csvRows.join('\n');
};
