import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchWebhooks = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/webhooks` });
};
export const updateWebhooks = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/webhooks`, body });
};
