import { useEffect, useState } from 'react';

function openWindowPopup(url: string) {
    const w = 800;
    const h = 600;
    const dualScreenLeft = window.screenLeft || window.screenX;
    const dualScreenTop = window.screenTop || window.screenY;
    const width = window.innerWidth || document.documentElement.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight;
    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;
    const windowParams = `menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,width=${w},height=${h},top=${top},left=${left}`;
    return window.open(url, 'POPUP', windowParams);
}

export function useWindowPopup(
    provider: 'google' | 'facebook',
    url: string,
    callback: (e: MessageEvent) => void,
) {
    const [wind, setWindow] = useState<Window | null>();
    useEffect(() => {
        window.addEventListener('message', listener);
        return () => window.removeEventListener('message', listener);
    }, []);

    function listener(e: MessageEvent) {
        if (e.origin !== window.location.origin) {
            return;
        }
        if (e.data && e.data.provider === provider) {
            callback(e);
        }
    }

    function open() {
        const win = openWindowPopup(url);
        if (!win) return;
        win.onunload = onWindowClose;
        setWindow(win);
    }

    function closeWindow() {
        if (!wind) return;
        wind.close();
    }

    function onWindowClose() {
        setWindow(null);
    }

    return { wind, open, closeWindow };
}
