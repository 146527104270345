import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import EpisodesPageHeader from './EpisodesPageHeader';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import Text from '@ui/atoms/Text';
import noEpisodeMainIllustration from '@public/images/empty-state/illustration/episodes.mp4';
// @ts-ignore
import noEpisodeHeadIllustration from '@public/images/empty-state/episodes.svg?url';
import EmptyState from '@ui/molecules/EmptyState';
import IconAdd from '@ui/icons/IconAdd';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import { USER_ROLES } from '@/shared/config/constants';
import Alert from '@ui/atoms/Alert';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { GUARD_DISALLOW_REASONS } from '@/shared/config/constants';
import EpisodesLayout from '../EpisodesLayout';
import { useEpisodeFiltersContext } from '@/context/EpisodeFiltersContext';
import { useParams } from 'react-router';
import useRssFeedQuery from '@/queries/rss/useRssFeedQuery.hook';
import useEpisodesQuery from '@/queries/episode/useEpisodesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Episodes from './Episodes';
import ServerPagination from '@/components/ui/ServerPagination';
import { EpisodeSelectContextProvider } from '@/context/EpisodeSelectContext';
import styled from 'styled-components';
import useImportedEpisodesQuery, {
    ImportedEpisode,
} from '@/queries/rss/useImportedEpisodesQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const EPISODE_POLLING_RATE = 5000;

const EpisodesPage = () => {
    useAmplitudeLogEvent('Episodes Episodes Tab Viewed');
    const { showId } = useParams<{ showId: string }>();
    const intl = useIntl();

    const { allow, reason } = useGuard({
        disallowedUserRoles: [USER_ROLES.VIEWER],
        disallowedShowStatuses: [STATUS_SHOWS.ARCHIVED_SHOWS],
    });

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    const disallowMessageLookup = {
        [GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE]: (
            <FormattedMessage defaultMessage="Vous devez être éditeur ou administrateur de l'émission pour ajouter un nouvel épisode." />
        ),
        [GUARD_DISALLOW_REASONS.INSUFFICIENT_SHOW_STATUS]: (
            <FormattedMessage defaultMessage="L'ajout d'un nouvel épisode n'est pas possible pour une émission archivée." />
        ),
    };

    const { filters, search } = useEpisodeFiltersContext();

    /**
     * Reset the pagination when the search or filters change
     */
    useEffect(() => {
        setPage(1);
    }, [search, filters.seasons, filters.statuses]);

    const { data: rssFeed, isLoading: rssFeedIsLoading } = useRssFeedQuery({ showId });
    const { data: imported } = useImportedEpisodesQuery({
        showId,
        config: {
            refetchInterval: (data: any) =>
                data?.importedEpisodes?.some((importedEpisode: ImportedEpisode) =>
                    ['processing', 'pending'].includes(importedEpisode.state),
                )
                    ? EPISODE_POLLING_RATE
                    : false,
        },
    });
    const currentShowIsImported = !!rssFeed;
    const { data: episodes, isLoading: episodesAreLoading } = useEpisodesQuery({
        showId,
        pagination: { page, perPage },
        q: search,
        filters,
        config: {
            refetchInterval: () =>
                currentShowIsImported &&
                imported?.importedEpisodes?.some((importedEpisode: ImportedEpisode) =>
                    ['processing', 'pending'].includes(importedEpisode.state),
                )
                    ? EPISODE_POLLING_RATE
                    : false,
        },
    });

    const hasEpisodes =
        (episodes && episodes?.episodes?.length > 0) ||
        (imported && imported?.importedEpisodes?.length > 0);

    if (
        rssFeedIsLoading ||
        (episodesAreLoading &&
            search === '' &&
            filters.seasons.length === 0 &&
            filters.statuses.length === 0)
    ) {
        return (
            <EpisodesLayout>
                <LoadingWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoadingWrapper>
            </EpisodesLayout>
        );
    }

    /**
     * If we are not on an imported show
     * If there's no episodes
     * If the search is empty
     * If there's no filters
     * Then we display the empty state
     */
    if (
        !currentShowIsImported &&
        episodes?.episodes.length === 0 &&
        search === '' &&
        filters.seasons.length === 0 &&
        filters.statuses.length === 0
    ) {
        return (
            <EpisodesLayout>
                <EmptyState
                    illustration={noEpisodeMainIllustration}
                    illustrationAlign="right"
                    illustrationType="video"
                >
                    <HeadIllustrationWrapper>
                        <HeadIllustration
                            src={noEpisodeHeadIllustration}
                            alt={intl.formatMessage({ defaultMessage: "Liste d'épisodes" })}
                        />
                    </HeadIllustrationWrapper>
                    <LabelWrapper>
                        <Text variant="heading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Ajoutez votre premier épisode" />
                        </Text>
                        <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                            <FormattedMessage defaultMessage="Créez et publiez le premier épisode de votre podcast 🎧" />
                        </Text>
                    </LabelWrapper>
                    {!allow && (
                        <Alert
                            variant="alert"
                            icon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'circle-info', style: 'solid' })}
                                    color={extractCssVariable('--alert500')}
                                    size="lg"
                                />
                            }
                        >
                            {disallowMessageLookup[
                                reason as keyof typeof disallowMessageLookup
                            ] ?? <FormattedMessage defaultMessage="Une erreur est survenue" />}
                        </Alert>
                    )}
                    <RouterLink
                        variant="button-primary"
                        startIcon={<IconAdd />}
                        isDisabled={!allow}
                        to={`/app/show/${showId}/episodes/edit/upload`}
                        onClick={() => {
                            sendAmplitudeLogEvent('Show Form Episode', {
                                is_new_episode: true,
                            });
                        }}
                        data-testid="episodes-empty-state-add-episode-button"
                    >
                        <FormattedMessage defaultMessage="Ajouter un épisode" />
                    </RouterLink>
                </EmptyState>
            </EpisodesLayout>
        );
    }

    return (
        <EpisodesLayout>
            <EpisodesPageWrapper>
                <EpisodesPageHeader />
                {episodesAreLoading ? (
                    <LoadingWrapper>
                        <FontAwesomeIcon
                            icon={icon({ name: 'spinner-third', style: 'solid' })}
                            spin
                        />
                    </LoadingWrapper>
                ) : hasEpisodes ? (
                    <EpisodeSelectContextProvider>
                        <Episodes
                            episodes={episodes?.episodes || []}
                            pagination={episodes?.pagination}
                            currentShowIsImported={!!currentShowIsImported}
                        />
                    </EpisodeSelectContextProvider>
                ) : (
                    <ResultsWrapper>
                        <DefaultStateWrapper>
                            <SearchIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                            <DefaultStateTitleWrapper>
                                <DefaultStateTitle>
                                    <FormattedMessage defaultMessage="Aucun résultat trouvé pour les filtres appliqués" />
                                </DefaultStateTitle>
                            </DefaultStateTitleWrapper>
                        </DefaultStateWrapper>
                    </ResultsWrapper>
                )}
                {episodes?.pagination &&
                    episodes?.episodes.length > 0 &&
                    episodes?.pagination.totalPages > 1 && (
                        <ServerPagination
                            pagination={episodes.pagination}
                            onGoToPage={(page: number) => setPage(page)}
                            onGoToFirstPage={() => setPage(1)}
                            onGoToLastPage={() => setPage(episodes.pagination.totalPages)}
                            onGoToPreviousPage={() => setPage(episodes.pagination.currentPage - 1)}
                            onGoToNextPage={() => setPage(episodes.pagination.currentPage + 1)}
                            onChangePerPage={(perPage: number) => setPerPage(perPage)}
                            options={{
                                goToFirstAndLastPageButtons: false,
                                showPaginationInfo: true,
                                showPageSelector: false,
                            }}
                        />
                    )}
            </EpisodesPageWrapper>
        </EpisodesLayout>
    );
};

const EpisodesPageWrapper = styled.div``;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const LoadingWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    padding-block: 6rem;
    margin-top: 4rem;
`;
const ResultsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-block: 1.5rem;
`;
const DefaultStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: var(--neutral500);
    width: 100%;
    max-width: 24rem;
    margin-inline: auto;
    padding-block: 2rem;
`;
const SearchIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 1.5rem;
`;
const DefaultStateTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
`;
const DefaultStateTitle = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
`;

export default EpisodesPage;
