import { request } from '../api';

const basePath = process.env.API_AUTH;

export const logout = () => {
    return request({ method: 'POST', basePath, target: '/logout' });
};
export const loginWith = ({ code, provider }) => {
    return request({
        method: 'GET',
        basePath,
        target: `/auth/${provider}/callback`,
        query: { code },
    });
};
export const requestAuthorizationCode = (query) => {
    return request({ method: 'POST', basePath, target: '/oauth/code', query });
};
export const fetchTimezones = () => {
    return request({ method: 'GET', basePath, target: '/timezones' });
};
export const fetchCountries = () => {
    return request({ method: 'GET', basePath, target: '/countries' });
};
export const updateProfile = (body) => {
    return request({ method: 'PUT', basePath, target: '/user', body });
};
export const updatePassword = (body) => {
    return request({ method: 'POST', basePath, target: '/password', body });
};
export const setResetPassword = (body) => {
    return request({ method: 'POST', basePath, target: '/password/email', body });
};
export const resetPassword = (body) => {
    return request({ method: 'POST', basePath, target: '/password/reset', body });
};
export const updateEmail = (body) => {
    return request({ method: 'POST', basePath, target: '/email', body });
};
export const deleteUserAvatar = () => {
    return request({ method: 'DELETE', basePath, target: '/user/avatar' });
};
export function uploadUserAvatar(image) {
    const body = new FormData();
    body.append('file', image);
    return request({ method: 'POST', basePath, target: '/user/avatar', body });
}
export const resendVerificationEmail = () => {
    return request({ method: 'POST', basePath, target: '/email/resend' });
};
