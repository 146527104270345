import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchUser = () => {
    return request({ method: 'GET', basePath, target: '/user' });
};
export const deleteAccount = (body) => {
    return request({ method: 'DELETE', basePath, target: '/user', body });
};
export const fetchEmailSettingsNotification = () => {
    return request({ method: 'GET', basePath, target: '/v1/user/alerts' });
};
export const updateEmailSettingsNotification = (body) => {
    return request({ method: 'PUT', basePath, target: '/v1/user/alerts', body });
};
export const fetchLanguages = () => {
    return request({ method: 'GET', basePath, target: '/v1/languages' });
};
