import { request } from '../api';
import { uploadImage } from '../uploadImage';

const basePath = process.env.API_CONTENT;

export const fetchPodcast = (podcast) => {
    return request({ method: 'GET', basePath, target: `/v1/podcasts/${podcast}` });
};
export const fetchPodcasts = (show, q, seasons, status, page, perPage = 20) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${show}/podcasts`,
        query: { q, page, per_page: perPage, status, seasons },
    });
};
export const fetchMinimalEpisodes = (showId, status, page, perPage = 20) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${showId}/podcasts`,
        query: { page, per_page: perPage, status, transformer: 'minimal' },
    });
};
export const fetchAllShrinkedEpisodes = (
    show,
    searchQuery,
    page,
    perPage,
    withClip,
    episodeStatus,
) => {
    let query = { q: searchQuery, page, per_page: perPage, state: episodeStatus };
    if (withClip !== null && withClip !== undefined) {
        query = { ...query, clip: withClip ? '1' : '0' };
    }

    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${show}/podcasts/minimal`,
        query,
    });
};
export const fetchImportedPodcasts = (id, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/rss/imports/${id}/episodes`,
        query,
    });
};
export const addPodcast = (show, body) => {
    return request({ method: 'POST', basePath, target: `/v1/shows/${show}/podcasts`, body });
};
export const duplicate = (podcast) => {
    return request({ method: 'POST', basePath, target: `/v1/podcasts/${podcast}/duplicate` });
};
export const getEpisodeSlug = (episodeId, name) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/podcasts/${episodeId}/slugify?name=${name}`,
    });
};
export const uploadEpisodeAudioFile = (podcast, fileModel) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/podcasts/${podcast}/file`,
        body: fileModel.rawFileData,
        onUploadProgress: fileModel.onProgress,
        cancelToken: fileModel.cancelToken,
    });
};
export const experimentalUploadEpisodeAudioFile = (podcast, audioFile) => {
    const body = new FormData();
    body.append('file', audioFile);
    return request({
        method: 'POST',
        basePath,
        target: `/v1/podcasts/${podcast}/file`,
        body,
    });
};
export function uploadPodcastImage(podcast, image) {
    return uploadImage(`/v1/podcasts/${podcast}/image`, image);
}
export const experimentalUploadEpisodeImage = (episodeId, image) => {
    const body = new FormData();
    body.append('file', image);
    return request({
        method: 'POST',
        basePath,
        target: `/v1/podcasts/${episodeId}/image`,
        body,
    });
};
export const uploadEpisodeYoutubeThumbnail = (episodeId, image) => {
    const body = new FormData();
    body.append('file', image);
    return request({
        method: 'POST',
        basePath,
        target: `/v1/podcasts/${episodeId}/youtube_thumbnail`,
        body,
    });
};
export const deletePodcastListeningLink = (podcast, platform) => {
    return request({
        method: 'DELETE',
        basePath,
        target: `/v1/podcasts/${podcast}/listening_links/${platform}`,
    });
};
export const deletePodcast = (podcast) => {
    return request({ method: 'DELETE', basePath, target: `/v1/podcasts/${podcast}` });
};
export const deletePodcastImage = (podcast) => {
    return request({ method: 'DELETE', basePath, target: `/v1/podcasts/${podcast}/image` });
};
export const deletePodcastYoutubeThumbnail = (podcast) => {
    return request({
        method: 'DELETE',
        basePath,
        target: `/v1/podcasts/${podcast}/youtube_thumbnail`,
    });
};
export const deleteAllPodcasts = (show) => {
    return request({ method: 'DELETE', basePath, target: `/v1/shows/${show}/podcasts` });
};
export const assignAllPodcasts = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/podcasts/season`, body });
};
export const moveAllPodcasts = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/podcasts`, body });
};
export const updatePodcast = (podcast, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/podcasts/${podcast}`, body });
};
export const updatePodcastListeningLink = (podcast, platform, body) => {
    return request({
        method: 'PUT',
        basePath,
        target: `/v1/podcasts/${podcast}/listening_links/${platform}`,
        body,
    });
};
export const addPodcastListeningLink = (podcast, body) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/podcasts/${podcast}/listening_links`,
        body,
    });
};
export const fetchPodcastListeningLinks = (podcast) => {
    return request({ method: 'GET', basePath, target: `/v1/podcasts/${podcast}/listening_links` });
};
export const fetchListenablePodcasts = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${show}/podcasts/listenables`,
        query,
    });
};
export const fetchEpisodeJobsProgress = (podcast) => {
    return request({ method: 'GET', basePath, target: `/v1/podcasts/${podcast}/jobs` });
};
export const fetchEpisodeMessages = (episodeId) => {
    return request({ method: 'GET', basePath, target: `/v1/podcasts/${episodeId}/pmp/messages` });
};
export const addEpisodeMessages = (episodeId, messages) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/podcasts/${episodeId}/pmp/messages`,
        body: messages,
    });
};
