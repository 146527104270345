import SimpleGrid from '@ui/layout/SimpleGrid';
import ClipFormat from './ClipFormat';
import FallbackClipFormat from './FallbackClipFormat';

import { makeArray } from '@/shared/utils/array';

const ClipFormatsGrid = ({ clipFormats, onEditClipFormat, isLoading = false }) => {
    return (
        <SimpleGrid $idealChildWidth={250} $rowGap={32} $columnGap={36}>
            {isLoading
                ? makeArray(16).map((_, index) => <FallbackClipFormat key={`fallback-${index}`} />)
                : clipFormats.map((clipFormat) => (
                      <ClipFormat
                          key={clipFormat.id}
                          clipFormat={clipFormat}
                          onEdit={onEditClipFormat}
                      />
                  ))}
        </SimpleGrid>
    );
};

export default ClipFormatsGrid;
