import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Login';
import LoginAs from './LoginAs';
import Register from './Register';
import EmailVerification from './PasswordRecovery/EmailVerification';
import EmailSent from './PasswordRecovery/EmailSent';
import ChangePassword from './PasswordRecovery/ChangePassword';
import HasResetPassword from './PasswordRecovery/HasResetPassword';
import ThirdPartyLoginCallback from './ThirdPartyLoginCallback';
import AushaClubCallback from './AushaClubCallback';
import { useAuthContext } from '@/context/AuthContext';

const Auth = () => {
    const { isAuthenticated } = useAuthContext();

    if (isAuthenticated) {
        return <Redirect to="/app" />;
    }

    return (
        <Switch>
            <Route exact path={'/login'}>
                <Login />
            </Route>
            <Route exact path={'/loginas'}>
                <LoginAs />
            </Route>
            <Route exact path={'/register'}>
                <Register />
            </Route>
            {/* TODO: This is a fallback route, it should be deleted in a few weeks (Today is 24/01/2025). */}
            <Route exact path={'/login/register'}>
                <Register />
            </Route>
            <Route exact path={'/password-recovery'}>
                <EmailVerification />
            </Route>
            <Route exact path={'/password-recovery/email-sent'}>
                <EmailSent />
            </Route>
            <Route exact path={'/password-recovery/change-password'}>
                <ChangePassword />
            </Route>
            <Route exact path={'/password-recovery/has-reset-password'}>
                <HasResetPassword />
            </Route>
            <Route path="/google-callback">
                <ThirdPartyLoginCallback provider="google" />
            </Route>
            <Route path="/facebook-callback">
                <ThirdPartyLoginCallback provider="facebook" />
            </Route>
            <Route path="/ausha-club-callback">
                <AushaClubCallback />
            </Route>

            {/**
             * These are fallback routes designed to prevent the third-party login
             * from breaking if the backend does not immediately redirect to the
             * new ones upon releasing the Auth refacto.
             * These should be deleted in a few weeks (Today is 24/01/2025).
             **/}
            <Route path="/login/google">
                <Redirect to="/google-callback" />
            </Route>
            <Route path="/login/facebook">
                <Redirect to="/facebook-callback" />
            </Route>
        </Switch>
    );
};

export default Auth;
