import { useState, useEffect } from 'react';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import edit from '@public/icons/edit.svg';
import trash from '@public/icons/trash.svg';
import { observer } from 'mobx-react';
import { useResponsive } from '@/shared/hooks/useResponsive';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import Desktop from '@ui/molecules/Desktop';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import Img from '@ui/atoms/Img';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Uploader from '@ui/smarties/Uploader';
import IconCamera from '@ui/icons/IconCamera';
import { commonMessages } from '@/shared/services/commonMessageService';

const variants = {
    visible: {
        opacity: 1,
        height: '100%',
        overflow: 'hidden',
        width: 'max-content',
        marginRight: 30,
    },
    hidden: { opacity: 0, height: '0%', overflow: 'hidden', width: '0px', marginRight: 0 },
};

function BackgroundUploader({
    field,
    message: CustomMessage,
    formatPage,
    getFileBackground,
    value,
    name,
    onChange,
    ...props
}) {
    const [src, setSrc] = useState(field?.value || value);
    const { isMobileOrTablet, isDesktop } = useResponsive();
    const [isOpen, onOpen] = useCycle(false, true);
    // Updates the image with the one coming from the API
    useEffect(() => {
        if (typeof field?.value === 'string' || typeof value === 'string') {
            setSrc(field?.value || value);
        }
    }, [field?.value, value]);

    function handleChange(file) {
        onChange && onChange(file);
        const reader = new FileReader();
        reader.onload = (e) => setSrc(e.target.result);
        reader.readAsDataURL(file);
        field.setValue(Object.assign(file, { formatName: formatPage }));
        getFileBackground(file);
    }

    function deleteFile() {
        setSrc(undefined);
        field && field.setValue(null);
        onChange ? onChange(null) : getFileBackground({ formatName: formatPage, action: 'DELETE' });
    }

    const noSrc = {
        minH: !src,
        h: !src,
        minW: !src,
        w: !src,
        mb: !src,
        mt: !src,
    };

    return (
        <DeprecatedPaper flex column={isDesktop} {...props} {...noSrc}>
            <DeprecatedPaper flex={isMobileOrTablet} w="max-content">
                <DeprecatedPaper flex>
                    <AnimatePresence>
                        {!isOpen && src && (
                            <motion.div
                                layout
                                key={formatPage}
                                variants={variants}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                            >
                                <Img
                                    src={src}
                                    w={props.w}
                                    minW={props.w}
                                    h={props.h}
                                    minH={props.h}
                                    style={{ objectFit: props.objectFit }}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <Desktop>
                        <DeprecatedPaper flex column align="flex-start" w="max-content">
                            {!src && (
                                <Uploader
                                    accept="image/*"
                                    onChange={handleChange}
                                    onClick={() => onOpen}
                                    name={name}
                                >
                                    <DeprecatedButton
                                        type="button"
                                        accent="secondary"
                                        maxW="max-content"
                                    >
                                        <IconCamera />
                                        {commonMessages('idealSizeImg', 'uploadImage')}
                                    </DeprecatedButton>
                                </Uploader>
                            )}
                            {src && (
                                <>
                                    <DeprecatedText
                                        disabled={!src}
                                        nowrap
                                        color="--primary"
                                        onClick={deleteFile}
                                    >
                                        {commonMessages('idealSizeImg', 'deletedImage')}
                                    </DeprecatedText>
                                    {CustomMessage}
                                </>
                            )}
                        </DeprecatedPaper>
                    </Desktop>
                </DeprecatedPaper>
                <MobileOrTablet>
                    <DeprecatedPaper column ml={10}>
                        <DeprecatedPaper flex justify="space-between">
                            <Uploader accept="image/*" onChange={handleChange}>
                                <DeprecatedButton
                                    minW="calc(50% - 5px)"
                                    align="center"
                                    accent="secondary"
                                    color="--primary"
                                    mr={10}
                                >
                                    <DeprecatedIcon as={edit} mr={0} />
                                </DeprecatedButton>
                            </Uploader>
                            <DeprecatedButton
                                minW="calc(50% - 5px)"
                                align="center"
                                accent="secondary"
                                disabled={!src}
                                color="--primary"
                                onClick={deleteFile}
                            >
                                <DeprecatedIcon as={trash} mr={0} />
                            </DeprecatedButton>
                        </DeprecatedPaper>
                        {CustomMessage}
                    </DeprecatedPaper>
                </MobileOrTablet>
            </DeprecatedPaper>
        </DeprecatedPaper>
    );
}

BackgroundUploader.defaultProps = {
    w: 480,
    minW: 480,
    h: 200,
    minH: 200,
};

export default observer(BackgroundUploader);
