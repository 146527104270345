import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useChangePasswordFormSchema, {
    ChangePasswordForm,
} from './useChangePasswordFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@/components/Button';
import useResetPasswordMutation from '@/queries/auth/useResetPasswordMutation.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useQuery from '@/shared/hooks/useQuery';

const Form = () => {
    const intl = useIntl();
    const history = useHistory();
    const ref = useRef(null);
    const toast = useModalToastQueue();
    const schema = useChangePasswordFormSchema();
    const resetPassword = useResetPasswordMutation();
    const params = useQuery();
    const token = params.get('token') || '';

    const { control, handleSubmit, formState, clearErrors } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
            password_confirmation: '',
        },
        resolver: zodResolver(schema),
    });

    const onSubmit = async (formData: ChangePasswordForm) => {
        const payload = { ...formData, token };

        resetPassword.mutate(payload, {
            onSuccess: () => {
                history.push('/password-recovery/has-reset-password');
            },
            onError: (error) => {
                const typedError = error as AxiosError;
                if (typedError?.response?.status === 422) {
                    toast.alert(
                        <FormattedMessage defaultMessage="L'adresse e-mail renseignée ne semble pas être la bonne" />,
                    );
                }
            },
        });
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                ref={ref}
                control={control}
                name="email"
                label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                placeholder={intl.formatMessage({ defaultMessage: 'neil.armstrong@nasa.com' })}
                errorMessage={formState.errors.email?.message}
                onBlur={() => clearErrors('email')}
                autoFocus
                isRequired
            />
            <InputTextController
                control={control}
                name="password"
                type="password"
                label={<FormattedMessage defaultMessage="Nouveau mot de passe" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                errorMessage={formState.errors.password?.message}
                onBlur={() => clearErrors('password')}
                isRequired
            />
            <InputTextController
                control={control}
                name="password_confirmation"
                type="password"
                label={<FormattedMessage defaultMessage="Confirmation du nouveau mot de passe" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                errorMessage={formState.errors.password_confirmation?.message}
                onBlur={() => clearErrors('password_confirmation')}
                isRequired
            />
            <SubmitButton
                type="submit"
                isLoading={resetPassword.isLoading}
                isDisabled={!formState.isValid || resetPassword.isLoading}
            >
                <FormattedMessage defaultMessage="Confirmer mon nouveau mot de passe" />
            </SubmitButton>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
`;
const SubmitButton = styled(Button)`
    align-self: stretch;
`;

export default Form;
