import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchDownloadableFiles = (query) => {
    return request({ method: 'GET', basePath, target: `/v1/downloads`, query });
};
export const deleteFile = (podcast) => {
    return request({ method: 'DELETE', basePath, target: `/v1/podcasts/${podcast}/file` });
};
