import styled from 'styled-components';
import List from './List';

interface SidebarProps {
    children: React.ReactNode;
    className?: string;
}

const Sidebar = ({ children, className }: SidebarProps) => (
    <Nav className={className}>
        <List>{children}</List>
    </Nav>
);

const Nav = styled.nav``;

export default Sidebar;
