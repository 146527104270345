import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import Spinner from '@ui/atoms/Spinner';
import useAddEpisodeMutation from '@/queries/episode/useAddEpisodeMutation.hook';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import { ACCEPTED_AUDIO_TYPES, MAX_AUDIO_FILE_SIZE } from '@/shared/config/constants';
import FileUpload from '@public/images/file-upload.svg';
import Stack from '@/components/ui/layout/Stack';
import { DropFileUploaderController } from '@/components/DropUploader';
import useNonHostingEpisodeAudioFileSchema, {
    NonHostingEpisodeAudioFileSchema,
} from '../../../NonHostingEpisodeForm/useNonHostingEpisodeAudioFileSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import Button from '@/components/Button';
import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
    useEpisodeEditState,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeFileUploadContext';

const EpisodeAudioFileUploadPage = () => {
    const intl = useIntl();
    const user = useUserQuery();
    const { showId } = useParams<{ showId: string }>();
    const addEpisodeMutation = useAddEpisodeMutation();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [_, setEpisodeEditAudioFile] = useEpisodeEditAudioFileState();
    const toast = useModalToastQueue();
    const schema = useNonHostingEpisodeAudioFileSchema(user.data?.language);
    const [_isNew, setIsNew] = useEpisodeEditState();
    const history = useHistory();

    const { control, handleSubmit, watch, setError } = useForm<NonHostingEpisodeAudioFileSchema>({
        mode: 'onChange',
        defaultValues: {
            audioFile: null,
        },
        resolver: zodResolver(schema),
    });

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const generateEpisodeName = () => {
        return intl.formatMessage(
            { defaultMessage: 'Nouvel épisode du {date} {time}' },
            {
                date: intl.formatDate(new Date(), {
                    day: '2-digit',
                    month: '2-digit',
                }),
                time: intl.formatTime(new Date()),
            },
        );
    };

    const onSkipStep = () => {
        addEpisodeMutation.mutate(
            {
                showId,
                name: generateEpisodeName(),
            },
            {
                onSuccess: (episode) => {
                    // Keep track of new episode to display or not the updated at message
                    setIsNew(true);
                    history.replace(`/app/show/${showId}/workspace/episodes/${episode.id}`);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    const onSubmit = ({ audioFile }: NonHostingEpisodeAudioFileSchema) => {
        addEpisodeMutation.mutate(
            {
                showId,
                name: generateEpisodeName(),
            },
            {
                onSuccess: (episode) => {
                    if (audioFile) {
                        // Fire mutation but does not wait because file upload
                        // progress is displayed in episode form.
                        episodeEditAudioFileMutation.mutate(
                            { episodeId: episode.id, audioFile: audioFile[0] },
                            {
                                onError: () =>
                                    setError('root.serverError', {
                                        type: '400',
                                        message: intl.formatMessage({
                                            defaultMessage: 'Une erreur est survenue',
                                        }),
                                    }),
                            },
                        );
                        // Keep audioFile in a state to display file data in
                        // episode form during file upload
                        setEpisodeEditAudioFile(audioFile[0]);
                    }

                    // Keep track of new episode to display or not the updated at message
                    setIsNew(true);
                    localStorage.setItem(`episode-${episode.id}-audio-file-uploaded`, 'true');
                    history.replace(`/app/show/${showId}/workspace/episodes/${episode.id}`);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <Container>
            <Header>
                <RouterLink variant="button-tertiary" to={`/app/show/${showId}/workspace`}>
                    <FormattedMessage defaultMessage="Annuler" />
                </RouterLink>
                <SkipButton
                    variant="ghost"
                    endIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'chevron-right', style: 'solid' })}
                            color="inherit"
                            size="sm"
                        />
                    }
                    onPress={onSkipStep}
                    data-testid="new-episode-upload-ignore-button"
                >
                    <FormattedMessage defaultMessage="Ignorer cette étape" />
                </SkipButton>
            </Header>
            <Content>
                {addEpisodeMutation.isLoading ? (
                    <SpinnerWrapper>
                        <Spinner size={64} />
                    </SpinnerWrapper>
                ) : (
                    <Form>
                        <Text
                            fontWeight="--fw-bold"
                            variant="headingL"
                            textAlign="center"
                            data-testid="new-episode-upload-heading"
                        >
                            <FormattedMessage defaultMessage="Ajouter un nouvel épisode" />
                        </Text>
                        <DropFileUploader
                            control={control}
                            name="audioFile"
                            acceptedFileTypes={[...ACCEPTED_AUDIO_TYPES]}
                        >
                            <Stack $gap="2rem" $align="center">
                                <FileUpload />
                                <Stack $gap="0.5rem" $align="center">
                                    <Text fontWeight="--fw-semibold" textAlign="center">
                                        <FormattedMessage
                                            defaultMessage="Glissez-déposez votre fichier audio, ou <label>Parcourir</label>"
                                            values={{
                                                label: (chunks: string) => (
                                                    <Text
                                                        as="span"
                                                        color="--primary"
                                                        fontWeight="--fw-semibold"
                                                    >
                                                        {chunks}
                                                    </Text>
                                                ),
                                            }}
                                        />
                                    </Text>
                                    <Text
                                        color="--neutral500"
                                        variant="footnote"
                                        textAlign="center"
                                    >
                                        <FormattedMessage
                                            defaultMessage=".MP3, .WAV, .M4A, .OGG ou .FLAC, jusqu’à {max}Mo"
                                            values={{ max: MAX_AUDIO_FILE_SIZE / 1000000 }}
                                        />
                                    </Text>
                                </Stack>
                            </Stack>
                        </DropFileUploader>
                    </Form>
                )}
            </Content>
        </Container>
    );
};

const Container = styled.div`
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 3rem;
    min-height: 100%;
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        gap: 4rem;
        padding: 2rem;
    }
`;
const Header = styled.header`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const Content = styled.div`
    align-self: center;
    max-width: 1200px;
    width: 100%;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const SpinnerWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 25rem;
`;
const DropFileUploader = styled(DropFileUploaderController)`
    height: 400px;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 4rem;
    }
`;
const SkipButton = styled(Button)`
    color: var(--neutral500);
`;

export default EpisodeAudioFileUploadPage;
