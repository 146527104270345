import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UpgradeBadge from '@ui/atoms/UpgradeBadge';
import Button from '@ui/atoms/Button';
import PsoIllustration from '@public/images/empty-state/illustration/pso.mp4';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

export interface EmptyStateProps {
    reason: 'unavailable' | 'unconfigured';
}

const EmptyState = ({ reason }: EmptyStateProps) => {
    const { showId }: { showId: string } = useParams();
    const unavailable = reason === 'unavailable';
    return (
        <Wrapper>
            <IllustrationWrapper>
                <IllustrationInnerWrapper>
                    <IllustrationVideo muted autoPlay loop playsInline>
                        <source src={PsoIllustration} />
                    </IllustrationVideo>
                </IllustrationInnerWrapper>
            </IllustrationWrapper>
            {unavailable && <UpgradeBadge pricing="boost" />}
            <TitleWrapper>
                <Title>
                    <FormattedMessage defaultMessage="Boostez votre visibilité sur les plateformes d'écoute" />
                </Title>
                <Description>
                    <FormattedMessage defaultMessage="Découvrez le PSO Control Panel d’Ausha, un outil complet pour analyser les performances de votre podcast dans les résultats de recherche sur les mots-clés de votre choix. " />
                </Description>
            </TitleWrapper>
            {/* @ts-ignore */}
            <Button
                startIcon={
                    unavailable && (
                        <FontAwesomeIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                    )
                }
                onPress={() => {
                    sendAmplitudeLogEvent(
                        unavailable ? 'PSO Discover from Episode' : 'PSO Setup from Episode',
                    );
                    window.open(`/app/show/${showId}/pso`, '_blank');
                }}
            >
                {unavailable ? (
                    <FormattedMessage defaultMessage="Découvrir PSO" />
                ) : (
                    <FormattedMessage defaultMessage="Configurer maintenant" />
                )}
            </Button>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: var(--r-m);
    background-color: var(--white);
`;
const IllustrationWrapper = styled.div`
    width: 100%;
    height: 14rem;
    background: var(--gradient-dusk);
    border-radius: var(--r-m);
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;
const IllustrationInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden;
`;
const IllustrationVideo = styled.video`
    max-height: 12rem;
    border-radius: var(--r-l) 0 0 var(--r-l);
    width: calc(100% - 2rem);
    width: calc(100% - 1rem);
    border-radius: 12px 12px 0 0;
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
`;
const Title = styled.h2`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
    text-align: center;
`;
const Description = styled.p`
    font-size: var(--fs-body-m);
    color: var(--neutral500);
    text-align: center;
`;

export default EmptyState;
