import { MenuItem as AriaMenuItem, MenuItemProps as RACMenuItemProps } from 'react-aria-components';
import styled from 'styled-components';

export interface MenuItemProps extends RACMenuItemProps {
    children: React.ReactNode;
    color?: string;
}

const MenuItem = ({ children, color, ...props }: MenuItemProps) => {
    return (
        <Item {...props} $color={color}>
            {children}
        </Item>
    );
};

const Item = styled(AriaMenuItem)<{ $color?: string }>`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--r-s);
    background-color: transparent;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    cursor: pointer;
    ${({ $color }) => $color && `color: var(${$color});`}
    text-decoration: none;

    &[data-hovered] {
        background-color: var(--neutral50);
    }
`;

export default MenuItem;
