import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { checkReferralCode } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import authKeys from './authKeys';
import { z } from 'zod';

export const CheckReferralCodeSchema = z.object({
    code: z.string(),
    type: z.string(),
});
export type CheckReferralCode = z.infer<typeof CheckReferralCodeSchema>;
type Key = QueryFunctionContext<ReturnType<typeof authKeys.referral>>;
type InputProps = {
    code: string;
};

const queryFn = async ({ queryKey: [{ code }] }: Key) => {
    const { data } = await checkReferralCode(code);
    return CheckReferralCodeSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useCheckReferralCodeQuery = ({ code }: InputProps) => {
    return useQuery({
        queryKey: authKeys.referral(code),
        queryFn,
        enabled: !!code,
    });
};

export default useCheckReferralCodeQuery;
