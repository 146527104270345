import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import useEpisodePublicationDateFormSchema from './useEpisodePublicationDateFormSchema.hook';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Alert from '@ui/atoms/Alert';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { EPISODE_STATUS } from '@/shared/config/constants';
import PublishButton from '@/components/unorganized/EpisodeEditForm/actions/PublishButton';
import DateTimePicker from '@/components/DateTimePicker';
import { formatDateForApi } from '@/shared/utils/date';

const EpisodePublicationDateForm = ({
    publishedAt,
    isOnline,
    isLoading,
    handleEditPublicationDate,
    onCloseModal,
}) => {
    const schema = useEpisodePublicationDateFormSchema();
    const { handleSubmit, formState, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            publishedAt: publishedAt || '',
        },
        resolver: yupResolver(schema),
    });
    const { isValid, isDirty } = formState;
    const episode = useEpisodeBeingEdited();
    const isScheduled = episode?.state === EPISODE_STATUS.SCHEDULED;

    return (
        <Form onSubmit={handleSubmit(handleEditPublicationDate)}>
            <Stack $gap="0.5rem">
                <DateTimePicker
                    value={publishedAt}
                    onChange={(value) => {
                        setValue('publishedAt', formatDateForApi(value), {
                            shouldValidate: true,
                            shouldDirty: true,
                        });
                    }}
                    dateProps={{ label: <FormattedMessage defaultMessage="Jour" /> }}
                    timeProps={{ label: <FormattedMessage defaultMessage="Heure" /> }}
                />
            </Stack>
            {!isOnline && (
                <Alert
                    variant="info"
                    icon={<InfoIcon icon={icon({ name: 'info-circle', style: 'solid' })} />}
                >
                    <Text color="--info500">
                        <FormattedMessage defaultMessage="L’épisode sera publié automatiquement à la date et à l’heure choisies." />
                    </Text>
                </Alert>
            )}
            <ButtonsWrapper>
                <ButtonsInner>
                    <Button type="submit" isLoading={isLoading} isDisabled={!isValid || !isDirty}>
                        {isOnline ? (
                            <FormattedMessage defaultMessage="Modifier" />
                        ) : (
                            <FormattedMessage defaultMessage="Planifier" />
                        )}
                    </Button>
                    {isScheduled && <PublishNowButton />}
                </ButtonsInner>
                <CancelButton onPress={onCloseModal} variant="ghost">
                    <FormattedMessage defaultMessage="Annuler" />
                </CancelButton>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const ButtonsWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: stretch;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > :first-child {
            order: 1;
        }
    }
`;
const ButtonsInner = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: stretch;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        column-gap: 0.5rem;
        align-items: center;
        flex-grow: 0;

        & > :first-child {
            order: 1;
        }
    }
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--info500);
    width: 1.25rem;
    height: 1.25rem;
`;
const PublishNowButton = styled(PublishButton)`
    background-color: var(--primary50);
    color: var(--primary);
    box-shadow: none;
`;

EpisodePublicationDateForm.propTypes = {
    publishedAt: PropTypes.string,
    isOnline: PropTypes.bool,
    isLoading: PropTypes.bool,
    handleEditPublicationDate: PropTypes.func,
    onCloseModal: PropTypes.func,
};

export default EpisodePublicationDateForm;
