import styled, { css, keyframes } from 'styled-components';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';

const ChannelGuestsSkeleton = () => {
    return (
        <Container>
            <Header>
                <Stack $gap="1.25rem">
                    <Line $w="10rem" $h="0.75rem" />
                    <Stack $gap="0.5rem">
                        <LightLine $w="5rem" $h="0.5rem" />
                        <LightLine $w="12.5rem" $h="0.5rem" />
                    </Stack>
                </Stack>
                <Cluster $gap="1rem">
                    <LightBlock $w="5rem" $h="2.5rem" />
                    <Block $w="10rem" $h="2.5rem" />
                </Cluster>
            </Header>
            <Stack $gap="1rem" $align="end">
                <LightLine $w="5rem" $h="0.5rem" />
                <Table>
                    <TableHeader />
                    <TableRow>
                        <TableCell1>
                            <Circle $size="2rem" />
                            <Line $maxW="7.5rem" $h="0.5rem" />
                        </TableCell1>
                        <TableCell2>
                            <Line $maxW="4rem" $h="0.5rem" />
                        </TableCell2>
                        <TableCell3>
                            <Circle $size="0.75rem" />
                        </TableCell3>
                    </TableRow>
                    <TableRow>
                        <TableCell1>
                            <Circle $size="2rem" />
                            <Line $maxW="10rem" $h="0.5rem" />
                        </TableCell1>
                        <TableCell2>
                            <Line $maxW="5rem" $h="0.5rem" />
                        </TableCell2>
                        <TableCell3>
                            <Circle $size="0.75rem" />
                        </TableCell3>
                    </TableRow>
                    <TableRow>
                        <TableCell1>
                            <Circle $size="2rem" />
                            <Line $maxW="5rem" $h="0.5rem" />
                        </TableCell1>
                        <TableCell2>
                            <Line $maxW="2rem" $h="0.5rem" />
                        </TableCell2>
                        <TableCell3>
                            <Circle $size="0.75rem" />
                        </TableCell3>
                    </TableRow>
                    <TableRow>
                        <TableCell1>
                            <Circle $size="2rem" />
                            <Line $maxW="7.5rem" $h="0.5rem" />
                        </TableCell1>
                        <TableCell2>
                            <Line $maxW="4rem" $h="0.5rem" />
                        </TableCell2>
                        <TableCell3>
                            <Circle $size="0.75rem" />
                        </TableCell3>
                    </TableRow>
                    <TableRow>
                        <TableCell1>
                            <Circle $size="2rem" />
                            <Line $maxW="10rem" $h="0.5rem" />
                        </TableCell1>
                        <TableCell2>
                            <Line $maxW="5rem" $h="0.5rem" />
                        </TableCell2>
                        <TableCell3>
                            <Circle $size="0.75rem" />
                        </TableCell3>
                    </TableRow>
                    <TableRow>
                        <TableCell1>
                            <Circle $size="2rem" />
                            <Line $maxW="7.5rem" $h="0.5rem" />
                        </TableCell1>
                        <TableCell2>
                            <Line $maxW="2rem" $h="0.5rem" />
                        </TableCell2>
                        <TableCell3>
                            <Circle $size="0.75rem" />
                        </TableCell3>
                    </TableRow>
                    <TableRow>
                        <TableCell1>
                            <Circle $size="2rem" />
                            <Line $maxW="5rem" $h="0.5rem" />
                        </TableCell1>
                        <TableCell2>
                            <Line $maxW="4rem" $h="0.5rem" />
                        </TableCell2>
                        <TableCell3>
                            <Circle $size="0.75rem" />
                        </TableCell3>
                    </TableRow>
                </Table>
            </Stack>
        </Container>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Container = styled.div`
    border-radius: var(--r-l);
    background: var(--white);
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1rem;
    padding-bottom: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 1.5rem;
    }
`;
const Line = styled.div<{ $w?: string; $h?: string; $maxW?: string }>`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${({ $maxW }) => $maxW && `max-width: ${$maxW};`}
    ${shimmering};
`;
const LightLine = styled(Line)`
    background-color: var(--neutral50);
`;
const Block = styled.div<{ $w?: string; $h?: string }>`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${shimmering};
`;
const LightBlock = styled(Block)<{ $w?: string; $h?: string }>`
    background-color: var(--neutral50);
`;
const Circle = styled.div<{ $size: string }>`
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};
    background: var(--neutral50);
    border-radius: var(--r-full);
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
        column-gap: 1.5rem;
    }
`;
const TableHeader = styled(LightBlock)`
    border-radius: var(--r-m);
    width: 100%;
    height: 3rem;
`;
const TableRow = styled.div`
    height: 3.5rem;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const Table = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const TableCell = styled.div`
    padding-inline: 1rem;

    ${Line} {
        flex-grow: 1;
    }
`;
const TableCell1 = styled(TableCell)`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    flex-grow: 1;
`;
const TableCell2 = styled(TableCell)`
    min-width: 7.5rem;
    max-width: 10rem;
    flex-grow: 1;
`;
const TableCell3 = styled(TableCell)`
    flex-shrink: 0;
`;

export default ChannelGuestsSkeleton;
