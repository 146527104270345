import { useState, useEffect } from 'react';
import TunnelStepper from '../TunnelStepper';
import KeywordInput from '@/components/ui/molecules/KeywordInput';
import KeywordSuggestions from '@/components/ui/molecules/KeywordSuggestions';
import useKeywordSuggestionsQuery from '@/queries/pso/useKeywordSuggestionsQuery.hook';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useSaveKeywordsMutation from '@/queries/pso/useSaveKeywordsMutation.hook';
import Spinner from '@/components/ui/atoms/Spinner';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useIndexedKeywordsQuery, {
    IndexedKeyword,
} from '@/queries/pso/useIndexedKeywordsQuery.hook';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface Suggestion {
    id: string;
    label: string;
    rank: number;
}

export interface KeywordConfigurationProps {
    isEditMode: boolean;
    onKeywordsSubmitted: () => void;
    existingKeywords: string[];
    keywordQuota: any;
    currentStore: string;
}

const KeywordConfiguration = ({
    isEditMode,
    onKeywordsSubmitted,
    existingKeywords,
    keywordQuota,
    currentStore,
}: KeywordConfigurationProps) => {
    const saveKeywords = useSaveKeywordsMutation();

    const { showId }: { showId: string } = useParams();
    const keywordSuggestions = useKeywordSuggestionsQuery({ showId });
    const keywordIndexedSuggestions = useIndexedKeywordsQuery({ showId, currentStore });
    const [keywords, setKeywords] = useState(isEditMode ? existingKeywords : []);
    const [keywordsCount, setKeywordsCount] = useState(0);
    const [submitIsLoading, setSubmitIsLoading] = useState(false);
    const [pristine, setPristine] = useState(true);
    const toast = useModalToastQueue();

    useEffect(() => {
        if (!keywordQuota?.data?.current) return;
        setKeywordsCount(keywordQuota.data.current);
    }, [keywordQuota?.data?.current]);

    const submitKeywords = () => {
        saveKeywords.mutate(
            { showId, keywords, currentStore },
            {
                onSuccess: () => {
                    if (isEditMode) {
                        sendAmplitudeLogEvent('PSO Keywords Edited');
                        toast.success(
                            <FormattedMessage defaultMessage="Votre nouvelle sélection de mots-clés a bien été prise en compte. Les données de vos nouveaux mots-clés seront disponibles dès demain. ⏳" />,
                        );
                    }
                    onKeywordsSubmitted();
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Une erreur s'est produite lors de l'enregistrement de vos mot-clés, veuillez réessayer ultérieurement." />,
                    );
                },
                onSettled: () => {
                    setSubmitIsLoading(false);
                },
            },
        );
    };

    if (keywordQuota.isLoading) {
        return (
            <LoaderWrapper>
                <Spinner />
            </LoaderWrapper>
        );
    }
    if (keywordQuota.isError) return null;

    const handleSelectKeyword = (keyword: Suggestion) => {
        if (keywordsCount >= keywordQuota.maximum) return;
        if (keywords.includes(keyword.label.toLowerCase())) return;
        setKeywordsCount(keywordsCount + 1);
        setPristine(false);
        setKeywords([...keywords, keyword.label.toLowerCase()]);
    };

    return (
        <KeywordConfigurationWrapper>
            <Left>
                {!isEditMode && <TunnelStepper currentStep={1} stepCount={3} />}
                <KeywordInput
                    keywords={keywords}
                    setKeywords={(v) => {
                        setKeywordsCount(keywordsCount - keywords.length + v.length);
                        setPristine(false);
                        setKeywords(v);
                    }}
                    onKeywordCreate={(keyword) => {
                        setKeywordsCount(keywordsCount + keyword.length);
                        setPristine(false);
                        setKeywords([...keywords, ...keyword]);
                    }}
                    onKeywordDelete={(keyword) => {
                        setKeywordsCount(keywordsCount - 1);
                        setPristine(false);
                        setKeywords(keywords.filter((k) => k !== keyword));
                    }}
                    onKeywordDeleteAll={() => {
                        setKeywordsCount(keywordsCount - keywords.length);
                        setPristine(false);
                        setKeywords([]);
                    }}
                    onSubmit={submitKeywords}
                    submitIsLoading={submitIsLoading}
                    setSubmitIsLoading={(v) => setSubmitIsLoading(v)}
                    keywordQuota={keywordQuota?.data}
                    keywordsCount={keywordsCount}
                    isEditMode={isEditMode}
                    pristine={pristine}
                />
            </Left>
            <Right>
                <KeywordSuggestions
                    isLoading={keywordSuggestions.isLoading}
                    isError={keywordSuggestions.isError}
                    indexedSuggestions={
                        keywordIndexedSuggestions?.data?.map((keyword: IndexedKeyword) => {
                            return {
                                id: `indexed-${keyword.label}`,
                                label: keyword.label,
                                rank: keyword.rank,
                            };
                        }) || []
                    }
                    aiSuggestions={keywordSuggestions?.data || []}
                    keywords={keywords}
                    onSelectKeyword={handleSelectKeyword}
                    hasReachedMaxKeywords={keywordsCount === keywordQuota.data.maximum}
                />
            </Right>
        </KeywordConfigurationWrapper>
    );
};

const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10rem;
`;
const KeywordConfigurationWrapper = styled.div`
    display: flex;
    gap: 2rem;
    & > * {
        flex: 1;
        align-self: stretch;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
`;
const Right = styled.div`
    max-height: 70vh;
`;

export default KeywordConfiguration;
