import { observer } from 'mobx-react';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { dateIsInTheFuture } from '@/shared/utils/date';
import { useParams } from 'react-router-dom';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const HideFor = ({
    roles = [],
    pricing = [],
    blockedSubscription,
    userOptions = [],
    showStatus = [],
    condition = true,
    lockedMessage,
    children,
}) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();

    const hasSubscriptionEnded = !!subscription?.endsAt && !dateIsInTheFuture(subscription.endsAt);
    const referencePricing = show?.ownerPricing || subscription?.pricing;

    const allowedByUserOptions = show
        ? show?.userOptions?.includes(userOptions)
        : user
        ? userOptions.every((option) => user?.options?.includes(option))
        : undefined;

    const hidden =
        (show && roles.includes(show.userRole)) ||
        (user && subscription && pricing.includes(referencePricing)) ||
        (userOptions.length > 0 && !allowedByUserOptions) ||
        (show && showStatus.includes(show.showStatus)) ||
        (blockedSubscription && subscription && hasSubscriptionEnded) ||
        !condition;

    if (hidden && lockedMessage) return lockedMessage;
    if (hidden) return null;
    if (children) return children;
    return null;
};

export default observer(HideFor);
