import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const updatePageSettings = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/page/settings`, body });
};
export const fetchPageSettings = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/page/settings` });
};
