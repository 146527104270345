import styled from 'styled-components';

const SidebarList = styled.ul`
    padding-left: 0;
    display: flex;
    gap: 1rem;
    overflow-x: auto;

    @media (min-width: 64rem) {
        flex-direction: column;
        overflow-x: visible;
    }
`;

export default SidebarList;
