import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchAISocialPostsSuggestions } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import aiKeys from './aiKeys';
import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import { decamelizeKeys } from 'humps';
import { z } from 'zod';
import { delayQuery } from '@/shared/utils/async';

export const ASocialPostsSuggestionsSchema = z.object({
    suggestions: z.object({
        facebook: z.string().optional(),
        twitter: z.string().optional(),
        instagram: z.string().optional(),
        linkedin: z.string().optional(),
    }),
});

export type AISocialPostsSuggestions = z.infer<typeof ASocialPostsSuggestionsSchema>;

type Key = QueryFunctionContext<ReturnType<typeof aiKeys.socialPostsDetails>>;

interface DelayedQueryFnParams {
    queryContext: Key;
    delay: number;
}

const delayedQueryFn = ({
    queryContext,
    delay,
}: DelayedQueryFnParams): ReturnType<typeof queryFn> =>
    delayQuery({ callback: queryFn, delay })(queryContext);

const queryFn = async ({ queryKey: [{ episodeId, settings, socialNetworks }] }: Key) => {
    const { data } = await fetchAISocialPostsSuggestions(
        episodeId,
        decamelizeKeys(settings),
        socialNetworks,
    );
    return ASocialPostsSuggestionsSchema.parse(camelcaseKeys(data, { deep: true }));
};

export type AISocialNetwork = 'facebook' | 'twitter' | 'instagram' | 'linkedin';

interface AISocialPostsSuggestionsQueryParams {
    episodeId: string;
    settings: AICustomPromptFormSchema;
    socialNetworks: AISocialNetwork[];
    delay?: number;
}

const useAISocialPostsSuggestionsQuery = ({
    episodeId,
    settings,
    socialNetworks,
    delay = 0,
}: AISocialPostsSuggestionsQueryParams) => {
    return useQuery({
        queryKey: aiKeys.socialPostsDetails(episodeId, settings, socialNetworks),
        queryFn: (queryContext: Key) => delayedQueryFn({ queryContext, delay }),
        enabled: false,
    });
};

export default useAISocialPostsSuggestionsQuery;
