import styled from 'styled-components';
import ChannelGuestsHeader from './ChannelGuestsHeader';
import ChannelGuestsTable from './ChannelGuestsTable';
import { ChannelGuestPermission } from '@/queries/guest/useChannelGuestPermissionsQuery.hook';
import { ChannelGuestInvitation } from '@/queries/guest/useChannelGuestInvitationsQuery.hook';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import exportChannelGuestsMutation from '@/queries/guest/exportChannelGuestsMutation';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import Stack from '@ui/layout/Stack';

interface ChannelGuestsProps {
    permissions: ChannelGuestPermission[];
    invitations: ChannelGuestInvitation[];
}

const ChannelGuests = ({ permissions, invitations }: ChannelGuestsProps) => {
    const channel = useChannelQuery();
    const exportChannelGuests = exportChannelGuestsMutation();

    const downloadDataToCsv = (data: string[], filename: string) => {
        const url = window.URL.createObjectURL(new Blob(data));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const handleExport = () => {
        exportChannelGuests.mutate(
            {
                channelId: `${channel?.data?.id}`,
            },
            {
                onSuccess: (data) => {
                    downloadDataToCsv(data.guests, data.filename);
                },
            },
        );
    };

    return (
        <Container>
            <ChannelGuestsHeader />
            <Stack $gap="1rem" $align="end">
                <Button
                    onPress={handleExport}
                    variant="link-secondary"
                    isLoading={exportChannelGuests.isLoading}
                    startIcon={
                        <ExportIcon
                            icon={icon({
                                name: 'arrow-down-to-bracket',
                                style: 'solid',
                            })}
                        />
                    }
                >
                    <FormattedMessage defaultMessage="Exporter" />
                </Button>
                <ChannelGuestsTable permissions={permissions} invitations={invitations} />
            </Stack>
        </Container>
    );
};

const Container = styled.div`
    border-radius: var(--r-l);
    background: var(--white);
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 1rem;
    padding-bottom: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 1.5rem;
        row-gap: 1.5rem;
    }
`;
const ExportIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default ChannelGuests;
