import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import useEmailVerificationFormSchema, {
    EmailVerificationForm,
} from './useEmailVerificationFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@/components/Button';
import RouterLink from '@/components/Link/RouterLink';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useSendVerificationEmailMutation from '@/queries/auth/useSendVerificationEmailMutation.hook';
import styled from 'styled-components';

const Form = () => {
    const intl = useIntl();
    const toast = useModalToastQueue();
    const schema = useEmailVerificationFormSchema();
    const sendVerificationEmail = useSendVerificationEmailMutation();

    const { control, handleSubmit, formState, clearErrors, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
        },
        resolver: zodResolver(schema),
    });

    const onSubmit = async (payload: EmailVerificationForm) => {
        sendVerificationEmail.mutate(payload, {
            onSuccess: () => {
                toast.success(
                    <FormattedMessage defaultMessage="Un e-mail de réinitialisation de mot de passe vous a été envoyé" />,
                );
            },
            onError: (error) => {
                const typedError = error as AxiosError;
                if (typedError.response?.status === 422) {
                    toast.alert(
                        <FormattedMessage defaultMessage="L'adresse e-mail renseignée ne semble pas être la bonne" />,
                    );
                }
            },
        });
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                control={control}
                name="email"
                label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                placeholder={intl.formatMessage({ defaultMessage: 'neil.armstrong@nasa.com' })}
                errorMessage={formState.errors.email?.message}
                onBlur={() => clearErrors('email')}
                isRequired
            />
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={sendVerificationEmail.isLoading}
                    isDisabled={
                        sendVerificationEmail.isLoading ||
                        watch('email') === '' ||
                        !formState.isValid
                    }
                >
                    <FormattedMessage defaultMessage="Envoyer le lien de réinitalisation" />
                </Button>
                <RouterLink to="/login">
                    <FormattedMessage defaultMessage="Retour à la connexion" />
                </RouterLink>
            </ButtonsWrapper>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    & > :first-child {
        align-self: stretch;
    }

    & > :last-child {
        align-self: center;
    }
`;

export default Form;
