import { useMutation } from '@tanstack/react-query';
import { register } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { useAuthContext } from '@/context/AuthContext';
import { z } from 'zod';

const RegisterResponseSchema = z.object({
    accessToken: z.string(),
    expiresIn: z.number(),
    refreshToken: z.string(),
    tokenType: z.string(),
});
export type RegisterResponse = z.infer<typeof RegisterResponseSchema>;

export interface MutationFnProps {
    payload: {
        firstname: string;
        lastname: string;
        email: string;
        password: string;
        referralCode?: string | null;
    };
    recaptcha: string | null;
    language: string;
    invitation_token: string | null;
    invitation_show: string | null;
}

const mutationFn = async ({
    payload,
    recaptcha,
    language,
    invitation_token,
    invitation_show,
}: MutationFnProps) => {
    const { referralCode, ...rest } = payload;
    const data = await register({
        ...rest,
        referer_code: referralCode,
        'g-recaptcha-response': recaptcha,
        language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...(invitation_token !== null && { invitation_token }),
        ...(invitation_show !== null && { invitation_show }),
    });
    return RegisterResponseSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useRegisterMutation = () => {
    const { login, redirectToApp } = useAuthContext();
    return useMutation({
        mutationFn,
        onSuccess: async (data: RegisterResponse) => {
            login(data);
            redirectToApp();
        },
    });
};

export default useRegisterMutation;
