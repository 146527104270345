import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ExternalLink from '@/components/Link/ExternalLink';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import usePsoKeywordsRankingQuery from '@queries/psoKeywordsRanking/usePsoKeywordsRankingQuery.hook';
import type { Platform } from '@/api/pso/types';
import Button from '@/components/Button';
import { getCountryISO3 } from '@/shared/services/countryService';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface HeaderProps {
    children: React.ReactNode;
    searchParams: {
        platform: Platform;
        store: string;
    };
}

const Header = ({ children, searchParams }: HeaderProps) => {
    const { showId }: { showId: string } = useParams();
    const { platform, store } = searchParams;
    const intl = useIntl();

    const keywordsRanking = usePsoKeywordsRankingQuery(
        {
            showId,
            platform,
            store: getCountryISO3(store),
            format: 'text/csv',
        },
        { enabled: false },
    );

    const handleExport = () => {
        sendAmplitudeLogEvent('PSO Keywords Ranking Export', {
            listeningPlatform: platform,
            country: store,
        });
        keywordsRanking.refetch();
    };

    useEffect(() => {
        if (keywordsRanking.data) {
            const url = window.URL.createObjectURL(
                new Blob([Object.values(keywordsRanking.data).slice(0, -1).join('')], {
                    type: 'text/csv',
                }),
            );
            const link = document.createElement('a');
            link.href = url;
            const platform = searchParams.platform === 'itunes' ? 'apple_podcasts' : 'spotify';
            const fileName = `${dayjs().format(
                'YYMMDD',
            )}-pso-keyword-ranking-${platform}-${store}.csv`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }, [keywordsRanking?.data]);

    return (
        <HeaderWrapper>
            <HeaderInnerWrapper>
                <TitleAndDescription>
                    <TitleWrapper>
                        <TitleIcon icon={icon({ name: 'check-circle', style: 'solid' })} />
                        <Title>
                            <FormattedMessage defaultMessage="Classement par mots-clés" />
                        </Title>
                    </TitleWrapper>
                    <Description>
                        <FormattedMessage
                            defaultMessage="Découvrez l'intégralité des mots-clés sur lesquels votre podcast est référencé ! Repérez ainsi de nouveaux mots-clés sur lesquels vous pouvez optimiser votre podcast pour accroître sa visibilité dans les résultats de recherche. <link>En savoir plus.</link>"
                            values={{
                                link: (chunks: string) => (
                                    <DescriptionLink
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://help.ausha.co/fr/articles/10290521-pso-control-panel-comment-consulter-l-in%5B%E2%80%A6%5Dsquels-mon-podcast-est-actuellement-reference-avec-pso-ranking',
                                        })}
                                        target="_blank"
                                    >
                                        {chunks}
                                    </DescriptionLink>
                                ),
                            }}
                        />
                    </Description>
                </TitleAndDescription>
                <ActionWrapper>
                    <ButtonWrapper>
                        <Button
                            variant="tertiary"
                            isDisabled={!searchParams?.platform}
                            startIcon={
                                keywordsRanking.isFetching ? (
                                    <ExportLoadingIcon
                                        icon={icon({
                                            name: 'spinner-third',
                                            style: 'solid',
                                        })}
                                        spin
                                        color="inherit"
                                        size="sm"
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'arrow-down-to-bracket',
                                            style: 'solid',
                                        })}
                                        color="inherit"
                                        size="sm"
                                    />
                                )
                            }
                            onPress={handleExport}
                        >
                            <FormattedMessage defaultMessage="Exporter" />
                        </Button>
                    </ButtonWrapper>
                </ActionWrapper>
            </HeaderInnerWrapper>
            {children}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeaderInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
`;
const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex: 1;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;
const Title = styled.h1`
    font-size: var(--fs-heading-m);
    font-weight: var(--fw-bold);
`;
const ActionWrapper = styled.div``;
const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
`;
const ExportLoadingIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;
const Description = styled.p`
    color: var(--neutral);
`;
const DescriptionLink = styled(ExternalLink)`
    color: inherit;
    text-decoration: underline;
    font-weight: inherit;

    &[data-hovered='true'] {
        color: inherit;
    }
`;

export default Header;
