import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchChapters = (podcast) => {
    return request({ method: 'GET', basePath, target: `/v1/podcasts/${podcast}/chapters` });
};
export const experimentalUploadChapterImage = (chapterId, image) => {
    const body = new FormData();
    body.append('file', image);
    return request({
        method: 'POST',
        basePath,
        target: `/v1/chapters/${chapterId}/image`,
        body,
    });
};
export const deleteChapterImage = (chapter) => {
    return request({ method: 'DELETE', basePath, target: `/v1/chapters/${chapter}/image` });
};
export const updateChapters = (podcast, body) => {
    return request({ method: 'POST', basePath, target: `/v1/podcasts/${podcast}/chapters`, body });
};
