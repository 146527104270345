import { useMutation } from '@tanstack/react-query';
import { loginWith } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { useAuthContext } from '@/context/AuthContext';
import { z } from 'zod';

const ThirdPartyLoginResponseSchema = z.object({
    accessToken: z.string(),
    expiresIn: z.number(),
    refreshToken: z.string(),
    tokenType: z.string(),
});
export type ThirdPartyLoginResponse = z.infer<typeof ThirdPartyLoginResponseSchema>;

export interface MutationFnProps {
    code: string;
}

interface HookProps {
    dataLayerEvent?: string | null;
}

const mutationFn = async ({ code }: MutationFnProps) => {
    const data = await loginWith({ code, provider: 'facebook' });
    return ThirdPartyLoginResponseSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useThirdPartyFacebookLoginMutation = ({ dataLayerEvent }: HookProps) => {
    const { login, redirectToApp } = useAuthContext();

    return useMutation({
        mutationFn,
        onSuccess: async (data: ThirdPartyLoginResponse) => {
            login(data);

            /**
             * dataLayer is a globally defined object that is used to push events to Google Tag Manager (yum yum...)
             */
            // @ts-expect-error
            if (dataLayer) {
                // @ts-expect-error
                dataLayer.push({
                    event: dataLayerEvent || 'login',
                });
            }
            redirectToApp();
        },
    });
};

export default useThirdPartyFacebookLoginMutation;
