import { useAuthContext } from '@/context/AuthContext';
import { createAuthorizer } from './createAuthorizer';
import useQuery from '@/shared/hooks/useQuery';

export const GuestRoute = createAuthorizer(() => {
    const { isAuthenticated } = useAuthContext();
    const params = useQuery();
    const token = params.get('token');
    return {
        isBlocked: isAuthenticated && !token,
        defaultRedirect: '/app',
    };
});

export const AuthRoute = createAuthorizer(() => {
    const { isAuthenticated } = useAuthContext();
    return {
        isBlocked: !isAuthenticated,
        defaultRedirect: '/login',
    };
});

export const ValidSubscriptionRoute = createAuthorizer(({ subscriptionStore, routerStore }) => {
    const { isAuthenticated } = useAuthContext();
    return {
        isBlocked:
            isAuthenticated &&
            subscriptionStore.subscription &&
            subscriptionStore.subscription.isBlockedForShow(routerStore.params.showId),
        defaultRedirect: routerStore.params.showId
            ? routerStore.link('menu.episodes')
            : routerStore.defaultRedirect,
    };
});

export const AccessRoute = createAuthorizer(({ routerStore }, { condition, to }) => {
    const { page, tab } = to;
    const { isAuthenticated } = useAuthContext();
    return {
        isBlocked: isAuthenticated && condition,
        defaultRedirect: routerStore.link(page, { tab }),
    };
});
