import Text from '@/components/ui/atoms/Text';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Snippet from '../Snippet';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const MessageSummary = () => {
    const { getValues } = useFormContext<NonHostingEpisodeFormSchema>();
    const htmlMessage = getValues('newsletter.message');
    const plainTextMessage = getValues('_meta.plainTextNewsletterMessage');

    if (!plainTextMessage) return null;

    const handleCopy = () => {
        sendAmplitudeLogEvent('Copy Episode Newsletter Message');
    };

    return (
        <Wrapper>
            <Text variant="bodyL" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Message" />
            </Text>
            <Snippet onCopy={handleCopy} valueToCopy={plainTextMessage}>
                <div>{ReactHtmlParser(htmlMessage)}</div>
            </Snippet>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

export default MessageSummary;
