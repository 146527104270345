import { request } from '../api';

const basePath = process.env.API_ANALYTICS as string;

export const psoSaveKeywords = (showId: string, keywords: string[]) => {
    return request({
        method: 'PUT',
        basePath,
        target: `/shows/${showId}/keywords`,
        body: keywords,
    });
};
export const fetchAvailableStores = () => {
    return request({ method: 'GET', basePath, target: '/stores' });
};
export const psoSaveStores = (showId: string, stores: string[]) => {
    return request({
        method: 'PUT',
        basePath,
        target: `/shows/${showId}/stores`,
        body: stores,
    });
};
export const psoGlobalConfiguration = (showId: string) => {
    return request({
        method: 'GET',
        basePath,
        target: `/shows/${showId}/global_configuration`,
    });
};
export const psoRankings = (showId: string, currentStore: string) => {
    return request({
        method: 'GET',
        basePath,
        target: `/shows/${showId}/stats/rankings?country=${currentStore}`,
    });
};
export const psoOverview = (showId: string, currentStore: string, doesExport: boolean) => {
    return request({
        method: 'GET',
        basePath,
        target: `/shows/${showId}/stats/overview?country=${currentStore}`,
        headers: { Accept: doesExport ? 'text/csv' : 'application/json' },
    });
};
export const psoAnalytics = (showId: string, currentStore: string) => {
    return request({
        method: 'GET',
        basePath,
        target: `/shows/${showId}/stats/analytics?country=${currentStore}`,
    });
};
export const psoKeywordQuota = (showId: string) => {
    return request({
        method: 'GET',
        basePath,
        target: `/shows/${showId}/channel/keywords_quota`,
    });
};
export const psoIndexedKeywords = (showId: string) => {
    return request({
        method: 'GET',
        basePath,
        target: `/shows/${showId}/indexed_keywords`,
    });
};
export const psoCheckerTargetKeyword = (episodeId: string) => {
    return request({
        method: 'GET',
        basePath,
        target: `/podcasts/${episodeId}/target_keyword`,
    });
};
export const psoCheckerUpdateTargetKeyword = (episodeId: string, keyword: string) => {
    return request({
        method: 'PUT',
        basePath,
        target: `/podcasts/${episodeId}/target_keyword`,
        body: { keyword },
    });
};
export const psoCheckerDeleteTargetKeyword = (episodeId: string) => {
    return request({
        method: 'DELETE',
        basePath,
        target: `/podcasts/${episodeId}/target_keyword`,
    });
};
export const psoCheckerFollowKeyword = (showId: string, keyword: string) => {
    return request({
        method: 'POST',
        basePath,
        target: `/shows/${showId}/keyword`,
        body: { keyword },
    });
};
