import { useRef, useState } from 'react';
import ShowsDropdown from './Dropdown/Dropdown';
import ShowsList from './ShowsList';
import ShowButton from './ShowButton';
import Menu from './Menu';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import useActiveShowsQuery from '@/queries/show/useActiveShowsQuery.hook';
import useGuestShowsQuery from '@/queries/show/useGuestShowsQuery.hook';
import useArchivedShowsQuery from '@/queries/show/useArchivedShowsQuery.hook';
import ShowButtonSkeleton from './ShowButton/ShowButtonSkeleton';

const ShowSelector = () => {
    const triggerRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const storedLayout = localStorage.getItem('show-selector-layout');
    const [layout, setLayout] = useState<'stack' | 'grid'>(
        (storedLayout as 'grid' | 'stack') || 'stack',
    );
    const [status, setStatus] = useState<'active' | 'archived'>('active');

    const activeShowsQuery = useActiveShowsQuery();
    const guestActiveShowsQuery = useGuestShowsQuery();
    const archivedShowsQuery = useArchivedShowsQuery();
    const isLoading =
        activeShowsQuery.isLoading ||
        guestActiveShowsQuery.isLoading ||
        archivedShowsQuery.isLoading;

    useAmplitudeLogEvent('Show Selector Opening', {}, isDropdownOpen);

    if (isLoading) {
        return <ShowButtonSkeleton />;
    }

    return (
        <>
            <ShowButton triggerRef={triggerRef} onDropdownOpenChange={setIsDropdownOpen} />
            <ShowsDropdown
                isOpen={isDropdownOpen}
                triggerRef={triggerRef}
                onOpenChange={setIsDropdownOpen}
            >
                <ShowsList
                    layout={layout}
                    status={status}
                    onClose={() => setIsDropdownOpen(false)}
                />
                <Menu
                    layout={layout}
                    status={status}
                    onLayoutChange={setLayout}
                    onStatusChange={setStatus}
                />
            </ShowsDropdown>
        </>
    );
};

export default ShowSelector;
