import { connect } from '@/components/legacy/connect';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Link from '@ui/atoms/Link';
import Pagination from '@ui/molecules/Pagination';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useToggle } from '@/shared/hooks/useToggle';
import TableArrow from '@ui/atoms/TableArrow';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import PaperGrid from '@ui/atoms/PaperGrid';
import Column from '@ui/atoms/Column';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import FetcherEpisodesRanking from '@/components/unorganized/FetcherEpisodesRanking';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import TooltipHover from '@ui/smarties/TooltipHover';
import help from '@public/icons/help.svg';
import { PRICING, PRICING_NAME } from '@/shared/config/pricing';
import HideFor from '@/components/unorganized/HideFor';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Alert from '@ui/atoms/Alert/Alert';
import IconInfo from '@ui/icons/IconInfo';
import Text from '@ui/atoms/Text';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';
import styled from 'styled-components';
import NoDataChart from '@/components/charts/NoData/NoDataChart';
import { formatDuration } from '@/shared/utils/duration';

const OfferLink = styled(Text)`
    text-decoration: underline;
    color: var(--black);
    font-weight: var(--fw-bold);
`;
const AlertStyled = styled(Alert)`
    margin-top: 25px;
`;
const NoDataChartWrapper = styled.div`
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 4rem;
    }
`;

const CompletionAndDurationTimeAllowsData = ({ allowsCompletion, children }) => {
    if (!allowsCompletion)
        return (
            <>
                <Column gridColumn="4/5" justify="flex-end" align="right">
                    <DeprecatedText>-</DeprecatedText>
                </Column>
                <Column gridColumn="5/6" justify="flex-end" align="right">
                    <DeprecatedText>-</DeprecatedText>
                </Column>
            </>
        );
    return children;
};
const enhance = connect(({ routerStore, listenersStatsStore }) => {
    return {
        itemType: routerStore.params.itemType,
        itemId: routerStore.params.itemId,
        ranks: listenersStatsStore.podcastRanks,
        pagination: listenersStatsStore.podcastRanksPagination,
        linkOffer: routerStore.link('user.offers'),
        episodeQuery: routerStore.query.episode,
    };
});

const ListenersPodcastRankingTable = ({
    itemType,
    itemId,
    ranks,
    pagination,
    episodeQuery,
    linkOffer,
}) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const allowsCompletion = show?.userOptions?.includes(USER_OPTIONS.COMPLETION_RATE);
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [order, toggleOrder] = useToggle('desc', 'asc');
    const { isMobileOrTablet } = useResponsive();
    return (
        <>
            <HideFor
                userOptions={USER_OPTIONS.COMPLETION_RATE}
                lockedMessage={
                    <AlertStyled variant="info" icon={<IconInfo />}>
                        <Text>
                            <FormattedMessage
                                defaultMessage="La durée d’écoute moyenne et le taux de complétion sont disponibles en {offerLink}"
                                values={{
                                    offerLink: (
                                        <OfferLink as={Link} to={linkOffer}>
                                            <FormattedMessage
                                                defaultMessage="offre {offerName}."
                                                values={{
                                                    offerName: PRICING_NAME[PRICING.SUPERSONIC],
                                                }}
                                            />
                                        </OfferLink>
                                    ),
                                }}
                            />
                        </Text>
                    </AlertStyled>
                }
            />

            <DeprecatedWhitePaper my={25} overflowHide={false}>
                <PaperGrid column={5} py={8} px={20} gap={20}>
                    <Column
                        gridColumn="1/3"
                        color="--neutral500"
                        ellipsis
                        display={isMobileOrTablet && 'block'}
                        noWrap
                        minW={isMobileOrTablet && 30}
                    >
                        <FormattedMessage defaultMessage="Titre" />
                    </Column>
                    <Column
                        gridColumn="3/4"
                        color="--neutral500"
                        justify="flex-end"
                        onClick={toggleOrder}
                        noWrap
                        minW={isMobileOrTablet && 30}
                    >
                        <DeprecatedText
                            as="span"
                            color="--neutral500"
                            ellipsis
                            display={isMobileOrTablet && 'block'}
                        >
                            <FormattedMessage defaultMessage="Nombre d'écoutes" />
                        </DeprecatedText>
                        <TableArrow order={order} ml={15} />
                    </Column>
                    <Column
                        gridColumn="4/5"
                        justify="flex-end"
                        noWrap
                        minW={isMobileOrTablet && 30}
                    >
                        <DeprecatedText
                            as="span"
                            color="--neutral500"
                            ellipsis
                            display={isMobileOrTablet && 'block'}
                        >
                            <FormattedMessage defaultMessage="Durée d’écoute moyenne" />
                        </DeprecatedText>
                        <TooltipHover
                            arrow="top"
                            flex
                            align="center"
                            target={<DeprecatedIcon as={help} color="--primary" ml={5} />}
                        >
                            <DeprecatedText>
                                <FormattedMessage defaultMessage="La durée d’écoute moyenne concerne uniquement la plateforme Deezer pour le moment." />
                            </DeprecatedText>
                        </TooltipHover>
                    </Column>
                    <Column
                        gridColumn="5/6"
                        color="--neutral500"
                        justify="flex-end"
                        noWrap
                        minW={isMobileOrTablet && 30}
                    >
                        <DeprecatedText
                            as="span"
                            color="--neutral500"
                            ellipsis
                            display={isMobileOrTablet && 'block'}
                        >
                            <FormattedMessage defaultMessage="Taux de complétion" />
                        </DeprecatedText>
                        <TooltipHover
                            arrow="top"
                            flex
                            align="center"
                            target={<DeprecatedIcon as={help} color="--primary" ml={5} />}
                        >
                            <DeprecatedText>
                                <FormattedMessage defaultMessage="Le taux de completion concerne uniquement la plateforme Deezer pour le moment." />
                            </DeprecatedText>
                        </TooltipHover>
                    </Column>
                </PaperGrid>
                <FetcherEpisodesRanking
                    showId={showId}
                    itemType={itemType}
                    itemId={itemId}
                    start={startDate}
                    end={endDate}
                    page={pagination.currentPage}
                    order={order}
                    fallback={ShortListFallback}
                    episodes={episodeQuery}
                >
                    {ranks.length > 0 ? (
                        ranks.map((rank) => (
                            <PaperGrid
                                key={rank.id}
                                bt={1}
                                border="--neutral100"
                                column={5}
                                py={15}
                                px={20}
                                gap={20}
                            >
                                <Column display="block" gridColumn="1/3" ellipsis>
                                    {rank.name}
                                </Column>
                                <Column gridColumn="3/4" align="right" justify="flex-end">
                                    <FormattedNumber value={rank.downloads} />
                                </Column>

                                <CompletionAndDurationTimeAllowsData
                                    key={rank.id}
                                    allowsCompletion={allowsCompletion}
                                >
                                    <Column gridColumn="4/5" justify="flex-end" align="right">
                                        {formatDuration(rank.averageListeningTime, {
                                            initialValue: true,
                                        })}
                                    </Column>
                                    <Column gridColumn="5/6" justify="flex-end" align="right">
                                        {rank.averageCompletionRate !== '-'
                                            ? `${rank.averageCompletionRate}%`
                                            : rank.averageCompletionRate}
                                    </Column>
                                </CompletionAndDurationTimeAllowsData>
                            </PaperGrid>
                        ))
                    ) : (
                        <NoDataChartWrapper>
                            <NoDataChart />
                        </NoDataChartWrapper>
                    )}
                </FetcherEpisodesRanking>
            </DeprecatedWhitePaper>
            <Pagination
                mb={25}
                pagination={pagination}
                singular={<FormattedMessage defaultMessage="épisode" />}
                plural={<FormattedMessage defaultMessage="épisodes" />}
            />
        </>
    );
};

export default enhance(ListenersPodcastRankingTable);
