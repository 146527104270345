import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { EPISODE_MAX_TAGS } from '@/shared/config/constants';

import { getPlainText } from '@/shared/utils/string';

const useNonHostingEpisodeFormSchema = () => {
    const intl = useIntl();
    const NEWSLETTER_MAX_CHARACTERS = 4000;

    return z.object({
        content: z.object({
            name: z
                .string({
                    required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
                })
                .min(3, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 3 }))
                .max(140, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 140 })),
            htmlDescription: z
                .string()
                .max(3900, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 3900 }))
                .optional(),
            tags: z.array(z.string()).max(EPISODE_MAX_TAGS),
        }),
        chapters: z.array(
            z.object({
                id: z.number().or(z.string()),
                title: z
                    .string({
                        required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
                    })
                    .max(140, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 140 })),
                startTime: z.number({
                    invalid_type_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.isNumber),
                }),
                imageUrl: z.instanceof(File).or(z.string()).nullable(),
                url: z
                    .string()
                    .url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl))
                    .nullable()
                    .optional(),
            }),
        ),
        newsletter: z.object({
            title: z.string().nullable(),
            message: z
                .string()
                .refine(
                    (value) => {
                        // Ensure that getPlainText does not return null before checking the length
                        const plainText = getPlainText(value);
                        return (
                            !value ||
                            (plainText !== null && plainText.length <= NEWSLETTER_MAX_CHARACTERS)
                        );
                    },
                    {
                        message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                            max: NEWSLETTER_MAX_CHARACTERS,
                        }),
                    },
                )
                .nullable(),
        }),
        socialPosts: z.object({
            facebook: z
                .string()
                .max(63000, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 63000 }))
                .optional()
                .nullable(),
            twitter: z
                .string()
                .max(257, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 257 }))
                .optional()
                .nullable(),
            instagram: z
                .string()
                .max(2200, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 2200 }))
                .optional()
                .nullable(),
            linkedin: z
                .string()
                .max(3000, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 3000 }))
                .optional()
                .nullable(),
        }),
        // internal meta data used by the form for display only
        _meta: z
            .object({
                plainTextDescription: z.string().optional().nullable(),
                plainTextNewsletterMessage: z.string().optional().nullable(),
            })
            .optional(),
    });
};

export type NonHostingEpisodeFormSchema = z.infer<
    ReturnType<typeof useNonHostingEpisodeFormSchema>
>;

export default useNonHostingEpisodeFormSchema;
