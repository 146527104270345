import { FormattedMessage } from 'react-intl';
import useQuery from '@/shared/hooks/useQuery';
import Illustration from './Illustration';
import Form from './Form';
import RouterLink from '@/components/Link/RouterLink';
import Alert from '@ui/atoms/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import GoogleButton from '@/shared/components/GoogleButton';
import FacebookButton from '@/shared/components/FacebookButton';
import AushaLogo from '@ui/atoms/AushaLogo';
import styled from 'styled-components';

const Register = () => {
    const searchParams = useQuery();
    const isInvitation =
        searchParams.get('email') && searchParams.get('show') && searchParams.get('token');

    const signupLink = (
        <FormattedMessage
            defaultMessage="Vous avez déjà un compte ? {login}"
            values={{
                login: (
                    <RouterLink
                        to={`/login?${searchParams.toString()}`}
                        data-testid="register-page-link"
                    >
                        <FormattedMessage defaultMessage="Se connecter" />
                    </RouterLink>
                ),
            }}
        />
    );

    return (
        <LoginWrapper>
            <Left>
                <InnerLeft>
                    <Header>
                        <Logo isComplete isColored />
                        <Title data-testid="register-page-heading">
                            <FormattedMessage defaultMessage="Bienvenue sur Ausha !" />
                        </Title>
                        <DesktopAndUpSignupCallToAction>
                            {signupLink}
                        </DesktopAndUpSignupCallToAction>
                    </Header>

                    {isInvitation ? (
                        <Alert
                            variant="info"
                            icon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'circle-info', style: 'solid' })}
                                />
                            }
                            description={
                                <FormattedMessage defaultMessage="Il ne reste plus qu’à vous inscrire, ou à vous connecter si vous avez déjà un compte. À très vite !" />
                            }
                        >
                            <FormattedMessage
                                defaultMessage="{name} vous invite à rejoindre Ausha.{linebreak}"
                                values={{
                                    name: searchParams.get('name') || searchParams.get('showname'),
                                    linebreak: <br />,
                                }}
                            />
                        </Alert>
                    ) : (
                        <>
                            <ThirdPartyLoginWrapper>
                                <GoogleButton
                                    label={
                                        <FormattedMessage defaultMessage="S’inscrire avec Google" />
                                    }
                                    dataLayerEvent="signup"
                                />
                                <FacebookButton
                                    label={
                                        <FormattedMessage defaultMessage="S’inscrire avec Facebook" />
                                    }
                                    rounded={true}
                                    dataLayerEvent="signup"
                                />
                            </ThirdPartyLoginWrapper>
                            <Separator>
                                <Line />
                                <FormattedMessage defaultMessage="Ou connectez-vous par email" />
                                <Line />
                            </Separator>
                        </>
                    )}
                    <Form />
                    <MobileLinkToLoginPageWrapper>{signupLink}</MobileLinkToLoginPageWrapper>
                </InnerLeft>
            </Left>

            <Right>
                <InstructionsInnerWrapper>
                    <Illustration />
                </InstructionsInnerWrapper>
            </Right>
        </LoginWrapper>
    );
};

const LoginWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;
    min-height: 100%;
    background-color: var(--white);

    @media (max-width: 900px) {
        padding: 1rem;
        flex-direction: column-reverse;
    }

    & > * {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
    }
`;
const Container = styled.div`
    align-self: stretch;
    padding: 1.5rem;
    overflow: hidden;
    max-width: 100%;
`;
const Left = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 900px) {
        padding-inline: 0;
    }
`;
const InnerLeft = styled.div`
    width: 100%;
    max-width: 28rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Header = styled.div`
    display: none;

    @media (min-width: 900px) {
        display: block;
    }
`;
const Logo = styled(AushaLogo)`
    height: 2rem;
    width: 7.25rem;
    margin-bottom: 2rem;
`;
const ThirdPartyLoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const Right = styled(Container)`
    background: var(--gradient-primary);
    border-radius: var(--r-xl);

    @media (min-width: 900px) {
        padding-bottom: 4rem;
    }
`;
const InstructionsInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
const MobileLinkToLoginPageWrapper = styled.span`
    display: none;
    text-align: center;

    @media (min-width: 900px) {
        display: block;
    }
`;
const DesktopAndUpSignupCallToAction = styled.span`
    display: none;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        display: block;
    }
`;
const Title = styled.h1`
    font-size: var(--fs-heading-m);
`;
const Separator = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--neutral);
`;
const Line = styled.span`
    height: 1px;
    background-color: var(--neutral100);
    flex: 1;
`;

export default Register;
