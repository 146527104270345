import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const usePasswordFormSchema = (isExternalUser: boolean | undefined) => {
    const intl = useIntl();

    return z.object({
        password: isExternalUser
            ? z.string().optional()
            : z
                  .string({ required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required) })
                  .min(8, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 })),
    });
};

export type DeleteAccountPasswordFormSchema = z.infer<ReturnType<typeof usePasswordFormSchema>>;

export default usePasswordFormSchema;
