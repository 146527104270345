import { useState } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import PropTypes from 'prop-types';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
    SOCIAL_NETWORK,
    SOCIAL_NETWORK_NAME,
    SOCIAL_NETWORK_POST_STATUS,
    SOCIAL_NETWORK_POST_TYPE,
} from '@/shared/config/constants';
import PostDetails from '@/components/PostDetails';
import useDeleteSocialNetworkPostMutation from '@/queries/socialNetworkPost/useDeleteSocialNetworkPostMutation.hook';
import { useEditedPostContext } from '@app/pages/SocialMediaPage/useEditedPostContext.hook';
import SocialMediaPostDeleteModal from '../../SocialMediaPostDeleteModal';
import { Dialog, DialogTrigger, Popover, Button as AriaButton } from 'react-aria-components';
import Modal from '@ui/atoms/Modal';
import { useResponsive } from '@/shared/hooks/useResponsive';
import ExperimentalTooltip from '@ui/atoms/ExperimentalTooltip';
import MenuDropdown from '@ui/atoms/MenuDropdown';
import styled from 'styled-components';
import smartlinkIllustration from '@public/images/post_smartlink_background.png';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const SocialMediaPost = ({
    post,
    insightsLoading,
    insights,
    postDetailsShownAt,
    setPostDetailsShownAt,
}) => {
    const deletePost = useDeleteSocialNetworkPostMutation();
    const editPostContext = useEditedPostContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isPlannedForEpisodeRelease = post.state === SOCIAL_NETWORK_POST_STATUS.PUBLISH_NOW;
    const isPlannedForCustomDate =
        post.state === SOCIAL_NETWORK_POST_STATUS.PUBLISH_AT && post.publishAt !== null;
    const isPlanned = isPlannedForEpisodeRelease || isPlannedForCustomDate;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { isDesktop } = useResponsive();
    const isSmartlink = post?.type === SOCIAL_NETWORK_POST_TYPE.SMARTLINK;
    const postImage = isSmartlink
        ? smartlinkIllustration
        : post?.imageUrl || post?.podcast?.imageUrl;

    const handleDeletePost = () => {
        deletePost.mutate(
            { postId: post.id },
            {
                onSuccess: () => {
                    setIsDeleteModalOpen(false);
                },
            },
        );
    };

    const dropdownItems = [
        {
            id: 'details',
            icon: <MenuItemIcon icon={icon({ name: 'eye', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Détails" />,
        },
        {
            id: 'edit',
            icon: <MenuItemIcon icon={icon({ name: 'pen-line', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Modifier" />,
        },
        {
            id: 'duplicate',
            icon: <MenuItemIcon icon={icon({ name: 'clone', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Dupliquer" />,
        },
        {
            id: 'delete',
            icon: <MenuItemIcon icon={icon({ name: 'trash', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Supprimer" />,
            color: '--alert',
        },
    ];
    const publishedDropdownItems = [
        {
            id: 'details',
            icon: <MenuItemIcon icon={icon({ name: 'eye', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Détails" />,
        },
        {
            id: 'duplicate',
            icon: <MenuItemIcon icon={icon({ name: 'clone', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Dupliquer" />,
        },
        {
            id: 'link',
            icon: <MenuItemIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />,
            name: (
                <MenuItemLink href={post.externalUrl} target="_blank" rel="noreferrer">
                    <FormattedMessage
                        defaultMessage="Voir sur {provider}"
                        values={{ provider: SOCIAL_NETWORK_NAME[post.provider] }}
                    />
                </MenuItemLink>
            ),
        },
    ];
    const menuItems =
        post.state === SOCIAL_NETWORK_POST_STATUS.DONE ? publishedDropdownItems : dropdownItems;

    const menuItemsHandler = (action) => {
        switch (action) {
            case 'details':
                sendAmplitudeLogEvent('Social Network Post Preview List');
                setPostDetailsShownAt(post.id);
                setIsMenuOpen(false);
                break;
            case 'edit':
                editPostContext.setEditedPost({ post });
                setIsMenuOpen(false);
                break;
            case 'duplicate':
                editPostContext.setEditedPost({ post, duplicate: true });
                setIsMenuOpen(false);
                break;
            case 'delete':
                setIsDeleteModalOpen(true);
                setIsMenuOpen(false);
                break;
            default:
                break;
        }
    };

    const platformLookup = {
        [SOCIAL_NETWORK.FACEBOOK]: {
            icon: <FontAwesomeIcon icon={icon({ name: 'facebook', style: 'brands' })} size="md" />,
            color: '--facebook',
            name: SOCIAL_NETWORK_NAME[SOCIAL_NETWORK.FACEBOOK],
        },
        [SOCIAL_NETWORK.INSTAGRAM]: {
            icon: <FontAwesomeIcon icon={icon({ name: 'instagram', style: 'brands' })} size="md" />,
            color: '--instagram',
            name: SOCIAL_NETWORK_NAME[SOCIAL_NETWORK.INSTAGRAM],
        },
        [SOCIAL_NETWORK.TWITTER]: {
            icon: <FontAwesomeIcon icon={icon({ name: 'x-twitter', style: 'brands' })} size="md" />,
            color: '--twitter',
            name: SOCIAL_NETWORK_NAME[SOCIAL_NETWORK.TWITTER],
        },
        [SOCIAL_NETWORK.LINKEDIN]: {
            icon: <FontAwesomeIcon icon={icon({ name: 'linkedin', style: 'brands' })} size="md" />,
            color: '--linkedin',
            name: SOCIAL_NETWORK_NAME[SOCIAL_NETWORK.LINKEDIN],
        },
    };

    const statusLookup = {
        [SOCIAL_NETWORK_POST_STATUS.DONE]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'circle-check', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="Publié" />,
            color: '--success',
        },
        [SOCIAL_NETWORK_POST_STATUS.PUBLISH_AT]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'clock', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="Planifié" />,
            color: '--info',
        },
        [SOCIAL_NETWORK_POST_STATUS.PUBLISH_NOW]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'clock', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="Planifié" />,
            color: '--info',
        },
        [SOCIAL_NETWORK_POST_STATUS.DRAFT]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'pen-line', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="Brouillon" />,
            color: '--neutral',
        },
        [SOCIAL_NETWORK_POST_STATUS.ERROR]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'circle-exclamation', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="En erreur" />,
            color: '--alert',
        },
        [SOCIAL_NETWORK_POST_STATUS.PROCESSING]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'gears', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="En cours" />,
            color: '--warning',
        },
    };

    const typeLookup = {
        [SOCIAL_NETWORK_POST_TYPE.CLIP]: {
            icon: <MediaTypeIcon icon={icon({ name: 'film', style: 'regular' })} color="inherit" />,
            text: <FormattedMessage defaultMessage="Clip vidéo" />,
        },
        [SOCIAL_NETWORK_POST_TYPE.IMAGE]: {
            icon: (
                <MediaTypeIcon icon={icon({ name: 'image', style: 'regular' })} color="inherit" />
            ),
            text: <FormattedMessage defaultMessage="Image" />,
        },
        [SOCIAL_NETWORK_POST_TYPE.PODCAST_COVER]: {
            icon: (
                <MediaTypeIcon icon={icon({ name: 'image', style: 'regular' })} color="inherit" />
            ),
            text: <FormattedMessage defaultMessage="Pochette d'épisode" />,
        },
        [SOCIAL_NETWORK_POST_TYPE.LINK]: {
            icon: (
                <MediaTypeIcon icon={icon({ name: 'podcast', style: 'regular' })} color="inherit" />
            ),
            text: <FormattedMessage defaultMessage="Lien de l'épisode" />,
        },
        [SOCIAL_NETWORK_POST_TYPE.SMARTLINK]: {
            icon: <MediaTypeIcon icon={icon({ name: 'link', style: 'regular' })} color="inherit" />,
            text: <FormattedMessage defaultMessage="Smartlink" />,
        },
    };

    const plannedMessageLookup = {
        [SOCIAL_NETWORK_POST_STATUS.PUBLISH_AT]: (
            <FormattedMessage
                defaultMessage="Planifié pour le {date} à {time}"
                values={{
                    date: post.publishAt && (
                        <FormattedDate
                            value={post.publishAt}
                            day="2-digit"
                            month="2-digit"
                            year={undefined}
                        />
                    ),
                    time: post.publishAt && <FormattedTime value={post.publishAt} />,
                }}
            />
        ),
        [SOCIAL_NETWORK_POST_STATUS.PUBLISH_NOW]: (
            <FormattedMessage defaultMessage="Planifié pour la sortie de l'épisode" />
        ),
    };

    return (
        <>
            <PostOuterWrapper>
                <Post>
                    <MobileWrapper>
                        <MobileWrapperLeft>
                            <PlatformMobileWrapper color={platformLookup[post.provider].color}>
                                {platformLookup[post.provider].icon}
                            </PlatformMobileWrapper>
                            <StatusMobileWrapper>
                                <Status color={statusLookup[post.state]?.color}>
                                    {statusLookup[post.state]?.icon}
                                    {statusLookup[post.state]?.name}
                                </Status>
                            </StatusMobileWrapper>
                        </MobileWrapperLeft>
                        {!isDesktop && (
                            <DialogTrigger>
                                <TriggerButton
                                    aria-label="Menu"
                                    onPress={() => setIsMenuOpen(true)}
                                >
                                    <TriggerIcon
                                        icon={icon({ name: 'ellipsis', style: 'solid' })}
                                    />
                                </TriggerButton>
                                <Popover
                                    isOpen={isMenuOpen}
                                    onOpenChange={setIsMenuOpen}
                                    placement="bottom end"
                                >
                                    <MenuDropdown items={menuItems} onAction={menuItemsHandler} />
                                </Popover>
                            </DialogTrigger>
                        )}
                    </MobileWrapper>
                    <PlatformWrapper color={platformLookup[post.provider].color}>
                        {platformLookup[post.provider].icon}
                    </PlatformWrapper>
                    {postImage ? (
                        <SharedImage src={postImage} />
                    ) : (
                        <SharedImagePlaceholder>
                            <FontAwesomeIcon
                                icon={icon({
                                    name: 'pen-ruler',
                                    style: 'solid',
                                })}
                            />
                        </SharedImagePlaceholder>
                    )}
                    <ContentWrapper>
                        <PodcastWrapper>
                            <PodcastCover src={post.podcast.imageUrl} />
                            <PodcastName>{post.podcast.name}</PodcastName>
                        </PodcastWrapper>
                        {post.messageTpl ? (
                            <MessageTemplate numberOfLines={1}>{post.messageTpl}</MessageTemplate>
                        ) : (
                            <EmptyContent>
                                <FormattedMessage defaultMessage="Post vide" />
                            </EmptyContent>
                        )}
                        <Context>
                            {post.publishAt && (
                                <PublishDate>
                                    <MediaTypeIcon
                                        icon={icon({ name: 'calendar', style: 'regular' })}
                                    />
                                    <DateWrapper>
                                        <Text>
                                            <FormattedDate value={post.publishAt} />
                                        </Text>
                                        <Text>
                                            <FormattedMessage defaultMessage="à" />
                                        </Text>
                                        <Text>
                                            <FormattedTime value={post.publishAt} />
                                        </Text>
                                    </DateWrapper>
                                </PublishDate>
                            )}
                            {post.publishAt && post.type && <Dot />}
                            {post.type && (
                                <Type>
                                    {typeLookup[post.type]?.icon}
                                    {typeLookup[post.type]?.text}
                                </Type>
                            )}
                        </Context>
                    </ContentWrapper>
                    <MetadataWrapper>
                        <ExperimentalTooltip
                            content={
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Nombre d'impressions" />
                                </Text>
                            }
                        >
                            <ViewsWrapper>
                                <FontAwesomeIcon
                                    icon={icon({ name: 'chart-simple', style: 'solid' })}
                                    size="md"
                                />
                                {insightsLoading ? (
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'spinner-third', style: 'solid' })}
                                        spin
                                        size="md"
                                    />
                                ) : insights ? (
                                    insights.impression
                                ) : (
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'minus', style: 'solid' })}
                                        size="md"
                                    />
                                )}
                            </ViewsWrapper>
                        </ExperimentalTooltip>
                        <StatusWrapper>
                            <ExperimentalTooltip
                                isDisabled={!isPlanned}
                                content={
                                    <Text fontWeight="--fw-semibold">
                                        {plannedMessageLookup[post.state]}
                                    </Text>
                                }
                            >
                                <Status color={statusLookup[post.state]?.color}>
                                    {statusLookup[post.state]?.icon}
                                    {statusLookup[post.state]?.name}
                                </Status>
                            </ExperimentalTooltip>
                        </StatusWrapper>
                        {isDesktop && (
                            <DialogTrigger>
                                <TriggerButton
                                    aria-label="Menu"
                                    onPress={() => setIsMenuOpen(true)}
                                >
                                    <TriggerIcon
                                        icon={icon({ name: 'ellipsis', style: 'solid' })}
                                    />
                                </TriggerButton>
                                <Popover
                                    isOpen={isMenuOpen}
                                    onOpenChange={setIsMenuOpen}
                                    placement="bottom end"
                                >
                                    <MenuDropdown items={menuItems} onAction={menuItemsHandler} />
                                </Popover>
                            </DialogTrigger>
                        )}
                    </MetadataWrapper>
                </Post>
            </PostOuterWrapper>
            <PostDetailsModal
                isOpen={postDetailsShownAt === post.id}
                onOpenChange={setPostDetailsShownAt}
            >
                <Dialog>
                    <PostDetails
                        id={post.id}
                        platformLookup={platformLookup}
                        onHide={() => setPostDetailsShownAt(null)}
                    />
                </Dialog>
            </PostDetailsModal>
            <SocialMediaPostDeleteModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                isLoading={deletePost.isLoading}
                onDelete={handleDeletePost}
                provider={post.provider}
            />
        </>
    );
};

const PostOuterWrapper = styled.li`
    position: relative;
    width: 100%;
`;
const Post = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
    border-radius: var(--r-m);
    padding: 0.75rem;
    gap: 0.75rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        align-items: center;
    }
`;
const MobileWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        display: none;
    }
`;
const MobileWrapperLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const PlatformWrapper = styled.div`
    display: none;
    width: 2rem;
    height: 5rem;
    background-color: ${({ color }) => (color ? `var(${color}50)` : 'var(--neutral50)')};
    color: ${({ color }) => (color ? `var(${color})` : 'var(--neutral500)')};
    border-radius: var(--r-s);
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
    }
`;
const PlatformMobileWrapper = styled(PlatformWrapper)`
    display: flex;
    width: 2rem;
    height: 2rem;
`;
const SharedImage = styled.img`
    display: none;
    width: 5rem;
    height: 5rem;
    border-radius: var(--r-m);
    object-fit: contain;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        display: block;
    }
`;
// const SharedSmartlink = styled.div`
//     display: none;
//     width: 5rem;
//     height: 5rem;
//     border-radius: var(--r-m);
//     background-image: url(${smartlinkIllustration});
//     background-size: cover; /* Ensure the image covers the whole element */
//     background-position: bottom 0 left 10px top 10px; /* Position the image */
//     background-color: var(--neutral50);

//     ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
//         display: flex;
//     }
// `;
const SharedImagePlaceholder = styled.div`
    display: none;
    width: 5rem;
    height: 5rem;
    border-radius: var(--r-m);
    background-color: var(--neutral50);
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: var(--neutral300);

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-grow: 1;
    min-width: 0;
`;
const PodcastWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const PodcastCover = styled.img`
    width: 1rem;
    height: 1rem;
    border-radius: var(--r-xs);
`;
const PodcastName = styled.div`
    font-size: var(--fs-body-m);
`;
const MessageTemplate = styled(Text)`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-bold);
    flex-grow: 1;
    min-width: 0;
`;
const EmptyContent = styled.div`
    font-size: var(--fs-body-l);
    font-style: italic;
    color: var(--neutral500);
    flex-grow: 1;
`;
const Context = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral500);
    font-size: var(--fs-body-m);
`;
const PublishDate = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Dot = styled.div`
    width: 0.25rem;
    height: 0.25rem;
    border-radius: var(--r-full);
    background-color: var(--neutral500);
`;
const Type = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const ViewsWrapper = styled.div`
    display: none;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
    }
`;
const StatusWrapper = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    width: 10rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
    }
`;
const StatusMobileWrapper = styled(StatusWrapper)`
    display: flex;
    width: auto;
`;
const Status = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background-color: ${({ color }) => (color ? `var(${color}50)` : 'var(--neutral50)')};
    color: ${({ color }) => (color ? `var(${color})` : 'var(--neutral500)')};
    padding: 0.5rem;
    border-radius: var(--r-s);
    font-weight: var(--fw-semibold);
`;
const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: inherit;
`;
const MenuItemLink = styled.a`
    color: inherit;
    text-decoration: none;
`;
const MetadataWrapper = styled.div`
    display: none;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
        align-items: center;
        column-gap: 0.75rem;
        flex-basis: 16rem;
        flex-shrink: 0;
    }
`;
const PostDetailsModal = styled(Modal)`
    width: 20rem;
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1);
    color: var(--black);
`;
const MediaTypeIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const TriggerButton = styled(AriaButton)`
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
`;
const TriggerIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

SocialMediaPost.propTypes = {
    post: PropTypes.objectOf({
        id: PropTypes.string,
        state: PropTypes.string,
        publishAt: PropTypes.string,
        messageTpl: PropTypes.string,
        type: PropTypes.string,
        provider: PropTypes.string,
        podcast: PropTypes.objectOf({
            name: PropTypes.string,
            imageUrl: PropTypes.string,
        }),
    }),
    dropdownOpenedAtId: PropTypes.string,
    setDropdownOpenedAtId: PropTypes.func,
    insightsLoading: PropTypes.bool,
    insights: PropTypes.objectOf({
        impression: PropTypes.number,
    }),
    postDetailsShownAt: PropTypes.string,
    setPostDetailsShownAt: PropTypes.func,
};

export default SocialMediaPost;
