import { request } from '../api';

const basePath = process.env.API_BILLING;

export const fetchSubscription = () => {
    return request({ method: 'GET', basePath, target: '/subscriptions' });
};
export const fetchPrices = () => {
    return request({ method: 'GET', basePath, target: '/prices' });
};
export const fetchSponsorshipCode = () => {
    return request({ method: 'GET', basePath, target: '/sponsorship/code' });
};
export const getStripeCustomerPortalSession = () => {
    return request({
        method: 'GET',
        basePath,
        target: '/customer_portal',
        query: {
            redirect_url: window.location.href,
        },
    });
};
export const checkReferralCode = (code) => {
    return request({ method: 'GET', basePath, target: '/referer_codes', query: { code } });
};
export const updateSponsorshipCode = (body) => {
    return request({ method: 'PUT', basePath, target: '/referer_codes', body });
};
export const resumeSubscription = () => {
    return request({ method: 'POST', basePath, target: '/subscriptions/resume' });
};
export const resignSubscription = (body) => {
    return request({ method: 'DELETE', basePath, target: '/subscriptions', body });
};
export const switchSubscriptionPlan = (body) => {
    return request({ method: 'POST', basePath, target: '/subscriptions/swap', body });
};
export const reactivateSubscription = () => {
    return request({ method: 'POST', basePath, target: '/subscriptions/reactivate' });
};
export const addShowPricesQuota = (body) => {
    return request({ method: 'POST', basePath, target: '/subscriptions/preview', body });
};
export const updateShowQuota = (body) => {
    return request({ method: 'PUT', basePath, target: '/subscriptions', body });
};
export const createStripeCheckoutSession = ({ currency, isYearly, price }) => {
    return request({
        method: 'POST',
        basePath,
        target: '/checkout',
        body: {
            currency,
            is_yearly: isYearly,
            price,
            success_url: `${window.location.origin}/checkout-success?offer=${price}`,
            cancel_url: `${window.location.href}${
                window.location.href.includes('?') ? '&fromCheckout=true' : '?fromCheckout=true'
            }`,
        },
    });
};
export const addSurvey = (body) => {
    return request({ method: 'POST', basePath, target: '/user/surveys', body });
};
