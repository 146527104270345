import { useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import Cookie from 'js-cookie';
import { useAuthContext } from '@/context/AuthContext';
import { setAuthorizationCookies } from '@/shared/services/authorizationService';

const useRefreshToken = () => {
    const {
        setAccessToken,
        setAccessTokenTTL,
        setRefreshToken,
        refreshToken: localStateRefreshToken,
        logout,
    } = useAuthContext();

    useEffect(() => {
        /**
         * This flag prevents an infinite loop where the refresh token request is made multiple times.
         */
        let isRefreshing = false;

        /**
         * This will store queued requests that 401ed while we are refreshing the access token.
         */
        let refreshSubscribers: ((token: string | null) => void)[] = [];

        /**
         * Retry all queued requests with the new access token.
         * @param newAccessToken We notify all queued requests with the new access token.
         */
        const notifyRefreshSubscribers = (newAccessToken: string | null) => {
            refreshSubscribers.forEach((callback) => callback(newAccessToken));
            refreshSubscribers = [];
        };

        /**
         * Add a 401 request to the queue.
         */
        const addRefreshSubscriber = (callback: (token: string | null) => void) => {
            refreshSubscribers.push(callback);
        };

        const responseInterceptor = axios.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error) {
                const typedError = error as AxiosError;

                /**
                 * We store the original request to retry it after refreshing the token.
                 */
                const originalRequest = typedError.config;

                /**
                 * If the error is a 401 Unauthorized, it might be because of an expired accessToken.
                 * We'll try to refresh it.
                 */
                if (typedError?.response?.status === 401) {
                    if (isRefreshing) {
                        /**
                         * We add the original request to a queue.
                         * We'll wait until the token is refreshed and then retry every request in the queue.
                         */
                        return new Promise((resolve) => {
                            addRefreshSubscriber((newAccessToken) => {
                                if (!originalRequest) return;
                                if (!originalRequest.headers) {
                                    originalRequest.headers = {};
                                }
                                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                                resolve(axios(originalRequest));
                            });
                        });
                    }

                    isRefreshing = true;

                    try {
                        /**
                         * First, we try to get the refresh token from the local context state or from the cookie.
                         * If we can't find it, game over 👾, throw an error.
                         */
                        const refreshToken = localStateRefreshToken || Cookie.get('refresh');
                        if (!refreshToken) {
                            throw new Error('No refresh token found');
                        }

                        /**
                         * Next, we contact the API to refresh the accessToken with our refreshToken.
                         */
                        const { data } = await axios.request({
                            url: '/refresh',
                            method: 'POST',
                            data: { refresh_token: refreshToken },
                        });
                        if (!data) throw new Error('Could not refresh token');

                        /**
                         * If we successfully refreshed the token, we update the context state...
                         */
                        setAccessToken(data.access_token);
                        setAccessTokenTTL(data.expires_in);
                        setRefreshToken(data.refresh_token);

                        /**
                         * ...and the auth cookies
                         */
                        setAuthorizationCookies({
                            accessToken: data.access_token,
                            accessTokenTTL: data.expires_in,
                            refreshToken: data.refresh_token,
                        });

                        /**
                         * Finally, we notify all queued requests with the new access token.
                         */
                        notifyRefreshSubscribers(data.access_token);
                    } catch (refreshError) {
                        logout();
                        return Promise.reject(refreshError);
                    } finally {
                        isRefreshing = false;
                    }
                }
                return Promise.reject(error);
            },
        );

        return () => {
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [axios, localStateRefreshToken, logout, setAccessToken, setAccessTokenTTL, setRefreshToken]);
};

export default useRefreshToken;
