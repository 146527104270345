import Button from '@/components/Button';
import { CheckboxController } from '@/components/ui/Checkbox';
import Alert from '@/components/ui/atoms/Alert';
import { InputTextController } from '@/components/ui/atoms/InputText';
import Text from '@/components/ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import useCustomPromptFormSchema, {
    AICustomPromptFormSchema,
} from './useAICustomPromptFormSchema.hook';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import RouterLink from '@/components/Link/RouterLink';
import { useParams } from 'react-router';
import UpgradeBadge from '@/components/ui/atoms/UpgradeBadge';
import Cluster from '@/components/ui/layout/Cluster';

const labels = {
    customPrompt: defineMessages({
        title: { defaultMessage: 'Instructions personnalisée pour le titre' },
        description: { defaultMessage: 'Instructions personnalisée pour la description' },
        tags: { defaultMessage: 'Instructions personnalisée pour les tags' },
        newsletter: { defaultMessage: 'Instructions personnalisée pour la newsletter' },
        smm: { defaultMessage: 'Instructions personnalisée pour le post' },
    }),
    saveAsDefault: defineMessages({
        title: {
            defaultMessage:
                'Appliquer ces instructions à toutes les futures générations de titres.',
        },
        description: {
            defaultMessage:
                'Appliquer ces instructions à toutes les futures générations de descriptions.',
        },
        tags: {
            defaultMessage: 'Appliquer ces instructions à toutes les futures générations de tags.',
        },
        newsletter: {
            defaultMessage:
                'Appliquer ces instructions à toutes les futures générations de newsletters.',
        },
        smm: {
            defaultMessage: 'Appliquer ces instructions à toutes les futures générations de posts.',
        },
    }),
};

const alertBulletPoints = {
    title: defineMessages({
        first: { defaultMessage: 'Formuler comme une question.' },
        second: { defaultMessage: 'Inclure les noms des intervenants.' },
        third: { defaultMessage: 'Inclure un chiffre clé.' },
    }),
    description: defineMessages({
        first: { defaultMessage: 'Commencer par une question.' },
        second: { defaultMessage: 'Présenter les orateurs.' },
        third: { defaultMessage: "Inclure une citation tirée de l'épisode." },
    }),
    tags: defineMessages({
        first: { defaultMessage: 'Inclure des mots-clés relatifs au thème' },
        second: { defaultMessage: 'Inclure les noms des intervenants.' },
        third: { defaultMessage: 'Utiliser des mots-clés composés de plusieurs mots.' },
    }),
    newsletter: defineMessages({
        first: { defaultMessage: 'Commencer le contenu par une question.' },
        second: { defaultMessage: 'Remercier les intervenants à la fin.' },
        third: { defaultMessage: "Inclure une citation tirée de l'épisode." },
    }),
    smm: defineMessages({
        first: { defaultMessage: 'Partager un extrait inspirant du dernier épisode.' },
        second: { defaultMessage: "Terminer par un appel à l'action engageant." },
        third: { defaultMessage: 'Inclure un chiffre clé.' },
    }),
};

interface EpisodeAICustomPromptFormProps {
    onClose: () => void;
    onSubmit: (data: AICustomPromptFormSchema) => void;
    type:
        | 'title'
        | 'description'
        | 'tags'
        | 'newsletter'
        | 'newsletter-title'
        | 'newsletter-message'
        | 'smm';
    values: AICustomPromptFormSchema;
    isFreeTrial: boolean;
}

const EpisodeAICustomPromptForm = ({
    onClose,
    onSubmit,
    type,
    values,
    isFreeTrial,
}: EpisodeAICustomPromptFormProps) => {
    const { showId } = useParams<{ showId: string }>();
    const intl = useIntl();
    const schema = useCustomPromptFormSchema();
    const { control, handleSubmit } = useForm<AICustomPromptFormSchema>({
        mode: 'onBlur',
        values,
        resolver: zodResolver(schema),
    });
    const forwardedType = type.startsWith('newsletter') ? 'newsletter' : type;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <CustomPrompt>
                <InputTextController
                    name="customPrompt"
                    elementType="textarea"
                    control={control}
                    label={intl.formatMessage(
                        labels.customPrompt[forwardedType as keyof typeof labels.customPrompt],
                    )}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Saisissez vos instructions personnalisées',
                    })}
                    minHeight="80px"
                    maxLength={200}
                />
                <SaveDefault>
                    <CheckboxController
                        name="saveAsDefault"
                        control={control}
                        label={
                            <Cluster $gap="0.5rem" $align="center">
                                <Text>
                                    <FormattedMessage
                                        {...labels.saveAsDefault[
                                            forwardedType as keyof typeof labels.saveAsDefault
                                        ]}
                                    />
                                </Text>
                                {isFreeTrial && (
                                    <UpgradeBadge variant="nebula">Add-on</UpgradeBadge>
                                )}
                            </Cluster>
                        }
                        isDisabled={isFreeTrial}
                    />
                    <CheckboxDescription color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Vous pouvez toujours modifier ce réglage dans les <link>Paramètres de l'émission</link>."
                            values={{
                                link: (chunks: string) => (
                                    <RouterLink
                                        to={`/app/show/${showId}/settings/ai`}
                                        target="_blank"
                                        endIcon={
                                            <LinkIcon
                                                icon={icon({
                                                    name: 'arrow-up-right',
                                                    style: 'solid',
                                                })}
                                            />
                                        }
                                    >
                                        {chunks}
                                    </RouterLink>
                                ),
                            }}
                        />
                    </CheckboxDescription>
                </SaveDefault>
            </CustomPrompt>
            <Alert variant="robot">
                <AlertContent>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Besoin d'inspiration pour vos instructions ? Voici quelques idées pour commencer !" />
                    </Text>
                    <List>
                        <ListItem>
                            <Text>
                                <FormattedMessage {...alertBulletPoints[forwardedType].first} />
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <FormattedMessage {...alertBulletPoints[forwardedType].second} />
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <FormattedMessage {...alertBulletPoints[forwardedType].third} />
                            </Text>
                        </ListItem>
                    </List>
                </AlertContent>
            </Alert>
            <Footer>
                <CancelButton variant="ghost" onPress={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </CancelButton>
                <Button
                    type="submit"
                    variant="magic"
                    startIcon={<SparklesIcon icon={icon({ name: 'sparkles', style: 'duotone' })} />}
                >
                    <FormattedMessage defaultMessage="Confirmer et regénérer" />
                </Button>
            </Footer>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const CustomPrompt = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const SaveDefault = styled.div`
    display: flex;
    flex-direction: column;
`;
const CheckboxDescription = styled(Text)`
    margin-left: 1.5rem;
`;
const AlertContent = styled.div`
    display: flex;
    flex-direction: column;
`;
const List = styled.ul`
    list-style-type: none;
    padding-inline: 0.5rem;
`;
const ListItem = styled.li`
    display: list-item;
    list-style-type: '- ';
    color: var(--neutral700);
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
    border-top: 1px solid var(--neutral100);
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const SparklesIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--white);
`;
const LinkIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default EpisodeAICustomPromptForm;
