import Stack from '@ui/layout/Stack';
import NoChaptersAlert from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormChapters/NoChaptersAlert';
import SelectedChapter from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormChapters/SelectedChapter';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useSwitch } from '@/shared/hooks/useSwitch';
import { useOverlayTriggerState } from 'react-stately';
import ChaptersAudioPlayer from './ChaptersAudioPlayer';
import ChaptersLabel from './ChaptersLabel';
import ChaptersList from './ChaptersList';
import EpisodeAIChaptersSuggestionsPanel from '../NonHostingEpisodeAI/EpisodeAIChaptersSuggestionsPanel';
import NextStepButton from '../NonHostingEpisodeFormAccordion/NextStepButton';
import DeleteChapterModal from './DeleteChapterModal';
import { useAuthContext } from '@/context/AuthContext';
import { addQueryParam } from '@/shared/utils/url';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;

        & > * {
            width: 50%;
        }
    }
`;

const ChaptersEditor = ({ episode, waveformUrl }) => {
    const { accessToken } = useAuthContext();
    const { control } = useFormContext();
    const { fields, append, remove, update } = useFieldArray({
        name: 'chapters',
        keyName: 'key',
        control,
    });

    const [isAudioPlaying, playAudio, pauseAudio] = useSwitch(false);
    const [audioPlayerCursorPosition, setAudioPlayerCursorPosition] = useState(false);
    const [audioPlayerCursorPositionHasChanged, setAudioPlayerCursorPositionHasChanged] =
        useState(false);

    const [selectedChapterId, setSelectedChapterId] = useState();
    const selectedChapterIndex = fields.findIndex((chapter) => chapter.id === selectedChapterId);
    const selectedField = fields[selectedChapterIndex];

    const [chapterIdToDelete, setChapterIdToDelete] = useState();
    const chapterIndexToDelete = fields.findIndex((chapter) => chapter.id === chapterIdToDelete);
    const fieldToDelete = fields[chapterIndexToDelete];
    const deleteChapterModalState = useOverlayTriggerState({});

    const onSelectChapter = (chapterId) => {
        setSelectedChapterId(chapterId);
    };

    const onAddChapter = (newChapter) => {
        append(newChapter);
    };

    const onUpdateChapterStartTime = (chapterId, startTime) => {
        const chapterIndex = fields.findIndex((chapter) => chapter.id === chapterId);

        if (chapterIndex < 0) {
            return;
        }

        const chapterField = fields[chapterIndex];
        update(chapterIndex, { ...chapterField, startTime });
    };

    const handleDeleteChapterById = (chapterId) => {
        setChapterIdToDelete(chapterId);
        deleteChapterModalState.open();
    };

    const handleDeleteSelectedChapter = () => {
        handleDeleteChapterById(selectedChapterId);
    };

    const confirmDeleteChapter = () => {
        if (chapterIdToDelete === selectedChapterId) {
            setSelectedChapterId(undefined);
        }

        setChapterIdToDelete(undefined);
        remove(chapterIndexToDelete);
    };

    return (
        <Stack $gap="1rem">
            <ChaptersAudioPlayer
                audioUrl={addQueryParam(episode.audioUrlInternal, 'token', accessToken)}
                waveformUrl={waveformUrl}
                selectedChapterId={selectedChapterId}
                onSelectChapter={onSelectChapter}
                onAddChapter={onAddChapter}
                onUpdateChapterStartTime={onUpdateChapterStartTime}
                cursorPosition={audioPlayerCursorPosition}
                cursorPositionHasChanged={audioPlayerCursorPositionHasChanged}
                isPlaying={isAudioPlaying}
                play={playAudio}
                pause={pauseAudio}
            />
            <Stack $gap="1rem">
                <ChaptersLabel />
                <EpisodeAIChaptersSuggestionsPanel />
                {fields.length === 0 ? (
                    <NoChaptersAlert />
                ) : (
                    <Container>
                        <ChaptersList
                            selectedChapterId={selectedChapterId}
                            onSelectChapter={setSelectedChapterId}
                            onDeleteChapter={handleDeleteChapterById}
                        />
                        {!!selectedField && (
                            <SelectedChapter
                                key={selectedField.key}
                                index={selectedChapterIndex}
                                onDeleteChapter={handleDeleteSelectedChapter}
                                setAudioPlayerCursorPosition={setAudioPlayerCursorPosition}
                                setAudioPlayerCursorPositionHasChanged={
                                    setAudioPlayerCursorPositionHasChanged
                                }
                                isAudioPlaying={isAudioPlaying}
                                playAudio={playAudio}
                            />
                        )}
                    </Container>
                )}
            </Stack>
            <NextStepButton />
            {deleteChapterModalState.isOpen && (
                <DeleteChapterModal
                    chapter={fieldToDelete}
                    handleDeleteChapter={confirmDeleteChapter}
                    isOpen={deleteChapterModalState.isOpen}
                    onClose={deleteChapterModalState.close}
                />
            )}
        </Stack>
    );
};

export default ChaptersEditor;
