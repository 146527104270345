import Nav from './Nav';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <LayoutWrapper>
            <Sidebar>
                <TitleAndSubtitle>
                    <Title>
                        <FormattedMessage defaultMessage="Compte" />
                    </Title>
                </TitleAndSubtitle>
                <Nav />
            </Sidebar>
            <ContentWrapper>{children}</ContentWrapper>
        </LayoutWrapper>
    );
};

const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--container-width);
    padding-inline: var(--container-padding);
    gap: 2rem;
    margin-block: 2rem;
    justify-content: center;

    @media screen and (min-width: 64rem) {
        flex-direction: row;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
`;
const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 1rem;

    @media screen and (min-width: 64rem) {
        width: 15rem;
        gap: 2rem;
        position: sticky;
        top: 8.5rem;
        left: 0;
    }
`;
const TitleAndSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const Title = styled.h1`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
    line-height: 1.4rem;
`;

export default Layout;
