import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import useWebsiteSettingsFormSchema from './useWebsiteSettingsFormSchema.hook';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ToggleController } from '@/components/ui/atoms/Toggle';
import InputText, { InputTextController } from '@/components/ui/atoms/InputText';
import { SelectController } from '@/components/ui/atoms/Select';
import { ColorPickerController } from '@ui/molecules/ColorPicker';
import CustomBackground from './CustomBackground';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import Text from '@/components/ui/atoms/Text';
import Link from '@/components/ui/atoms/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import useListenableEpisodesQuery from '@/queries/episode/useListenableEpisodesQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useWebsiteSettingsMutation from '@/queries/websiteSettings/useWebsiteSettingsMutation.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import styled from 'styled-components';
import UnsavedFormModal from '@/components/unorganized/UnsavedFormModal';
import Button from '@/components/Button';
import IconButton from '@/components/IconButton';

import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
interface WebsiteSettingsFormProps {
    defaultValues: any;
}

const WebsiteSettingsForm = ({ defaultValues }: WebsiteSettingsFormProps) => {
    const intl = useIntl();
    const toast = useModalToastQueue();
    const { showId }: { showId: string } = useParams();
    const updateSettings = useWebsiteSettingsMutation();
    const { data: show } = useShowQuery(showId);
    const schema = useWebsiteSettingsFormSchema();
    const episodes = useListenableEpisodesQuery(showId);

    const featuredEpisodeCandidates: { id: number; name: string }[] = useMemo(() => {
        return (
            episodes.data?.map((episode) => ({
                id: episode.id,
                name: episode.name,
            })) || []
        );
    }, [episodes?.data]);

    const { control, handleSubmit, formState, clearErrors, watch, setValue, reset } = useForm({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: defaultValues || {},
    });

    const { fields, append, remove } = useFieldArray({
        name: 'recommendedShows',
        control,
    });

    const onSubmit = (formData: any) => {
        const payload = {
            ...formData,
            multiShow: formData.isMultiShowEnabled,
        };
        updateSettings.mutate(
            {
                showId,
                payload,
            },
            {
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Une erreur est survenue, les données n'ont pas été enregistrées." />,
                    );
                },
                onSuccess: () => {
                    sendAmplitudeLogEvent('Website Activated');
                    toast.success(
                        <FormattedMessage defaultMessage="Les modifications ont bien été enregistrées. 👌" />,
                    );
                    reset(formData);
                },
            },
        );
    };

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={formState.isDirty} />
            <Form onSubmit={handleSubmit((formData) => onSubmit(formData))}>
                <FormSection>
                    <Title>
                        <FormattedMessage defaultMessage="Paramètres" />
                    </Title>
                    <ToggleController
                        name="isPublished"
                        control={control}
                        label={<FormattedMessage defaultMessage="Activer le Site Web" />}
                    >
                        <FormattedMessage defaultMessage="Le Site Web de votre émission est en ligne." />
                    </ToggleController>
                    <InputText
                        name="slug"
                        prefix={process.env.URL_PODCAST_AUSHA}
                        label={<FormattedMessage defaultMessage="Lien de votre site web" />}
                        description={
                            <FormattedMessage
                                defaultMessage="Ce permalien peut-être modifié dans les {settings} de votre émission."
                                values={{
                                    settings: (
                                        <Text
                                            // @ts-ignore
                                            as={Link}
                                            to={`/app/show/${showId}/settings/info`}
                                            color="--primary"
                                            fontWeight="--fw-semibold"
                                        >
                                            <FormattedMessage defaultMessage="paramètres" />
                                        </Text>
                                    ),
                                }}
                            />
                        }
                        copyToClipboardEnabled
                        isDisabled={true}
                        customButton={
                            show?.slug ? (
                                <LinkToWebsite
                                    href={`https://podcast.ausha.co/${show?.slug}`}
                                    target="_BLANK"
                                >
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'arrow-up-right-from-square',
                                            style: 'solid',
                                        })}
                                        size="xs"
                                    />
                                    <FormattedMessage defaultMessage="Ouvrir" />
                                </LinkToWebsite>
                            ) : null
                        }
                        value={show?.slug}
                    />
                    <ToggleController
                        name="isMultiShowEnabled"
                        control={control}
                        label={<FormattedMessage defaultMessage="Multi-émission" />}
                    >
                        <FormattedMessage defaultMessage="Permettre aux visiteurs de votre page de voir les différentes émissions de votre compte." />
                    </ToggleController>
                    <ToggleController
                        name="displayTranscript"
                        control={control}
                        label={<FormattedMessage defaultMessage="Transcription" />}
                    >
                        <FormattedMessage defaultMessage="Afficher la transcription de vos épisodes (nécessite que la transcription soit activée et générée par épisode)." />
                    </ToggleController>
                    <ToggleController
                        name="useTwitter"
                        control={control}
                        onChange={(isChecked: boolean) => {
                            if (isChecked) {
                                setValue('twitterAccount', '', {
                                    shouldDirty: true,
                                });
                                return;
                            }
                            setValue('twitterAccount', null, {
                                shouldDirty: true,
                            });
                        }}
                        label={<FormattedMessage defaultMessage="Fil X (Twitter)" />}
                    >
                        <FormattedMessage defaultMessage="Afficher votre fil X (Twitter) sur votre Site Web." />
                    </ToggleController>
                    <InputTextController
                        name="twitterAccount"
                        control={control}
                        prefix={'https://x.com/'}
                        label={<FormattedMessage defaultMessage="Lien du compte X (Twitter)" />}
                    />
                    <FeaturedEpisodeSelectController
                        name="featuredEpisode"
                        control={control}
                        onSelectionChange={(id) => {
                            setValue('featuredEpisode', id);
                        }}
                        label={
                            <FeaturedLabelWrapper>
                                <FormattedMessage defaultMessage="Episode à la une" />
                                <TooltipTrigger delay={0} closeDelay={0}>
                                    <TooltipTriggerWrapper>
                                        <InfoIcon
                                            icon={icon({
                                                name: 'circle-info',
                                                style: 'solid',
                                            })}
                                        />
                                    </TooltipTriggerWrapper>
                                    <Tooltip placement="top">
                                        <Text>
                                            <FormattedMessage defaultMessage="Choisissez un épisode que vous souhaitez mettre en avant en haut de votre site web Ausha" />
                                        </Text>
                                    </Tooltip>
                                </TooltipTrigger>
                            </FeaturedLabelWrapper>
                        }
                        items={[
                            {
                                id: 'none',
                                name: intl.formatMessage({
                                    defaultMessage: "Ne pas afficher d'épisode à la une",
                                }),
                            },
                            {
                                id: 'latest',
                                name: intl.formatMessage({
                                    defaultMessage: "Toujours afficher l'épisode le plus récent",
                                }),
                                hasSeparator: true,
                            },
                            ...(featuredEpisodeCandidates || []),
                        ]}
                    >
                        {(item) => <ListBoxItem {...item} />}
                    </FeaturedEpisodeSelectController>

                    <RecommandedShowWrapper>
                        <SubTitle>
                            <FormattedMessage defaultMessage="Recommander des émissions (4 maximum) " />
                        </SubTitle>
                        <RecommandedShowFields>
                            {fields.map((field, index) => {
                                return (
                                    <RecommandedShowField key={field.id}>
                                        <InputText
                                            name={`recommandedShows.${index}`}
                                            prefix={process.env.URL_PODCAST_AUSHA}
                                            label={
                                                <FormattedMessage defaultMessage="Lien de l'émission à recommander" />
                                            }
                                            value={watch(`recommendedShows.${index}.value`)}
                                            onChange={(value) => {
                                                setValue(`recommendedShows.${index}.value`, value);
                                                return { value };
                                            }}
                                        />
                                        {fields.length > 1 && (
                                            <RemoveButton
                                                aria-label="Remove recommanded show"
                                                variant="tertiary"
                                                onPress={() => remove(index)}
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={icon({
                                                            name: 'xmark',
                                                            style: 'solid',
                                                        })}
                                                        size="lg"
                                                    />
                                                }
                                            />
                                        )}
                                    </RecommandedShowField>
                                );
                            })}
                        </RecommandedShowFields>
                        {fields.length < 4 && (
                            // @ts-ignore
                            <AddRecommandedShowButton
                                isDisabled={false}
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'plus',
                                            style: 'solid',
                                        })}
                                    />
                                }
                                variant="ghost"
                                onPress={() => append({ value: '' })}
                            >
                                <FormattedMessage defaultMessage="Ajouter une émission" />
                            </AddRecommandedShowButton>
                        )}
                    </RecommandedShowWrapper>
                </FormSection>
                <FormSection>
                    <Title>
                        <FormattedMessage defaultMessage="Apparence" />
                    </Title>
                    <ColorPickerController
                        control={control}
                        name="color"
                        label={<FormattedMessage defaultMessage="Couleur principale" />}
                        onBlur={() => clearErrors('color')}
                    />
                    <CustomBackground
                        control={control}
                        name="cover"
                        formatPage="cover_page"
                        type={<FormattedMessage defaultMessage="Site Web" />}
                        coverSize="2000*800px"
                    />
                    <CTAWrapper>
                        <Label>
                            <FormattedMessage defaultMessage="Boutons personnalisés" />
                        </Label>
                        <Description>
                            <FormattedMessage
                                defaultMessage="Ces boutons seront affichés sur l’en-tête de votre site web et peuvent être gérés via l’onglet {link} du menu Configuration"
                                values={{
                                    link: (
                                        <Text
                                            // @ts-ignore
                                            as={Link}
                                            to={`/app/show/${showId}/communication/settings/call-to-action`}
                                            fontWeight="--fw-semibold"
                                            color="--primary"
                                        >
                                            Call-to-Action
                                        </Text>
                                    ),
                                }}
                            />
                        </Description>
                    </CTAWrapper>
                </FormSection>
                <SubmitWrapper>
                    <Button
                        type="submit"
                        isDisabled={!formState.isValid}
                        isLoading={formState.isSubmitting}
                    >
                        <FormattedMessage defaultMessage="Enregistrer" />
                    </Button>
                </SubmitWrapper>
            </Form>
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Title = styled.div`
    width: 100%;
    font-size: var(--fs-heading);
    font-weight: var(--fw-bold);
    border-bottom: 1px solid var(--neutral100);
    padding-block: 0.25rem;
    margin-block: 0.5rem;
`;
const SubTitle = styled.div`
    font-size: var(--fs-body);
    font-weight: var(--fw-semibold);
    color: var(--neutral500);
`;
const LinkToWebsite = styled.a`
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--primary);
    text-decoration: none;
    font-weight: var(--fw-semibold);

    & svg {
        margin-bottom: 0.125rem;
    }
`;
const FeaturedEpisodeSelectController = styled(SelectController)`
    max-width: none;
`;
const FeaturedLabelWrapper = styled.div`
    display: inline-flex;
    gap: 0.5rem;
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const RecommandedShowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const RecommandedShowFields = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const RecommandedShowField = styled.li`
    list-style-type: none;
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
`;
const RemoveButton = styled(IconButton)`
    width: 2.5rem;
    height: 2.5rem;
`;
const AddRecommandedShowButton = styled(Button)`
    width: max-content;
`;
const CTAWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const Label = styled(Text)`
    font-weight: var(--fw-semibold);
`;
const Description = styled(Text)`
    color: var(--neutral500);
`;
const SubmitWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export default WebsiteSettingsForm;
