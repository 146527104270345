import { Popover } from 'react-aria-components';
import styled, { keyframes } from 'styled-components';

interface DesktopDropdownProps {
    triggerRef: React.RefObject<HTMLButtonElement>;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    children: React.ReactNode;
}

const DesktopDropdown = (props: DesktopDropdownProps) => {
    return (
        <PopoverStyled
            // shouldCloseOnInteractOutside={() => false}
            offset={-4}
            placement="bottom"
            {...props}
        />
    );
};

const animationIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;
const animationOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-5px);
    }
`;
const PopoverStyled = styled(Popover)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-top-left-radius: var(--r-l);
        border-top-right-radius: var(--r-l);
        background-color: var(--white);
        box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.1);
        height: fit-content;
        width: 100%;
        max-width: 17.5rem;
        border-radius: var(--r-m);

        &[data-entering] {
            animation: ${animationIn} 0.25s ease-in-out;
        }
        &[data-exiting] {
            animation: ${animationOut} 0.25s ease-in-out;
        }
    }
`;

export default DesktopDropdown;
