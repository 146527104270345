import { useEffect, useState } from 'react';
import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@/queries/ai/aiKeys';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useEpisodeAILoading } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAILoadingContext';
import { useEpisodeAISettings } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAISettingsContext';
import useAISocialPostsSuggestionsQuery from '@/queries/ai/useAISocialPostsSuggestionsQuery';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface EpisodeAITwitterSuggestionPanelProps {
    setLoadingContent: (content: string) => void;
}

const EpisodeAITwitterSuggestionPanel = ({
    setLoadingContent,
}: EpisodeAITwitterSuggestionPanelProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const smmSettings = settings.get('smm') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const smmSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['facebook', 'twitter', 'instagram', 'linkedin'],
        delay: 2000,
    });
    const twitterSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['twitter'],
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('smm-twitter') || false;
    const queryClient = useQueryClient();
    const { setValue } = useFormContext<NonHostingEpisodeFormSchema>();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
    }, [isLoading]);

    useEffect(() => {
        // If the query is successful and the panel displayed, set the value of the form field
        if (twitterSuggestionsQuery.data && twitterSuggestionsQuery.isSuccess) {
            setLoadingContent(twitterSuggestionsQuery.data.suggestions.twitter || '');
            setValue('socialPosts.twitter', twitterSuggestionsQuery.data.suggestions.twitter, {
                shouldDirty: true,
            });
        }
    }, [twitterSuggestionsQuery.data, twitterSuggestionsQuery.isSuccess]);

    useEffect(() => {
        // If the query is successful and the panel displayed, set the value of the form field
        if (smmSuggestionsQuery.data && smmSuggestionsQuery.isSuccess) {
            setLoadingContent(smmSuggestionsQuery.data.suggestions.twitter || '');
            setValue('socialPosts.twitter', smmSuggestionsQuery.data.suggestions.twitter, {
                shouldDirty: true,
            });
        }
    }, [smmSuggestionsQuery.data, smmSuggestionsQuery.isSuccess]);

    if (!isOpen) return null;

    const handleClose = () => {
        // Reset the suggestions query when the panel is closed
        queryClient.resetQueries({
            queryKey: aiKeys.socialPostsDetails(episodeId, smmSettings, ['twitter']),
        });
        setIsOpen(false);
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'smm-twitter',
            custom_prompt: smmSettings.customPrompt || '',
            suggestions:
                smmSuggestionsQuery.data?.suggestions.twitter ||
                twitterSuggestionsQuery.data?.suggestions.twitter ||
                '',
        });
    };

    return (
        <EpisodeAISuggestionsPanel
            title={
                <FormattedMessage
                    defaultMessage="Votre post {socialNetwork} est prêt !"
                    values={{ socialNetwork: 'Twitter' }}
                />
            }
            type="smm-twitter"
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-smm-twitter-suggestions-panel"
            onDislike={handleDislike}
        />
    );
};

export default EpisodeAITwitterSuggestionPanel;
