import psoKeywordsRankingKeys from '@queries/psoKeywordsRanking/psoKeywordsRankingKeys';
import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { Platform } from '@/api/pso/types';
import { psoKeywordsRanking } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { z } from 'zod';

const KeywordRankingSchema = z.object({
    keyword: z.string(),
    ranking: z.number(),
    searchVolume: z.number(),
    difficultyScore: z.number(),
});
const KeywordsRankingSchema = z.array(KeywordRankingSchema);

export type KeywordRanking = z.infer<typeof KeywordRankingSchema>;
export type KeywordsRanking = z.infer<typeof KeywordsRankingSchema>;

type Key = QueryFunctionContext<ReturnType<typeof psoKeywordsRankingKeys.list>>;

const queryFn = async ({ queryKey: [{ showId, platform, store, format }] }: Key) => {
    const response = await psoKeywordsRanking(showId, platform, store, format);

    if (format === 'text/csv') {
        return response;
    }

    if (!response.data) return [];

    const transformedData = camelcaseKeys(response.data, { deep: true });
    return KeywordsRankingSchema.parse(transformedData);
};

interface PsoKeywordsRankingProps {
    showId: string;
    platform: Platform;
    store: string;
    format?: string;
}

const usePsoKeywordsRankingQuery = (
    { showId, platform, store, format }: PsoKeywordsRankingProps,
    { enabled } = { enabled: true },
) => {
    return useQuery({
        queryKey: psoKeywordsRankingKeys.list(showId, platform, store, format),
        queryFn,
        enabled,
    });
};

export default usePsoKeywordsRankingQuery;
