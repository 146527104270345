import { useInfiniteQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchMinimalEpisodes } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from '@/queries/episode/episodeKeys';
import { z } from 'zod';

export const EpisodeSchema = z.object({
    id: z.number(),
    state: z.enum(['draft', 'blocked', 'scheduled', 'active']),
    publishedAt: z.string().nullable(),
    publicId: z.string(),
    name: z.string(),
    createdAt: z.string(),
    audioUrl: z.string().nullable().optional(),
    imageUrl: z.string().nullable().optional(),
});
const EpisodesSchema = z.array(EpisodeSchema);
const EpisodePaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    totalItems: z.number().optional(),
    itemsPerPage: z.number().optional(),
});
const EpisodeResponseSchema = z.object({
    episodes: EpisodesSchema,
    pagination: EpisodePaginationSchema,
});

interface InputProps {
    showId: string;
    perPage: number;
}

type Key = QueryFunctionContext<ReturnType<typeof episodeKeys.listByShowIdInfinite>>;
export type Episode = z.infer<typeof EpisodeSchema>;

const queryFn = async ({ queryKey: [{ showId, perPage, status }], pageParam = 1 }: Key) => {
    if (!status) status = [];
    const { data, meta } = await fetchMinimalEpisodes(showId, status, pageParam, perPage);

    // TODO: Do camelcase transformation in Axios interceptor
    const { data: camelcaseData, meta: camelcaseMeta } = camelcaseKeys(
        { data, meta },
        { deep: true },
    );

    const formattedData = {
        episodes: camelcaseData,
        pagination: camelcaseMeta.pagination,
    };

    return EpisodeResponseSchema.parse(formattedData);
};

const useListenableEpisodesInfiniteQuery = ({ showId, perPage }: InputProps) => {
    const status = ['active', 'scheduled', 'public', 'unlisted'];

    return useInfiniteQuery({
        queryKey: episodeKeys.listByShowIdInfinite(showId, '', perPage, status),
        queryFn,
        enabled: !!showId,
        getNextPageParam: (lastPage) => {
            if (lastPage.pagination.currentPage === lastPage.pagination.totalPages) {
                return undefined;
            }
            return Math.min(lastPage.pagination.currentPage + 1, lastPage.pagination.totalPages);
        },
    });
};

export default useListenableEpisodesInfiniteQuery;
