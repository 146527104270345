import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchAIChaptersSuggestions } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import aiKeys from './aiKeys';
import { z } from 'zod';

import { delayQuery } from '@/shared/utils/async';

export const AIChaptersSuggestionsSchema = z.object({
    suggestions: z.array(
        z.object({
            name: z.string(),
            timecode: z.string(),
        }),
    ),
});

type Key = QueryFunctionContext<ReturnType<typeof aiKeys.chaptersDetails>>;

export type AIChaptersSuggestions = z.infer<typeof AIChaptersSuggestionsSchema>;
export type AIChaptersSuggestion = z.infer<
    typeof AIChaptersSuggestionsSchema
>['suggestions'][number];

interface DelayedQueryFnParams {
    queryContext: Key;
    delay: number;
}

const delayedQueryFn = ({
    queryContext,
    delay,
}: DelayedQueryFnParams): ReturnType<typeof queryFn> =>
    delayQuery({ callback: queryFn, delay })(queryContext);

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await fetchAIChaptersSuggestions(episodeId);
    // TODO: Extract suggestions from data ?
    return AIChaptersSuggestionsSchema.parse(camelcaseKeys(data, { deep: true }));
};

interface AIChaptersSuggestionsQueryParams {
    episodeId: string;
    delay?: number;
}

const useAIChaptersSuggestionsQuery = ({
    episodeId,
    delay = 0,
}: AIChaptersSuggestionsQueryParams) => {
    return useQuery({
        queryKey: aiKeys.chaptersDetails(episodeId),
        queryFn: (queryContext: Key) => delayedQueryFn({ queryContext, delay }),
        enabled: false,
    });
};

export default useAIChaptersSuggestionsQuery;
