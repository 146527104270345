import styled from 'styled-components';
import Results from './Results';
import { useParams } from 'react-router';
import EmptyState from '../EmptyState';
import Header from '@app/pages/PsoPage/PsoKeywordsRanking/Header';
import Form from '@app/pages/PsoPage/PsoKeywordsRanking/Form';
import type { Platform } from '@/api/pso/types';
import useHasAccessToPsoKeywordsRanking from '@/shared/hooks/useHasAccessToPsoKeywordsRanking.hook';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import useQuery from '@/shared/hooks/useQuery';

const PsoKeywordsRanking = () => {
    const { showId }: { showId: string } = useParams();
    const query = useQuery();
    const platform: Platform = query.get('platform') as Platform;
    const store: string = query.get('store') || '';

    useAmplitudeLogEvent('PSO Keywords Ranking', {
        listeningPlatform: platform,
        country: store,
    });

    const hasAccessToPsoKeywordsRanking = useHasAccessToPsoKeywordsRanking({ showId });

    if (!hasAccessToPsoKeywordsRanking) {
        return <EmptyState />;
    }

    return (
        <PsoKeywordsRankingWrapper>
            <Header searchParams={{ platform, store }}>
                <Form />
            </Header>
            <Results searchParams={{ platform, store }} />
        </PsoKeywordsRankingWrapper>
    );
};

const PsoKeywordsRankingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export default PsoKeywordsRanking;
