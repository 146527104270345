import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface RankCellProps {
    ranking: number;
}

const RankCell = ({ ranking }: RankCellProps) => {
    return (
        <RankCellWrapper>
            {ranking <= 3 && ranking > 0 && (
                <AwardIcon ranking={ranking} icon={icon({ name: 'award', style: 'solid' })} />
            )}
            {ranking > 0 ? ranking : <DashIcon icon={icon({ name: 'dash', style: 'solid' })} />}
        </RankCellWrapper>
    );
};

const RankCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const AwardIcon = styled(FontAwesomeIcon)<{ ranking: number }>`
    ${({ ranking }) => ranking === 1 && `color: var(--decorativeD);`}
    ${({ ranking }) => ranking === 2 && `color: var(--neutral300);`}
    ${({ ranking }) => ranking > 2 && `color: var(--decorativeC);`}
`;
const DashIcon = styled(FontAwesomeIcon)`
    color: var(--neutral300);
`;

export default RankCell;
