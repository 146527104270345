import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ArchivedEmptyState = () => {
    return (
        <Wrapper>
            <Icon icon={icon({ name: 'box-archive', style: 'solid' })} />
            <Text fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Aucune émission archivée" />
            </Text>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

export default ArchivedEmptyState;
