import { useState } from 'react';
import Button from '@/components/Button';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useNewsletterFormSchema, { NewsletterFormSchema } from './useNewsletterFormSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { ToggleController } from '@/components/ui/atoms/Toggle';
import NewsletterFormCallToActions from './NewsletterFormCallToActions';
import RouterLink from '@/components/Link/RouterLink';
import { useParams } from 'react-router';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ExternalLink from '@/components/Link/ExternalLink';
import InputText from '@/components/ui/atoms/InputText';
import Alert from '@/components/ui/atoms/Alert';
import NewsletterEmailsQuota from '@/components/unorganized/NewsletterEmailsQuota';
import useWebsiteSettingsQuery from '@/queries/websiteSettings/useWebsiteSettingsQuery.hook';
import UnsavedFormModal from '@/components/unorganized/UnsavedFormModal';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface NewsletterFormProps {
    isLoading: boolean;
    onSubmit: (data: NewsletterFormSchema) => void;
    values?: NewsletterFormSchema;
    websiteUrl: string;
    hasAccessToCTA: boolean;
}

const NewsletterForm = ({
    isLoading,
    onSubmit,
    values,
    websiteUrl,
    hasAccessToCTA,
}: NewsletterFormProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: websiteSettings } = useWebsiteSettingsQuery({ showId });
    const show = useShowQuery(showId);
    const subscription = useSubscriptionQuery();
    const isWebsiteEnabled = websiteSettings?.isPublished || false;

    const schema = useNewsletterFormSchema();
    const { control, handleSubmit, formState, watch } = useForm<NewsletterFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        values,
    });
    const { isValid, isDirty } = formState;
    const callToActions = watch('callToActions');
    const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);
    const subscriptionUrl = `${websiteUrl}?s=1`;

    const isGuestShow = show?.data?.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const isEnterprise = isGuestShow
        ? show?.data?.ownerPricing === PRICING.ENTERPRISE
        : subscription?.data?.pricing === PRICING.ENTERPRISE;

    const handleActivateChange = (isChecked: boolean) => {
        const eventMessage = isChecked ? 'Newsletter Activated' : 'Newsletter Deactivated';
        sendAmplitudeLogEvent(eventMessage);
    };

    const handleCopyToClipboard = async () => {
        await navigator.clipboard.writeText(subscriptionUrl);
        setHasCopiedToClipboard(true);
    };

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={isDirty} />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <SectionWrapper>
                    <SectionTitle variant="heading">
                        <FormattedMessage defaultMessage="Paramètres" />
                    </SectionTitle>
                    <Stack $gap="0.5rem">
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Abonnement à la newsletter" />
                        </Text>
                        <ToggleController
                            name="activated"
                            control={control}
                            onChange={handleActivateChange}
                        >
                            <FormattedMessage defaultMessage="Permettre à vos auditeurs de s'abonner à votre newsletter." />
                        </ToggleController>
                    </Stack>
                    <Stack $gap="0.5rem">
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Lien d’inscription à la newsletter" />
                        </Text>
                        {isWebsiteEnabled ? (
                            <Stack $gap="2rem">
                                <Stack $gap="0.5rem">
                                    <InputText isReadOnly={true} value={subscriptionUrl} />
                                    <LinkInputActions>
                                        <CopyButton
                                            variant="tertiary"
                                            size="small"
                                            startIcon={
                                                <Icon
                                                    icon={icon({ name: 'copy', style: 'solid' })}
                                                />
                                            }
                                            onPress={handleCopyToClipboard}
                                        >
                                            <AnimatePresence>
                                                {hasCopiedToClipboard ? (
                                                    <motion.span
                                                        initial={{ opacity: 1 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ delay: 1 }}
                                                        onAnimationComplete={() =>
                                                            setHasCopiedToClipboard(false)
                                                        }
                                                    >
                                                        <FormattedMessage defaultMessage="Copié" />
                                                    </motion.span>
                                                ) : (
                                                    <FormattedMessage defaultMessage="Copier le lien" />
                                                )}
                                            </AnimatePresence>
                                        </CopyButton>
                                        <WebsiteLink
                                            href={subscriptionUrl}
                                            target="_blank"
                                            variant="button-tertiary"
                                            size="small"
                                            startIcon={
                                                <Icon
                                                    icon={icon({
                                                        name: 'arrow-up-right-from-square',
                                                        style: 'solid',
                                                    })}
                                                />
                                            }
                                        >
                                            <FormattedMessage defaultMessage="Ouvrir" />
                                        </WebsiteLink>
                                    </LinkInputActions>
                                </Stack>
                                <NewsletterEmailsQuota
                                    disallowQuotaUpdate={isEnterprise}
                                    withBorder={true}
                                />
                            </Stack>
                        ) : (
                            <Alert variant="info" defaultIcon={true}>
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage
                                        defaultMessage="Pour accéder à votre lien d’abonnement veuillez <link>activer votre Site Web.</link>"
                                        values={{
                                            link: (chunks: string) => (
                                                <WebsiteRouterLink
                                                    to={`/app/show/${showId}/website/settings`}
                                                    target="_blank"
                                                >
                                                    {chunks}
                                                </WebsiteRouterLink>
                                            ),
                                        }}
                                    />
                                </Text>
                            </Alert>
                        )}
                    </Stack>
                </SectionWrapper>
                {hasAccessToCTA && (
                    <SectionWrapper>
                        <SectionTitle variant="heading">
                            <FormattedMessage defaultMessage="Apparence" />
                        </SectionTitle>
                        <Stack $gap="0.5rem">
                            <Stack>
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Boutons personnalisés" />
                                </Text>
                                <Text color="--neutral500">
                                    <FormattedMessage
                                        defaultMessage="Ces boutons seront affichés dans le pied de page de votre Newsletter et peuvent être gérés via l’onglet <link>Call-to-Action</link> du menu Configuration."
                                        values={{
                                            link: (chunks: string) => (
                                                <CallToActionLink
                                                    to={`/app/show/${showId}/communication/settings/call-to-action`}
                                                >
                                                    {chunks}
                                                </CallToActionLink>
                                            ),
                                        }}
                                    />
                                </Text>
                            </Stack>
                            <NewsletterFormCallToActions
                                control={control}
                                callToActions={callToActions}
                            />
                        </Stack>
                    </SectionWrapper>
                )}
                <SubmitButton type="submit" isDisabled={!isDirty || !isValid} isLoading={isLoading}>
                    <FormattedMessage defaultMessage="Enregistrer" />
                </SubmitButton>
            </Form>
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        row-gap: 2.5rem;
    }
`;
const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const SectionTitle = styled(Text)`
    width: 100%;
    padding-bottom: 0.25rem;
    border-bottom: solid 1px var(--neutral100);
    font-weight: var(--fw-bold);
`;
const SubmitButton = styled(Button)`
    margin-top: 1rem;
    align-self: center;
`;
const CallToActionLink = styled(RouterLink)`
    &:hover {
        text-decoration: underline;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const CopyButton = styled(Button)`
    background: transparent;
`;
const WebsiteLink = styled(ExternalLink)`
    background: transparent;
`;
const LinkInputActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    column-gap: 0.5rem;
`;
const WebsiteRouterLink = styled(RouterLink)`
    color: var(--black);

    text-decoration: underline;
`;

export default NewsletterForm;
