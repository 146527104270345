import { useState } from 'react';
import Button from '@/components/Button';
import ExternalLink from '@/components/Link/ExternalLink';
import Alert from '@/components/ui/atoms/Alert';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';
import useShowHasHostingAccess from '@/shared/hooks/useShowHasHostingAccess.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import dayjs from 'dayjs';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useArchivedShowsQuery from '@/queries/show/useArchivedShowsQuery.hook';
import useActiveShowsQuery from '@/queries/show/useActiveShowsQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import ArchiveShowModal from './components/ArchiveShowModal';
import updateShowMutation from '@/queries/show/updateShowMutation.hook';
import UnarchiveShowModal from './components/UnarchiveShowModal';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const UPDATED_DAYS_AGO = 30;

const ArchiveShow = () => {
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const showHasHostingAccess = useShowHasHostingAccess(showId);
    const updateShow = updateShowMutation();
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
    const { data: subscription } = useSubscriptionQuery();
    const { data: archivedShows } = useArchivedShowsQuery();
    const { data: activeShows } = useActiveShowsQuery();
    const { data: user } = useUserQuery();
    const hasData = !!show && !!subscription && !!archivedShows && !!activeShows && !!user;

    if (hasData) {
        const isArchived = show.archived;
        const DAYS_AGO = dayjs().diff(dayjs(show.podcastsUpdated || dayjs()), 'days');
        const showHasBeenUpdated = DAYS_AGO <= UPDATED_DAYS_AGO;
        const waitingDaysCount = UPDATED_DAYS_AGO - DAYS_AGO;
        const activeQuotaMax = (subscription.showsMax || 0) + (subscription.extraShows || 0);
        const archivedQuotaMax = subscription.archivedShowsMax;
        const currentArchivedShows = archivedShows?.shows.length ?? 0;
        const currentActiveShows = activeShows?.shows.length ?? 0;
        const isActiveQuotaReached = currentActiveShows >= activeQuotaMax;
        const isArchivedQuotaReached = currentArchivedShows >= archivedQuotaMax;
        const userHasHostingAccess = user.canHostOnAusha;

        const handleChangeShowStatus = async () => {
            const isQuotaReached = show.archived ? isActiveQuotaReached : isArchivedQuotaReached;
            if (isQuotaReached) return;

            updateShow.mutate(
                { showId: `${show.id}`, show: { archived: !isArchived } },
                {
                    onSuccess: (show) => {
                        if (show.archived) {
                            setIsArchiveModalOpen(false);
                            return;
                        }
                        setIsUnarchiveModalOpen(false);
                    },
                },
            );
        };

        return (
            <>
                <Container>
                    <Show>
                        <ShowCover src={show.imageUrl ?? IMG_PLACEHOLDER} alt={show.name} />
                        <Text fontWeight="--fw-bold">{show.name}</Text>
                    </Show>
                    <ResponsiverWrapper>
                        <Stack $gap="0.25rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Archivez votre émission si vous souhaitez mettre en pause ou arrêter définitivement la publication de nouveaux épisodes." />
                            </Text>
                            <Text color="--neutral500">
                                <FormattedMessage
                                    defaultMessage="L'archivage est idéal si vous ne souhaitez plus publier de nouveaux épisodes mais que vous souhaitez que votre podcast soit toujours disponible à l'écoute pour vos auditeurs. Vous ne pourrez plus apporter de modification mais vous pourrez toujours consulter votre liste d'épisodes, les informations de l'émission et ses statistiques (qui seront toujours mises à jour). <link>En savoir plus</link>"
                                    values={{
                                        link: (chunks: string) => (
                                            <ExternalLink
                                                href={intl.formatMessage({
                                                    defaultMessage:
                                                        'https://help.ausha.co/fr/articles/7146553-quelle-est-la-difference-entre-une-emission-active-et-une-emission-archivee#h_e7c112b4db',
                                                })}
                                                target="_blank"
                                            >
                                                {chunks}
                                            </ExternalLink>
                                        ),
                                    }}
                                />
                            </Text>
                        </Stack>
                        <Button
                            variant="secondary"
                            startIcon={
                                <TrashIcon icon={icon({ name: 'archive', style: 'solid' })} />
                            }
                            isDisabled={
                                (showHasHostingAccess && showHasBeenUpdated) ||
                                !userHasHostingAccess
                            }
                            onPress={() => {
                                if (isArchived) {
                                    setIsUnarchiveModalOpen(true);
                                    return;
                                }
                                setIsArchiveModalOpen(true);
                            }}
                        >
                            {isArchived ? (
                                <FormattedMessage defaultMessage="Désarchiver" />
                            ) : (
                                <FormattedMessage defaultMessage="Archiver l'émission" />
                            )}
                        </Button>
                    </ResponsiverWrapper>
                    {!userHasHostingAccess || !archivedQuotaMax ? (
                        <Alert
                            variant="primary"
                            icon={<GemIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
                            action={
                                <RouterLink
                                    to="/app/subscription/offers"
                                    variant="button-primary"
                                    size="small"
                                >
                                    <FormattedMessage defaultMessage="S'abonner à une offre d'hébergement" />
                                </RouterLink>
                            }
                            align="center"
                        >
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="Besoin d'archiver une émission ? Ajoutez une offre d'hébergement dès maintenant !"
                                    values={{
                                        quota: currentArchivedShows,
                                        quotaMax: archivedQuotaMax,
                                    }}
                                />
                            </Text>
                        </Alert>
                    ) : (
                        <>
                            {isArchivedQuotaReached ? (
                                <Alert
                                    variant="primary"
                                    icon={
                                        <GemIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                                    }
                                    action={
                                        <RouterLink
                                            to="/app/subscription/offers"
                                            variant="button-primary"
                                            size="small"
                                        >
                                            <FormattedMessage defaultMessage="Passer à une offre supérieure" />
                                        </RouterLink>
                                    }
                                    description={
                                        <FormattedMessage defaultMessage="Vous souhaitez archiver plus d'émissions ? Passez à une offre supérieure et archivez des émissions supplémentaires !" />
                                    }
                                >
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage
                                            defaultMessage="Vous avez atteint votre quota d'archivage avec {quota} émissions archivées sur {quotaMax}."
                                            values={{
                                                quota: currentArchivedShows,
                                                quotaMax: archivedQuotaMax,
                                            }}
                                        />
                                    </Text>
                                </Alert>
                            ) : (
                                <GhostAlert variant="info" defaultIcon={true}>
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage
                                            defaultMessage="Vous utilisez {quota} de vos {quotaMax} émissions archivées disponibles."
                                            values={{
                                                quota: currentArchivedShows,
                                                quotaMax: archivedQuotaMax,
                                            }}
                                        />
                                    </Text>
                                </GhostAlert>
                            )}
                            {showHasBeenUpdated && !isArchived && (
                                <Alert variant="warning" defaultIcon={true}>
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage
                                            defaultMessage="Cette émission a été modifiée il y a moins de 30 jours et ne peut pas encore être archivée. Vous pourrez l'archiver dans {dayCount} jours."
                                            values={{
                                                dayCount: waitingDaysCount,
                                            }}
                                        />
                                    </Text>
                                </Alert>
                            )}
                        </>
                    )}
                </Container>
                <ArchiveShowModal
                    isOpen={isArchiveModalOpen}
                    onOpenChange={setIsArchiveModalOpen}
                    onArchive={handleChangeShowStatus}
                    isLoading={updateShow.isLoading}
                />
                <UnarchiveShowModal
                    isOpen={isUnarchiveModalOpen}
                    onOpenChange={setIsUnarchiveModalOpen}
                    onActivate={handleChangeShowStatus}
                    isLoading={updateShow.isLoading}
                />
            </>
        );
    }

    return <ShortListFallback />;
};

const Container = styled.div`
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Show = styled.div`
    padding: 0.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral50);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    width: fit-content;
`;
const ShowCover = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-xs);
`;
const ResponsiverWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > :first-child {
            max-width: 38rem;
            flex-grow: 1;
        }

        & > :last-child {
            flex-shrink: 0;
        }
    }
`;
const TrashIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const GhostAlert = styled(Alert)`
    background: none;
    color: var(--info500);
    padding: 0;

    & > :first-child {
        column-gap: 0.5rem;
    }
`;
const GemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default ArchiveShow;
