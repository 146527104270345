import { useLocation } from 'react-router-dom';
import { parseQuery } from '@/api/query';

import { parseIntArray } from '@/shared/utils/array';

const useStatsSelectedEpisodeIds = (): number[] => {
    const location = useLocation();
    const query = parseQuery(location.search);
    const episodeQueryParam = query.episode;

    if (!episodeQueryParam) {
        return [];
    }

    return parseIntArray(episodeQueryParam);
};

export default useStatsSelectedEpisodeIds;
