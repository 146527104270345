import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useEmailNotificationSettingsFormSchema, {
    EditPasswordForm,
} from './useEmailNotificationSettingsFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@/components/Button';
import { ToggleController } from '@ui/atoms/Toggle';
import useUpdateEmailNotificationSettingsMutation from '@queries/emailNotificationSettings/useUpdateEmailNotificationSettingsMutation.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';

interface FormProps {
    defaultValues: any;
}

const Form = ({ defaultValues }: FormProps) => {
    const updateSettings = useUpdateEmailNotificationSettingsMutation();
    const toast = useModalToastQueue();

    const schema = useEmailNotificationSettingsFormSchema();
    const { control, handleSubmit, formState } = useForm({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues,
    });

    const onSubmit = (formData: EditPasswordForm) => {
        updateSettings.mutate(formData, {
            onSuccess: () => {
                toast.success();
            },
            onError: () => {
                toast.alert();
            },
        });
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            <ToggleController name="scheduledPodcast" control={control}>
                <FormattedMessage defaultMessage="Publication d’un épisode programmé" />
            </ToggleController>
            <ToggleController name="youtubeGenerated" control={control}>
                <FormattedMessage defaultMessage="Génération d’une vidéo YouTube" />
            </ToggleController>
            <SubmitButton
                type="submit"
                isLoading={updateSettings.isLoading}
                isDisabled={!formState.isDirty}
            >
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const SubmitButton = styled(Button)`
    margin-top: 1rem;
    width: fit-content;
`;

export default Form;
