import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const psoCheckerSuggestedKeywords = (episodeId) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/podcasts/${episodeId}/keywords`,
    });
};
