import { Button as RACButton } from 'react-aria-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css, keyframes } from 'styled-components';

const ShowButtonSkeleton = () => {
    return (
        <Wrapper>
            <CoverWrapper>
                <Cover />
            </CoverWrapper>
            <ShowInfo>
                <Title />
                <SubTitle />
            </ShowInfo>
            <Icon icon={icon({ name: 'angles-up-down', style: 'solid' })} />
        </Wrapper>
    );
};
const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Wrapper = styled(RACButton)`
    max-width: 17.5rem;
    display: flex;
    column-gap: 0.75rem;
    cursor: pointer;
    padding: 0.75rem;
    background: none;
    border: none;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
`;
const CoverWrapper = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: var(--r-xs);
        background-color: var(--neutral100);
        position: absolute;
        bottom: -0.25rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }
`;
const Cover = styled.div`
    width: 100%;
    height: 100%;
    border-radius: var(--r-s);
    flex-shrink: 0;
    z-index: 1;
    position: relative;
    background-color: var(--neutral50);
    ${shimmering};
`;
const ShowInfo = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        padding-top: 0.5rem;
    }
`;
const Title = styled.div`
    flex-grow: 1;
    height: 0.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${shimmering};
`;
const SubTitle = styled.div`
    width: 4rem;
    height: 0.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral50);
    ${shimmering};
`;

const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
    align-self: center;
`;

export default ShowButtonSkeleton;
