import { MinimalShow } from '@/queries/show/useShowsQuery.hook';
import styled from 'styled-components';
import ShowsListItem from './ShowsListItem';
import { ListBox, Selection } from 'react-aria-components';
import NewShowAction from './NewShowAction';
import { useHistory, useLocation, useParams } from 'react-router';
import useArchivedShowsQuery from '@/queries/show/useArchivedShowsQuery.hook';
import useActiveShowsQuery from '@/queries/show/useActiveShowsQuery.hook';
import { useCurrentShow } from '@/context/CurrentShowContext';
import useGuestShowsQuery from '@/queries/show/useGuestShowsQuery.hook';
import ShowListSkeleton from './ShowListSkeleton';
import ArchivedEmptyState from './ArchivedEmptyState';

type ShowListItem = (MinimalShow | { id: 'new-show' }) & { layout: 'stack' | 'grid' };

interface ShowsListProps {
    layout: 'stack' | 'grid';
    status?: 'active' | 'archived';
    onClose: () => void;
}

const ShowsList = ({ layout, status, onClose }: ShowsListProps) => {
    const { showId } = useParams<{ showId: string }>();
    const history = useHistory();
    const location = useLocation();
    const { showId: storedShowId, setShowId } = useCurrentShow();
    const currentShowId = showId || storedShowId;
    const { data: activeShows, isError: isActiveShowsError } = useActiveShowsQuery();
    const { data: guestActiveShows, isError: isGuestActiveShowsError } = useGuestShowsQuery();
    const { data: archivedShows, isError: isArchivedShowsError } = useArchivedShowsQuery();
    const isError = isActiveShowsError || isArchivedShowsError || isGuestActiveShowsError;
    const hasData = (activeShows && guestActiveShows) || archivedShows;

    if (isError) return null;

    const handleSelectShow = (showIds: Selection) => {
        if (!showIds || showIds === 'all') return;
        const id = Array.from(showIds)[0]?.toString();

        if (!id || id === currentShowId) return;

        if (id === 'new-show') {
            return history.push('/app/show/new');
        }

        setShowId(id);

        // Some pages should not be accessed while switching shows, such as episode preview pages
        const nonHostingEpisodepreviewPattern = /^\/app\/show\/[^/]+\/feed\/[^/]+$/;
        const hostingEpisodePreviewPattern = /^\/app\/show\/[^/]+\/episodes\/preview\/[^/]+$/;
        const shouldRedirectToEpisodesPage =
            nonHostingEpisodepreviewPattern.test(location.pathname) ||
            hostingEpisodePreviewPattern.test(location.pathname);

        /* If showId is present in the URL, replace showId param */
        if (showId && !shouldRedirectToEpisodesPage) {
            onClose();

            return history.push(location.pathname.replace(showId, id), { showId: id });
        }

        /**
         * If showId is not present in the URL OR if we are on the
         * episode preview page, redirect to episodes page
         */
        onClose();
        return history.push(`/app/show/${id}/episodes`);
    };

    if (hasData) {
        const activeItems = [
            {
                id: 'new-show',
                layout,
            },
            ...(activeShows?.shows.map((show) => ({ ...show, layout })) || []),
            ...(guestActiveShows?.shows.map((show) => ({ ...show, layout })) || []),
        ];
        const archivedItems = [
            {
                id: 'new-show',
                layout,
            },
            ...(archivedShows?.shows.map((show) => ({ ...show, layout })) || []),
        ];
        const items = status === 'active' ? activeItems : archivedItems;
        const filteredItems = status === 'archived' && archivedItems.length === 1 ? [] : items;

        return (
            <List
                aria-label="List of shows"
                layout={layout}
                selectedKeys={new Set([currentShowId])}
                selectionMode="single"
                selectionBehavior="toggle"
                items={filteredItems}
                onSelectionChange={handleSelectShow}
                renderEmptyState={() => (status === 'archived' ? <ArchivedEmptyState /> : null)}
                autoFocus={true}
            >
                {({ layout, ...item }: ShowListItem) =>
                    item.id === 'new-show' ? (
                        <NewShowAction layout={layout} />
                    ) : (
                        <ShowsListItem layout={layout} key={item.id} show={item} />
                    )
                }
            </List>
        );
    }

    return <ShowListSkeleton layout={layout} />;
};

const List = styled(ListBox)`
    list-style: none;
    flex-grow: 1;
    overflow-y: auto;
    min-height: 0px;
    width: 100%;

    &[data-layout='stack']:not([data-empty='true']) {
        display: flex;
        flex-direction: column;
        row-gap: 0.25rem;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        padding-inline: 0.5rem;
    }

    &[data-layout='grid']:not([data-empty='true']) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        padding: 1rem;
    }

    /* WebKit browsers (Chrome, Edge, Safari) */
    ::-webkit-scrollbar {
        width: 0.375rem;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--neutral200);
        border-radius: var(--r-full);
        background-clip: padding-box;
    }

    ::-webkit-scrollbar-track {
        background-color: var(--neutral200);
        border-radius: var(--r-full);
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: var(--neutral200) #f1f1f1;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        max-height: 22.125rem;
    }
`;

export default ShowsList;
