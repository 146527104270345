import { useQuery } from '@tanstack/react-query';
import { fetchEpisodeJobsProgress } from '@/api';
import { EPISODE_JOB_STATUS } from '@/shared/config/constants';
import episodeKeys from './episodeKeys';
import { z } from 'zod';

interface RawJob {
    status: string;
    progress_percentage: number;
    result: unknown | null;
}

interface RawJobs {
    converttomp3: RawJob;
    generatewaveform: RawJob;
    readchapters: RawJob;
    importaudiofile: RawJob;
}

const JobSchema = z
    .object({
        status: z.string(),
        percentage: z.number(),
        result: z.any().nullable(),
        isFinished: z.boolean(),
        isFailed: z.boolean(),
        isQueued: z.boolean(),
        isExecuting: z.boolean(),
    })
    .optional();

const JobsSchema = z.object({
    converttomp3: JobSchema,
    generatewaveform: JobSchema,
    readchapters: JobSchema,
    importaudiofile: JobSchema,
});
const JobsWithGlobalStatusSchema = JobsSchema.extend({
    isFinished: z.boolean(),
    isFailed: z.boolean(),
});

export type AudioFileJob = z.infer<typeof JobSchema>;
export type AudioFileJobs = z.infer<typeof JobsWithGlobalStatusSchema>;

interface EpisodeAudioFileJobsQueryParams {
    episodeId: string | number;
    enabled?: boolean;
}

const useEpisodeAudioFileJobsQuery = ({
    episodeId: rawEpisodeId,
    enabled = true,
}: EpisodeAudioFileJobsQueryParams) => {
    const episodeId = Number(rawEpisodeId);

    return useQuery({
        queryKey: episodeKeys.audioFileJobsByEpisodeId(episodeId),
        queryFn: async () => {
            const { data } = await fetchEpisodeJobsProgress(episodeId);
            return data;
        },
        select: (data: RawJobs) => {
            let transformedData = JobsSchema.parse(
                Object.entries(data).reduce(
                    (jobs, [job, progress]) => ({
                        ...jobs,
                        [job]: {
                            status: progress.status,
                            percentage: progress.progress_percentage,
                            result: progress.result,
                            isQueued: progress.status === EPISODE_JOB_STATUS.QUEUED,
                            isExecuting: progress.status === EPISODE_JOB_STATUS.EXECUTING,
                            isFinished: progress.status === EPISODE_JOB_STATUS.FINISHED,
                            isFailed: progress.status === EPISODE_JOB_STATUS.FAILED,
                        },
                    }),
                    {},
                ),
            );

            const transformedDataWithGlobalStatus = {
                ...transformedData,
                isFinished:
                    Object.keys(transformedData).length > 0 &&
                    Object.values(transformedData).every(({ isFinished }) => isFinished),
                isFailed:
                    Object.keys(transformedData).length > 0 &&
                    Object.values(transformedData).some(({ isFailed }) => isFailed),
            };

            return JobsWithGlobalStatusSchema.parse(transformedDataWithGlobalStatus);
        },
        refetchInterval: (data) => {
            if (data?.isFinished || data?.isFailed) {
                return false;
            }

            return 2000;
        },
        staleTime: Infinity,
        enabled,
    });
};

export default useEpisodeAudioFileJobsQuery;
