import { Menu as RACMenu } from 'react-aria-components';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { useHistory } from 'react-router';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import useActiveShowsQuery from '@/queries/show/useActiveShowsQuery.hook';
import useGuestShowsQuery from '@/queries/show/useGuestShowsQuery.hook';
import useArchivedShowsQuery from '@/queries/show/useArchivedShowsQuery.hook';

interface MenuProps {
    layout: 'stack' | 'grid';
    status: 'active' | 'archived';
    onLayoutChange: React.Dispatch<React.SetStateAction<'stack' | 'grid'>>;
    onStatusChange: React.Dispatch<React.SetStateAction<'active' | 'archived'>>;
}

const Menu = ({ layout, status, onLayoutChange, onStatusChange }: MenuProps) => {
    const { data: user } = useUserQuery();
    const history = useHistory();
    const { data: activeShows } = useActiveShowsQuery();
    const { data: guestActiveShows } = useGuestShowsQuery();
    const { data: archivedShows } = useArchivedShowsQuery();
    const activeShowsCount =
        (activeShows?.shows?.length ?? 0) + (guestActiveShows?.shows?.length ?? 0);
    const archivedShowsCount = archivedShows?.shows?.length ?? 0;
    const hasAccessToChannelPage = user?.options.includes(USER_OPTIONS.CHANNEL_PAGE);

    return (
        <MenuStyled>
            <MenuItem
                onAction={() => {
                    if (layout === 'stack') {
                        sendAmplitudeLogEvent('Show Grid View');
                        onLayoutChange('grid');
                        localStorage.setItem('show-selector-layout', 'grid');
                        return;
                    }
                    onLayoutChange('stack');
                    localStorage.setItem('show-selector-layout', 'stack');
                }}
            >
                {layout === 'stack' ? (
                    <>
                        <MenuItemIcon icon={icon({ name: 'grid-2', style: 'solid' })} />
                        <FormattedMessage defaultMessage="Vue grille" />
                    </>
                ) : (
                    <>
                        <MenuItemIcon icon={icon({ name: 'list-ul', style: 'solid' })} />
                        <FormattedMessage defaultMessage="Vue liste" />
                    </>
                )}
                <ShowsCount>
                    {status === 'active' ? activeShowsCount : archivedShowsCount}
                </ShowsCount>
            </MenuItem>
            <MenuItem
                onAction={() =>
                    onStatusChange((status) => (status === 'active' ? 'archived' : 'active'))
                }
            >
                <MenuItemIcon icon={icon({ name: 'box-archive', style: 'solid' })} />
                {status === 'archived' ? (
                    <FormattedMessage defaultMessage="Voir les émissions actives" />
                ) : (
                    <FormattedMessage defaultMessage="Voir les émissions archivées" />
                )}
            </MenuItem>
            {hasAccessToChannelPage && (
                <MenuItem
                    onAction={() => {
                        sendAmplitudeLogEvent('Show Channel Page');
                        history.push('/app/channel/settings');
                    }}
                >
                    <MenuItemIcon icon={icon({ name: 'gear', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Paramètres de la chaîne" />
                </MenuItem>
            )}
        </MenuStyled>
    );
};

const MenuStyled = styled(RACMenu)`
    border-top: solid 1px var(--neutral100);
    padding: 0.5rem;
    background-color: var(--white);
    flex-shrink: 0;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const ShowsCount = styled.div`
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary50);
    color: var(--primary500);
    padding-inline: 0.5rem;
    border-radius: var(--r-s);
    margin-left: auto;
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;

export default Menu;
