import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Stack from '../../ui/layout/Stack';
import Button from '../../ui/atoms/Button';
import { FormattedMessage } from 'react-intl';
import IconRocket from '../../ui/icons/IconRocket';
import peopleAndRobots from '@public/images/people_and_robots.webp';
import inLoveHearts from '@public/images/emoji/in-love-hearts.png';
import Text from '../../ui/atoms/Text';
import useQuery from '@/shared/hooks/useQuery';
import { PRICING_NAME } from '@/shared/config/pricing';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { connect } from '../../legacy/connect';
import Link from '../../ui/atoms/Link';
import useInterval from '@/shared/hooks/useInterval';
import { observer } from 'mobx-react';
import useSubscriptionStore from '@/shared/hooks/useSubscriptionStore';
import { prefetchUserQuery } from '@queries/user/useUserQuery.hook';

const Image = styled.img`
    width: 16rem;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        width: 17.5rem;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    min-height: 100vh;
`;

const GoToAushaButton = styled(Button)`
    align-self: stretch;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        align-self: revert;
    }
`;

const EmojiInLoveHeart = styled.img.attrs({
    src: inLoveHearts,
})`
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    vertical-align: middle;
    margin-left: 0.25rem;
`;

const enhance = connect(({ routerStore }) => ({
    subscriptionPageLink: routerStore.link(
        'user.edit.tab',
        { tab: 'subscription' },
        { displaySurvey: true },
    ),
}));

const SubscriptionFetcher = observer(({ onSubscriptionFetchedAndUpToDate }) => {
    const { subscription, fetchSubscription, addSubscriptionDataToGoogleTagManager } =
        useSubscriptionStore();
    const [delay, setDelay] = useState(1000);
    const [isRunning, setIsRunning] = useState(true);
    const [isSubscriptionAddedToGTM, setSubscriptionAddedToGTM] = useState(false);

    useInterval(
        async () => {
            await fetchSubscription();
        },
        isRunning ? delay : null,
    );

    useEffect(() => {
        if (subscription?.id) {
            // The subscription now has an ID and has been processed by Stripe. The retrieved subscription is therefore up-to-date after the checkout.
            // Recurrent subscription fetching can be stopped.
            setIsRunning(false);
            if (!isSubscriptionAddedToGTM) {
                addSubscriptionDataToGoogleTagManager(subscription);
            }
            // Prevent GTM event to be triggered multiple times.
            setSubscriptionAddedToGTM(true);
            onSubscriptionFetchedAndUpToDate(true);
        } else {
            // Subscription is not fetched or do not have an ID yet, Stripe webhook may not be processed for now and a new fetch is required.
            // Delay is increased to limit API calls if staying on the page.
            setDelay((delay) => delay * 2);
        }
    }, [subscription, isSubscriptionAddedToGTM]);

    return null;
});

const StripeCheckoutSuccessPage = ({ subscriptionPageLink }) => {
    const { isDesktop } = useResponsive();
    const query = useQuery();
    const offer = query.get('offer');
    const [isLoading, setLoading] = useState(true);

    const refreshUserAndSubscription = async () => {
        await prefetchUserQuery();
    };

    return (
        <Container>
            <Stack $gap="1rem" $align="center">
                <Image src={peopleAndRobots} />
                <Stack $gap="0.25rem" $align="center">
                    <Stack $align="center">
                        <Text
                            variant={isDesktop ? 'title' : 'heading'}
                            fontWeight="--fw-bold"
                            textAlign="center"
                        >
                            <FormattedMessage defaultMessage="Merci pour votre confiance" />
                            <EmojiInLoveHeart />
                        </Text>
                        <Text
                            variant={isDesktop ? 'title' : 'heading'}
                            fontWeight="--fw-bold"
                            textAlign="center"
                        >
                            <FormattedMessage defaultMessage="Vous êtes maintenant abonné à Ausha !" />
                        </Text>
                    </Stack>
                    <Text textAlign="center">
                        {offer ? (
                            <FormattedMessage
                                defaultMessage="Vous avez maintenant accès à toutes les fonctionnalités du plan <bold>{offer}</bold>."
                                values={{
                                    bold: (chunks) => (
                                        <Text as="span" fontWeight="--fw-bold">
                                            {chunks}
                                        </Text>
                                    ),
                                    offer: PRICING_NAME[offer],
                                }}
                            />
                        ) : null}
                    </Text>
                </Stack>
                <GoToAushaButton
                    forwardedAs={isLoading ? undefined : Link}
                    to={subscriptionPageLink}
                    replace
                    onClick={refreshUserAndSubscription}
                    startIcon={<IconRocket />}
                    isLoading={isLoading}
                >
                    <FormattedMessage defaultMessage="Accéder à Ausha" />
                </GoToAushaButton>
            </Stack>
            <SubscriptionFetcher
                onSubscriptionFetchedAndUpToDate={(isUpToDate) => {
                    setLoading(!isUpToDate);
                }}
            />
        </Container>
    );
};

export default enhance(StripeCheckoutSuccessPage);
