import MainLayoutSkeleton from '@/components/app/layout/Main/Skeleton';
import { useCurrentShow } from '@/context/CurrentShowContext';
import useShowsQuery from '@/queries/show/useShowsQuery.hook';
import { Redirect } from 'react-router';

/**
 * AppMiddleware component is responsible for handling the redirection logic
 * while accessing /app.
 * It uses the `useShowsQuery` hook to fetch the list of shows and the `useCurrentShow`
 * context to get the current show. Depending on the state of these data, it redirects
 * the user to different routes.
 */
const AppMiddleware = () => {
    const { data: shows, isError } = useShowsQuery();
    const currentShow = useCurrentShow();

    /* If there is an error fetching the shows, it redirects to the user profile page. */
    if (isError) {
        return <Redirect to="/app/user/profile" />;
    }

    /* If there are no shows, it redirects to the new show creation page. */
    if (shows && shows.shows.length < 1) {
        return <Redirect to="/app/show/new" />;
    }

    /**
     * If there is a current show selected in CurrentShow context (mostly from localStorage),
     * it redirects to the episodes page of that show.
     */
    if (Number(currentShow?.showId)) {
        return <Redirect to={`/app/show/${currentShow?.showId}/episodes`} />;
    }

    /* If there are shows available, it redirects to the episodes page of the first show */
    if (shows && shows.shows.length > 0) {
        const firstShow = shows.shows[0];

        return <Redirect to={`/app/show/${firstShow.id}/episodes`} />;
    }

    /* If none of the above conditions are met, it renders loading state. */
    return <MainLayoutSkeleton />;
};

export default AppMiddleware;
