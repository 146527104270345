import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logout } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { CurrentShow } from '@/context/CurrentShowContext';
import { useAuthContext } from '@/context/AuthContext';

export interface MutationFnProps {}

const mutationFn = async () => {
    const { data } = await logout();
    return camelcaseKeys(data, { deep: true });
};

const useLogoutMutation = () => {
    const { logout: localLogout } = useAuthContext();
    const currentShow = useContext(CurrentShow);
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_) => {
            queryClient.removeQueries();
        },
        onSettled: () => {
            // Remove this show from the current show context
            if (currentShow) {
                currentShow.setShowId('');
            }
            localLogout();
        },
    });
};

export default useLogoutMutation;
