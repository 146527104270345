import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const updatePlayerSettings = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/widget/settings`, body });
};
export const fetchPlayerSettings = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/widget/settings` });
};
