import { FormattedMessage, useIntl } from 'react-intl';
import useSubscriptionStore from '@/shared/hooks/useSubscriptionStore';
import { observer } from 'mobx-react';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useResignSubscriptionFormSchema from './useResignSubscriptionFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import Alert from '@ui/atoms/Alert/Alert';
import IconInfo from '@ui/icons/IconInfo';
import Text from '@ui/atoms/Text';
import useResignSubscription from './useResignSubscription.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import { useQueryClient } from '@tanstack/react-query';
import subscriptionKeys from '@/queries/subscription/subscriptionKeys';
import userKeys from '@/queries/user/userKeys';
import quotaKeys from '@/queries/quota/quotaKeys';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

const ResignSubscriptionForm = ({ onAfterSubmit }) => {
    const intl = useIntl();
    const { data: user } = useUserQuery();
    const { subscription } = useSubscriptionStore();
    const resignSubscription = useResignSubscription(subscription?.id);
    const isLoading = resignSubscription.isLoading;
    const schema = useResignSubscriptionFormSchema(user);
    const queryClient = useQueryClient();
    const { control, handleSubmit, formState, clearErrors, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            password: '',
        },
        resolver: yupResolver(schema),
    });
    const toast = useModalToastQueue();

    const onSubmit = (formData) => {
        resignSubscription.mutate(formData, {
            onSuccess: ({ data }) => {
                subscription.updateData(data);
                onAfterSubmit();
                toast.success(
                    <FormattedMessage defaultMessage="Votre abonnement a bien été résilié 👌" />,
                );
            },
            onError: (error) => {
                if (error.response.status === 422) {
                    setError('password', {
                        type: '422',
                        message: intl.formatMessage({
                            defaultMessage: "Votre mot de passe n'est pas valide",
                        }),
                    });
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(subscriptionKeys.all());
                queryClient.invalidateQueries(userKeys.all());
                queryClient.invalidateQueries(quotaKeys.all());
            },
        });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Alert variant="alert" icon={<IconInfo />}>
                <Text fontWeight="--fw-semibold" color="--alert500">
                    <FormattedMessage defaultMessage="La résiliation de votre abonnement prendra effet à la date d'échéance. Cela entrainera la suppression de l'ensemble de vos données." />
                </Text>
            </Alert>
            {!user.external && (
                <InputTextController
                    control={control}
                    name="password"
                    type="password"
                    label={<FormattedMessage defaultMessage="Mot de passe" />}
                    placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                    errorMessage={formState.errors.password?.message}
                    onBlur={() => clearErrors('password')}
                    isRequired
                />
            )}
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!formState.isValid || isLoading}
                >
                    <FormattedMessage defaultMessage="Résilier mon abonnement" />
                </Button>
                <Button variant="secondary" onPress={onAfterSubmit}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

export default observer(ResignSubscriptionForm);
