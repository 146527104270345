import { FormattedMessage } from 'react-intl';

export function renderDates(calendar, params) {
    const months = [
        <FormattedMessage key="jan" defaultMessage="Janvier" />,
        <FormattedMessage key="feb" defaultMessage="Février" />,
        <FormattedMessage key="mar" defaultMessage="Mars" />,
        <FormattedMessage key="apr" defaultMessage="Avril" />,
        <FormattedMessage key="may" defaultMessage="Mai" />,
        <FormattedMessage key="jun" defaultMessage="Juin" />,
        <FormattedMessage key="jul" defaultMessage="Juillet" />,
        <FormattedMessage key="aug" defaultMessage="Août" />,
        <FormattedMessage key="sep" defaultMessage="Septembre" />,
        <FormattedMessage key="oct" defaultMessage="Octobre" />,
        <FormattedMessage key="nov" defaultMessage="Novembre" />,
        <FormattedMessage key="dec" defaultMessage="Décembre" />,
    ];
    const weeks = [
        <FormattedMessage key="mon" defaultMessage="Lundi" />,
        <FormattedMessage key="tue" defaultMessage="Mardi" />,
        <FormattedMessage key="wed" defaultMessage="Mercredi" />,
        <FormattedMessage key="thu" defaultMessage="Jeudi" />,
        <FormattedMessage key="fri" defaultMessage="Vendredi" />,
        <FormattedMessage key="sat" defaultMessage="Samedi" />,
        <FormattedMessage key="sun" defaultMessage="Dimanche" />,
    ];
    const times = {
        hour: <FormattedMessage defaultMessage="Heure" />,
        minute: <FormattedMessage defaultMessage="Minute" />,
    };
    const range = {
        days: <FormattedMessage defaultMessage="Jour" />,
        weeks: <FormattedMessage defaultMessage="Semaine" />,
        months: <FormattedMessage defaultMessage="Mois" />,
        years: <FormattedMessage defaultMessage="Année" />,
    };
    const calendarChoice = {
        months: months[params],
        weeks: weeks[params],
        range: range[params],
        times: times[params],
    };
    return calendarChoice[calendar];
}
