import PropTypes from 'prop-types';
import styled from 'styled-components';

interface MainLayoutProps {
    header: React.ReactNode;
    children: React.ReactNode;
    footer: React.ReactNode;
}

const MainLayout = ({ header, children, footer }: MainLayoutProps) => {
    return (
        <MainLayoutWrapper>
            <Header>{header}</Header>
            <Content>{children}</Content>
            <Footer>{footer}</Footer>
        </MainLayoutWrapper>
    );
};

MainLayout.propTypes = {
    header: PropTypes.node,
    children: PropTypes.node,
    footer: PropTypes.node,
};

MainLayout.defaultProps = {
    header: null,
    children: null,
    footer: null,
};

const MainLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Header = styled.header`
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: var(--s-medium);
`;

const Content = styled.div`
    z-index: 1;
    flex-grow: 1;
    position: relative;
`;

const Footer = styled.footer``;

export default MainLayout;
