import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import TRUSTPILOT from '@/shared/config/trustpilotReviews';
import CAPTERRA from '@/shared/config/capterraReviews';
import Img from '@ui/atoms/Img';
import DeuxHeuresDePerduesCover from '@public/images/podcasts/fr/2-heures-de-perdues.jpg';
import EntreNosLevresCover from '@public/images/podcasts/fr/entre-nos-levres.jpg';
import ConseilSportCover from '@public/images/podcasts/fr/conseil-sport.jpg';
import CommentTuFaisCover from '@public/images/podcasts/fr/comment-tu-fais.jpg';
import PodcastDuMarketingCover from '@public/images/podcasts/fr/le-podcast-du-marketing.jpg';
import MarketingSquareCover from '@public/images/podcasts/fr/marketing-square.jpg';
import InpowerCover from '@public/images/podcasts/fr/inpower.jpg';
import PommeDApiCover from '@public/images/podcasts/fr/pomme-d-api-la-grande-histoire.jpg';
import JpeuxPasJaiBusinessCover from '@public/images/podcasts/fr/j-peux-pas-j-ai-business.jpg';
import TipsCover from '@public/images/podcasts/fr/tips-ausha.jpg';
import SurLeFilCover from '@public/images/podcasts/fr/sur-le-fil.jpg';
import LeBureauDesMysteresCover from '@public/images/podcasts/fr/le-bureau-des-mysteres.jpg';
import Culture2000Cover from '@public/images/podcasts/fr/culture2000.jpg';
import BourlinguezCover from '@public/images/podcasts/fr/bourlinguez.jpg';
import DiorUntoldCover from '@public/images/podcasts/en/dior-untold.jpg';
import MimCutsToTheChaseCover from '@public/images/podcasts/en/mim-cuts-to-the-chase.jpg';
import FeedTheQueueCover from '@public/images/podcasts/en/feed-the-queue.jpg';
import TrailerParkCover from '@public/images/podcasts/en/trailer-park.jpg';
import ThePodBroadsCover from '@public/images/podcasts/en/the-pod-broads.jpg';
import BankingOnDigitalGrowthCover from '@public/images/podcasts/en/banking-on-digital-growth.jpg';
import SoulSistasSleepMeditationsCover from '@public/images/podcasts/en/soul-sistas-sleep-meditations.jpg';
import PepperAndMeCover from '@public/images/podcasts/en/pepper-and-me.jpg';
import MatriarchMovementCover from '@public/images/podcasts/en/matriarch-movement.jpg';
import InvestorsCover from '@public/images/podcasts/en/2050-investors.jpg';
import ToolboxCover from '@public/images/podcasts/en/tool-box.jpg';
import HublotCover from '@public/images/podcasts/en/hublot.jpg';
import BridgingTheGapCover from '@public/images/podcasts/en/bridging the gap.jpg';
import VermontSmallTownCover from '@public/images/podcasts/en/vermont-small-town.jpg';
import { useResponsive } from '@/shared/hooks/useResponsive';
import AushaLogo from '@ui/atoms/AushaLogo';
import { useLocale } from 'react-aria';
import styled from 'styled-components';

const Illustration = () => {
    const { locale } = useLocale();
    const defaultLocale = locale === 'fr' ? 'fr' : 'en';
    const { isDesktopLarge } = useResponsive();

    const thirdPartyReviews = {
        fr: TRUSTPILOT,
        en: CAPTERRA,
    };

    const ListItem = ({ message }: { message: React.ReactNode }) => (
        <Cluster $gap="0.5rem" $align="baseline" $wrap="nowrap">
            <FontAwesomeIcon
                icon={icon({
                    name: 'check',
                    style: 'solid',
                })}
                color="white"
                size="xs"
            />
            <Text variant="bodyL" fontWeight="--fw-semibold" color="--white">
                {message}
            </Text>
        </Cluster>
    );

    const ReviewStars = (
        <Stars $gap="0.75rem">
            <>
                {Array(Math.floor(thirdPartyReviews[defaultLocale].NOTE.score))
                    .fill(0)
                    .map((index) => (
                        <StarIcon
                            key={index}
                            icon={icon({
                                name: 'star',
                                style: 'solid',
                            })}
                        />
                    ))}
            </>
            <StarIcon
                icon={icon({
                    name: 'star-half',
                    style: 'duotone',
                })}
            />
        </Stars>
    );

    const FrenchPodcastsCovers = (
        <>
            <OffScreenLeftPodcastsCover>
                <PodcastCover src={SurLeFilCover} />
                <PodcastCover src={LeBureauDesMysteresCover} />
            </OffScreenLeftPodcastsCover>
            <OuterLeftPodcastsCover>
                <PodcastCover src={InpowerCover} />
                <PodcastCover src={PommeDApiCover} />
            </OuterLeftPodcastsCover>
            <PodcastsCoversColumn>
                <PodcastCover src={DeuxHeuresDePerduesCover} />
                <PodcastCover src={CommentTuFaisCover} />
            </PodcastsCoversColumn>
            <MiddlePodcastsCovers>
                <PodcastCover src={EntreNosLevresCover} />
                <PodcastCover src={PodcastDuMarketingCover} />
            </MiddlePodcastsCovers>
            <PodcastsCoversColumn>
                <PodcastCover src={ConseilSportCover} />
                <PodcastCover src={MarketingSquareCover} />
            </PodcastsCoversColumn>
            <OuterRightPodcastsCover>
                <PodcastCover src={JpeuxPasJaiBusinessCover} />
                <PodcastCover src={TipsCover} />
            </OuterRightPodcastsCover>
            <OffScreenRightPodcastsCover>
                <PodcastCover src={Culture2000Cover} />
                <PodcastCover src={BourlinguezCover} />
            </OffScreenRightPodcastsCover>
        </>
    );

    const EnglishPodcastsCovers = (
        <>
            <OffScreenLeftPodcastsCover>
                <PodcastCover src={ToolboxCover} />
                <PodcastCover src={HublotCover} />
            </OffScreenLeftPodcastsCover>
            <OuterLeftPodcastsCover>
                <PodcastCover src={SoulSistasSleepMeditationsCover} />
                <PodcastCover src={PepperAndMeCover} />
            </OuterLeftPodcastsCover>
            <PodcastsCoversColumn>
                <PodcastCover src={DiorUntoldCover} />
                <PodcastCover src={TrailerParkCover} />
            </PodcastsCoversColumn>
            <MiddlePodcastsCovers>
                <PodcastCover src={MimCutsToTheChaseCover} />
                <PodcastCover src={ThePodBroadsCover} />
            </MiddlePodcastsCovers>
            <PodcastsCoversColumn>
                <PodcastCover src={FeedTheQueueCover} />
                <PodcastCover src={BankingOnDigitalGrowthCover} />
            </PodcastsCoversColumn>
            <OuterRightPodcastsCover>
                <PodcastCover src={MatriarchMovementCover} />
                <PodcastCover src={InvestorsCover} />
            </OuterRightPodcastsCover>
            <OffScreenRightPodcastsCover>
                <PodcastCover src={BridgingTheGapCover} />
                <PodcastCover src={VermontSmallTownCover} />
            </OffScreenRightPodcastsCover>
        </>
    );

    return (
        <Container>
            <ListAndReviews>
                <Checklist $align="baseline">
                    {!isDesktopLarge && <Logo isComplete />}
                    <Text variant="headingXL" fontWeight="--fw-bold" color="--white">
                        <FormattedMessage defaultMessage="Lancez-vous gratuitement" />
                    </Text>
                    <Stack $gap="1rem">
                        <ListItem
                            message={
                                <FormattedMessage defaultMessage="Publiez votre 1er épisode sur Spotify, Apple Podcasts et plus." />
                            }
                        />
                        <ListItem
                            message={
                                <FormattedMessage defaultMessage="Démarrez ou migrez votre podcast en bénéficiant de tous nos outils essentiels." />
                            }
                        />
                        <ListItem
                            message={
                                <FormattedMessage defaultMessage="Pas de CB requise. Sans engagement." />
                            }
                        />
                    </Stack>
                </Checklist>
                <Reviews $align="center" $justify="space-between">
                    <Cluster $gap="1rem">
                        <ThirdPartyReviewsLogo src={thirdPartyReviews[defaultLocale].LOGO} />
                        <Stack $gap={isDesktopLarge && '0.25rem'}>
                            <Cluster $gap="1rem" $align="baseline">
                                <Cluster $gap="0.5rem" $align="baseline">
                                    <Text
                                        variant={isDesktopLarge ? 'headingL' : 'headingM'}
                                        fontWeight="--fw-bold"
                                        color="--white"
                                    >
                                        {thirdPartyReviews[defaultLocale].NOTE.score}
                                    </Text>
                                    <Text
                                        variant={isDesktopLarge ? 'headingS' : 'bodyL'}
                                        fontWeight="--fw-semibold"
                                        color="--white"
                                    >
                                        / {thirdPartyReviews[defaultLocale].NOTE.gradeOf}
                                    </Text>
                                </Cluster>
                                {!isDesktopLarge && ReviewStars}
                            </Cluster>
                            <Text color="--white">
                                <FormattedMessage defaultMessage="sur 155 avis clients Trustpilot" />
                            </Text>
                        </Stack>
                    </Cluster>
                    {isDesktopLarge && ReviewStars}
                </Reviews>
            </ListAndReviews>
            {isDesktopLarge && (
                <PodcastsCoversPanel $gap="2rem" $align="center">
                    <PodcasterTrust $align="center">
                        <Text variant="headingS" fontWeight="--fw-semibold" color="--white">
                            <FormattedMessage defaultMessage="+11 000 podcasts nous font déjà confiance" />
                        </Text>
                        <Text variant="headingS" color="--white" numberOfLines={2}>
                            <FormattedMessage defaultMessage="Depuis 2018, nous aidons les plus grands podcasteurs et entreprises à booster leur podcast." />
                        </Text>
                    </PodcasterTrust>
                    <Cluster $gap="2rem" $wrap="nowrap">
                        {locale === 'fr' ? FrenchPodcastsCovers : EnglishPodcastsCovers}
                    </Cluster>
                </PodcastsCoversPanel>
            )}
        </Container>
    );
};

const ThirdPartyReviewsLogo = styled.img`
    width: 3rem;
    height: 3rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        width: 4rem;
        height: 4rem;
    }
`;
const Logo = styled(AushaLogo)`
    fill: var(--white);
    height: 1.5rem;
`;

const Container = styled(Stack)`
    width: inherit;
    height: 100%;
`;

const ListAndReviews = styled(Stack)`
    gap: 1.5rem;
    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        gap: 4rem;
    }
`;

const Checklist = styled(Stack)`
    gap: 1.5rem;
    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        gap: 2rem;
    }
`;

const Reviews = styled(Cluster)`
    background: var(--white300);
    border-radius: var(--r-l);
    padding: 1.5rem;
`;

const Stars = styled(Cluster)`
    color: var(--white);
`;

const StarIcon = styled(FontAwesomeIcon)`
    font-size: 1rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        font-size: 1.5rem;
    }
`;

const PodcastsCoversPanel = styled(Stack)`
    position: relative;
    transform: translateY(4rem);
`;

const PodcasterTrust = styled(Stack)`
    max-width: 30rem;
    text-align: center;
`;

const PodcastsCoversColumn = styled(Stack)`
    gap: 2rem;
`;

const OffScreenLeftPodcastsCover = styled(PodcastsCoversColumn)`
    position: absolute;
    top: 0;
    transform: translateX(-24rem) translateZ(0);
`;

const OuterLeftPodcastsCover = styled(PodcastsCoversColumn)`
    position: absolute;
    top: 4rem;
    transform: translateX(-12rem) translateZ(0);
`;

const MiddlePodcastsCovers = styled(PodcastsCoversColumn)`
    margin-top: 2rem;
`;

const OuterRightPodcastsCover = styled(PodcastsCoversColumn)`
    position: absolute;
    top: 4rem;
    transform: translateX(36rem) translateZ(0);
`;

const OffScreenRightPodcastsCover = styled(PodcastsCoversColumn)`
    position: absolute;
    top: 0;
    transform: translateX(48rem) translateZ(0);
`;

const PodcastCover = styled(Img)`
    max-width: 10rem;
    border-radius: var(--r-l);
`;

export default Illustration;
