import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage, useIntl } from 'react-intl';
import EpisodeEditPublicationDateModal from '@/components/unorganized/EpisodeEditPublicationDateModal';
import useInternalRouteMapping from '@/shared/hooks/useInternalRouteMapping.hook';
import { useFormContext } from 'react-hook-form';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import getScheduleSchema from '@/components/unorganized/EpisodeEditForm/schemas/scheduleSchema';
import { useOverlayTriggerState } from 'react-stately';
import { useQueryClient } from '@tanstack/react-query';
import useSaveEpisodeFormMutation from '@/components/unorganized/EpisodeEditForm/helpers/useSaveEpisodeFormMutation';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import EpisodeEditFormActionsTooltipMessage from '@/components/unorganized/EpisodeEditForm/actions/EpisodeEditFormActionsTooltipMessage';
import { EPISODE_STATUS, USER_ROLES } from '@/shared/config/constants';
import userKeys from '@queries/user/userKeys';
import { useEpisodeEditAudioFileMutation } from '@/context/EpisodeEditContext';
import { getChaptersIdsRequiringImageDeletion } from '@/components/unorganized/EpisodeEditForm/helpers/getChaptersIdsRequiringImageDeletion';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import Button from '@/components/Button';
import { getDirtyValues } from '@/shared/utils/reactHookForm';

const ScheduleButton = () => {
    const intl = useIntl();
    const { push } = useInternalRouteMapping();
    const queryClient = useQueryClient();
    const {
        watch,
        reset,
        formState: { dirtyFields },
    } = useFormContext();
    const saveEpisodeFormMutation = useSaveEpisodeFormMutation();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();

    const modalState = useOverlayTriggerState({});

    const episodeBeingEdited = useEpisodeBeingEdited();
    const schema = getScheduleSchema(intl);
    const formData = watch();
    const isSchemaValid = schema.isValidSync(formData);
    const hasAudioFile =
        episodeBeingEdited.files.length > 0 && !episodeEditAudioFileMutation.isLoading;
    const isFreemiumQuotaReached =
        subscription.isFreemium &&
        episodeBeingEdited.show?.userRole === USER_ROLES.OWNER &&
        user.trialEpisodes >= user.maximumTrialEpisodes;
    const isValid = isSchemaValid && hasAudioFile && !isFreemiumQuotaReached;

    const onPress = () => {
        modalState.open();
    };

    const handleScheduleEpisode = async ({ publishedAt }) => {
        const dirtyValues = getDirtyValues(dirtyFields, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );

        const { content, options, privacy } = dirtyValues;
        const episodeData = {
            ...content,
            ...options,
            ...privacy,
            publishedAt,
            state: EPISODE_STATUS.SCHEDULED,
        };

        try {
            await saveEpisodeFormMutation.mutateAsync(
                {
                    episodeId: episodeBeingEdited.id,
                    episode: episodeData,
                    chapters: dirtyFields.chapters ? formData.chapters : null,
                    chaptersIdsRequiringImageDeletion,
                },
                {
                    onSuccess: () => {
                        reset(formData);
                        if (subscription.isFreemium) {
                            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
                        }
                        push('experimental.episodes.recap');
                    },
                },
            );
        } catch (error) {
            // Handle error here ?
        }
    };

    return (
        <>
            <TooltipTrigger delay={0} closeDelay={0} isDisabled={isValid}>
                <TooltipTriggerWrapper>
                    <Button
                        variant="secondary"
                        onPress={onPress}
                        isDisabled={!isValid}
                        startIcon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'calendar-clock', style: 'regular' })}
                                color="inherit"
                            />
                        }
                    >
                        <FormattedMessage defaultMessage="Planifier" />
                    </Button>
                </TooltipTriggerWrapper>
                <Tooltip placement="bottom">
                    <EpisodeEditFormActionsTooltipMessage
                        isFreemiumQuotaReached={isFreemiumQuotaReached}
                        hasAudioFile={hasAudioFile}
                        isSchemaValid={isSchemaValid}
                    />
                </Tooltip>
            </TooltipTrigger>
            {modalState.isOpen && (
                <EpisodeEditPublicationDateModal
                    isOpen={modalState.isOpen}
                    onClose={modalState.close}
                    isOnline={false}
                    handleEditPublicationDate={handleScheduleEpisode}
                    isLoading={saveEpisodeFormMutation.isLoading}
                />
            )}
        </>
    );
};

export default ScheduleButton;
