import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@/queries/ai/aiKeys';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useAIChaptersSuggestionsQuery, {
    AIChaptersSuggestion,
} from '@/queries/ai/useAIChaptersSuggestionsQuery.hook';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import styled from 'styled-components';
import { useAccordion } from '@/components/ui/atoms/Accordion/useAccordion.hook';
import { useParams } from 'react-router';
import { useEpisodeAILoading } from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import { durationToSeconds } from '@/shared/utils/duration';

dayjs.extend(duration);

const EpisodeAIChaptersSuggestionsPanel = () => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const chaptersSuggestionsQuery = useAIChaptersSuggestionsQuery({
        episodeId,
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('chapters') || false;
    const queryClient = useQueryClient();
    const { open, index } = useAccordionItem();
    const { disabledIndexes } = useAccordion();
    const { setValue } = useFormContext();

    /*
     * If the query is successful, set the chapters on the form and open the accordion panel
     * disabledIndexes is used here because of concurrency issues with the accordion
     */
    useEffect(() => {
        if (
            chaptersSuggestionsQuery.data &&
            !chaptersSuggestionsQuery.isRefetching &&
            !disabledIndexes.includes(index as number)
        ) {
            const formattedChapters = handleFormatChapters(
                chaptersSuggestionsQuery.data.suggestions,
            );
            setValue('chapters', formattedChapters, {
                shouldDirty: true,
            });
            open?.();
        }
    }, [chaptersSuggestionsQuery.data, chaptersSuggestionsQuery.isRefetching, disabledIndexes]);

    const handleFormatChapters = (chapters: AIChaptersSuggestion[]) => {
        const sortedChapters = chapters.sort((chapter1, chapter2) => {
            const time1 = dayjs.duration(chapter1.timecode).asMilliseconds();
            const time2 = dayjs.duration(chapter2.timecode).asMilliseconds();
            return time1 - time2;
        });

        return sortedChapters.map((chapter) => ({
            id: `local-chapter-${crypto.randomUUID()}`,
            title: chapter.name,
            startTime: durationToSeconds(chapter.timecode),
            imageUrl: null,
            url: null,
        }));
    };

    // If there is no data, suggestions panel should not be displayed
    if (!chaptersSuggestionsQuery.data) return null;

    const handleClose = () => {
        // Reset the suggestions query when the panel is closed
        queryClient.resetQueries({ queryKey: aiKeys.chapters() });
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'chapters',
            suggestions: chaptersSuggestionsQuery.data?.suggestions,
        });
    };

    return (
        <Panel
            title={<FormattedMessage defaultMessage="Vos chapitres sont prêts !" />}
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-chapters-suggestions-panel"
            disablePreferences={true}
            type="chapters"
            onDislike={handleDislike}
        />
    );
};

const Panel = styled(EpisodeAISuggestionsPanel)`
    margin-inline: 0;
    width: 100%;
`;

export default EpisodeAIChaptersSuggestionsPanel;
