import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import Text from '@ui/atoms/Text';
import Alert from '@ui/atoms/Alert';
import styled from 'styled-components';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import Button from '@/components/Button';
import Stack from '@/components/ui/layout/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@/components/ui/layout/Cluster';
import { CurrentShow } from '@/context/CurrentShowContext';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import ConfirmationModal from './ConfirmationModal';
import PasswordModal from './PasswordModal';

const DeleteAccount = () => {
    useAmplitudeLogEvent('Show Delete Account');
    const currentShow = useContext(CurrentShow);
    const { data: show } = useShowQuery(currentShow?.showId ?? '');
    const { data: subscription } = useSubscriptionQuery();
    const subscriptionIsActive = !!show?.ownerPricing || subscription?.activated;

    // User have an active subscription and should cancel it to be able to delete his account
    const subscriptionCancellationRequired = subscriptionIsActive && !subscription?.endsAt;

    const [isPassordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const startUserDeletion = () => {
        if (subscriptionCancellationRequired) {
            return;
        }

        setIsConfirmationModalOpen(true);
    };

    const handleConfirmation = () => {
        setIsConfirmationModalOpen(false);
        setIsPasswordModalOpen(true);
    };

    return (
        <>
            <Wrapper>
                <Header>
                    <Cluster $gap="0.5rem" $align="center">
                        <TitleIcon icon={icon({ name: 'trash', style: 'solid' })} />
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Supprimer votre compte" />
                        </Text>
                    </Cluster>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Attention, la suppression de votre compte est une action définitive ! ⚠️" />
                    </Text>
                </Header>
                <DeleteAccountWrapper>
                    <ResponsiveWrapper>
                        <Stack $gap="0.25rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Supprimer votre compte" />
                            </Text>
                            <Text color="--neutral500">
                                <FormattedMessage defaultMessage="Cette action est irréversible, et toutes vos émissions, épisodes et données associées seront perdues." />
                            </Text>
                        </Stack>
                        <Button
                            variant="danger"
                            isDisabled={subscriptionCancellationRequired}
                            onPress={startUserDeletion}
                        >
                            <FormattedMessage defaultMessage="Supprimer mon compte" />
                        </Button>
                    </ResponsiveWrapper>
                    {subscriptionCancellationRequired && (
                        <Alert variant="warning" defaultIcon={true}>
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Pour supprimer votre compte, vous devez d’abord résilier votre abonnement." />
                            </Text>
                        </Alert>
                    )}
                </DeleteAccountWrapper>
            </Wrapper>
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                onOpenChange={setIsConfirmationModalOpen}
                onAccept={handleConfirmation}
            />
            <PasswordModal isOpen={isPassordModalOpen} onOpenChange={setIsPasswordModalOpen} />
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Container = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
`;
const Header = styled(Container)`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
`;
const DeleteAccountWrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;

        & > :last-child {
            flex-shrink: 0;
        }
    }
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;

export default DeleteAccount;
