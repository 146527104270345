import { useEffect, useRef, useState } from 'react';
import { Item, useTabListState } from 'react-stately';
import { useTab, useTabList, useTabPanel } from 'react-aria';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import { DeprecatedText } from '../atoms/DeprecatedText';
import Stack from '../layout/Stack';
import Cluster from '../layout/Cluster';
import Img from '../atoms/Img';
import Flex from '../layout/Flex';
import carolineMignauxPicture from '@public/images/reviews/caroline-mignaux.png';
import lauryThillemanPicture from '@public/images/reviews/laury-thilleman.png';
import thomasBurbidgePicture from '@public/images/reviews/thomas-burbidge.png';
import charlesBrumauldPicture from '@public/images/reviews/charles-brumauld.png';
import ladyTheatricksPicture from '@public/images/reviews/lady-theatricks.png';
import rickHierroPicture from '@public/images/reviews/rick-hierro.png';
import arielleNissenblattPicture from '@public/images/reviews/arielle-nissenblatt.png';
import alexandraMPicture from '@public/images/reviews/alexandra-m.png';
import useInterval from '../../../shared/hooks/useInterval';
import { observer } from 'mobx-react';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const SWIPE_CONFIDENCE_THRESHOLD = 10000;
const AUTOMATIC_ROTATION_DEFAULT_DELAY = 5000;

const CUSTOMER_REVIEWS = {
    fr: [
        {
            key: 'marketingSquare',
            podcastName: 'Marketing Square',
            customerPicture: carolineMignauxPicture,
            customerName: 'Caroline Mignaux',
            content:
                '« C’est facile à utiliser, c’est ludique, ça donne envie ! Outil de Growth Podcast numéro 1 dans mon coeur. »',
        },
        {
            key: 'commentTuFais',
            podcastName: 'Comment tu fais ?',
            customerPicture: lauryThillemanPicture,
            customerName: 'Laury Thilleman',
            content:
                '« Équipe au top, jeune, réactive, dynamique et toujours à l’écoute de nos besoins. J’étais novice avant de lancer mon podcast alors merci pour cette plateforme aussi intuitive et facile d’accès. Coeur sur vous, et longue vie à Ausha ! »',
        },
        {
            key: 'youngWildFreelance',
            podcastName: 'Young, Wild & Freelance',
            customerPicture: thomasBurbidgePicture,
            customerName: 'Thomas Burbidge',
            content:
                "« J'adore toutes les fonctionnalités de communication et surtout le player ! C’est plus avantageux pour moi que les écoutes aient lieu sur mon site pour convertir vers mes autres canaux et mes produits ensuite. »",
        },
        {
            key: 'dansLaPoire',
            podcastName: 'Dans La Poire',
            customerPicture: charlesBrumauldPicture,
            customerName: 'Charles Brumauld',
            content:
                '« Moi qui ne suis pas ultra technophile, j’ai pu mettre en ligne mon podcast tout seul, télécharger un extrait audio et vidéo pour en faire la promotion sur les réseaux sociaux. »',
        },
    ],
    en: [
        {
            key: 'spookeryPodcast',
            podcastName: 'Spookery Podcast',
            customerPicture: ladyTheatricksPicture,
            customerName: 'Lady Theatricks',
            content:
                '"The Transition was seamless. Ausha is super easy to use, very intuitive, and I’m so glad I made the switch. I absolutely love the smart link, having all my sources and settings in one place is incredible. I also adore the social media manager. Just being able to see how many people I reached, how many people are engaging, it’s a game changer."',
        },
        {
            key: 'rickHShow',
            podcastName: 'The Rick H Show',
            customerPicture: rickHierroPicture,
            customerName: 'Rick Hierro',
            content:
                '"What I like most about Ausha is the fact that it is user friendly, ease to import an already established podcast or create a new one, you can distribute your podcast to all platforms and the communication aspect of it. You are able to promote and communicate with your audience."',
        },
        {
            key: 'trailerPark',
            podcastName: 'Trailer Park: The Podcast Trailer Podcast',
            customerPicture: arielleNissenblattPicture,
            customerName: 'Arielle Nissenblatt',
            content:
                '"My overall experience with Ausha has been nothing but positive. The team at Ausha is committed to providing the best service and are dedicated to not just hosting your podcast but helping you build by offering you all the features on the site."',
        },
        {
            key: 'meetTheMakers',
            podcastName: 'Meet the Makers',
            customerPicture: alexandraMPicture,
            customerName: 'Alexandra M.',
            content:
                '"Ausha has made the hosting and publishing side of my podcast super easy and reliable. Their platform is fast and straightforward to use."',
        },
    ],
};

const Review = ({ value }) => (
    <DeprecatedText weight="bold" size="big" css="line-height: 1.5em;">
        {value}
    </DeprecatedText>
);

const CustomerPicture = ({ url }) => <Img src={url} width="2.5rem" height="2.5rem" radius="50%" />;

const PodcastName = ({ value }) => (
    <DeprecatedText css="line-height: 1.25em;">{value}</DeprecatedText>
);

const CustomerName = ({ value }) => (
    <DeprecatedText weight="bold" css="line-height: 1.25em;">
        {value}
    </DeprecatedText>
);

const CustomerReview = ({ podcastName, customerPicture, customerName, review }) => (
    <Flex
        $minHeight="12.5rem"
        $padding="1.5rem"
        $borderColor="--neutral100"
        $border="1px solid"
        $borderRadius="--r-xs"
        $flexDirection="column"
        $gap="1.5rem"
    >
        <Flex $align="center" $flexGrow={1}>
            <Review value={review} />
        </Flex>
        <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
            <CustomerPicture url={customerPicture} />
            <Stack>
                <CustomerName value={customerName} />
                <PodcastName value={podcastName} />
            </Stack>
        </Cluster>
    </Flex>
);

const Dot = styled.div`
    height: 0.5rem;
    width: 0.5rem;
    border-radius: var(--r-full);
    background-color: ${(props) => (props.isSelected ? 'var(--primary)' : 'var(--primary200)')};
    cursor: pointer;
`;
const TabPanelWrapper = styled.div`
    position: relative;
    overflow: hidden;
`;

const Tab = ({ item, tabListState }) => {
    const { key } = item;
    const tabRef = useRef();
    const { tabProps } = useTab({ key }, tabListState, tabRef);
    const isSelected = tabListState.selectedKey === key;

    return <Dot {...tabProps} ref={tabRef} isSelected={isSelected} />;
};

const TabPanel = ({ tabListState, onSwipeNext, onSwipePrevious, ...props }) => {
    const tabPanelRef = useRef();
    const { tabPanelProps } = useTabPanel(props, tabListState, tabPanelRef);

    return (
        <motion.div
            {...tabPanelProps}
            ref={tabPanelRef}
            variants={{
                enter: { x: 300, opacity: 0, position: 'absolute' },
                center: { x: 0, opacity: 1, position: 'static' },
                exit: { x: -300, opacity: 0, position: 'absolute' },
            }}
            initial="enter"
            animate="center"
            exit="exit"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={0}
            onDragEnd={(event, { offset, velocity }) => {
                const swipePower = Math.abs(offset.x) * velocity.x;

                if (swipePower < -SWIPE_CONFIDENCE_THRESHOLD) {
                    onSwipeNext({ stopAutomaticRotation: true });
                } else if (swipePower > SWIPE_CONFIDENCE_THRESHOLD) {
                    onSwipePrevious({ stopAutomaticRotation: true });
                }
            }}
        >
            {tabListState.selectedItem?.props.children}
        </motion.div>
    );
};

const Carousel = (props) => {
    const tabListRef = useRef();
    const tabListState = useTabListState(props);
    const { tabListProps } = useTabList(props, tabListState, tabListRef);
    const [automaticRotationDelay, setAutomaticRotationDelay] = useState(
        AUTOMATIC_ROTATION_DEFAULT_DELAY,
    );

    const selectNextTab = ({ stopAutomaticRotation }) => {
        tabListState.setSelectedKey(
            tabListState.collection.getKeyAfter(tabListState.selectedKey) ??
                tabListState.collection.getFirstKey(),
        );
        if (stopAutomaticRotation) {
            setAutomaticRotationDelay(null);
        }
    };

    const selectPreviousTab = ({ stopAutomaticRotation }) => {
        tabListState.setSelectedKey(
            tabListState.collection.getKeyBefore(tabListState.selectedKey) ??
                tabListState.collection.getLastKey(),
        );
        if (stopAutomaticRotation) {
            setAutomaticRotationDelay(null);
        }
    };

    useInterval(() => {
        selectNextTab({ stopAutomaticRotation: false });
    }, automaticRotationDelay);

    useEffect(() => {
        setAutomaticRotationDelay(
            tabListState.selectionManager.isFocused ? null : AUTOMATIC_ROTATION_DEFAULT_DELAY,
        );
    }, [tabListState.selectionManager.isFocused]);

    return (
        <Stack $gap="1rem" $align="center" {...props}>
            <TabPanelWrapper>
                <AnimatePresence initial={false}>
                    <TabPanel
                        key={tabListState.selectedItem?.key}
                        tabListState={tabListState}
                        onSwipeNext={selectNextTab}
                        onSwipePrevious={selectPreviousTab}
                    />
                </AnimatePresence>
            </TabPanelWrapper>
            <Cluster $gap="0.5rem" {...tabListProps} ref={tabListRef}>
                {[...tabListState.collection].map((item) => (
                    <Tab key={item.key} item={item} tabListState={tabListState} />
                ))}
            </Cluster>
        </Stack>
    );
};

const CustomerReviews = (props) => {
    const { data: user } = useUserQuery();
    const supportedLocale = ['en', 'fr'].includes(user?.language) ? user?.language : 'en';

    return (
        <FormattedMessage defaultMessage="Liste des avis clients">
            {(label) => (
                <Carousel aria-label={label.join('')} {...props}>
                    {CUSTOMER_REVIEWS[supportedLocale].map((review) => (
                        <Item key={review.key} title={review.podcastName}>
                            <CustomerReview
                                podcastName={review.podcastName}
                                customerPicture={review.customerPicture}
                                customerName={review.customerName}
                                review={review.content}
                            />
                        </Item>
                    ))}
                </Carousel>
            )}
        </FormattedMessage>
    );
};

export default observer(CustomerReviews);
