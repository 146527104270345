import { FormattedMessage } from 'react-intl';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import styled, { css, keyframes } from 'styled-components';
import useEmailNotificationSettingsQuery from '@queries/emailNotificationSettings/useEmailNotificationSettingsQuery.hook';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Form from './Form';

const EmailNotifications = () => {
    useAmplitudeLogEvent('Notifications Viewed');
    const { data: settings, isLoading } = useEmailNotificationSettingsQuery();

    return (
        <Stack $gap="2rem">
            {isLoading ? (
                <EmailNotificationWrapperSkeleton />
            ) : (
                <EmailNotificationWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Paramétrez vos options de notifications pour recevoir une alerte par e-mail lors de ces évènements :" />
                    </Text>

                    <Form defaultValues={settings} />
                </EmailNotificationWrapper>
            )}
        </Stack>
    );
};

const EmailNotificationWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const EmailNotificationWrapperSkeleton = styled.div`
    background-color: var(--neutral50);
    border-radius: var(--r-l);
    width: 100%;
    height: 17rem;
    ${shimmering};
`;

export default EmailNotifications;
