import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { checkImgFormat, checkImgSize, experimentalCheckImgDimensions } from '@/shared/utils/file';

const usePlaylistFormSchema = ({
    locale,
    isPrivacyOptionEnabled,
    mode,
}: {
    locale: string;
    isPrivacyOptionEnabled: boolean;
    mode: 'edit' | 'create';
}) => {
    const intl = useIntl();
    const acceptedMimes = ['jpg', 'png'];
    const IMG_DIMENSIONS = { minWidth: 400, minHeight: 400 };
    const slugRegex = /^[a-zA-Z0-9-À-ÿ]+$/;

    return z
        .object({
            name: z.string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            }),
            privacy: z.enum(['private', 'unlisted', 'public']).refine(
                (value) => {
                    if (isPrivacyOptionEnabled) {
                        return !!value;
                    }
                    return true;
                },
                {
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
                },
            ),
            password: z.string().optional(),
            html_description: z
                .string()
                .max(4000, {
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 4000 }),
                })
                .optional(),
            slug: z
                .string()
                .optional()
                .refine(
                    (value) => {
                        if (mode === 'edit') {
                            return value && slugRegex.test(value);
                        }
                        return true;
                    },
                    {
                        message: intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters),
                    },
                ),
            image: z
                .union([
                    z
                        .instanceof(File)
                        .refine((file: File) => checkImgFormat(file), {
                            message: intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                                acceptedMimes,
                            }) as string,
                        })
                        .refine((file: File) => checkImgSize(file, locale, 10), {
                            message: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, {
                                max: 10,
                            }) as string,
                        })
                        .refine(
                            (file: File) => experimentalCheckImgDimensions(file, IMG_DIMENSIONS),
                            {
                                message: intl.formatMessage(
                                    FORM_VALIDATION_MESSAGE.imgMinDimensions,
                                    IMG_DIMENSIONS,
                                ) as string,
                            },
                        ),
                    z.string(),
                ])
                .optional()
                .nullable(),
        })
        .superRefine((schema, ctx) => {
            const { privacy, password } = schema;
            if (privacy === 'private' && !password) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
                    path: ['password'],
                });
            }
        });
};

export type PlaylistFormSchema = z.infer<ReturnType<typeof usePlaylistFormSchema>>;

export default usePlaylistFormSchema;
