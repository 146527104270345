import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { STATUS_SHOWS } from '../config/statusShows';

const usePricing = (showId?: string) => {
    const { data: show } = useShowQuery(showId as string);
    const { data: subscription } = useSubscriptionQuery();

    const isGuestShow = show?.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const pricing = isGuestShow ? show?.ownerPricing : subscription?.pricing;

    return pricing;
};

export default usePricing;
