import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useResignSubscriptionFormSchema = (user) => {
    const intl = useIntl();

    if (!user) return null;

    if (user.external) return yup.object({});

    return yup.object({
        password: yup
            .string()
            .min(8, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 }))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
    });
};

export default useResignSubscriptionFormSchema;
