import { z } from 'zod';

const useEditPasswordFormSchema = () => {
    return z.object({
        scheduledPodcast: z.boolean(),
        youtubeGenerated: z.boolean(),
    });
};

export type EditPasswordForm = z.infer<ReturnType<typeof useEditPasswordFormSchema>>;
export default useEditPasswordFormSchema;
