import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useChangePasswordFormSchema = () => {
    const intl = useIntl();

    return z
        .object({
            email: z
                .string()
                .email(intl.formatMessage(FORM_VALIDATION_MESSAGE.isEmail))
                .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
            password: z
                .string()
                .min(8, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 })),
            password_confirmation: z
                .string()
                .min(8, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 })),
        })
        .superRefine((schema, ctx) => {
            const { password, password_confirmation } = schema;
            if (password !== password_confirmation) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.valuesDontMatch),
                    path: ['password_confirmation'],
                });
            }
        });
};

export type ChangePasswordForm = z.infer<ReturnType<typeof useChangePasswordFormSchema>>;
export default useChangePasswordFormSchema;
