import usePlayerSettingsQuery from '@queries/playerSettings/usePlayerSettingsQuery.hook';
import { useParams } from 'react-router';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import PlayerListenableEpisodesEmptyState from './PlayerListenableEpisodesEmptyState';
import PlayerForm from '../PlayerForm';
import useShowQuery from '@queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import useSavePlayerSettingsFormMutation from './useSavePlayerSettingsMutation.hook';
import useCallToActionButtonsQuery, {
    CtaType,
} from '@queries/callToAction/useCallToActionButtonsQuery.hook';
import { PlayerFormSchema } from '@/components/unorganized/PlayerForm/usePlayerFormSchema.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { LINKS_NAMES } from '@/components/CallToAction';
import useListenableEpisodesInfiniteQuery from '@/queries/episode/useListenableEpisodesInfiniteQuery.hook';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const Player = () => {
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const settings = usePlayerSettingsQuery(showId);
    const listenableEpisodes = useListenableEpisodesInfiniteQuery({ showId, perPage: 15 });
    const isGranted =
        show.data?.userRole === USER_ROLES.OWNER || show.data?.userRole === USER_ROLES.ADMIN;
    const callToActionButtons = useCallToActionButtonsQuery({
        showId,
        type: LINKS_NAMES.PLAYER as CtaType,
    });
    const savePlayerSettings = useSavePlayerSettingsFormMutation();
    const toast = useBodyToastQueue();

    if (listenableEpisodes.data && listenableEpisodes.data.pages.length === 0) {
        return <PlayerListenableEpisodesEmptyState />;
    }

    if (listenableEpisodes.data) {
        const onSubmit = (formData: PlayerFormSchema) => {
            const { type, ...settings } = formData;
            savePlayerSettings.mutate(
                {
                    showId,
                    settings,
                },
                {
                    onSuccess: () => {
                        toast.success();
                    },
                },
            );
            sendAmplitudeLogEvent('Smartplayer Updated');
        };

        return (
            <PlayerForm
                onSubmit={onSubmit}
                isLoading={savePlayerSettings.isLoading}
                isGranted={isGranted}
                settings={settings.data}
                callToActionButtons={callToActionButtons.data || []}
            />
        );
    }

    return <ShortListFallback />;
};

export default Player;
