import styled from 'styled-components';

interface KeywordCellProps {
    keyword: string;
}

const KeywordCell = ({ keyword }: KeywordCellProps) => {
    return <Keyword>{keyword}</Keyword>;
};

const Keyword = styled.span`
    font-weight: var(--fw-semibold);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    max-width: 100%;
`;

export default KeywordCell;
