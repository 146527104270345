export const compose = (...fns) =>
    fns.reduce(
        (f, g) =>
            (...xs) =>
                f(g(...xs)),
        (x) => x,
    );

export const composeDecorators = (...fns) =>
    fns.reduce(
        (acc, f) => (t, k, d) => acc(t, k, f(t, k, d)),
        (t, k, d) => d,
    );
