import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import posed, { PoseGroup } from 'react-pose';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import StatsListeners from '../../unorganized/StatsListeners';
import StatsSources from '../../unorganized/StatsSources';
import StatsGeolocalization from '../../unorganized/StatsGeolocalization';
import StatsApplePodcasts from '../../unorganized/StatsApplePodcasts';
import HorizontalTabSlide from '../../ui/molecules/HorizontalTabSlide';
import { AccessRoute } from '../../legacy/authorisationRoutes';
import { connect } from '../../legacy/connect';
import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import useStatsSelectableEpisodes from '@/shared/hooks/useStatsSelectableEpisodes.hook';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { PRICING } from '@/shared/config/pricing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import StatsAudiencePage from './StatsAudiencePage';
import PeakTimePage from './PeakTimePage';
import { parseIntArray } from '@/shared/utils/array';

export const TABS_STATS_NAME = {
    LISTENERS: 'listeners',
    SOURCES: 'sources',
    GEOLOC: 'geoloc',
    AUDIENCE: 'audience',
    PEAKTIME: 'peaktime',
    APPLE_PODCASTS: 'apple-podcasts',
};

const TABS_STATS_CONTENT = {
    [TABS_STATS_NAME.LISTENERS]: <StatsListeners />,
    [TABS_STATS_NAME.SOURCES]: <StatsSources />,
    [TABS_STATS_NAME.GEOLOC]: <StatsGeolocalization />,
    [TABS_STATS_NAME.AUDIENCE]: <StatsAudiencePage />,
    [TABS_STATS_NAME.PEAKTIME]: <PeakTimePage />,
    [TABS_STATS_NAME.APPLE_PODCASTS]: <StatsApplePodcasts />,
};

const RouteContainer = posed(DeprecatedPaper)({
    enter: { opacity: 1, y: 0, transition: { duration: 250 } },
    exit: { opacity: 0, y: -20, transition: { duration: 250 } },
});

const StatsTabsNavigation = ({ pageUrl, query }) => {
    const guardRequiresSupersonic = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH, PRICING.BOOST],
    });
    const guardRequiresBoost = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH],
    });

    const TABS_STATS_TITLES = {
        [TABS_STATS_NAME.LISTENERS]: <FormattedMessage defaultMessage="Écoutes" />,
        [TABS_STATS_NAME.SOURCES]: <FormattedMessage defaultMessage="Sources" />,
        [TABS_STATS_NAME.GEOLOC]: <FormattedMessage defaultMessage="Localisation" />,
        [TABS_STATS_NAME.AUDIENCE]: (
            <TitleWrapper>
                <FormattedMessage defaultMessage="Audience" />
                {!guardRequiresSupersonic.allow && (
                    <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                )}
            </TitleWrapper>
        ),
        [TABS_STATS_NAME.PEAKTIME]: (
            <TitleWrapper>
                <FormattedMessage defaultMessage="PeakTime" />
                {!guardRequiresBoost.allow && (
                    <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                )}
            </TitleWrapper>
        ),
        [TABS_STATS_NAME.APPLE_PODCASTS]: (
            <TitleWrapper>
                <FormattedMessage defaultMessage="Apple Podcasts" />
                {!guardRequiresBoost.allow && (
                    <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                )}
            </TitleWrapper>
        ),
    };

    return (
        <HorizontalTabSlide
            tabsTitles={TABS_STATS_TITLES}
            redirectTab={[
                TABS_STATS_NAME.AUDIENCE,
                TABS_STATS_NAME.PEAKTIME,
                TABS_STATS_NAME.APPLE_PODCASTS,
            ]}
            pageUrl={pageUrl}
            query={query}
            mt={25}
        />
    );
};

const StatsTabsContent = ({ pageUrl }) => {
    const {
        params: { tab },
    } = useRouteMatch();

    if (TABS_STATS_CONTENT[tab] === undefined)
        return <Redirect to={`${pageUrl}/${TABS_STATS_NAME.LISTENERS}`} />;
    return (
        <PoseGroup>
            <RouteContainer z={-1} key={tab}>
                {TABS_STATS_CONTENT[tab]}
            </RouteContainer>
        </PoseGroup>
    );
};

const enhance = connect(({ routerStore }) => ({
    episodeQuery: parseIntArray(routerStore.query.episode),
}));

const StatsPage = ({ episodeQuery }) => {
    useAmplitudeLogEvent('Stats Section Viewed');
    const numberOfEpisodesSelectable = useStatsSelectableEpisodes();

    const { path, url } = useRouteMatch();
    const hasQueryAccess = episodeQuery?.length > numberOfEpisodesSelectable;

    return (
        <Switch>
            <Route
                exact
                path={[
                    // Tab with Query String
                    `${path}/:tab?`,
                    // Try to match a specific campaign...
                    `${path}/:itemType(campaign)/:itemId/:tab(${Object.keys(
                        TABS_STATS_CONTENT,
                    ).join('|')})`,
                ]}
                /* eslint-disable-next-line react/no-children-prop */
                children={({ match }) => (
                    <AccessRoute
                        condition={hasQueryAccess}
                        to={{ page: 'stats.show', tab: match.params.tab }}
                    >
                        <StatsPageWrapper>
                            <StatsTabsNavigation pageUrl={url} query={episodeQuery} />
                            <StatsTabsContent pageUrl={url} />
                        </StatsPageWrapper>
                    </AccessRoute>
                )}
            />
            <Redirect to={`${path}/${TABS_STATS_NAME.LISTENERS}`} />
        </Switch>
    );
};

const StatsPageWrapper = styled.div`
    width: 100%;
    max-width: var(--container-width);
    margin-inline: auto;
    padding-inline: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding-inline: var(--container-padding-mobile);
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;

export default enhance(StatsPage);
