import { FormattedMessage } from 'react-intl';
import TranscriptionMinutesQuota from './TranscriptionMinutesQuota';
import NewsletterEmailsQuota from '@/components/unorganized/NewsletterEmailsQuota';
import ActiveShowsQuota from './ActiveShowsQuota';
import ArchivedShowsQuota from './ArchivedShowsQuota';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import Alert from '@ui/atoms/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';

const Quotas = () => {
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();
    const hasAccessToTranscription = user?.options?.includes('transcription');

    const giftedShows = subscription?.extraShows || 0;

    return (
        <QuotasOuterWrapper>
            <QuotasWrapper transcriptionReleased={hasAccessToTranscription ?? false}>
                {hasAccessToTranscription && <TranscriptionMinutesQuota />}
                <NewsletterEmailsQuota />
                <ActiveShowsQuota />
                <ArchivedShowsQuota />
            </QuotasWrapper>
            {giftedShows > 0 && (
                <GiftedShowsWrapper>
                    <Alert
                        variant="primary"
                        icon={<FontAwesomeIcon icon={icon({ name: 'gift', style: 'solid' })} />}
                        defaultIcon={false}
                    >
                        <AlertText>
                            <FormattedMessage
                                defaultMessage="{giftedShows, plural, one {Vous disposez également de # émission active offerte. 🎉} other {Vous disposez également de # émissions actives offertes. 🎉}}"
                                values={{
                                    giftedShows,
                                }}
                            />
                        </AlertText>
                    </Alert>
                </GiftedShowsWrapper>
            )}
        </QuotasOuterWrapper>
    );
};

interface QuotasWrapperProps {
    transcriptionReleased: boolean;
}

const QuotasOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const QuotasWrapper = styled.div<QuotasWrapperProps>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;

    /* === Remove this when transcription is released === */
    ${({ transcriptionReleased }) =>
        !transcriptionReleased &&
        css`
            & > :first-of-type {
                grid-column: 1 / span 2;
            }
        `};

    @media (max-width: 1050px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);

        /* === Remove this when transcription is released === */
        ${({ transcriptionReleased }) =>
            !transcriptionReleased &&
            css`
                & > :first-of-type {
                    grid-column: 1 / span 1;
                }
            `};
    }
`;
const GiftedShowsWrapper = styled.div`
    grid-column: 1 / span 2;
    grid-row: 3 / span 1;
`;
const AlertText = styled.span`
    font-weight: var(--fw-semibold);
`;

export default Quotas;
