import { useWindowPopup } from './useWindowPopup';

export const useLinkLoginProviderWithPopup = (
    provider: 'google' | 'facebook',
    login: (e: any) => void,
) => {
    const { wind, open, closeWindow } = useWindowPopup(
        provider,
        `${process.env.API_AUTH}/auth/${provider}`,
        login,
    );

    return { hasWindow: !!wind, openWindow: open, closeWindow };
};
