import IconButton from '@/components/IconButton';
import Text from '@/components/ui/atoms/Text';
import { PlaylistWithEpisodes } from '@/queries/playlist/usePlaylistQuery.hook';
import useSeasonQuery from '@/queries/season/useSeasonQuery.hook';
import { EPISODE_PRIVACY, IMG_PLACEHOLDER, USER_ROLES } from '@/shared/config/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import removePlaylistEpisodeMutation from '@/queries/playlist/removePlaylistEpisodeMutation';
import { formatDuration } from '@/shared/utils/duration';

interface PlaylistEpisodeProps {
    episode: PlaylistWithEpisodes['episodes'][0];
    index: number;
}

const PlaylistEpisode = ({ episode, index }: PlaylistEpisodeProps) => {
    const { showId, playlistId } = useParams<{ showId: string; playlistId: string }>();
    const season = useSeasonQuery({ showId, seasonId: String(episode.seasonId) });
    const { data: show } = useShowQuery(showId);
    const isGuestViewer = show?.userRole === USER_ROLES.VIEWER;
    const removeEpisodeMutation = removePlaylistEpisodeMutation();

    const PRIVACY_ICONS = {
        [EPISODE_PRIVACY.PUBLIC]: <Icon icon={icon({ name: 'eye', style: 'solid' })} />,
        [EPISODE_PRIVACY.PRIVATE]: <Icon icon={icon({ name: 'eye-slash', style: 'solid' })} />,
        [EPISODE_PRIVACY.UNLISTED]: <Icon icon={icon({ name: 'link', style: 'solid' })} />,
    };

    const handleRemoveEpisode = () => {
        removeEpisodeMutation.mutate({
            playlistId: playlistId,
            episodeId: episode.playlistPodcastId,
        });
    };

    return (
        <Draggable
            draggableId={episode.playlistPodcastId.toString()}
            index={index}
            isDragDisabled={isGuestViewer}
        >
            {(provided) => (
                <Container
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {!isGuestViewer && (
                        <DraggableIcon icon={icon({ name: 'bars', style: 'solid' })} />
                    )}
                    <Image src={episode.imageUrl ?? IMG_PLACEHOLDER} alt={episode.name ?? ''} />
                    <TitleWrapper>
                        <Title>{episode.name}</Title>
                        <MetaDataWrapper>
                            <MetaData>
                                <Icon icon={icon({ name: 'arrow-down-to-line', style: 'solid' })} />
                                <Text color="--neutral500">{episode.downloadsCount}</Text>
                            </MetaData>
                            <MetaData>
                                <Icon icon={icon({ name: 'clock', style: 'regular' })} />
                                <Text color="--neutral500">
                                    {' '}
                                    {formatDuration(episode.duration)}
                                </Text>
                            </MetaData>
                            {season.data && (
                                <MetaData>
                                    <Icon icon={icon({ name: 'bars-filter', style: 'solid' })} />
                                    <Text color="--neutral500">
                                        <FormattedMessage
                                            defaultMessage="Saison {number}"
                                            values={{ number: season.data.seasonNumber }}
                                        />
                                    </Text>
                                </MetaData>
                            )}
                        </MetaDataWrapper>
                    </TitleWrapper>
                    {PRIVACY_ICONS[episode.privacy]}
                    <DeleteButton
                        onPress={handleRemoveEpisode}
                        variant="tertiary"
                        aria-label="Supprimer l'épisode de la playlist"
                        icon={<Icon icon={icon({ name: 'xmark', style: 'solid' })} />}
                    />
                </Container>
            )}
        </Draggable>
    );
};

const Container = styled.div`
    background-color: var(--white);
    padding: 1rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;

    &:first-child {
        border-top-left-radius: var(--r-m);
        border-top-right-radius: var(--r-m);
    }
    &:last-child {
        border-bottom-left-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }

    &:not(:last-child) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const DraggableIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const Image = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;
const TitleWrapper = styled.div`
    min-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;
const Title = styled(Text)`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
    white-space: nowrap;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const MetaDataWrapper = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }
`;
const MetaData = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const DeleteButton = styled(IconButton)`
    padding: 0.5rem;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.2);
`;

export default PlaylistEpisode;
