import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useLoginFormSchema = () => {
    const intl = useIntl();

    return z.object({
        email: z
            .string()
            .email(intl.formatMessage(FORM_VALIDATION_MESSAGE.isEmail))
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        password: z.string().min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
    });
};

export type LoginForm = z.infer<ReturnType<typeof useLoginFormSchema>>;
export default useLoginFormSchema;
