import Button from '@/components/Button';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import aiVideo from '@public/videos/ai.mp4';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import enableAIFreeTrialMutation from '@/queries/ai/enableAIFreeTrialMutation.hook';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import episodeKeys from '@/queries/episode/episodeKeys';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { AxiosError } from 'axios';

import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface EpisodeAIFreeTrialModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const EpisodeAIFreeTrialModal = ({ isOpen, onOpenChange }: EpisodeAIFreeTrialModalProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const queryClient = useQueryClient();
    const enableFreeTrial = enableAIFreeTrialMutation();
    const toast = useBodyToastQueue();

    const handleStartFreeTrial = () => {
        enableFreeTrial.mutate(episodeId, {
            onSuccess: () => {
                sendAmplitudeLogEvent('AI Free Trial Activated');
                queryClient.invalidateQueries({ queryKey: episodeKeys.detailById(episodeId) });
                onOpenChange(false);
            },
            onError: (error) => {
                const err = error as AxiosError;
                // TODO: Get correct translations
                switch (err.response?.status) {
                    case 422:
                        toast.alert(
                            <FormattedMessage defaultMessage="Le podcast n’appartient pas à cette chaîne ou la chaîne n’est plus éligible à l’essai gratuit." />,
                        );
                        break;
                    case 403:
                        toast.alert(
                            <FormattedMessage defaultMessage="Il semblerait que vous n\'êtes pas le propriétaire de cette chaîne." />,
                        );
                        break;
                    default:
                        toast.alert();
                }
            },
        });
    };

    const handleCancel = () => {
        localStorage.setItem('ai-free-trial-modal-closed', 'true');
        onOpenChange(false);
    };

    return (
        <ModalStyled
            data-testid="episode-ai-free-trial-modal"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="small"
        >
            <Video muted autoPlay loop playsInline>
                <source src={aiVideo} type="video/mp4" />
            </Video>
            <Stack $gap="0.25rem">
                <Text variant="headingM" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Essayez gratuitement Ausha Intelligence pour cet épisode" />
                </Text>
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Profitez d'un accès gratuit à Ausha Intelligence pour cet épisode et obtenez le titre, la description, les tags, les chapitres et la newsletter générés par l'IA." />
                </Text>
            </Stack>
            <Actions>
                <Button
                    data-testid="episode-ai-free-trial-modal-button"
                    variant="magic"
                    startIcon={<SparklesIcon icon={icon({ name: 'sparkles', style: 'duotone' })} />}
                    onPress={handleStartFreeTrial}
                >
                    <FormattedMessage defaultMessage="Essayez AI gratuitement" />
                </Button>
                <Button
                    data-testid="episode-ai-free-trial-modal-close-button"
                    variant="link-secondary"
                    onPress={handleCancel}
                >
                    <FormattedMessage defaultMessage="Peut-être plus tard" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Video = styled.video`
    display: block;
    border-radius: var(--r-m);
    width: 100%;
    height: 10rem;
    object-fit: cover;
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;
const SparklesIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--white);
`;

export default EpisodeAIFreeTrialModal;
