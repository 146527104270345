import { ACCEPTED_AUDIO_TYPES, IMG_TYPES } from '@/shared/config/constants';

export function download(data, filename, type) {
    const file = new Blob([data], { type });
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(file, filename);
        return;
    }

    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }, 0);
}

export const checkAudioFileFormat = (file) => {
    if (!file) return;
    return ACCEPTED_AUDIO_TYPES.includes(file.type);
};

export const checkAudioFileSize = (file, locale = 'en', maxSize = 500) => {
    if (!file) {
        throw new Error('Audio file is required');
    }
    const conversionFactor = locale === 'fr' ? 1024 * 1024 : 1000 * 1000;
    return (file.size / conversionFactor).toFixed(1) <= maxSize;
};

export const checkImgFormat = (file) => {
    if (!file) return;
    return IMG_TYPES.includes(file.type);
};

export const checkImgSize = (img, locale, maxSize = 10) => {
    if (!img) return;
    const denominator = locale === 'fr' ? 1000 * 1000 : 1024 * 1024;
    return (img.size / denominator).toFixed(1) <= maxSize;
};

export const checkImgDimensions = (img, dimensions) => {
    if (!img) return;
    const DIMENSIONS = {
        minWidth: 0,
        minHeight: 0,
        maxWidth: Infinity,
        maxHeight: Infinity,
        ...dimensions,
    };

    return (
        img.width >= DIMENSIONS.minWidth &&
        img.height >= DIMENSIONS.minHeight &&
        img.width <= DIMENSIONS.maxWidth &&
        img.height <= DIMENSIONS.maxHeight
    );
};

export const experimentalCheckImgDimensions = async (file, dimensions) => {
    if (!(file instanceof File)) {
        console.error("'file' must be an instance of File");
        return false;
    }

    const img = new Image();
    img.src = URL.createObjectURL(new File([file], 'pic'));

    return new Promise((resolve) => {
        img.onload = () => {
            const DIMENSIONS = { minWidth: 400, minHeight: 400, ...dimensions };
            const isValid = img.width >= DIMENSIONS.minWidth && img.height >= DIMENSIONS.minHeight;
            resolve(isValid);
        };
    });
};
