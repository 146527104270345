import useHasPsoFullUserOption from './useHasPsoFullUserOption.hook';

interface useHasAccessToPsoKeywordsRankingProps {
    showId: string;
}

const useHasAccessToPsoKeywordsRanking = ({
    showId,
}: useHasAccessToPsoKeywordsRankingProps): boolean => {
    const hasPsoFull = useHasPsoFullUserOption({ showId });

    return hasPsoFull || false;
};

export default useHasAccessToPsoKeywordsRanking;
