import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const updateSmartlinkSettings = (show, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/shows/${show}/smartlink`, body });
};
export const fetchSmartlinkSettings = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/smartlink` });
};
export const fetchSmartlinkDominantColor = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/colors` });
};
