import { useEffect } from 'react';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import ChaptersProgressTracker from './ChaptersProgressTracker';
import { useQueryClient } from '@tanstack/react-query';
import chapterKeys from '@/queries/chapter/chapterKeys';
import ChaptersEditor from './ChaptersEditor';
import useEpisodeAudioFileJobsQuery from '@/queries/episode/useEpisodeAudioFileJobsQuery';

const NonHostingEpisodeFormChapters = () => {
    const episode = useEpisodeBeingEdited();
    const { waveformUrl: episodeWaveformUrl } = episode;
    const queryClient = useQueryClient();
    const isEpisodeJobsProgressQueryEnabled = episode.files.length > 0 && !episodeWaveformUrl;
    const episodeJobsProgressQuery = useEpisodeAudioFileJobsQuery({
        episodeId: episode.id,
        enabled: isEpisodeJobsProgressQueryEnabled,
    });

    useEffect(() => {
        // Refetch chapters once jobs are completed
        if (episodeJobsProgressQuery.data?.isFinished) {
            queryClient.invalidateQueries({
                queryKey: chapterKeys.listByEpisodeId(episode.id),
            });
        }
    }, [episodeJobsProgressQuery.data?.isFinished, episode.id, queryClient]);

    if (isEpisodeJobsProgressQueryEnabled && episodeJobsProgressQuery.isLoading) {
        // TODO Handle loading state
        return <div>Loading...</div>;
    }

    if (isEpisodeJobsProgressQueryEnabled && episodeJobsProgressQuery.error) {
        // TODO Handle error state
        return <div>ERROR</div>;
    }

    const { data: episodeJobsProgress } = episodeJobsProgressQuery;

    // Jobs are still running
    if (episodeJobsProgress && !episodeJobsProgress.isFinished && !episodeJobsProgress.isFailed) {
        return <ChaptersProgressTracker progress={episodeJobsProgress} />;
    }

    // TODO Camelcase useEpisodeJobsProgressQuery data
    const jobWaveformUrl = episodeJobsProgress?.generatewaveform?.result?.waveform_url;
    const waveformUrl = episodeWaveformUrl || jobWaveformUrl;

    if (!waveformUrl) {
        // TODO Handle error state
        return <div>ERROR</div>;
    }

    return <ChaptersEditor episode={episode} waveformUrl={waveformUrl} />;
};

export default NonHostingEpisodeFormChapters;
