import AvatarIcon from '@public/icons/avatar-third-party-review.svg';
import TrustpilotLogo from '@public/images/trustpilot.png';

const PROVIDER = 'Trustpilot';

const LOGO = TrustpilotLogo;

const NOTE = {
    score: 4.8,
    gradeOf: 5,
    colorScheme: 'trust-pilot',
};

const NOTICES = [
    {
        id: crypto.randomUUID(),
        user: 'Cynthia L.',
        avatar: AvatarIcon,
        link: 'https://fr.trustpilot.com/users/5e61428bd2439827d1ede2dd',
        title: 'Superbe plateforme, très facile d’utilisation !!',
        notice: '« L’équipe est très réactive, et puis c’est français !!! »',
    },
    {
        id: crypto.randomUUID(),
        user: 'Ben A.',
        avatar: AvatarIcon,
        link: 'https://fr.trustpilot.com/users/5eb3da92ee8f297924cb6f7b',
        title: 'En plus d’être sympathique, l’équipe Ausha est très réactive.',
        notice: '« Tout est facilité pour lancer son podcast, et les fonctionnalités telles que la création de clip justifient à elles seules l’abonnement. Bravo la team ! »',
    },
    {
        id: crypto.randomUUID(),
        user: 'Sébastien B.',
        avatar: AvatarIcon,
        link: 'https://fr.trustpilot.com/users/4f8735880000640001178230',
        title: 'J’adore Ausha, et je ne m’en passerais plus.',
        notice: '« Tout est plus simple, beau, et esthétique. Je considère Ausha comme un partenaire important pour le développement de mon entreprise. »',
    },
];

export default { PROVIDER, LOGO, NOTE, NOTICES };
