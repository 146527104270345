import { useEffect } from 'react';
import { AISettingsMapItem } from '../../contexts/NonHostingEpisodeAISettingsContext';
import { AITitleSuggestions } from '@/queries/ai/useAITitleSuggestionsQuery.hook';
import { AIDescriptionSuggestions } from '@/queries/ai/useAIDescriptionSuggestionQuery.hook';
import { AITagsSuggestions } from '@/queries/ai/useAITagsSuggestionsQuery.hook';
import { AIChaptersSuggestions } from '@/queries/ai/useAIChaptersSuggestionsQuery.hook';
import { AISocialPostsSuggestions } from '@/queries/ai/useAISocialPostsSuggestionsQuery';
import { AINewsletterTitleSuggestions } from '@/queries/ai/useAINewsletterTitleSuggestionQuery.hook';
import { AINewsletterMessageSuggestions } from '@/queries/ai/useAINewsletterMessageSuggestionQuery.hook';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface AIRegenerationLoggerProps {
    suggestions:
        | AITitleSuggestions['suggestions']
        | AIDescriptionSuggestions['suggestion']
        | AITagsSuggestions['suggestions']
        | AIChaptersSuggestions['suggestions']
        | AISocialPostsSuggestions['suggestions']
        | AINewsletterTitleSuggestions
        | AINewsletterMessageSuggestions
        | undefined;
    field: AISettingsMapItem;
    episodeId: string;
    prompt: string;
}

export const useAIRegenerationLogger = ({
    suggestions,
    field,
    episodeId,
    prompt,
}: AIRegenerationLoggerProps) => {
    useEffect(() => {
        if (!suggestions) return;
        const isRegenerated =
            localStorage.getItem(`episode-ai-${episodeId}-regenerated-${field}`) === 'true';
        if (!isRegenerated) return;
        sendAmplitudeLogEvent('AI Regeneration', {
            field,
            suggestions,
            prompt,
        });
    }, [suggestions]);
};
