import { useRef } from 'react';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import type { Competitor } from '@queries/pso/usePsoCompetitorsQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useQuery from '@/shared/hooks/useQuery';
import type { Platform, Mode } from '@/api/pso/types';
import RouterLink from '@/components/Link/RouterLink';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { Button, Tooltip as RATooltip, TooltipTrigger } from 'react-aria-components';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface CompetitorsCellProps {
    keyword: string;
    competitors: Competitor[];
}

const CompetitorsCell = ({ keyword, competitors }: CompetitorsCellProps) => {
    let triggerRef = useRef(null);
    const { showId } = useParams<{ showId: string }>();
    const query = useQuery();
    const platform: Platform = query.get('platform') as Platform;
    const store: string = query.get('store') || '';
    const mode: Mode = query.get('mode') as Mode;

    const shouldDisplayLivesearchLink = competitors.length >= 10;

    if (competitors.length === 0) {
        return (
            <NoResult>
                <FormattedMessage defaultMessage="Aucun résultat" />
            </NoResult>
        );
    }

    return (
        <CompetitorsCellWrapper>
            <CoverStack>
                {competitors.slice(0, 10).map((competitor) => (
                    <TooltipTrigger delay={300} closeDelay={100} key={competitor.id}>
                        <CoverWrapper>
                            <Cover ref={triggerRef} src={competitor.cover} />
                        </CoverWrapper>
                        <CompetitorTooltip placement="top">
                            <CompetitorTooltipInnerWrapper>
                                <TooltipRanking>{competitor.rank}</TooltipRanking>
                                <TooltipCover
                                    src={competitor.cover}
                                    alt={`covert art for ${competitor.name}`}
                                />
                                <TooltipTitleAndAuthor>
                                    <TooltipTitle>{competitor.name}</TooltipTitle>
                                    <TooltipAuthor>{competitor.author}</TooltipAuthor>
                                </TooltipTitleAndAuthor>
                                <PlatformLink
                                    href={competitor.link}
                                    target="_blank"
                                    onClick={() => {
                                        sendAmplitudeLogEvent('PSO Competitors Listening Link', {
                                            url: competitor.link,
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                                    />
                                </PlatformLink>
                            </CompetitorTooltipInnerWrapper>
                        </CompetitorTooltip>
                    </TooltipTrigger>
                ))}
                {shouldDisplayLivesearchLink && (
                    <TooltipTrigger delay={300} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <LivesearchRouterLink
                                to={`/app/show/${showId}/pso/livesearch?keyword=${keyword}&platform=${platform}&store=${store}&mode=${mode}`}
                                onClick={() => {
                                    sendAmplitudeLogEvent('PSO Competitors Live Search', {
                                        keyword,
                                        platform,
                                        store,
                                        mode,
                                    });
                                }}
                                variant="button-secondary"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'ellipsis', style: 'solid' })}
                                    />
                                }
                            />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Voir plus dans le Live Search" />
                        </Tooltip>
                    </TooltipTrigger>
                )}
            </CoverStack>
        </CompetitorsCellWrapper>
    );
};

const CompetitorsCellWrapper = styled.div``;
const CoverStack = styled.div`
    display: flex;
    align-items: center;
    padding-inline: 0.75rem;
`;
const CoverWrapper = styled(Button)`
    border: none;
    background-color: transparent;
    padding: 0;
    &:not(:first-child) {
        margin-left: -0.5rem;
    }
`;
const Cover = styled.img`
    width: 2rem;
    border-radius: var(--r-s);
    border: 2px solid var(--white);

    &:hover {
        transition-duration: 0.25s;
        transform: scale(1.1);
    }
`;
const LivesearchRouterLink = styled(RouterLink)`
    height: 2rem;
    width: 2rem;
    padding: 0.25rem;
    margin-left: -0.5rem;

    &:hover {
        transition-duration: 0.25s;
        transform: scale(1.1);
    }
`;
const CompetitorTooltip = styled(RATooltip)`
    padding: 0.5rem;
`;
const CompetitorTooltipInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    background-color: var(--white);
    box-shadow: var(--s-light);
    padding: 0.75rem;
    border-radius: var(--r-m);
`;
const TooltipRanking = styled.span`
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-l);
`;
const TooltipCover = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;
const TooltipTitleAndAuthor = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 10rem;
`;
const TooltipTitle = styled.span`
    font-weight: var(--fw-semibold);
    line-height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16rem;
`;
const TooltipAuthor = styled.span`
    color: var(--neutral);
    line-height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16rem;
`;
const PlatformLink = styled.a`
    transition-duration: 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-s);

    &:hover {
        color: var(--primary);
        background-color: var(--primary50);
    }

    & > * {
        transition-duration: 0.5s;
    }

    &:hover > * {
        transform: translate(0.125rem, -0.125rem);
    }
`;
const NoResult = styled.span`
    color: var(--neutral);
    font-style: italic;
    margin-inline: 0.5rem;
`;

export default CompetitorsCell;
