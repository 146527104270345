import { useMutation } from '@tanstack/react-query';
import { login } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';
import { useAuthContext } from '@/context/AuthContext';
import { z } from 'zod';

const LoginResponseSchema = z.object({
    accessToken: z.string(),
    expiresIn: z.number(),
    refreshToken: z.string(),
    tokenType: z.string(),
});
export type LoginResponse = z.infer<typeof LoginResponseSchema>;

interface MutationFnProps {
    formData: {
        email: string;
        password: string;
    };
    invitationToken: string | null;
    invitationShow: string | null;
}

const mutationFn = async ({ formData, invitationToken, invitationShow }: MutationFnProps) => {
    const data = await login(
        decamelizeKeys({
            ...formData,
            ...(invitationToken && { invitationToken }),
            ...(invitationShow && { invitationShow }),
        }),
    );

    return LoginResponseSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useLoginMutation = () => {
    const { login: localLogin, redirectToApp } = useAuthContext();
    return useMutation({
        mutationFn,
        onSuccess: (data: LoginResponse) => {
            localLogin(data);
            redirectToApp();
        },
    });
};

export default useLoginMutation;
