import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchPersonalAccessTokens = () => {
    return request({ method: 'GET', basePath, target: '/v1/personal-access/tokens' });
};
export const addPersonalAccessToken = (body) => {
    return request({ method: 'POST', basePath, target: '/v1/personal-access/tokens', body });
};
export const deletePersonalAccessToken = (tokenId) => {
    return request({ method: 'DELETE', basePath, target: `/v1/personal-access/tokens/${tokenId}` });
};
