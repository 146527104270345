import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchSeasons = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/shows/${show}/seasons` });
};
export const addSeason = (show) => {
    return request({ method: 'POST', basePath, target: `/v1/shows/${show}/seasons` });
};
export const deleteSeason = (season) => {
    return request({ method: 'DELETE', basePath, target: `/v1/seasons/${season}` });
};
