import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Link from '@ui/atoms/Link';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import StarIcon from '@public/icons/star.svg';
import IlluEmojiLocked from '@public/images/illu_emoji_locked.svg';
import publicApiImage from '@public/images/limit_publicAPI.png';
import osBrowsersImage from '@public/images/limit_sources.png';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { PRICING_NAME } from '@/shared/config/pricing';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Stack from '@ui/layout/Stack';
import Flex from '@ui/layout/Flex';
import Center from '@ui/layout/Center';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const LOCK_MESSAGES = {
    [USER_OPTIONS.PUBLIC_API]: {
        title: <FormattedMessage defaultMessage="API Public" />,
        paragraph: (
            <FormattedMessage defaultMessage="Générez des jetons d'accès personnels pour chaque application ayant besoin d'accéder à Ausha." />
        ),
    },
    [USER_OPTIONS.SOURCES_OS_BROWSERS]: {
        title: <FormattedMessage defaultMessage="Comment vos auditeurs vous écoutent" />,
        paragraph: (
            <FormattedMessage defaultMessage="Découvrez les systèmes d'exploitation les plus appréciés de vos auditeurs et les navigateurs les plus utilisés pour vous écouter." />
        ),
    },
};

const getLockedImage = (type) => {
    switch (type) {
        case USER_OPTIONS.PUBLIC_API:
            return publicApiImage;
        case USER_OPTIONS.SOURCES_OS_BROWSERS:
            return osBrowsersImage;
        default:
            return null;
    }
};

const LockedMessage = ({ type, upgradeOfferLink, offer, readMoreLink }) => (
    <Center max="25rem">
        <Stack $gap="1rem">
            <Stack $gap="0.5rem">
                <DeprecatedIcon as={IlluEmojiLocked} size="4rem" />
                <DeprecatedText size="big" weight="semibold">
                    {LOCK_MESSAGES[type].title}
                </DeprecatedText>
                <DeprecatedText color="--neutral500">
                    {LOCK_MESSAGES[type].paragraph}
                </DeprecatedText>
            </Stack>
            <Stack $gap="0.25rem">
                {readMoreLink ? (
                    <DeprecatedText as="a" href={readMoreLink} target="_blank" color="--primary">
                        <FormattedMessage defaultMessage="En savoir plus" />
                    </DeprecatedText>
                ) : null}
                <DeprecatedButton as={Link} to={upgradeOfferLink} w="max-content">
                    <DeprecatedIcon as={StarIcon} size="0.75rem" color="white" />
                    <FormattedMessage
                        defaultMessage="Passer en offre {offer}"
                        values={{ offer: PRICING_NAME[offer] }}
                    />
                </DeprecatedButton>
            </Stack>
        </Stack>
    </Center>
);

const LockedImage = styled.img`
    max-width: 100%;
    max-height: ${(props) => (props.isDesktop ? '80%' : '12.5rem')};
`;

const LockMessage = ({ type, offer, readMoreLink }) => {
    const { isDesktop } = useResponsive();
    const { data: user } = useUserQuery();

    return (
        <Flex $flexDirection={isDesktop ? 'row' : 'column'} $height={isDesktop ? '31.25rem' : null}>
            <Flex $flex="1 1 50%" $align="center" $justify="center" $padding="1.5rem">
                <LockedMessage
                    type={type}
                    upgradeOfferLink={'/app/user/subscription/offers'}
                    offer={offer}
                    readMoreLink={readMoreLink}
                />
            </Flex>
            <Flex
                $flex="1 1 50%"
                $align="center"
                $justify="center"
                $padding="2.75rem"
                $background="--gradient-primary"
            >
                <LockedImage
                    src={getLockedImage(type, user?.language)}
                    alt=""
                    isDesktop={isDesktop}
                />
            </Flex>
        </Flex>
    );
};

export default LockMessage;
