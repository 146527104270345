import { useState } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import type { Episode as EpisodeT } from '@queries/episode/useEpisodesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory, useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { EPISODE_STATUS, USER_ROLES } from '@/shared/config/constants';
import RouterLink from '@/components/Link/RouterLink';
import Button from '@/components/Button';
import { mediaQueries } from '@/styles/theme';
import { useAudioPlayerContext } from '@/context/AudioPlayerContext';
import CircularProgress from '@ui/atoms/CircularProgress';
import styled from 'styled-components';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import useDuplicateEpisodeMutation from '@/queries/episode/useDuplicateEpisodeMutation.hook';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import useHasAccessToPsoChecker from '@/shared/hooks/useHasAccessToPsoChecker.hook';

import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

interface EpisodeProps {
    episode: EpisodeT;
}

const isDesktop = window.matchMedia(
    mediaQueries.mediaQueries.desktopLargeAndUp.split('@media ')[1],
).matches;

const Episode = ({ episode }: EpisodeProps) => {
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const [hovered, setHovered] = useState(false);
    const episodeIsDraft = episode.state === EPISODE_STATUS.DRAFT;
    const duplicateEpisode = useDuplicateEpisodeMutation();
    const userHasWriteAccess = show?.userRole !== USER_ROLES.VIEWER;
    const toast = useBodyToastQueue();
    const hasAccessToPsoChecker = useHasAccessToPsoChecker({ showId, mode: 'read+write' });
    const psoCheckerScore = episode?.psoScore?.data?.score ?? 0;

    const { currentlyPlaying, setCurrentlyPlaying, audioIsPlaying, play, pause } =
        useAudioPlayerContext();

    const MediaButton = (
        <PlayControlOverlay>
            {audioIsPlaying && currentlyPlaying?.url === episode.audioUrl ? (
                <PlayButton onPress={pause} variant="ghost">
                    <FontAwesomeIcon icon={icon({ name: 'pause', style: 'solid' })} />
                </PlayButton>
            ) : (
                <PlayButton
                    onPress={() => {
                        if (!episode?.audioUrl) return;

                        const media = {
                            name: episode.name,
                            url: episode.audioUrl,
                        };

                        setCurrentlyPlaying(media);
                        play(media);
                    }}
                    variant="ghost"
                >
                    <FontAwesomeIcon icon={icon({ name: 'play', style: 'solid' })} />
                </PlayButton>
            )}
        </PlayControlOverlay>
    );

    const CoverContent = episode.imageUrl ? (
        <Cover src={episode.imageUrl} alt={episode.name} />
    ) : show?.imageUrl ? (
        <Cover src={show.imageUrl} alt={show.name} />
    ) : (
        <Cover src="https://image.ausha.co/default_cover.png" alt="Default cover" />
    );

    const psoCheckerColor =
        psoCheckerScore > 70 ? '--success' : psoCheckerScore > 20 ? '--warning' : '--alert';

    const handleCreateDraft = () => {
        sendAmplitudeLogEvent('Copy in Workspace');
        duplicateEpisode.mutate(
            { episodeId: `${episode.id}` },
            {
                onSuccess: (episode) => {
                    localStorage.setItem(`episode-${episode.id}-audio-file-uploaded`, 'true');
                    history.replace(
                        `/app/show/${showId}/workspace/episodes/${episode.id}?name=${episode.name}&loader=true`,
                    );
                },
                onError: () => {
                    // TODO: Use specific error message
                    toast.alert();
                },
            },
        );
    };

    return (
        <EpisodeWrapper onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Left>
                <CoverWrapper>
                    {CoverContent}
                    {episode.audioUrl &&
                        (currentlyPlaying?.url === episode.audioUrl || hovered) &&
                        MediaButton}
                </CoverWrapper>
                <LeftTextWrapper>
                    <EditLinkTitle to={`/app/show/${showId}/feed/${episode.id}`}>
                        <EpisodeTitle>{episode.name}</EpisodeTitle>
                    </EditLinkTitle>
                    <EpisodeMetaData>
                        {!episodeIsDraft && (
                            <MetaData>
                                <FontAwesomeIcon
                                    icon={icon({ name: 'calendar', style: 'regular' })}
                                />
                                <FormattedDate value={episode?.publishedAt || ''} />
                            </MetaData>
                        )}
                    </EpisodeMetaData>
                </LeftTextWrapper>
            </Left>
            <Right>
                {hasAccessToPsoChecker && (
                    <PsoChecker $color={psoCheckerColor}>
                        <CircularProgress
                            progressColor={psoCheckerColor}
                            backgroundColor={`${psoCheckerColor}200`}
                            size={20}
                            strokeWidth={4}
                            percentage={psoCheckerScore}
                        />
                        <span>{psoCheckerScore}%</span>
                        <FormattedMessage defaultMessage="optimisé" />
                    </PsoChecker>
                )}
                <TooltipTrigger delay={0} closeDelay={0} isDisabled={userHasWriteAccess}>
                    <TooltipTriggerWrapper>
                        <Button
                            isDisabled={!userHasWriteAccess}
                            onPress={handleCreateDraft}
                            isLoading={duplicateEpisode.isLoading}
                            startIcon={
                                <FontAwesomeIcon icon={icon({ name: 'copy', style: 'solid' })} />
                            }
                        >
                            {isDesktop ? (
                                <FormattedMessage defaultMessage="Copier dans l'espace de travail" />
                            ) : null}
                        </Button>
                    </TooltipTriggerWrapper>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="L'ajout ou la modification des épisodes n'est pas disponible en tant qu'invité Lecteur sur l'émission." />
                    </Tooltip>
                </TooltipTrigger>
            </Right>
        </EpisodeWrapper>
    );
};

const EpisodeWrapper = styled.li`
    transition-duration: 0.2s;
    height: 4.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    padding-block: 0.75rem;
    padding-inline: 1rem;
    min-width: 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--neutral100);
    }

    &:hover {
        z-index: 2;
        box-shadow: var(--s-whatever);
    }

    &:not(:last-child):hover {
        border-bottom: 1px solid transparent;
    }
`;
const CoverWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
`;
const Cover = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    border-radius: var(--r-s);
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    min-width: 0;
`;
const EditLinkTitle = styled(RouterLink)`
    color: var(--black);
    flex: 1;
`;
const EpisodeTitle = styled.div`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
    flex: 1;

    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1450px) {
        max-width: 600px;
    }

    &:hover {
        text-decoration: underline;
    }
`;
const LeftTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    min-width: 0;

    &:hover ${EpisodeTitle} {
        text-decoration: underline;
    }
`;
const EpisodeMetaData = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: var(--fs-body-m);
`;
const MetaData = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--neutral500);
`;
const Right = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    gap: 1rem;
    align-items: center;
`;
const PlayControlOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--r-s);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;
const PlayButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: var(--white);
`;
const PsoChecker = styled.div<{ $color: string }>`
    display: flex;
    align-items: center;
    font-weight: var(--fw-semibold);
    gap: 0.25rem;
    color: var(${({ $color }) => $color});
    background-color: var(${({ $color }) => $color}50);
    padding: 0.5rem;
    border-radius: var(--r-s);
`;

export default Episode;
