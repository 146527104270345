import Text from '@/components/ui/atoms/Text';
import { MinimalShow } from '@/queries/show/useShowsQuery.hook';
import { IMG_PLACEHOLDER, USER_ROLES } from '@/shared/config/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListBoxItem } from 'react-aria-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

const ROLE_TRANSLATIONS = defineMessages({
    [USER_ROLES.VIEWER]: {
        defaultMessage: 'Lecteur',
    },
    [USER_ROLES.EDITOR]: {
        defaultMessage: 'Éditeur',
    },
    [USER_ROLES.ADMIN]: {
        defaultMessage: 'Administrateur',
    },
});

interface ShowsListItemProps {
    show: MinimalShow;
    layout: 'stack' | 'grid';
}

const ShowsListItem = ({ show, layout }: ShowsListItemProps) => {
    return (
        <Item textValue={show.name} $layout={layout} id={show.id.toString()}>
            <CoverWrapper>
                <Cover $isArchived={show.archived} src={show.imageUrl ?? IMG_PLACEHOLDER} />
                <CoverOverlay>
                    <GridCheckIcon icon={icon({ name: 'check', style: 'solid' })} />
                    <GridHoverIcon icon={icon({ name: 'eye', style: 'solid' })} />
                </CoverOverlay>
            </CoverWrapper>
            {layout === 'stack' && (
                <>
                    <ShowInfo>
                        <Text fontWeight="--fw-semibold">{show.name}</Text>
                        <UserRole>
                            {show?.currentUserRole === USER_ROLES.OWNER ? (
                                <FormattedMessage defaultMessage="Propriétaire" />
                            ) : (
                                <>
                                    <FormattedMessage defaultMessage="Invité" />
                                    <Circle />
                                    <FormattedMessage
                                        {...ROLE_TRANSLATIONS[
                                            show?.currentUserRole as keyof typeof ROLE_TRANSLATIONS
                                        ]}
                                    />
                                </>
                            )}
                        </UserRole>
                    </ShowInfo>
                    <CheckIcon icon={icon({ name: 'check', style: 'solid' })} />
                </>
            )}
        </Item>
    );
};

const CoverOverlay = styled.div`
    display: none;
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    transition: ease-out 250ms;
`;
const CoverWrapper = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-s);
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
`;
const Cover = styled.img<{ $isArchived: boolean }>`
    width: 100%;
    height: 100%;

    ${({ $isArchived }) => $isArchived && `filter: grayscale(100%);`}
`;
const ShowInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    flex-grow: 1;
    min-width: 0;

    & > :first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
const CheckIcon = styled(FontAwesomeIcon)`
    display: none;
    width: 0.75rem;
    height: 0.75rem;
    color: var(--primary500);
    align-self: center;
`;
const GridIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--white);
`;
const GridCheckIcon = styled(GridIcon)`
    display: none;
`;
const GridHoverIcon = styled(GridIcon)`
    display: none;
`;
const Item = styled(ListBoxItem)<{ $layout: 'stack' | 'grid' }>`
    border-radius: var(--r-s);
    cursor: pointer;

    ${({ $layout }) =>
        $layout === 'stack' &&
        css`
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            padding-block: 0.25rem;
            padding-inline: 0.5rem;

            &[data-hovered]:not([data-selected]) {
                background-color: var(--neutral50);
            }
        `}

    &[data-selected] {
        background-color: var(--primary50);

        & > ${CheckIcon} {
            display: block;
        }

        ${({ $layout }) =>
            $layout === 'grid' &&
            css`
                & > ${CoverWrapper} > ${CoverOverlay} {
                    display: flex;

                    & > ${GridCheckIcon} {
                        display: block;
                    }
                }
            `}
    }

    ${({ $layout }) =>
        $layout === 'grid' &&
        css`
            &[data-hovered]:not([data-selected]) {
                & > ${CoverWrapper} > ${CoverOverlay} {
                    display: flex;

                    & > ${GridHoverIcon} {
                        display: block;
                    }
                }
            }

            & > ${CoverWrapper} {
                width: 100%;
                height: 100%;
            }
        `}

    &[data-focused] {
        outline: solid 2px var(--primary50);
    }
`;

const UserRole = styled(Text)`
    font-size: var(--fs-body-s);
    color: var(--neutral500);
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const Circle = styled.div`
    width: 0.25rem;
    height: 0.25rem;
    border-radius: var(--r-full);
    background-color: var(--neutral200);
`;

export default ShowsListItem;
