const isFloat = (n: any): boolean => {
    return Number(n) === n && n % 1 !== 0;
};

export const isNumber = (n: any): boolean => {
    return Number.isSafeInteger(n) || isFloat(n);
};

export const roundToNearestHalf = (n: number): number => {
    return Math.round(n * 2) / 2;
};

export const priceValueToInt = (str) => {
    if (!str) return 0;
    return typeof str === 'string' ? parseInt(str.replace(/[^0-9]+/, ''), 10) : str;
};

export function round(value) {
    // Skip the decimal part to check if a specific rounding is required
    const int = Math.floor(value);

    if (int < 1) {
        // Round keeping one decimal (0.734 -> 0.7)
        return Math.round(value * 10) / 10;
    }

    // Round keeping no decimal (12.734 -> 13)
    return Math.round(value);
}
