import MobileDropdown from './MobileDropdown';
import DesktopDropdown from './DesktopDropdown';
import { useResponsive } from '@/shared/hooks/useResponsive';

interface ShowsDropdownProps {
    triggerRef: React.RefObject<HTMLButtonElement>;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    children: React.ReactNode;
}

const ShowsDropdown = ({ triggerRef, ...props }: ShowsDropdownProps) => {
    const { isDesktop } = useResponsive();

    if (isDesktop) {
        return <DesktopDropdown triggerRef={triggerRef} {...props} />;
    }

    return <MobileDropdown {...props} />;
};

export default ShowsDropdown;
