export const getDirtyValues = (dirtyFields, allValues) => {
    // NOTE: Recursive function.

    // If *any* item in an array was modified, the entire array must be submitted,
    // because there's no way to indicate "placeholders" for unchanged elements.
    // `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }

    // Here, we have an object.
    return Object.fromEntries(
        Object.entries(dirtyFields).map(([key, value]) => [
            key,
            typeof value === 'object' ? getDirtyValues(value, allValues[key]) : allValues[key],
        ]),
    );
};
