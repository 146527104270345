import { useRef, useState } from 'react';
import Stack from '@ui/layout/Stack';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import useRegisterFormSchema, { RegisterForm } from './useRegisterFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import Button from '@/components/Button';
import useRegisterMutation from '@/queries/auth/useRegisterMutation.hook';
import useCheckReferralCodeQuery from '@/queries/auth/useCheckReferralLinkQuery.hook';
import useQuery from '@/shared/hooks/useQuery';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AxiosError } from 'axios';
import ExternalLink from '@/components/Link/ExternalLink';
import Alert from '@ui/atoms/Alert';
// @ts-ignore
import { IntercomAPI } from 'react-intercom';
import styled from 'styled-components';

const isProduction = process.env.NODE_ENV !== 'development';
const generalConditions = {
    fr: 'https://fr.ausha.co/cgu/',
    en: 'https://www.ausha.co/terms/',
};
const language: 'fr' | 'en' = navigator.language.split('-')[0] as 'fr' | 'en';
const guideLink = generalConditions[language || 'en'];

const Form = () => {
    const intl = useIntl();
    const captchaRef = useRef<ReCAPTCHA | null>(null);
    const registerMutation = useRegisterMutation();
    const params = useQuery();
    const isInvitation = params.get('email') && params.get('show') && params.get('token');
    const email = params.get('email');
    const defaultEmail = email?.replace(/ /g, '+'); // Replace spaces by + in email to avoid issues with email validation
    const token = params.get('token');
    const show = params.get('show');

    const [showReferalCodeInput, setShowReferralCodeInput] = useState(false);
    const [isVerifying, setVerify] = useState(false);
    const [isRegisterFailed, setRegisterFailed] = useState(false);

    const schema = useRegisterFormSchema();
    const { control, handleSubmit, formState, clearErrors, setError, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            firstname: '',
            lastname: '',
            email: defaultEmail || '',
            password: '',
            referralCode: '',
        },
        resolver: zodResolver(schema),
    });

    const {
        data: referralCodeCheck,
        isLoading: referralCodeCheckIsLoading,
        isError: referralCodeCheckIsError,
    } = useCheckReferralCodeQuery({
        code: watch('referralCode'),
    });

    const onSubmit = async (payload: RegisterForm) => {
        let recaptchaToken = null;
        if (isProduction) {
            recaptchaToken = await captchaRef.current?.executeAsync();
        }

        registerMutation.mutate(
            {
                payload,
                recaptcha: recaptchaToken || null,
                language: navigator.language.split('-')[0],
                invitation_token: token,
                invitation_show: show,
            },
            {
                onError: (error) => {
                    const typedError = error as AxiosError;
                    if (typedError.response?.status === 422) {
                        setError('email', {
                            type: '422',
                            message: intl.formatMessage({
                                defaultMessage: 'Cette adresse e-mail existe déjà',
                            }),
                        });
                        return;
                    }
                    if (typedError.response?.status === 403) {
                        setRegisterFailed(true);
                        return;
                    }

                    throw error;
                },
                onSettled: () => {
                    setVerify(false);
                },
            },
        );
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            {process.env.RECAPTCHA_KEY && isProduction && (
                <ReCAPTCHA sitekey={process.env.RECAPTCHA_KEY} size="invisible" ref={captchaRef} />
            )}
            <Stack $gap="2rem">
                <Stack $gap="1.5rem">
                    <NameInputWrapper>
                        <InputTextController
                            control={control}
                            name="firstname"
                            id="register_firstname"
                            label={<FormattedMessage defaultMessage="Prénom" />}
                            placeholder={intl.formatMessage({ defaultMessage: 'Thomas' })}
                            errorMessage={formState.errors.firstname?.message}
                            onBlur={() => clearErrors('firstname')}
                            data-testid="register-firstname-input"
                        />
                        <InputTextController
                            control={control}
                            name="lastname"
                            id="register_lastname"
                            label={<FormattedMessage defaultMessage="Nom" />}
                            placeholder={intl.formatMessage({ defaultMessage: 'Pesquet' })}
                            errorMessage={formState.errors.lastname?.message}
                            onBlur={() => clearErrors('lastname')}
                            data-testid="register-lastname-input"
                        />
                    </NameInputWrapper>
                    <InputTextController
                        control={control}
                        name="email"
                        id="register_email"
                        label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'thomas.pesquet@esa.com',
                        })}
                        errorMessage={formState.errors.email?.message}
                        onBlur={() => clearErrors('email')}
                        isDisabled={isInvitation && defaultEmail}
                        data-testid="register-email-input"
                    />
                    <InputTextController
                        control={control}
                        name="password"
                        id="register_password"
                        type="password"
                        label={<FormattedMessage defaultMessage="Mot de passe" />}
                        placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                        errorMessage={formState.errors.password?.message}
                        onBlur={() => clearErrors('password')}
                        data-testid="register-password-input"
                    />
                </Stack>
                {showReferalCodeInput ? (
                    <InputTextController
                        control={control}
                        name="referralCode"
                        id="register_referer"
                        label={<FormattedMessage defaultMessage="Code de parrainage" />}
                        errorMessage={
                            watch('referralCode') !== '' &&
                            referralCodeCheckIsError &&
                            !referralCodeCheckIsLoading && (
                                <FormattedMessage defaultMessage="Ce code de parrainage est incorrect." />
                            )
                        }
                        onBlur={() => {
                            clearErrors('referralCode');
                        }}
                        inputIcon={
                            watch('referralCode') === '' ? null : referralCodeCheckIsLoading ? (
                                <FontAwesomeIcon
                                    icon={icon({ name: 'spinner-third', style: 'solid' })}
                                    spin
                                />
                            ) : referralCodeCheck ? (
                                <CheckedIcon
                                    icon={icon({ name: 'circle-check', style: 'solid' })}
                                />
                            ) : (
                                <ErrorIcon
                                    icon={icon({ name: 'circle-exclamation', style: 'solid' })}
                                />
                            )
                        }
                        isOptional
                        data-testid="register-referral-code-input"
                    />
                ) : (
                    <AddReferralCodeButton
                        variant="ghost"
                        startIcon={
                            <FontAwesomeIcon icon={icon({ name: 'plus', style: 'solid' })} />
                        }
                        onPress={() => setShowReferralCodeInput(true)}
                        data-testid="register-referral-code-add-button"
                    >
                        <FormattedMessage defaultMessage="Ajouter un code de parrainage" />
                    </AddReferralCodeButton>
                )}
                <span>
                    <FormattedMessage
                        defaultMessage="En vous inscrivant vous approuvez les {link}"
                        values={{
                            link: (
                                <ExternalLink
                                    href={guideLink}
                                    target="_blank"
                                    data-testid="register-tos-link"
                                >
                                    <FormattedMessage defaultMessage="conditions générales d'utilisation." />
                                </ExternalLink>
                            ),
                        }}
                    />
                </span>
                {isRegisterFailed && (
                    <Alert variant="alert">
                        <FormattedMessage
                            defaultMessage="L'inscription n'a pas pu être finalisée, merci de <intercom>nous contacter</intercom>."
                            values={{
                                intercom: (chunks: React.ReactNode) => (
                                    <Button onPress={() => IntercomAPI('show')} variant="ghost">
                                        {chunks}
                                    </Button>
                                ),
                            }}
                        />
                    </Alert>
                )}
                <Button
                    type="submit"
                    isLoading={registerMutation.isLoading || isVerifying}
                    isDisabled={
                        !formState.isDirty ||
                        !formState.isValid ||
                        isVerifying ||
                        registerMutation.isLoading
                    }
                    data-testid="register-submit"
                >
                    <FormattedMessage defaultMessage="Créer mon compte" />
                </Button>
            </Stack>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
`;
const NameInputWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const CheckedIcon = styled(FontAwesomeIcon)`
    color: var(--success);
`;
const ErrorIcon = styled(FontAwesomeIcon)`
    color: var(--alert);
`;
const AddReferralCodeButton = styled(Button)`
    padding: 0;
    align-self: flex-start;
    color: var(--primary);
`;

export default Form;
