import Stack from '@ui/layout/Stack';
import EpisodeEditFormOptionsSeasonField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsSeasonField';
import EpisodeEditFormOptionsTypeField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsTypeField';
import EpisodeEditFormOptionsExplicitContentField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsExplicitContentField';
import EpisodeEditFormOptionsPublishOnYoutubeField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsPublishOnYoutubeField';
import EpisodeEditFormOptionsYoutubeTitleField from './EpisodeEditFormOptionsYoutubeTitleField';
import EpisodeEditFormOptionsYoutubeThumbnailField from './EpisodeEditFormOptionsYoutubeThumbnailField';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import EpisodeEditFormOptionsPublishOnSoundcloudField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsPublishOnSoundcloudField';
import NextStepButton from '@/components/unorganized/EpisodeEditForm/NextStepButton';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import usePricing from '@/shared/hooks/usePricing.hook';
import { PRICING } from '@/shared/config/pricing';

const EpisodeEditFormOptions = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { watch } = useFormContext();
    const pricing = usePricing(showId);
    const isFreemiumOrLaunch = pricing === PRICING.FREEMIUM || pricing === PRICING.LAUNCH;
    /* Skip check for freemium or launch to display blocking state */
    const isYoutubeAllowed =
        isFreemiumOrLaunch ||
        (show?.userOptions?.includes(USER_OPTIONS.BROADCAST_YOUTUBE) ?? false);
    const isSoundcloudAllowed =
        isFreemiumOrLaunch || (show?.userOptions?.includes(USER_OPTIONS.SOUNDCLOUD) ?? false);

    return (
        <Stack $gap="2rem">
            <EpisodeEditFormOptionsSeasonField />
            <EpisodeEditFormOptionsTypeField />
            <EpisodeEditFormOptionsExplicitContentField />
            <EpisodeEditFormOptionsPublishOnYoutubeField isAllowed={isYoutubeAllowed} />
            {watch('options.publishYoutube') && (
                <>
                    <EpisodeEditFormOptionsYoutubeTitleField />
                    <EpisodeEditFormOptionsYoutubeThumbnailField />
                </>
            )}
            <EpisodeEditFormOptionsPublishOnSoundcloudField isAllowed={isSoundcloudAllowed} />
            <NextStepButton />
        </Stack>
    );
};

export default EpisodeEditFormOptions;
