import Text from '@/components/ui/atoms/Text';
import DeleteShow from '@/features/DeleteShow';
import ArchiveShow from '@/features/ArchiveShow';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ArchiveAndDeleteShowPage = () => {
    return (
        <Wrapper>
            <Header>
                <Title>
                    <Icon icon={icon({ name: 'ban', style: 'solid' })} />
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Archiver ou Supprimer" />
                    </Text>
                </Title>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Archivez ou supprimez votre podcast, assurez-vous d'être bien sûr de cette décision ! ⚠️" />
                </Text>
            </Header>
            <Section>
                <Text color="--neutral500" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Archiver" />
                </Text>
                <ArchiveShow />
            </Section>
            <Section>
                <Text color="--neutral500" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Supprimer" />
                </Text>
                <DeleteShow />
            </Section>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const Header = styled.div`
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const Section = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

export default ArchiveAndDeleteShowPage;
